import React, { useEffect, useState } from "react";
import MainInput from "../../../../MainInput";
import { useTranslation } from "react-i18next";
import toastPopup from "../../../../../helpers/toastPopup";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../../../../locales/i18n";
import VendorServices from "../../../../../services/VendorServices";
import ImagesUpload from "../../../../ImagesUpload/ImagesUpload";
import Multiselect from "multiselect-react-dropdown";
import "./ProductEdit.scss";

export default function ProductEdit() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [vendorProductCategories, setVendorProductCategories] = useState([]);
  const [isActiveVendor, setIsActiveVendor] = useState(true);
  const [imageUrl, setImageUrl] = useState("")
  const [productImages, setProductImages] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [imageToBeDeleted, setImageToBeDeleted] = useState("");
  const [uploadImages, setUploadImages] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([])
  const [branches, setBranches] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  let params = useParams();

  const [product, setProduct] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    price: 0,
    originalPrice: 0,
    isActive: false,
    isHotDeal: false,
    isLimited: false,
    isEditable: false,
  });

  const [newProduct, setNewProduct] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    price: 0,
    originalPrice: 0,
    isActive: false,
    isHotDeal: false,
    isLimited: false,
    isEditable: false,
    category: "",
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "description_en", type: "text", required: false },
    { name: "description_ar", type: "text", required: false },
    { name: "price", type: "number", required: false },
    { name: "originalPrice", type: "number", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getProduct() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getProduct(params.productID);
      setLoading(false);
      setProduct({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        description_en: data?.record[0]?.description?.en,
        description_ar: data?.record[0]?.description?.ar,
        price: data?.record[0]?.price,
        originalPrice: data?.record[0]?.originalPrice,
        isActive: data?.record[0]?.isActive,
        isHotDeal: data?.record[0]?.isHotDeal,
        isLimited: data?.record[0]?.isLimited,
        isEditable: data?.record[0]?.isEditable,
        category: data?.record[0]?.category._id,
      });

      setNewProduct({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        description_en: data?.record[0]?.description?.en,
        description_ar: data?.record[0]?.description?.ar,
        price: data?.record[0]?.price,
        originalPrice: data?.record[0]?.originalPrice,
        isActive: data?.record[0]?.isActive,
        isHotDeal: data?.record[0]?.isHotDeal,
        isLimited: data?.record[0]?.isLimited,
        isEditable: data?.record[0]?.isEditable,
        category: data?.record[0]?.category._id,
      });
      setProductImages(data.record[0].image)

      setSelectedBranches(data?.record[0]?.branches.map((branch) => {
        return {
          id: branch?._id,
          name: branch?.name?.[lang]
        }
      }))
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editProduct(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newProduct, product)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newProduct["name_en"], ar: newProduct["name_ar"] },
        };
      } else if (key === "description_en" || key === "description_ar") {
        editedData = {
          ...editedData,
          description: {
            en: newProduct["description_en"],
            ar: newProduct["description_ar"],
          },
        };
      } else {
        editedData = {
          ...editedData,
          [key]: newProduct[key],
        };
      }
    });

    let finalProductBranches = selectedBranches.map((branch) => {
      return {
        _id: branch.id
      }
    })

    editedData = { ...editedData, branches: finalProductBranches }

    try {
      const { data } = await VendorServices.editProduct(
        params.productID,
        editedData
      );
      setLoading(false);
      toastPopup.success(t("Updated successfully"));

      var formData = new FormData();
      uploadImages.forEach((image) => {
        formData.append('image', image.file);
      });
      setLoading(true);
      try {
        const { data } =
          await VendorServices.addProductImage(params.productID, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(
        `/vendors/${params.id}/branchdetails/${params.branchID}/productdetails/${params.productID}`
      );
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function getVendor() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendor(params.id);
      setLoading(false);
      setVendorProductCategories(data?.record[0]?.productCategories);
      setIsActiveVendor(data?.record[0]?.isActive)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function getImage(image) {
    setImageUrl(image);
  }

  function onDleteFromCurrent(image) {
    setImageToBeDeleted(image)
    setModalShow(true)
  }

  async function deleteImage() {
    setLoading(true);
    try {
      const { data } = await VendorServices.removeProductImage(params.productID,
        {
          data: {
            "keys": [
              imageToBeDeleted.key
            ]
          }
        });
      setLoading(false);
      setModalShow(false);
      getProduct();
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      console.log(e);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getBranches() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendorActiveBranches(params.id);
      setLoading(false);
      setBranches(data.records);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let branchesOptions = branches.map((branch) => {
    return ({
      name: branch?.name?.[lang],
      id: branch._id
    })
  })

  function isSelectedBranch(branchId) {
    let selectedBranchesIds = selectedBranches.map((selectedBranch) => selectedBranch.id)
    return selectedBranchesIds.includes(branchId)
  }

  function toggleSelectedBranchesHandler(branch) {
    if (isSelectedBranch(branch.id)) {
      let oldSelectedBranches = selectedBranches
      let newSelectedBranches = oldSelectedBranches.filter((singleBranch) => { return singleBranch.id !== branch.id })
      setSelectedBranches(newSelectedBranches)
    } else {
      setSelectedBranches((prev) => {
        return [...prev, branch]
      })
    }
  }

  useEffect(() => {
    getProduct();
    getVendor()
    getBranches()
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete this image?")}</h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteImage()}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(
                `/vendors/${params.id}/branchdetails/${params.branchID}/productdetails/${params.productID}`
              );
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Product Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <ImagesUpload
          type="edit"
          viewList={productImages}
          getImage={getImage}
          onDleteFromCurrent={onDleteFromCurrent}
          uploadedImagesList={uploadImages}
          setUploadedImagesList={setUploadImages}
        />

        <form className="form-grid" onSubmit={editProduct}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newProduct}
                  setState={setNewProduct}
                />
              );
            })}
            <div className="main-input-select">
              <select
                value={newProduct.category}
                onChange={(e) => {
                  setNewProduct((prev) => {
                    return { ...prev, category: e.target.value };
                  });
                }}
                className="main-input"
                id="Category"
                name="Category"
                title="Category"
              >
                <option defaultValue="category" disabled>
                  -- {t("Category")} --
                </option>
                {vendorProductCategories?.map((cat, index) => {
                  return (
                    <option key={index} value={cat?._id}>
                      {(cat?.name?.[lang])}
                    </option>
                  );
                })}
              </select>
              <label className="main-label">{t("Category")}</label>
            </div>
            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Hot Deal")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newProduct.isHotDeal}
                  onChange={(e) => {
                    setNewProduct((prev) => {
                      return { ...prev, isHotDeal: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>

            {isActiveVendor && <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newProduct.isActive}
                  onChange={(e) => {
                    setNewProduct((prev) => {
                      return { ...prev, isActive: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>}

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Limited")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newProduct.isLimited}
                  onChange={(e) => {
                    setNewProduct((prev) => {
                      return { ...prev, isLimited: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Editable")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newProduct.isEditable}
                  onChange={(e) => {
                    setNewProduct((prev) => {
                      return { ...prev, isEditable: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>

            <Multiselect
              displayValue="name"
              selectedValues={selectedBranches}
              onKeyPressFn={function noRefCheck() { }}
              onRemove={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedBranchesHandler({ id: selectedItem.id, name: selectedItem.name })
              }}
              onSearch={function noRefCheck() { }}
              onSelect={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedBranchesHandler({ id: selectedItem.id, name: selectedItem.name })
              }}
              options={branchesOptions}
              showCheckbox
              placeholder={t("Product Category")}
            />

          </div>
          <div className="button-grid">
            <button
              className="main-button-admin"
              onClick={() => {
                navigate(
                  `/vendors/${params.id}/branchdetails/${params.branchID}/productdetails/${params.productID}`
                );
              }}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Cancel")
              )}
            </button>
            <button className="main-button-admin">
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
