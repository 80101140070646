import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../../locales/i18n';
import SubscriptionServices from '../../../../services/SubscriptionServices';
import ClientServices from '../../../../services/ClientServices';
import toastPopup from '../../../../helpers/toastPopup';
import InfoInput from '../../../../Components/InfoInput/InfoInput';
import './RejectedSubscriptionDetails.scss'

export default function RejectedSubscriptionDetails() {

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18n.language

  const [subscription, setSubscription] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [isZoomedImage, setIsZoomedImage] = useState(false);

  async function getSubscription() {
    setLoading(true);
    try {
      const { data } = await SubscriptionServices.getSubscription(params.subscriptionID);
      setLoading(false);
      setSubscription(data.record[0]);
      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let clientName = ""
  if (localStorage.getItem("i18nextLng") === "en" && subscription?.client?.name?.en) {
    clientName = subscription?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !subscription?.client?.name?.en && subscription?.client?.name?.ar) {
    clientName = subscription?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && subscription?.client?.name?.ar) {
    clientName = subscription?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !subscription?.client?.name?.ar && subscription?.client?.name?.en) {
    clientName = subscription?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !subscription?.client?.name?.en && !subscription?.client?.name?.ar) {
    clientName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !subscription?.client?.name?.ar && !subscription?.client?.name?.en) {
    clientName = "لا يوجد أسم"
  }

  let subscriptionDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: clientName,
    },
    {
      id: "date",
      title: "Date",
      type: "text",
      value: formatDate(new Date(subscription?.date)),
    },
    {
      id: "walletNumber",
      title: "Wallet number",
      type: "text",
      value: subscription?.walletNumber,
    },
    {
      id: "subscriptionMethod",
      title: "Subscription method",
      type: "text",
      value: subscription?.subscriptionMethod,
    },
  ];

  async function onApprove() {

    const currentDate = new Date(Date.now());
    const currentDateISO = new Date(currentDate).toISOString();
    const nextYear = currentDate.setFullYear(currentDate.getFullYear() + 1);
    const nextYearISO = new Date(nextYear).toISOString();

    setLoading(true);
    try {
      const { data } = await ClientServices.editClient(params.subscriptionID, {
        isSubscribed: true,
        isActive: true,
        subStartDate: currentDateISO,
        subEndDate: nextYearISO,
        isPending: false
      });
      setLoading(false);
      try {
        const { data } = await SubscriptionServices.editSubscription(subscription?._id, {
          status: 'approved'
        });
        setLoading(true);
      } catch (e) {
        setLoading(false);
        setErrorMessage("Unexpected Error happened. Please try again later");
      }
      toastPopup.success(t("Subscribe successfully"))
      navigate(`/clients/${params.subscriptionID}`)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <>

      {isZoomedImage && (
        <div
          className={isZoomedImage ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <img
              src={subscription.image.Location}
              alt="Admin Image" />
          </div>
          <div className="cancel" onClick={() => setIsZoomedImage(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="category-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate("/subscriptions");
                }}
              ></i>
            </div>
            <div className="client-image subscription-image-details">
              {image ? (
                <img
                  src={subscription.image.Location}
                  alt="Admin Image"
                  onClick={() => { setIsZoomedImage(true) }} />
              ) : (
                <img
                  src={
                    "https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/790027/grocery-store-clipart-md.png"
                  }
                  alt="Admin Image"
                />
              )}
            </div>
            <div className="client-datails">
              <div className="actions">
                <button className='approve' disabled={loading} onClick={onApprove}>
                  {loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    t("Approve")
                  )}</button>
              </div>
              <div className="data">
                {subscriptionDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
