import React, { useEffect, useState } from "react";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import { useTranslation } from "react-i18next";
import toastPopup from "../../helpers/toastPopup";
import { useNavigate } from "react-router-dom";
import VendorServices from "../../services/VendorServices";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import i18n from "../../locales/i18n";
import { useSelector } from "react-redux";
import "./Vendor.scss";

export default function Vendor() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { vendor: vendorPermissions } = frontPermissions;

  let canDeleteVendor = vendorPermissions.canDelete;
  let canAddVendor = vendorPermissions.canCreate;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [activeVendor, setActiveVendor] = useState([]);
  const [totalActiveVendors, setTotalActiveVendors] = useState(0);
  const [inActiveVendor, setInActiveVendor] = useState([]);
  const [totalInActiveVendors, setTotalInActiveVendors] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");
  const [isActiveVendor, setIsActiveVendor] = useState(true)
  const [isInActiveVendor, setIsInActiveVendor] = useState(false)
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState("");

  async function getAllActiveVendors() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await VendorServices.listAllActiveVendors(
        lang,
        searchActiveValue,
        filterValue === 'All' ? '' : filterValue,
        activeCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let vendorName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          vendorName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          vendorName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          vendorName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          vendorName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          vendorName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          vendorName = "لا يوجد أسم"
        }
        let returnedObject = canDeleteVendor
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: vendorName,
            Email: record?.email,
            Phone: record?.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "vendor",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: vendorName,
            Email: record.email,
            Phone: record.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "vendor",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
          };
        return returnedObject;
      });

      setActiveVendor(vendor => {
        if ((filterValue && activeCurrentPage === 1) || (searchActiveValue && activeCurrentPage === 1) || (isSorted && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...vendor, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveVendors(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInActiveVendors() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await VendorServices.listAllInActiveVendors(
        lang,
        searchInActiveValue,
        filterValue === 'All' ? '' : filterValue,
        inActiveCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let vendorName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          vendorName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          vendorName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          vendorName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          vendorName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          vendorName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          vendorName = "لا يوجد أسم"
        }
        let returnedObject = canDeleteVendor
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: vendorName,
            Email: record.email,
            Phone: record.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: vendorName,
            Email: record.email,
            Phone: record.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
          };
        return returnedObject;
      });

      setInActiveVendor(vendor => {
        if ((filterValue && inActiveCurrentPage === 1) || (searchInActiveValue && inActiveCurrentPage === 1) || (isSorted && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...vendor, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveVendors(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteVendor(id) {
    setLoading(true);
    try {
      const { data } = await VendorServices.deleteVendor(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getVendorCategories() {
    try {
      const { data } = await VendorServices.getVendorCategories("vendor");
      setCategories(data?.records);
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleVendorRowClick(id) {
    navigate(`/vendors/${id}`);
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (isActiveVendor) {
      getAllActiveVendors();
      setInActiveVendor([])
    }
  }, [
    lang,
    searchActiveValue,
    filterValue,
    activeCurrentPage,
    isActiveVendor,
    isSorted
  ]);

  useEffect(() => {
    if (isInActiveVendor) {
      getAllInActiveVendors();
      setActiveVendor([])
    }
  }, [
    lang,
    searchInActiveValue,
    filterValue,
    inActiveCurrentPage,
    isInActiveVendor,
    isSorted
  ]);

  useEffect(() => {
    getVendorCategories();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteVendor(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveVendor ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveVendor(true); setIsInActiveVendor(false) }}
                className='all-branches-btn'>
                <h2>{t("Active Vendors")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveVendor(true); setIsInActiveVendor(false) }}
              className='all-branches-btn'>
              <h2>{t("Active Vendors")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveVendor ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveVendor(false); setIsInActiveVendor(true) }}
                className='all-branches-btn'>
                <h2>{t("Inactive Vendors")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveVendor(false); setIsInActiveVendor(true) }}
              className='all-branches-btn'>
              <h2>{t("Inactive Vendors")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveVendor ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Active Vendors"}
                text2={"Active Vendors"}
                total={totalActiveVendors}
                showBtn={canAddVendor}
                btnText="Add Vendor"
                onBtnClick={() => {
                  navigate("/vendors/addvendor");
                }}
              />
              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setActiveCurrentPage(1);
                      setSearchActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>
              <div className="filter">
                <div className="items">
                  <div>
                    <input
                      defaultChecked
                      value="All"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="all"
                    />
                    <label htmlFor="all">{t("All")}</label>
                  </div>

                  {categories?.map((category, index) => {
                    return (
                      <div key={index}>
                        <input
                          value={category?._id}
                          onClick={(e) => {
                            setActiveCurrentPage(1);
                            setFilterValue(e.target.value);
                          }}
                          type="radio"
                          name="filter"
                          id={category?.name?.en}
                        />
                        <label htmlFor={category?.name?.en}>
                          {category?.name?.[lang]}
                        </label>
                      </div>
                    );
                  })}

                  <div className="alpha">
                    <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
                  </div>
                </div>
              </div>
              {isSearchEmpty || totalActiveVendors === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleVendorRowClick} tableRows={activeVendor} />
              )}
              {
                activeVendor.length !== totalActiveVendors &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveVendor ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Inactive Vendors"}
                text2={"Inactive Vendors"}
                total={totalInActiveVendors}
              />
              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setInActiveCurrentPage(1);
                      setSearchInActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>
              <div className="filter">
                <div className="items">
                  <div>
                    <input
                      defaultChecked
                      value="All"
                      onClick={(e) => {
                        setInActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="all"
                    />
                    <label htmlFor="all">{t("All")}</label>
                  </div>

                  {categories?.map((category, index) => {
                    return (
                      <div key={index}>
                        <input
                          value={category?._id}
                          onClick={(e) => {
                            setInActiveCurrentPage(1);
                            setFilterValue(e.target.value);
                          }}
                          type="radio"
                          name="filter"
                          id={category?.name?.en}
                        />
                        <label htmlFor={category?.name?.en}>
                          {category?.name?.[lang]}
                        </label>
                      </div>
                    );
                  })}

                  <div className="alpha">
                    <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
                  </div>
                </div>
              </div>
              {isSearchEmpty || totalInActiveVendors === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleVendorRowClick} tableRows={inActiveVendor} />
              )}
              {
                inActiveVendor.length !== totalInActiveVendors &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
