import React, { useEffect, useRef, useState } from 'react'
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { socket } from '../../../services/socket'
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import toastPopup from '../../../helpers/toastPopup';
import i18n from '../../../locales/i18n';
import VendorServices from '../../../services/VendorServices';
import { useNavigate } from 'react-router-dom';
import './Chat.scss'

export default function Chat(props) {

  const chatRef = useRef(null);
  const { t } = useTranslation();
  const lang = i18n.language
  const navigate = useNavigate()

  const [message, setMessage] = useState('')
  const [otherChatterImage, setOtherChatterImage] = useState('')
  const [otherChatterName, setOtherChatterName] = useState('')
  const [chatData, setChatData] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");

  function sendMessage() {
    if (message !== "") {
      if (message.trim() !== "") {
        let decodedToken = jwtDecode(localStorage.getItem('AdminToken'))
        const messageData = {
          "_id": props?.ChatId,
          "message": {
            "admin": decodedToken?._id,
            "text": message,
            "timestamp": new Date().toISOString()
          }
        };

        socket.emit("sendMessageToRoom", messageData);
        setMessage('');
      }
    }
  }

  function blockChat() {
    socket.emit("blockRoom", { _id: props.ChatId }, (data) => {
      if (data.success) {
        setIsBlocked(true)
        setModalShow(false)
        toastPopup.success(t("Blocked successfully"));
      }
    });
  }

  function unblockChat() {
    socket.emit("unblockRoom", { _id: props.ChatId }, (data) => {
      if (data.success) {
        setIsBlocked(false)
        setModalShow(false)
        toastPopup.success(t("Unblocked successfully"));
      }
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    }
  };

  async function getUserInfo() {
    // vendor branch client agent org
    const otherChatter = Object.keys(props?.chatInfo?.members).filter((item) => item !== 'admin')[0];
    if (otherChatter === 'vendor') {
      navigate(`/vendors/${props?.chatInfo?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'branch') {
      let vendorId = ""
      try {
        const { data } = await VendorServices.getBranchData(
          props?.chatInfo?.members?.[otherChatter]?._id
        );
        vendorId = data?.record[0]?.vendor?._id
      } catch (e) {
        setErrorMessage("Unexpected Error happened. Please try again later");
      }
      navigate(`/vendors/${vendorId}/branchdetails/${props?.chatInfo?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'client') {
      navigate(`/clients/${props?.chatInfo?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'agent') {
      navigate(`/agents/${props?.chatInfo?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'org') {
      navigate(`/organizations/${props?.chatInfo?.members?.[otherChatter]?._id}`);
    }
  }

  useEffect(() => {
    if (chatRef && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatData]);

  useEffect(() => {
    if (props.ChatId !== '') {
      socket.emit("getRoom", {
        "_id": props?.ChatId
      });
    }
  }, [props.ChatId])


  useEffect(() => {

    socket.on('connection', () => {
      console.log('Connected to server');
    });

    socket.connect();

    socket.on('getRoom', (data) => {
      setChatData(data?.record?.messages)
    })

    socket.on('sendMessageToRoom', (data) => {
      setChatData((list) => [...list, data?.message])
    })

    return () => {
      socket.disconnect();
    }
  }, [])

  useEffect(() => {
    setIsBlocked(props?.chatInfo?.isBlocked)
  }, [props?.chatInfo])

  useEffect(() => {
    if (props?.chatInfo?.members) {
      const otherChatter = Object?.keys(props?.chatInfo?.members)?.filter((item) => item !== 'admin')[0]
      setOtherChatterImage(props?.chatInfo?.members?.[otherChatter]?.image?.Location)
      setOtherChatterName(props?.chatInfo?.members?.[otherChatter]?.name)
    }
  }, [props?.chatInfo])

  const currentDate = new Date();
  const targetDate = new Date(props?.chatInfo?.lastUpdated);
  const timeDiff = currentDate.getTime() - targetDate.getTime();
  const daysBehind = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hoursBehind = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutesBehind = Math.floor(timeDiff / (1000 * 60));

  return <>
    {modalShow && (
      <div
        className={modalShow ? "overlay" : "overlay-remove-cancel"}
        id="overlay-remove"
      >
        <div className="add-admin-box">
          <h3>{isBlocked ? t("Are you sure you want to unblock chat?") : t("Are you sure you want to block chat?")}</h3>
          <div className="modal-buttons">
            <div
              onClick={() => setModalShow(false)}
              className="cancel-remove"
            >
              {t("Cancel")}
            </div>
            <div
              onClick={() => isBlocked ? unblockChat() : blockChat()}
              className="delete"
            >
              {isBlocked ? t("Unblock") : t("Block")}
            </div>
          </div>
        </div>
        <div className="cancel" onClick={() => setModalShow(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
      </div>
    )}

    {
      props?.ChatId ?
        <div className="chat-messages">
          <div className="chat-bar">
            <div className="chat-data" onClick={getUserInfo}>
              <div className="image">
                {otherChatterImage ?
                  <img src={otherChatterImage} alt="Image" /> :
                  <FontAwesomeIcon
                    icon={faUserTie}
                    size="xl"
                    className="text-slate-800 icon-avatar"
                  />
                }
              </div>
              <div className="name">
                <p>
                  {
                    localStorage.getItem("i18nextLng") === "en" && otherChatterName?.en ?
                      otherChatterName?.en?.slice(0, 30)
                      : localStorage.getItem("i18nextLng") === "en" && !otherChatterName?.en && otherChatterName?.ar ?
                        otherChatterName?.ar?.slice(0, 30)
                        : localStorage.getItem("i18nextLng") === "ar" && otherChatterName?.ar ?
                          otherChatterName?.ar?.slice(0, 30)
                          : localStorage.getItem("i18nextLng") === "ar" && !otherChatterName?.ar && otherChatterName?.en ?
                            otherChatterName?.en?.slice(0, 30)
                            : localStorage.getItem("i18nextLng") === "en" && !otherChatterName?.en && !otherChatterName?.ar ?
                              "No name"
                              : localStorage.getItem("i18nextLng") === "ar" && !otherChatterName?.ar && !otherChatterName?.en ?
                                "لا يوجد أسم" : ""
                  }</p>
                <p className='time'>{daysBehind === 0 ? hoursBehind === 0 ? `${minutesBehind} minutes ago` : `${hoursBehind} hours ago` : `${daysBehind} days ago`}</p>
              </div>
            </div>
            <div className="block">
              <button onClick={() => { setModalShow(true) }}>{isBlocked ? t("Unblock") : "Block"}</button>
            </div>
          </div>
          <div className="chat-body" ref={chatRef}>
            {chatData?.map((message) => {
              if (message?.admin) {
                return (
                  <div className="sender" key={message?._id}>
                    <div className="message">
                      {message?.text}
                      <div className="date">
                        {new Date(message?.timestamp).toLocaleDateString()} - {new Date(message?.timestamp).toLocaleTimeString()}
                      </div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className="receiver" key={message?._id}>
                    <div className="message">
                      {message?.text}
                      <div className="date">
                        {new Date(message?.timestamp).toLocaleDateString()} - {new Date(message?.timestamp).toLocaleTimeString()}
                      </div>
                    </div>
                  </div>
                )
              }
            })}

          </div>
          <div className="send-message">
            {isBlocked ?
              <p className='block-message'>{t("You can't send messages to this chat. Unblock chat if you want to send a message.")}</p> : <>
                <textarea
                  name="chat"
                  id="chat"
                  className='message-input'
                  placeholder='Type...'
                  onChange={(e) => { setMessage(e.target.value) }}
                  value={message}
                  onKeyDown={handleKeyDown}></textarea>
                <FontAwesomeIcon
                  size="xl"
                  icon={faPaperPlane}
                  className="send-message-icon"
                  onClick={sendMessage}
                /></>}
          </div>
        </div> : ""
    }
  </>
}
