import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SubscriptionServices from '../../../services/SubscriptionServices';
import MainCard from '../../../Components/MainCard/MainCard';
import MainTable from '../../../Components/MainTable/MainTable';
import './PendingSubscription.scss'

export default function PendingSubscription() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pendingSubscriptions, setPendingSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPendingSubscriptions, setTotalPendingSubscriptions] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [subscriptionMethod, setSubscriptionMethod] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllPendingSubscriptions() {
    try {
      const { data } = await SubscriptionServices.listAllPendingSubscriptions(
        subscriptionMethod === "Subscription method" ? "" : subscriptionMethod,
        currentPage
      );
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && !record?.client?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && !record?.client?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?.client?._id,
          Name: clientName,
          Date: formatDate(new Date(record?.date)),
          "Wallet number": record?.walletNumber,
          "Subscription method": record?.subscriptionMethod,
        }
      });

      setPendingSubscriptions(sub => {
        if ((subscriptionMethod && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...sub, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalPendingSubscriptions(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handlePendingSubscriptionRowClick(id) {
    navigate(`/subscriptions/pending/${id}`);
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getAllPendingSubscriptions();
  }, [subscriptionMethod, currentPage]);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Pending Subscriptions"}
            text2={"Pending Subscriptions"}
            total={totalPendingSubscriptions}
          />

          <div className="form-search">
            <div className="filter-by-container">
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSubscriptionMethod(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Subscription method">
                    {t("Subscription method")}
                  </option>
                  <option value={"wallet"}>{t("Wallet")}</option>
                  <option value={"visa"}>{t("Visa")}</option>
                </select>
              </div>
            </div>
          </div>

          {totalPendingSubscriptions === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handlePendingSubscriptionRowClick} tableRows={pendingSubscriptions} />
          )}
          {
            pendingSubscriptions.length !== totalPendingSubscriptions &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
