import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClientServices from "../../../../services/ClientServices";
import toastPopup from "../../../../helpers/toastPopup";
import i18n from "../../../../locales/i18n";
import { useParams } from "react-router-dom";
import Rating from "react-rating";
import { useSelector } from "react-redux";
import "./ClientReviews.scss";

export default function ClientReviews() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;
  let { review: reviewPermissions } = frontPermissions;

  const lang = i18n.language;
  const { t } = useTranslation();
  let params = useParams();

  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [clientReviews, setClientReviews] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  async function getClientReviews() {
    try {
      const { data } = await ClientServices.getClientReviews(params.id);
      setLoading(false);
      setClientReviews(data.records);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteReview(id) {
    setLoading(true);
    try {
      const { data } = await ClientServices.deleteClientReview(id);
      setLoading(false);
      setModalShow(false);
      getClientReviews();
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getClientReviews();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">{t("the review ?")}</p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => {
                  deleteReview(currentUser._id);
                }}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : clientReviews.length > 0 ? (
        clientReviews.map((review) => {
          return (
            <div className="reviews-card" key={review._id}>
              <div className="image">
                {review?.product?.image ? (
                  <img
                    src={review?.product?.image?.Location}
                    alt="Product Image"
                  />
                ) : review?.vendor?.image ? (
                  <img
                    src={review?.vendor?.image?.Location}
                    alt="Product Image"
                  />
                ) : (
                  <img
                    src="../../../../assets/images/img.png"
                    alt="Product Image"
                  />
                )}
              </div>
              <div className="data">
                <h3>
                  {review?.vendor?.name[lang]}{" "}
                  {review?.type === "product"
                    ? -review?.product?.name[lang]
                    : ""}
                </h3>
                <p>{review?.review}</p>
                <Rating
                  emptySymbol="fa-regular fa-star"
                  fullSymbol="fa-solid fa-star"
                  fractions={2}
                  start={0}
                  stop={5}
                  readonly
                  placeholderSymbol="fa-solid fa-star"
                  placeholderRating={review?.rating}
                  quiet
                />
              </div>
              {reviewPermissions.canDelete && (
                <div className="delete">
                  <button
                    onClick={() => {
                      setModalShow(true);
                      setCurrentUser(review);
                    }}
                    className="remove"
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <p className="no-results">
          {t("This client didn't write any reviews yet!")}
        </p>
      )}
    </>
  );
}
