import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import VendorServices from "../../../../../services/VendorServices";
import InfoInput from "../../../../InfoInput/InfoInput";
import i18next from "i18next";
import MainTable from "../../../../MainTable/MainTable";
import ProductReviews from "./ProductReviews/ProductReviews";
import ImagesUpload from "../../../../ImagesUpload/ImagesUpload";

export default function ProductDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { product: productPermissions } = frontPermissions;

  let {
    canCreate: canCreateProduct,
    canView: canViewProduct,
    canEdit: canEditProduct,
    canDelete: canDeleteProduct,
  } = productPermissions;

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18next.language;

  const [product, setProduct] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("")
  const [productImages, setProductImages] = useState([]);
  const [allProductBranches, setAllProductBranches] = useState(true)
  const [allProductReviews, setAllProductReviews] = useState(false)
  const [productBranches, setProductBranches] = useState({})

  async function getProduct() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getProduct(params.productID);
      setLoading(false);
      setProduct(data.record[0]);

      let tableData = data.record[0].branches.map((record, index) => {
        return {
          Num: <div className='index'>{index + 1}</div>,
          id: record?._id,
          'English Name': record?.name?.en,
          'Arabic Name': record?.name?.ar,
        }
      })
      setProductBranches(tableData)

      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }

      setProductImages(data.record[0].image)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function getImage(image) {
    setImageUrl(image);
  }

  let productDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: product?.name?.[lang],
    },
    {
      id: "description",
      title: "Description",
      type: "text",
      value: product?.description?.[lang],
    },
    {
      id: "vendor_name",
      title: "Vendor Name",
      type: "text",
      value: product?.vendor?.name?.[lang],
    },
    {
      id: "vendor_rank",
      title: "Vendor Rank",
      type: "number",
      value: product?.vendorRank,
    },
    {
      id: "category",
      title: "Category",
      type: "text",
      value: product?.category?.name?.[lang],
    },
    {
      id: "sale_price",
      title: "Sale Price",
      type: "number",
      value: product?.price
    },
    {
      id: "originalPrice",
      title: "Full Price",
      type: "number",
      value: product?.originalPrice
    },
    {
      id: "rate",
      title: "Rate",
      type: "text",
      value: product?.rate,
    },
    {
      id: "numberOFReviews",
      title: "Number of Reviews",
      type: "number",
      value: product?.numOfReviews,
    },
    {
      id: "rank",
      title: "Rank",
      type: "number",
      value: product?.rank,
    },
  ];

  function handleBranchRowClick(id) {
    navigate(`/vendors/${params.id}/branchdetails/${id}`)
  }

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="vendor-datails">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  params.branchID !== "undefined"
                    ? navigate(
                      `/vendors/${params.id}/branchdetails/${params.branchID}`
                    )
                    : navigate(`/vendors/${params.id}`);
                }}
              ></i>
            </div>
            <div className="client-image product-image-details">
              {image ? (
                <img src={imageUrl ? imageUrl : product?.image?.[0]?.Location} alt="Admin Image" />
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Branch Image"
                />
              )}
            </div>
            <ImagesUpload
              type="view"
              viewList={productImages}
              getImage={getImage}
              selectedImage={imageUrl}
            />
            <div className="client-datails">
              {canEditProduct && (
                <div className="edit">
                  <button
                    onClick={() =>
                      navigate(
                        `/vendors/${params.id}/branchdetails/${params.branchID}/productdetails/${params.productID}/edit`
                      )
                    }
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                </div>
              )}
              <div className="data">
                {productDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Active")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isActive} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Hot Deal")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isHotDeal} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Limited")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isLimited} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Editable")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isEditable} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="user">
              {
                allProductBranches ? (
                  <div className='active-btn'>
                    <button
                      onClick={() => { setAllProductBranches(true); setAllProductReviews(false) }}
                      className='all-branches-btn'>
                      <h2>{t("All Product Branches")}</h2>
                    </button>
                  </div>
                ) : (<div className='all-branches'>
                  <button
                    onClick={() => { setAllProductBranches(true); setAllProductReviews(false) }}
                    className='all-branches-btn'>
                    <h2>{t("All Product Branches")}</h2>
                  </button>
                </div>)
              }
              {
                allProductReviews ? (
                  <div className='active-btn'>
                    <button
                      onClick={() => { setAllProductBranches(false); setAllProductReviews(true) }}
                      className='all-branches-btn'>
                      <h2>{t("All Product Reviews")}</h2>
                    </button>
                  </div>
                ) : (<div className='all-branches'>
                  <button
                    onClick={() => { setAllProductBranches(false); setAllProductReviews(true) }}
                    className='all-branches-btn'>
                    <h2>{t("All Product Reviews")}</h2>
                  </button>
                </div>)
              }
            </div>

            {allProductBranches ? (
              <div className="branch-cards-container">
                {productBranches.length === 0 ?
                  <p className='no-results'>{t("No results found!")}</p>
                  : <MainTable onRowClick={handleBranchRowClick} tableRows={productBranches} />}
              </div>
            ) : ""}

            {allProductReviews ? (
              <div className="branch-cards-container">
                <ProductReviews />
              </div>
            ) : ""}
          </div >
        )
      )
      }
    </>
  );
}
