import React from "react";
import { useTranslation } from "react-i18next";
import "./MainCard.scss";

export default function MainCard({
  total = 0,
  text1 = "",
  text2 = "",
  btnText = "",
  showBtn = true,
  onBtnClick = () => { },
  vendorCategory = "",
  productCategory = "",
}) {
  const { t } = useTranslation();

  return (
    <>
      {text2 === "Admins" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Active Categories" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>

          <div className="card">
            <div className="card-header">{t("Vendors")}</div>
            <div className="card-body">
              {vendorCategory} {t("Vendors")}
            </div>
          </div>

          <div className="card">
            <div className="card-header">{t("Products")}</div>
            <div className="card-body">
              {productCategory} {t("Products")}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Inactive Categories" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Requested Categories" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Active Vendors" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Inactive Vendors" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Active Clients" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>

          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Inactive Clients" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Barcodes" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Active Agents" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>

          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Inactive Agents" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Active Organizations" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-org"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Inactive Organizations" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Active Vouchers" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Inactive Vouchers" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Notifications" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Products" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          <button
            className="main-button-add"
            onClick={() => {
              onBtnClick();
            }}
          >
            {t(btnText)}
          </button>
        </div>
      ) : (
        ""
      )}

      {text2 === "Sort Vendors" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t("Vendors")}
            </div>
          </div>
          <button
            className="main-button-add"
            onClick={() => {
              onBtnClick();
            }}
          >
            {t(btnText)}
          </button>
        </div>
      ) : (
        ""
      )}

      {text2 === "Sort Categories" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t("Categories")}
            </div>
          </div>
          <button
            className="main-button-add"
            onClick={() => {
              onBtnClick();
            }}
          >
            {t(btnText)}
          </button>
        </div>
      ) : (
        ""
      )}

      {text2 === "Pending Subscriptions" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Accepted Subscriptions" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Rejected Subscriptions" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Reviews" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Wishlists" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Carts" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Orders" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Order Requests" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Points" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {text2}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {text2 === "Professions" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {text2 === "Interests" ? (
        <div className="mainCard">
          <div className="card">
            <div className="card-header">{t(text1)}</div>
            <div className="card-body">
              {total} {t(text2)}
            </div>
          </div>
          {showBtn && (
            <button
              className="main-button-add"
              onClick={() => {
                onBtnClick();
              }}
            >
              {t(btnText)}
            </button>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
