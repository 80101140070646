import Axios from "./Axios";

let PagesServices = {
  addPage: async (obj) => {
    const response = await Axios.post(`/admin/page/create`, obj);
    return response;
  },

  getPage: async (id) => {
    const response = await Axios.get(`/admin/page/get?_id=${id}`);
    return response;
  },

  editPage: async (id, obj) => {
    const response = await Axios.put(`/admin/page/update?_id=${id}`, obj);
    return response;
  },

  deletePage: async (id) => {
    const response = await Axios.delete(`/admin/page/remove?_id=${id}`);
    return response;
  },

  listAllFaqs: async () => {
    const response = await Axios.get(
      `/admin/page/list?type=faqs`
    );
    return response;
  },

  listAllAbout: async () => {
    const response = await Axios.get(
      `/admin/page/list?type=about`
    );
    return response;
  },
};

export default PagesServices;
