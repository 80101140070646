import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import InfoInput from '../../../../Components/InfoInput/InfoInput';
import i18n from '../../../../locales/i18n';
import EmployeeServices from '../../../../services/EmployeeServices';
import './EmployeeDetails.scss'

export default function EmployeeDetails() {

  const { t } = useTranslation();
  let params = useParams()
  const navigate = useNavigate()
  const lang = i18n.language

  const [employee, setEmployee] = useState({})
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false);

  async function getEmployee() {
    setLoading(true)
    try {
      const { data } = await EmployeeServices.getEmployee(params.id)
      setLoading(false);
      setEmployee(data.record[0])
      setActive(true)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let employeeName = ""
  if (localStorage.getItem("i18nextLng") === "en" && employee?.name?.en) {
    employeeName = employee?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !employee?.name?.en && employee?.name?.ar) {
    employeeName = employee?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && employee?.name?.ar) {
    employeeName = employee?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !employee?.name?.ar && employee?.name?.en) {
    employeeName = employee?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !employee?.name?.en && !employee?.name?.ar) {
    employeeName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !employee?.name?.ar && !employee?.name?.en) {
    employeeName = "لا يوجد أسم"
  }

  let jobTitle = ""
  if (localStorage.getItem("i18nextLng") === "en" && employee?.jobTitle?.en) {
    jobTitle = employee?.jobTitle?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !employee?.jobTitle?.en && employee?.jobTitle?.ar) {
    jobTitle = employee?.jobTitle?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && employee?.jobTitle?.ar) {
    jobTitle = employee?.jobTitle?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !employee?.jobTitle?.ar && employee?.jobTitle?.en) {
    jobTitle = employee?.jobTitle?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !employee?.jobTitle?.en && !employee?.jobTitle?.ar) {
    jobTitle = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !employee?.jobTitle?.ar && !employee?.jobTitle?.en) {
    jobTitle = "لا يوجد أسم"
  }

  let employeeDetails = [
    { id: "name", title: "Name", type: "text", value: employeeName },
    { id: "jobTitle", title: "Job Title", type: "text", value: jobTitle },
    { id: "description", title: "Description", type: "text", value: employee?.description?.[lang] },
    { id: "phone", title: "Phone", type: "text", value: employee?.phone },
    { id: "publishDate", title: "Publish Date", type: "text", value: formatDate(new Date(employee?.publishDate)) },
    { id: "category", title: "Category", type: "text", value: employee?.category?.map((cat) => cat?._id?.name?.[lang]) },
  ]

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getEmployee()
  }, [])

  return <>

    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}
    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (active && <div className="main-details">
        <div className="back">
          <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/jobs') }}></i>
        </div>
        <div className="client-image vendor-image-details">
          <img src={"https://blog.vantagecircle.com/content/images/2021/01/Employee-Management-Meaning-Importance-Tips-Tools---More.png"} alt="Employee Image" />
        </div>
        <div className="client-datails">
          <div className="data">
            {
              employeeDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                )
              })
            }


            <div className='toggler'>
              <label htmlFor="productImage" className="word">{t("Active")}</label>
              <label className="switch form-group">
                <input checked={employee?.isActive} type="checkbox" readOnly />
                <span className="slider round"></span>
              </label>
            </div>
            <a href={employee?.link} target='_blank' className='view'>{t("View CV")}</a>
          </div>
        </div>
      </div>)}
  </>
}
