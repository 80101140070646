import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import PagesServices from "../../../services/PagesServices";
import { useTranslation } from "react-i18next";
import "./AboutDetails.scss";
import { useSelector } from "react-redux";

export default function AboutDetails(props) {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { page: pagePermissions } = frontPermissions;

  const navigate = useNavigate();
  const lang = i18next.language;
  const { t } = useTranslation();

  const [about, setAbout] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getAbout() {
    setLoading(true);
    try {
      const { data } = await PagesServices.listAllAbout();
      setLoading(true);
      setLoading(false);
      setAbout(data.records[0]);
      setActive(true);
      props.isAbout(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="faqs-details">
            <div className="client-datails">
              <div className="edit">
                {pagePermissions.canEdit && (
                  <button onClick={() => navigate(`/about/${about._id}/edit`)}>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                )}
              </div>

              <div className="data">
                <div className="input-label-disabled">
                  <label className="disabled-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <div className="input-textarea-question">
                    <textarea
                      id="name"
                      className="disabled-input"
                      type="text"
                      value={about?.name?.[lang]}
                      disabled
                    ></textarea>
                  </div>
                </div>

                <div className="input-label-disabled">
                  <label className="disabled-label" htmlFor="content">
                    {t("Content")}
                  </label>
                  <div className="input-textarea-answer">
                    <textarea
                      id="content"
                      className="disabled-input"
                      type="text"
                      value={about?.content?.[lang]}
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
