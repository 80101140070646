import Axios from "./Axios";

let PointServices = {
  addPoints: async (id, obj) => {
    const response = await Axios.put(`/admin/points/updateSystemPoints?client=${id}&isAdd=true`,
      obj
    );
    return response;
  },

  removePoints: async (id, obj) => {
    const response = await Axios.put(`/admin/points/updateSystemPoints?client=${id}&isAdd=false`,
      obj
    );
    return response;
  },

  getPoint: async (id) => {
    const response = await Axios.get(`/admin/points/get?client=${id}`);
    return response;
  },

  listAllPoints: async (page, limit = 25) => {
    const response = await Axios.get(`/admin/points/list?${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllVendorPoints: async (id, page, limit = 25) => {
    const response = await Axios.get(`/admin/points/list?vendorPoints.vendor=${id}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default PointServices;
