import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Jobs from "./Jobs/Jobs";
import Employees from "./Employees/Employees";
import "./Job.scss";
import { useSelector } from "react-redux";

export default function Job() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { job: jobPermissions } = frontPermissions;

  const { t } = useTranslation();

  const [allJobs, setAllJobs] = useState(true);
  const [allEmployees, setAllEmployees] = useState(false);

  return (
    <>
      <div className="user">
        {allJobs ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setAllJobs(true);
                setAllEmployees(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("All Jobs")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setAllJobs(true);
                setAllEmployees(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("All Jobs")}</h2>
            </button>
          </div>
        )}
        {allEmployees ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setAllJobs(false);
                setAllEmployees(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("All Employees")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setAllJobs(false);
                setAllEmployees(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("All Employees")}</h2>
            </button>
          </div>
        )}
      </div>

      {allJobs ? (
        <div className="branch-cards-container">
          <Jobs />
        </div>
      ) : (
        ""
      )}

      {allEmployees ? (
        <div className="branch-cards-container">
          <Employees />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
