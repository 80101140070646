import React, { useEffect, useState } from "react";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import { useTranslation } from "react-i18next";
import toastPopup from "../../helpers/toastPopup";
import { useNavigate } from "react-router-dom";
import CategoryServices from "../../services/CategoryServices";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import { useSelector } from "react-redux";
import "./Category.scss";

export default function Category() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { category: categoryPermissions } = frontPermissions;
  let canDeleteCategory = categoryPermissions.canDelete;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeCategory, setActiveCategory] = useState([]);
  const [inActiveCategory, setInActiveCategory] = useState([]);
  const [requestedcategory, setRequestedCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalActiveCategories, setTotalActiveCategories] = useState(0);
  const [totalInActiveCategories, setTotalInActiveCategories] = useState(0);
  const [totalRequestedCategories, setTotalRequestedCategories] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [activeCategories, setActiveCategories] = useState(true)
  const [inActiveCategories, setInActiveCategories] = useState(false)
  const [requestedCategories, setRequestedCategories] = useState(false)
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [requestedCurrentPage, setRequestedCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState("");

  function toggleActive() {
    setActiveCategories(true)
    setInActiveCategories(false)
    setRequestedCategories(false)
  }

  function toggleInActive() {
    setActiveCategories(false)
    setInActiveCategories(true)
    setRequestedCategories(false)
  }

  function toggleRequested() {
    setActiveCategories(false)
    setInActiveCategories(false)
    setRequestedCategories(true)
  }

  async function getActiveCategories() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await CategoryServices.listActiveCategories(
        lang,
        searchValue,
        filterValue === 'All' ? '' : filterValue,
        activeCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let returnedObject = canDeleteCategory
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            "English Name": (record?.name?.en).toUpperCase(),
            "Arabic Name": record?.name?.ar,
            Type: record.type,
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            "English Name": (record?.name?.en).toUpperCase(),
            "Arabic Name": record?.name?.ar,
            Type: record.type,
          };

        return returnedObject;
      });

      setActiveCategory(cat => {
        if ((filterValue && activeCurrentPage === 1) || (searchValue && activeCurrentPage === 1) || (isSorted && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...cat, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveCategories(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getInActiveCategories() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await CategoryServices.listInActiveCategories(
        lang,
        searchValue,
        filterValue === 'All' ? '' : filterValue,
        inActiveCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let returnedObject = canDeleteCategory
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            "English Name": (record?.name?.en).toUpperCase(),
            "Arabic Name": record?.name?.ar,
            Type: record.type,
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            "English Name": (record?.name?.en).toUpperCase(),
            "Arabic Name": record?.name?.ar,
            Type: record.type,
          };

        return returnedObject;
      });

      setInActiveCategory(cat => {
        if ((filterValue && inActiveCurrentPage === 1) || (searchValue && inActiveCurrentPage === 1) || (isSorted && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...cat, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveCategories(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getRequestedCategories() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await CategoryServices.listRequestedCategories(
        lang,
        searchValue,
        filterValue === 'All' ? '' : filterValue,
        requestedCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let returnedObject = canDeleteCategory
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            "English Name": (record?.name?.en).toUpperCase(),
            "Arabic Name": record?.name?.ar,
            Type: record.type,
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            "English Name": (record?.name?.en).toUpperCase(),
            "Arabic Name": record?.name?.ar,
            Type: record.type,
          };

        return returnedObject;
      });

      setRequestedCategory(cat => {
        if ((filterValue && requestedCurrentPage === 1) || (searchValue && requestedCurrentPage === 1) || (isSorted && requestedCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...cat, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalRequestedCategories(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteCategory(id) {
    setLoading(true);
    try {
      const { data } = await CategoryServices.deleteCategory(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let vendorCategory = activeCategory.filter(
    (categoryData) => categoryData.Type === "vendor"
  );
  let productCategory = activeCategory.filter(
    (categoryData) => categoryData.Type === "product"
  );

  function handleCategoryRowClick(id) {
    navigate(`/categories/${id}`);
  }

  useEffect(() => {
    if (activeCategories) {
      getActiveCategories();
      setInActiveCategory([])
      setRequestedCategory([])
    }
  }, [searchValue, filterValue, activeCurrentPage, activeCategories, isSorted]);

  useEffect(() => {
    if (inActiveCategories) {
      getInActiveCategories();
      setActiveCategory([])
      setRequestedCategory([])
    }
  }, [searchValue, filterValue, inActiveCurrentPage, inActiveCategories, isSorted]);

  useEffect(() => {
    if (requestedCategories) {
      getRequestedCategories();
      setInActiveCategory([])
      setActiveCategory([])
    }
  }, [searchValue, filterValue, requestedCurrentPage, requestedCategories, isSorted]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {(currentUser?.name?.en).toUpperCase()} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteCategory(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          activeCategories ? (
            <div className='active-btn'>
              <button
                onClick={() => { toggleActive() }}
                className='all-branches-btn'>
                <h2>{t("Active Categories")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { toggleActive() }}
              className='all-branches-btn'>
              <h2>{t("Active Categories")}</h2>
            </button>
          </div>)
        }
        {
          inActiveCategories ? (
            <div className='active-btn'>
              <button
                onClick={() => { toggleInActive() }}
                className='all-branches-btn'>
                <h2>{t("Inactive Categories")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { toggleInActive() }}
              className='all-branches-btn'>
              <h2>{t("Inactive Categories")}</h2>
            </button>
          </div>)
        }
        {
          requestedCategories ? (
            <div className='active-btn'>
              <button
                onClick={() => { toggleRequested() }}
                className='all-branches-btn'>
                <h2>{t("Requested Categories")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { toggleRequested() }}
              className='all-branches-btn'>
              <h2>{t("Requested Categories")}</h2>
            </button>
          </div>)
        }
      </div>

      {activeCategories ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Active Categories"}
                text2={"Active Categories"}
                total={totalActiveCategories}
                showBtn={categoryPermissions.canCreate}
                btnText="Add Category"
                onBtnClick={() => {
                  navigate("/categories/addcategory");
                }}
                vendorCategory={vendorCategory.length}
                productCategory={productCategory.length}
              />
              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setActiveCurrentPage(1);
                      setSearchValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>
              <div className="filter">
                <div className="items">
                  <div>
                    <input
                      defaultChecked
                      value="All"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="all"
                    />
                    <label htmlFor="all">{t("All")}</label>
                  </div>

                  <div>
                    <input
                      value="vendor"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="vendor"
                    />
                    <label htmlFor="vendor">{t("Vendor")}</label>
                  </div>

                  <div>
                    <input
                      value="product"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="product"
                    />
                    <label htmlFor="product">{t("Product")}</label>
                  </div>

                  <div>
                    <input
                      value="org"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="org"
                    />
                    <label htmlFor="org">{t("Org")}</label>
                  </div>

                  <div>
                    <input
                      value="job"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="job"
                    />
                    <label htmlFor="job">{t("Job")}</label>
                  </div>

                  <div>
                    <input
                      value="service"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="service"
                    />
                    <label htmlFor="service">{t("Service")}</label>
                  </div>

                  <div className="alpha">
                    <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
                  </div>
                </div>
              </div>
              {isSearchEmpty || activeCategory.length === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable
                  onRowClick={handleCategoryRowClick}
                  tableRows={activeCategory}
                />
              )}
              {
                activeCategory.length !== totalActiveCategories &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {inActiveCategories ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Inactive Categories"}
                text2={"Inactive Categories"}
                total={totalInActiveCategories}
              />
              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setInActiveCurrentPage(1);
                      setSearchValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>
              <div className="filter">
                <div className="items">
                  <div>
                    <input
                      defaultChecked
                      value="All"
                      onClick={(e) => {
                        setInActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="all"
                    />
                    <label htmlFor="all">{t("All")}</label>
                  </div>

                  <div>
                    <input
                      value="vendor"
                      onClick={(e) => {
                        setInActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="vendor"
                    />
                    <label htmlFor="vendor">{t("Vendor")}</label>
                  </div>

                  <div>
                    <input
                      value="product"
                      onClick={(e) => {
                        setInActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="product"
                    />
                    <label htmlFor="product">{t("Product")}</label>
                  </div>

                  <div>
                    <input
                      value="org"
                      onClick={(e) => {
                        setInActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="org"
                    />
                    <label htmlFor="org">{t("Org")}</label>
                  </div>

                  <div>
                    <input
                      value="job"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="job"
                    />
                    <label htmlFor="job">{t("Job")}</label>
                  </div>

                  <div>
                    <input
                      value="service"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="service"
                    />
                    <label htmlFor="service">{t("Service")}</label>
                  </div>

                  <div className="alpha">
                    <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
                  </div>
                </div>
              </div>
              {isSearchEmpty || inActiveCategory.length === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable
                  onRowClick={handleCategoryRowClick}
                  tableRows={inActiveCategory}
                />
              )}
              {
                inActiveCategory.length !== totalInActiveCategories &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {requestedCategories ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Requested Categories"}
                text2={"Requested Categories"}
                total={totalRequestedCategories}
              />
              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setRequestedCurrentPage(1);
                      setSearchValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>

              <div className="alpha">
                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>
              {isSearchEmpty || requestedCategories.length === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable
                  onRowClick={handleCategoryRowClick}
                  tableRows={requestedcategory}
                />
              )}
              {
                requestedcategory.length !== totalRequestedCategories &&
                <div className="load">
                  <button onClick={() => { setRequestedCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
