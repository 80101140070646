import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MainInput from "../../../../Components/MainInput";
import toastPopup from "../../../../helpers/toastPopup";
import i18n from "../../../../locales/i18n";
import OrganizationServices from "../../../../services/OrganizationServices";
import "./OrganizationEdit.scss";

export default function OrganizationEdit() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { org: orgPermissions } = frontPermissions;

  let { canChangePassword } = orgPermissions;
  let params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [uploadImage, setUploadImage] = useState("");

  const [organization, setOrganization] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    phone: "",
    category: "",
    credit: 0,
    isActive: false,
  });

  const [newOrganization, setNewOrganization] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    phone: "",
    category: "",
    credit: 0,
    isActive: false,
  });

  const [newPassword, setNewPassword] = useState({
    email: "",
    new_password: "",
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "email", type: "email", required: false },
    { name: "phone", type: "text", required: false },
    { name: "credit", type: "number", required: false },
  ];

  let newPasswordFormData = [
    { name: "email", type: "email", required: false, disabled: true },
    { name: "new_password", type: "password", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getOrganization() {
    setLoading(true);
    try {
      const { data } = await OrganizationServices.getOrganization(params.id);
      setLoading(false);
      setOrganization({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        category: data?.record[0]?.category._id,
        credit: data?.record[0]?.credit,
        isActive: data?.record[0]?.isActive,
      });

      setNewOrganization({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        category: data?.record[0]?.category._id,
        credit: data?.record[0]?.credit,
        isActive: data?.record[0]?.isActive,
      });

      setNewPassword({
        email: data?.record[0]?.email,
        new_password: "",
      });

      setUploadImage(data?.record[0]?.image?.Location);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getOrganizationCategory() {
    setLoading(true);
    try {
      const { data } = await OrganizationServices.getOrganizationCategories(
        "org"
      );
      setLoading(false);
      setCategories(data.records);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editOrganization(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newOrganization, organization)).forEach(
      (key) => {
        if (key === "name_en" || key === "name_ar") {
          editedData = {
            ...editedData,
            name: {
              en: newOrganization["name_en"],
              ar: newOrganization["name_ar"],
            },
          };
        } else {
          editedData = {
            ...editedData,
            [key]: newOrganization[key],
          };
        }
      }
    );

    try {
      const { data } = await OrganizationServices.editOrganization(
        params.id,
        editedData
      );

      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } =
          typeof uploadImage === "object" &&
          (await OrganizationServices.uploadOrganizationImage(
            params.id,
            formData
          ));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/organizations/${params.id}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function changePasswordHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        email: newPassword.email,
        newPassword: newPassword.new_password,
      };
      const { data } = await OrganizationServices.changePassword(obj);
      setLoading(false);
      toastPopup.success(t("Password changed successfully"));
      navigate(`/organizations/${params.id}`);
    } catch (e) {
      setLoading(false);
      setPasswordErrorMessage(e.response.data.error);
    }
  }

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  useEffect(() => {
    getOrganization();
    getOrganizationCategory();
  }, []);

  return (
    <>
      <div className="add-client">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/organizations/${params.id}`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Organization Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>
        <div className="main-image-label vendor-image">
          {uploadImage && (
            <img
              src={
                typeof uploadImage === "object"
                  ? URL.createObjectURL(uploadImage)
                  : uploadImage
                    ? uploadImage
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgeRojQCaSZCVc3qj9TW_-2URYUpSq1AjwFw&usqp=CAU"
              }
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadImage ? URL.createObjectURL(uploadImage) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          <label
            className="main-label-image"
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            {t("Add Image")}
          </label>
        </div>
        <form className="form-grid" onSubmit={editOrganization}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newOrganization}
                  setState={setNewOrganization}
                />
              );
            })}
            <div className="main-input-select">
              <select
                value={newOrganization.category}
                onChange={(e) => {
                  setNewOrganization((prev) => {
                    return { ...prev, category: e.target.value };
                  });
                }}
                className="main-input"
                id="Category"
                name="Category"
                title="Category"
              >
                <option value="category">-- {t("Category")} --</option>
                {categories.map((cat, index) => {
                  return (
                    <option key={index} value={cat._id}>
                      {(cat?.name?.[lang]).charAt(0).toUpperCase() +
                        (cat?.name?.[lang]).slice(1)}
                    </option>
                  );
                })}
              </select>
              <label className="main-label">{t("Category")}</label>
            </div>
            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newOrganization.isActive}
                  onChange={(e) => {
                    setNewOrganization((prev) => {
                      return { ...prev, isActive: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>

      {canChangePassword && (
        <div className="change-password">
          <div className="header">
            <h3>{t("Change Password")}</h3>
            {passwordErrorMessage ? (
              <div className="err">{t(passwordErrorMessage)}</div>
            ) : (
              ""
            )}
          </div>

          <form className="form-grid" onSubmit={changePasswordHandler}>
            <div className="input-grid">
              {newPasswordFormData.map((formInput, index) => {
                return (
                  <MainInput
                    className="input-edit-admin"
                    key={index}
                    name={formInput.name}
                    type={formInput.type}
                    required={formInput.required}
                    disabled={formInput.disabled}
                    state={newPassword}
                    setState={setNewPassword}
                  />
                );
              })}
            </div>
            <div className="button-grid">
              <button className="main-button-admin" disabled={loading}>
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  t("Submit")
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
