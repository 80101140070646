import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationServices from '../../../services/NotificationServices';
import toastPopup from '../../../helpers/toastPopup';
import COUNTRIES_CITIES from '../../../locales/app-countries.json'
import MainInput from '../../../Components/MainInput';
import { socket } from '../../../services/socket';
import i18n from '../../../locales/i18n';
import AdsServices from '../../../services/AdsServices';
import Multiselect from "multiselect-react-dropdown";
import './AddNotification.scss'

export default function AddNotification() {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const params = useParams()
  const lang = i18n.language

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [countryIndex, setCountryIndex] = useState('')
  const [cityIndex, setCityIndex] = useState('')
  const [uploadImage, setUploadImage] = useState(null);
  const [image, setImage] = useState(null);
  const [gender, setGender] = useState("");
  const [selectedCities, setSelectedCities] = useState([]);

  const [newNotification, setNewNotification] = useState({
    text: "",
    link: "",
    startDate: "",
    endDate: "",
    ageFrom: 0,
    ageTo: 0
  });

  async function getAd() {
    setLoading(true)
    try {
      const { data } = await AdsServices.getAd(params?.ad_id)
      setLoading(false);
      let StartDate = (data?.record[0]?.startDate)?.indexOf('T')
      let EndDate = (data?.record[0]?.endDate)?.indexOf('T')
      setNewNotification({
        text: data?.record[0]?.notification?.[lang],
        link: data?.record[0]?.link,
        startDate: data?.record[0]?.startDate?.slice(0, StartDate),
        endDate: data?.record[0]?.endDate?.slice(0, EndDate),
        ageFrom: data?.record[0]?.age?.from,
        ageTo: data?.record[0]?.age?.to
      });
      setGender(data?.record[0]?.gender)
      setCountryIndex(data?.record[0]?.country?.index)
      setSelectedCities(data?.record[0]?.city.map((city) => {
        return {
          index: city?.index,
          name: city?.[lang]
        }
      }))
      setImage(data?.record[0]?.image)
      setUploadImage(data?.record[0]?.image?.Location)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function resetInputs() {
    setNewNotification({
      text: "",
      link: "",
      startDate: "",
      endDate: "",
      ageFrom: 0,
      ageTo: 0
    })
  }

  let formData = [
    { name: "text", type: "text", required: false },
    { name: "link", type: "text", required: false },
    { name: "startDate", type: "Date", required: true },
    { name: "endDate", type: "Date", required: true },
    { name: "ageFrom", type: "number", required: true },
    { name: "ageTo", type: "number", required: true },
  ];

  async function addNewNotification(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        text: newNotification?.text,
        country: {
          en: selectedCountry?.en,
          ar: selectedCountry?.ar,
          index: countryIndex,
        },
        city: getFinalCities(),
        age: {
          from: newNotification.ageFrom,
          to: newNotification.ageTo
        },
        gender: gender,
        link: newNotification.link,
        startDate: newNotification.startDate,
        endDate: newNotification.endDate,
        timestamp: new Date(Date.now()).toISOString()
      }

      const finalObj = {};
      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }

      socket.emit('createNotification', obj, (data) => {
        setLoading(false);
        resetInputs()
        let notificationId = data?.record?._id
        var formData = new FormData();
        formData.append("image", uploadImage);
        uploadNotificationImage(notificationId, formData)
      })
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  async function uploadNotificationImage(notificationId, formData) {
    setLoading(true)
    try {
      const { data } = await NotificationServices.uploadNotificationImage(notificationId, formData)
      setLoading(false);
      navigate(`/notifications/${notificationId}`)
      toastPopup.success(t("Added"));
    } catch (error) {
      setLoading(false);
      setErrorMessage(error);
    }
  }

  async function addNewNotificationFromAds(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        text: newNotification?.text,
        country: {
          en: selectedCountry?.en,
          ar: selectedCountry?.ar,
          index: countryIndex,
        },
        city: getFinalCities(),
        age: {
          from: newNotification.ageFrom,
          to: newNotification.ageTo
        },
        gender: gender,
        link: newNotification.link,
        startDate: newNotification.startDate,
        endDate: newNotification.endDate,
        timestamp: new Date(Date.now()).toISOString(),
        image: image,
        ad: params?.ad_id
      }

      const finalObj = {};

      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }

      let notificationId = ""
      setLoading(false);
      resetInputs()
      socket.emit('createNotification', finalObj, (data) => {
        notificationId = data?.record?._id
      })
      toastPopup.success(t("Added"));
      try {
        const { data } = await AdsServices.editAd(params?.ad_id, { status: "accepted" })
        setLoading(false);
        toastPopup.success(t("Ad Accepted"));
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/notifications/${notificationId}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(countryIndex))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(cityIndex))[0]

  function toggleSelectedCityHandler(city) {
    if (selectedCities.includes(city)) {
      let oldSelectedCities = selectedCities;
      let newSelectedCities = oldSelectedCities.filter((singleCity) => {
        return singleCity !== city;
      });
      setSelectedCities(newSelectedCities);
    } else {
      setSelectedCities((prev) => {
        return [...prev, city];
      });
    }
  }

  function getFinalCities() {
    let finalCities = [];
    selectedCities.forEach((selectedCity) => {
      selectedCountry?.cities
        ?.filter((city) => city?.index === selectedCity?.index)
        ?.map((city) => {
          finalCities.push({
            en: city?.en,
            ar: city?.ar,
            index: city?.index
          });
        });
    });

    return finalCities;
  }

  let citiesOptions = selectedCountry?.cities?.map((city) => {
    return ({
      name: city?.[lang],
      index: city?.index
    })
  })

  function isSelectedCity(city) {
    let selectedCitiesIndex = selectedCities.map((selectedCity) => selectedCity?.index)
    return selectedCitiesIndex.includes(city?.index)
  }

  function toggleSelectedCitiesHandler(city) {
    if (isSelectedCity(city)) {
      let oldSelectedCities = selectedCities
      let newSelectedCities = oldSelectedCities.filter((singleCity) => { return singleCity !== city })
      setSelectedCities(newSelectedCities)
    } else {
      setSelectedCities((prev) => {
        return [...prev, city]
      })
    }
  }

  useEffect(() => {
    socket.on('connection', () => {
      console.log('Connected to server');
    });

    socket.connect();

    return () => {
      socket.disconnect();
    }
  }, [])

  useEffect(() => {
    if (params?.ad_id) {
      getAd();
    }
  }, [params?.ad_id]);

  return <>

    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate(`/notifications`) }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Notification")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}

      </div>
      <div className="main-image-label vendor-cover">
        {uploadImage && (
          <img
            src={typeof uploadImage === "object" ? URL.createObjectURL(uploadImage) : uploadImage ? uploadImage : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadImage ? URL.createObjectURL(uploadImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={ref}
          onChange={(e) => {
            setUploadImage(e.target.files[0]);
          }}
        />
        <label
          className="main-label-image"
          onClick={imageUploader}
          htmlFor="upload-img"
        >
          {t("Add Notification")}
        </label>
      </div>

      <form className='form-grid' onSubmit={(params.ad_id) ? addNewNotificationFromAds : addNewNotification}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newNotification}
                setState={setNewNotification}
              />
            );
          })}

          <div className="main-input-select">
            <select
              onChange={(e) => { setGender(e.target.value) }}
              className='main-input'
              id="gender"
              name="gender"
              title='gender'
              value={gender}>
              <option value=''>-- {t("Gender")} --</option>
              <option value={"male"}>{t("Male")}</option>
              <option value={"female"}>{t("Female")}</option>
              <option value={"both"}>{t("Both")}</option>
            </select>
            <label className='main-label'>{t("Gender")}</label>
          </div>

          <div className="main-input-select">
            <select
              selected={countryIndex}
              value={countryIndex}
              onChange={(e) => { setCountryIndex(e.target.value) }}
              className='main-input'
              id="country"
              name="country"
              title='country'>
              <option value=''>{t("Country")}</option>
              {
                COUNTRIES_CITIES?.map((country, index) => {
                  return <option key={index} value={country?.index}>{country?.[lang]}</option>
                })
              }
            </select>
            <label className="main-label">{t("Country")}</label>
          </div>

          {params?.ad_id ?
            <Multiselect
              displayValue="name"
              selectedValues={selectedCities}
              onKeyPressFn={function noRefCheck() { }}
              onRemove={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedCitiesHandler(selectedItem)
              }}
              onSearch={function noRefCheck() { }}
              onSelect={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedCitiesHandler(selectedItem)
              }}
              options={citiesOptions}
              showCheckbox
              placeholder={t("Cities")}
            />
            :
            <Multiselect
              displayValue="name"
              onKeyPressFn={function noRefCheck() { }}
              onRemove={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedCityHandler(selectedItem)
              }}
              onSearch={function noRefCheck() { }}
              onSelect={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedCityHandler(selectedItem)
              }}
              options={citiesOptions}
              showCheckbox
              placeholder={t("Cities")}
            />}

        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate(`/notifications`) }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Notification")
            )}
          </button>
        </div>
      </form>
    </div>

  </>
}
