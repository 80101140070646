import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import toastPopup from "../../../helpers/toastPopup";
import BannerServices from "../../../services/BannerServices";
import { useSelector } from "react-redux";
import { ReactComponent as SearchLogo } from "../../../assets/VIP-ICON-SVG/SearchLogo.svg";
import "./Small.scss";

export default function Small() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { banner: bannerPermissions } = frontPermissions;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [activeBanners, setActiveBanners] = useState([]);
  const [inActiveBanners, setInActiveBanners] = useState([]);
  const [isActiveBanner, setIsActiveBanner] = useState(true);
  const [isInActiveBanner, setIsInActiveBanner] = useState(false);
  const [totalActiveBanner, setTotalActiveBanner] = useState(0);
  const [totalInActiveBanner, setTotalInActiveBanner] = useState(0);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");

  async function getAllActiveSmallBanners() {
    setLoading(true);
    try {
      const { data } = await BannerServices.listAllActiveBannersBySize("small", searchActiveValue, activeCurrentPage);
      setLoading(false);
      setActiveBanners(banner => {
        if ((searchActiveValue && activeCurrentPage === 1)) {
          return [...data.records]
        }
        return [...banner, ...data.records]
      })
      setTotalActiveBanner(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInActiveSmallBanners() {
    setLoading(true);
    try {
      const { data } = await BannerServices.listAllInActiveBannersBySize(
        "small",
        searchInActiveValue,
        inActiveCurrentPage
      );
      setLoading(false);
      setInActiveBanners(banner => {
        if ((inActiveCurrentPage && inActiveCurrentPage === 1)) {
          return [...data.records]
        }
        return [...banner, ...data.records]
      })
      setTotalInActiveBanner(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteBanner(id) {
    setLoading(true);
    try {
      const { data } = await BannerServices.deleteBanner(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleBannerClick(id) {
    navigate(`/banners/${id}`);
  }

  useEffect(() => {
    if (isActiveBanner) {
      getAllActiveSmallBanners();
      setInActiveBanners([])
    }
  }, [activeCurrentPage, searchActiveValue, isActiveBanner]);

  useEffect(() => {
    if (isInActiveBanner) {
      getAllInActiveSmallBanners();
      setActiveBanners([])
    }
  }, [inActiveCurrentPage, searchInActiveValue, isInActiveBanner]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>
              {t("Are you sure you want to delete ")}
              {t("?")}
            </h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteBanner(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {isActiveBanner ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setIsActiveBanner(true);
                setIsInActiveBanner(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Active Banners")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setIsActiveBanner(true);
                setIsInActiveBanner(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Active Banners")}</h2>
            </button>
          </div>
        )}
        {isInActiveBanner ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setIsActiveBanner(false);
                setIsInActiveBanner(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Inactive Banners")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setIsActiveBanner(false);
                setIsInActiveBanner(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Inactive Banners")}</h2>
            </button>
          </div>
        )}
      </div>

      {isActiveBanner ? (
        <div className="branch-cards-container">
          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setActiveCurrentPage(1);
                  setSearchActiveValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
          </div>
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              {activeBanners.length === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                activeBanners.map((banner) => {
                  return (
                    <div className="small-banner" key={banner?._id}>
                      <img
                        src={banner?.image?.Location}
                        alt="imag-viewer"
                        className="uploaded-img"
                        onClick={() => {
                          handleBannerClick(banner?._id);
                        }}
                      />
                      {bannerPermissions.canDelete && (
                        <button
                          onClick={(e) => {
                            setModalShow(true);
                            setCurrentUser(banner);
                          }}
                          className="remove"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                    </div>
                  );
                })
              )}
              {
                activeBanners.length !== totalActiveBanner &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      {isInActiveBanner ? (
        <div className="branch-cards-container">
          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setInActiveCurrentPage(1);
                  setSearchInActiveValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
          </div>
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              {inActiveBanners.length === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                inActiveBanners.map((banner) => {
                  return (
                    <div className="small-banner" key={banner?._id}>
                      <img
                        src={banner?.image?.Location}
                        alt="imag-viewer"
                        className="uploaded-img"
                        onClick={() => {
                          handleBannerClick(banner?._id);
                        }}
                      />
                      {bannerPermissions.canDelete && (
                        <button
                          onClick={(e) => {
                            setModalShow(true);
                            setCurrentUser(banner);
                          }}
                          className="remove"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                    </div>
                  );
                })
              )}
              {
                inActiveBanners.length !== totalInActiveBanner &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
