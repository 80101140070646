import Axios from "./Axios";

let ProfessionServices = {
  addProfession: async (obj) => {
    const response = await Axios.post(`/admin/profession/create`, obj);
    return response;
  },

  getProfession: async (id) => {
    const response = await Axios.get(`/admin/profession/get?_id=${id}`);
    return response;
  },

  editProfession: async (id, obj) => {
    const response = await Axios.put(`/admin/profession/update?_id=${id}`, obj);
    return response;
  },

  deleteProfession: async (id) => {
    const response = await Axios.delete(`/admin/profession/remove?_id=${id}`);
    return response;
  },

  listAllProfessions: async (lang = "", search = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/profession/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },
};

export default ProfessionServices;
