import Axios from "./Axios";

let OrganizationServices = {
  addOrganization: async (obj) => {
    const response = await Axios.post(`/admin/org/create`, obj);
    return response;
  },

  getOrganization: async (id) => {
    const response = await Axios.get(`/admin/org/get?_id=${id}`);
    return response;
  },

  uploadOrganizationImage: async (id, obj) => {
    const response = await Axios.post(`/admin/org/image?_id=${id}`, obj);
    return response;
  },

  deleteOrganizationImage: async (id) => {
    const response = await Axios.delete(`/admin/org/image?_id=${id}`);
    return response;
  },

  getOrganizationCategories: async (type) => {
    const response = await Axios.get(`/admin/category/list?type=${type}&isActive=true`);
    return response;
  },

  editOrganization: async (id, obj) => {
    const response = await Axios.put(`/admin/org/update?_id=${id}`, obj);
    return response;
  },

  changePassword: async (obj) => {
    const response = await Axios.put(`/admin/org/resetPassword`, obj);
    return response;
  },

  deleteOrganization: async (id) => {
    const response = await Axios.delete(`/admin/org/remove?_id=${id}`);
    return response;
  },

  listAllActiveOrganizations: async (lang, search = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/org/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted?.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listAllInActiveOrganizations: async (lang, search = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/org/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted?.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  renewAllOrgClients: async (orgId, obj) => {
    const response = await Axios.put(`/admin/client/updateMany?org=${orgId}`, obj);
    return response;
  },

  generateExcel: async (orgId) => {
    const response = await Axios.put(`/admin/org/excel?_id=${orgId}`);
    return response;
  },
};

export default OrganizationServices;
