import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InterestServices from '../../../services/InterestServices';
import toastPopup from '../../../helpers/toastPopup';
import MainInput from '../../../Components/MainInput';
import './InterestEdit.scss'

export default function InterestEdit() {

  let params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [interest, setInterest] = useState({
    name_en: "",
    name_ar: ""
  });

  const [newInterest, setNewInterest] = useState({
    name_en: "",
    name_ar: ""
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false }
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getInterest() {
    setLoading(true);
    try {
      const { data } = await InterestServices.getInterest(params?.interest_id);
      setLoading(false);
      setInterest({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar
      });

      setNewInterest({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar
      });
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editInterest(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newInterest, interest)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newInterest["name_en"], ar: newInterest["name_ar"] },
        };
      } else {
        editedData = {
          ...editedData,
          [key]: newInterest[key],
        };
      }
    });

    try {
      const { data } = await InterestServices.editInterest(params?.interest_id, editedData);
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      navigate(`/interests`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  useEffect(() => {
    getInterest();
  }, []);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/interests`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Interest Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <form className="form-grid" onSubmit={editInterest}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newInterest}
                  setState={setNewInterest}
                />
              );
            })}
          </div>

          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
