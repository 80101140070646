import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ProfessionServices from '../../../services/ProfessionServices';
import toastPopup from '../../../helpers/toastPopup';
import MainInput from '../../../Components/MainInput';
import './AddProfession.scss'

export default function AddProfession() {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [newProfession, setNewProfession] = useState({
    name_en: "",
    name_ar: ""
  });

  function resetInputs() {
    setNewProfession({
      name_en: "",
      name_ar: ""
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true }
  ];

  async function addNewProfession(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newProfession?.name_en,
          ar: newProfession?.name_ar
        }
      }

      const { data } = await ProfessionServices.addProfession(obj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      navigate(`/professions`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  return <>

    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/professions') }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Profession")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <form className='form-grid' onSubmit={addNewProfession}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newProfession}
                setState={setNewProfession}
              />
            );
          })}

        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate('/professions') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Profession")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
