import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import toastPopup from "../../../helpers/toastPopup";
import AdminServices from "../../../services/AdminServices";
import MainInput from "../../../Components/MainInput";
import GrandPermissionsTable from "../../../Components/GrandPermissionsTable/GrandPermissionsTable";
import { getMappeedPermissions } from "../../../helpers/permissions";
import "./AddAdmin.scss";

export default function AddAdmin() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [role, setRole] = useState("admin");
  const [uploadImage, setUploadImage] = useState(null);

  const [permissionsObject, setPermissionsObject] = useState({
    admin: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    vendor: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canSort: false,
      canChangePassword: false,
    },

    branch: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    cashier: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    product: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canSort: false,
      canDelete: false,
    },

    client: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    order: {
      // canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    orderRequest: { canView: false },

    wishlist: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      // canDelete: false,
    },

    cart: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      // canDelete: false,
    },

    points: {
      // canCreate: false,
      canView: false,
      canEdit: false,
      // canDelete: false,
    },

    review: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      canDelete: false,
    },

    category: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canSort: false,
      canDelete: false,
    },

    agent: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    org: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    coupon: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    join: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      canDelete: false,
    },

    job: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    post: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    service: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    ad: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    banner: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    notification: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    page: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    interest: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    profession: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    barcode: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    subscription: {
      // canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    chat: {
      canView: false,
    },

    setting: { canView: false, canEdit: false },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [newAdmin, setNewAdmin] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    password: "",
  });

  function resetInputs() {
    setNewAdmin({
      name_en: "",
      name_ar: "",
      email: "",
      password: "",
    });
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "email", type: "email", required: true },
    { name: "password", type: "password", required: true },
  ];

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  async function addAdminHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newAdmin.name_en,
          ar: newAdmin.name_ar,
        },
        email: newAdmin.email,
        password: newAdmin.password,
        role: role,
        permissions: getMappeedPermissions(permissionsObject),
        frontPermissions: permissionsObject
      };
      const { data } = await AdminServices.addAdmin(obj);
      setLoading(false);
      resetInputs();
      toastPopup.success(t("Added"));
      let adminID = data.record._id;
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } = await AdminServices.uploadAdminImage(
          adminID,
          formData
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/admins/${data.record._id}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  return (
    <>
      <div className="add-admin">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate("/admins");
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Add Admin")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <form className="admin-form" onSubmit={addAdminHandler}>
          <div className="main-image-label admin-image">
            {uploadImage && (
              <img
                src={uploadImage ? URL.createObjectURL(uploadImage) : null}
                alt="imag-viewer"
                className="uploaded-img"
                onClick={() => {
                  window.open(
                    uploadImage ? URL.createObjectURL(uploadImage) : null
                  );
                }}
              />
            )}
            <input
              className="main-input-image"
              type="file"
              name="upload-img"
              ref={ref}
              onChange={(e) => {
                setUploadImage(e.target.files[0]);
              }}
            />
            <label
              className="main-label-image"
              onClick={imageUploader}
              htmlFor="upload-img"
            >
              {t("Add Image")}
            </label>
          </div>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newAdmin}
                  setState={setNewAdmin}
                />
              );
            })}
            <GrandPermissionsTable
              permissionsObject={permissionsObject}
              setPermissionsObject={setPermissionsObject}
            />
          </div>
          <div className="button-grid">
            <button
              className="main-button-admin"
              onClick={() => {
                resetInputs();
                navigate("/admins");
              }}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Cancel")
              )}
            </button>
            <button className="main-button-admin">
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Add Admin")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
