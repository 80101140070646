import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotificationServices from "../../../services/NotificationServices";
import toastPopup from "../../../helpers/toastPopup";
import MainTable from "../../../Components/MainTable/MainTable";
import MainCard from "../../../Components/MainCard/MainCard";
import { useSelector } from "react-redux";
import { ReactComponent as SearchLogo } from "../../../assets/VIP-ICON-SVG/SearchLogo.svg";
import "./AllNotification.scss";

export default function AllNotification(props) {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { notification: notificationPermissions } = frontPermissions;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [notifications, setNotifications] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [totalNotification, setTotalNotification] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  async function getAllNotification() {
    setLoading(true);
    try {
      const { data } = await NotificationServices.listAllNotification(
        props?.text,
        searchValue,
        currentPage
      );
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let returnedObject = notificationPermissions.canDelete
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Photo: (
              <div className="product-image-container">
                <img
                  src={`${record?.image?.Location}`}
                  alt="Notification Image"
                  className="product-image"
                />
              </div>
            ),
            Text: (record?.text).slice(0, 50),
            Gender: record?.gender,
            "Seen By": record?.seenBy.length,
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Photo: (
              <div className="product-image-container">
                <img
                  src={`${record?.image?.Location}`}
                  alt="Notification Image"
                  className="product-image"
                />
              </div>
            ),
            Text: (record?.text).slice(0, 50),
            Gender: record?.gender,
            "Seen By": record?.seenBy.length,
          };

        return returnedObject;
      });

      setNotifications(notification => {
        if ((searchValue && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...notification, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalNotification(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteNotification(id) {
    setLoading(true);
    try {
      const { data } = await NotificationServices.deleteNotification(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleNotificationRowClick(id) {
    navigate(`/notifications/${id}`);
  }

  useEffect(() => {
    getAllNotification();
  }, [searchValue, currentPage]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>
              {t("Are you sure you want to delete")}
              {t("?")}
            </h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteNotification(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div>
        <MainCard
          text1={"Total Notifications"}
          text2={"Notifications"}
          btnText="Add Notification"
          showBtn={notificationPermissions.canCreate}
          total={totalNotification}
          onBtnClick={() => {
            navigate(`/notifications/add-notification`);
          }}
        />

        <div className="form-search">
          <div className="form-input-search">
            <input
              onChange={(e) => {
                setCurrentPage(1);
                setSearchValue(prev => {
                  if (prev && e.target.value === '') {
                    navigate(0)
                  }
                  return e.target.value
                });
              }}
              className="input-search"
              type="text"
              name="search"
              id="search"
              placeholder={t("What Are You Looking For?")}
            />
            <label className="label-search">
              <SearchLogo className="SearchLogo" />
            </label>
          </div>
        </div>

        {loading ? (
          <div className="loading-spinner">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        ) : (

          notifications?.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable
              onRowClick={handleNotificationRowClick}
              tableRows={notifications}
            />
          )
        )}

        {notifications.length !== totalNotification &&
          <div className="load">
            <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
              {t("Load more...")}
            </button>
          </div>}

      </div>
    </>
  );
}
