import Axios from "./Axios";

let ReviewServices = {
  getReview: async (id) => {
    const response = await Axios.get(`/admin/review/get?_id=${id}`);
    return response;
  },

  deleteReview: async (id) => {
    const response = await Axios.delete(`/admin/review/remove?_id=${id}`);
    return response;
  },

  listAllReviews: async (type = "", page, limit = 25) => {
    const response = await Axios.get(`/admin/review/list?${type ? `&type=${type}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default ReviewServices;
