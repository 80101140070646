import Axios from "./Axios";

let RequestServices = {
  getRequest: async (id) => {
    const response = await Axios.get(`/admin/join/get?_id=${id}`);
    return response;
  },

  deleteRequest: async (id) => {
    const response = await Axios.delete(`/admin/join/remove?_id=${id}`);
    return response;
  },

  listAllPendingRequests: async (lang = "", search = "", type = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/join/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}${type ? `&type=${type}` : ""}&status=pending${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  listAllAcceptedRequests: async (lang = "", search = "", type = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/join/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}${type ? `&type=${type}` : ""}&status=accepted${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  listAllRejectedRequests: async (lang = "", search = "", type = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/join/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}${type ? `&type=${type}` : ""}&status=rejected${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  editRequest: async (id, obj) => {
    const response = await Axios.put(`/admin/join/update?_id=${id}`, obj);
    return response;
  },
};

export default RequestServices;
