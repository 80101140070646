import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainTable from "../../Components/MainTable/MainTable";
import toastPopup from "../../helpers/toastPopup";
import i18n from "../../locales/i18n";
import ServiceServices from "../../services/ServiceServices";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import CategoryServices from "../../services/CategoryServices";
import { useSelector } from "react-redux";
import "./Service.scss";

export default function Service() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { service: servicePermissions } = frontPermissions;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = i18n.language;

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [services, setServices] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [totalServices, setTotalServices] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllServices() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await ServiceServices.listAllServices(
        lang,
        searchValue,
        filterValue === 'All' ? '' : filterValue,
        currentPage
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let serviceName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.serviceName?.en) {
          serviceName = record?.serviceName?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.serviceName?.en && record?.serviceName?.ar) {
          serviceName = record?.serviceName?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.serviceName?.ar) {
          serviceName = record?.serviceName?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.serviceName?.ar && record?.serviceName?.en) {
          serviceName = record?.serviceName?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.serviceName?.en && !record?.serviceName?.ar) {
          serviceName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.serviceName?.ar && !record?.serviceName?.en) {
          serviceName = "لا يوجد أسم"
        }

        let providerName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.providerName?.en) {
          providerName = record?.providerName?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.providerName?.en && record?.providerName?.ar) {
          providerName = record?.providerName?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.providerName?.ar) {
          providerName = record?.providerName?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.providerName?.ar && record?.providerName?.en) {
          providerName = record?.providerName?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.providerName?.en && !record?.providerName?.ar) {
          providerName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.providerName?.ar && !record?.providerName?.en) {
          providerName = "لا يوجد أسم"
        }
        let returnedObject = servicePermissions.canDelete
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            "Service Name": serviceName,
            Provider: providerName,
            Phone: record?.contacts?.phone,
            "Publish Date": formatDate(new Date(record?.publishDate)),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            "Service Name": serviceName,
            Provider: providerName,
            Phone: record?.contacts?.phone,
            "Publish Date": formatDate(new Date(record?.publishDate)),
          };

        return returnedObject;
      });
      setServices(service => {
        if ((filterValue && currentPage === 1) || (searchValue && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...service, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalServices(data?.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteService(id) {
    setLoading(true);
    try {
      const { data } = await ServiceServices.deleteService(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getServiceCategories() {
    try {
      const { data } = await CategoryServices.listCategoriesByType("service");
      setCategories(data?.records);
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleServiceRowClick(id) {
    navigate(`/services/${id}`);
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getServiceCategories();
  }, []);

  useEffect(() => {
    getAllServices();
  }, [lang, searchValue, filterValue, currentPage]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {(currentUser?.serviceName?.[lang]).toUpperCase()} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteService(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
          </div>
          <div className="filter">
            <div className="items">
              <div>
                <input
                  defaultChecked
                  value="All"
                  onClick={(e) => {
                    setCurrentPage(1);
                    setFilterValue(e.target.value);
                  }}
                  type="radio"
                  name="filter"
                  id="all"
                />
                <label htmlFor="all">{t("All")}</label>
              </div>

              {categories?.map((category, index) => {
                return (
                  <div key={index}>
                    <input
                      value={category?._id}
                      onClick={(e) => {
                        setCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id={category?.name?.en}
                    />
                    <label htmlFor={category?.name?.en}>
                      {category?.name?.[lang]}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          {services.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable
              onRowClick={handleServiceRowClick}
              tableRows={services}
            />
          )}

          {
            services.length !== totalServices &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
