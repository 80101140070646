import Axios from "./Axios";

let JobServices = {
  getJob: async (id) => {
    const response = await Axios.get(`/admin/job/get?_id=${id}`);
    return response;
  },

  deleteJob: async (id) => {
    const response = await Axios.delete(`/admin/job/remove?_id=${id}`);
    return response;
  },

  listAllJobs: async (lang = "", search = "", categoryId = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/job/list?${search.length > 0 ? `&jobTitle.${lang}=${search}` : ""
      }${categoryId ? `&category._id=${categoryId}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },
};

export default JobServices;
