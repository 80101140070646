import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../locales/i18n";
import CategoryServices from "../../services/CategoryServices";
import MainTable from "../../Components/MainTable/MainTable";
import MainCard from "../../Components/MainCard/MainCard";
import toastPopup from "../../helpers/toastPopup";
import "./SortCategory.scss";
import {
  moveOneDown,
  moveOneUp,
  moveToEnd,
  moveToTop,
} from "../../helpers/arraySorting";

export default function SortCategory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  const params = useParams();

  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCategories, setTotalCategories] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryType, setCategoryType] = useState("")

  async function getCategories() {
    try {
      const { data } = await CategoryServices.listAllCategories(
        categoryType
      );
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        return {
          id: record._id,
          "English Name": (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {(record?.name?.en).toUpperCase()}
            </div>
          ),
          "Arabic Name": (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.name?.ar}
            </div>
          ),
          Type: (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.type}
            </div>
          ),
          Rank: (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.rank}
            </div>
          ),
        };
      });

      setCategory(tableData);
      setTotalCategories(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleSortCategoryRowClick(id) {
    navigate(`/sort-categories/category-details/${id}`);
  }

  useEffect(() => {
    getCategories();
  }, [categoryType]);

  let tableRows = category.map((singleCategorey, index) => {
    return {
      Num: (
        <div
          className={
            params.id === singleCategorey.id ? "index to-be-sorted" : "index"
          }
        >
          {index + 1}
        </div>
      ),
      ...singleCategorey,
      Arrange: (
        <div
          className={
            params.id === singleCategorey.id
              ? " sorting to-be-sorted"
              : "sorting"
          }
        >
          <button
            disabled={index === 0}
            onClick={(e) => {
              e.stopPropagation();
              moveOneUp(index, category, setCategory);
            }}
            className={
              index === 0 ? "arrange-button-disabled" : "arrange-button"
            }
          >
            <i className="fa-solid fa-angle-up"></i>
          </button>
          <button
            disabled={index === 0}
            onClick={(e) => {
              e.stopPropagation();
              moveToTop(index, category, setCategory);
            }}
            className={
              index === 0 ? "arrange-button-disabled" : "arrange-button"
            }
          >
            <i className="fa-solid fa-angles-up"></i>
          </button>
          <button
            disabled={index === category.length - 1}
            onClick={(e) => {
              e.stopPropagation();
              moveOneDown(index, category, setCategory);
            }}
            className={
              index === category.length - 1
                ? "arrange-button-disabled"
                : "arrange-button"
            }
          >
            <i className="fa-solid fa-angle-down"></i>
          </button>
          <button
            disabled={index === category.length - 1}
            onClick={(e) => {
              e.stopPropagation();
              moveToEnd(index, category, setCategory);
            }}
            className={
              index === category.length - 1
                ? "arrange-button-disabled"
                : "arrange-button"
            }
          >
            <i className="fa-solid fa-angles-down"></i>
          </button>
        </div>
      ),
    };
  });

  async function applySortHandler() {
    let sortedArray = tableRows.map((row, index) => {
      return {
        _id: row.id,
        rank: index + 1,
      };
    });

    let finalSortedArray = {
      list: sortedArray,
    };

    setLoading(true);
    try {
      const { data } = await CategoryServices.sortCategories(finalSortedArray);
      setLoading(false);
      toastPopup.success(t("Sorted Successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Categories"}
            text2={"Sort Categories"}
            total={totalCategories}
            btnText="Apply Sort"
            onBtnClick={applySortHandler}
          />

          <div className="form-search">
            <div className="filter-by-container">
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCategoryType(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="">
                    {t("Type")}
                  </option>
                  <option value={"vendor"}>{t("Vendor")}</option>
                  <option value={"org"}>{t("Organization")}</option>
                  <option value={"product"}>{t("Product")}</option>
                  <option value={"job"}>{t("Job")}</option>
                  <option value={"service"}>{t("Service")}</option>
                </select>
              </div>
            </div>
          </div>

          {totalCategories === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable
              onRowClick={handleSortCategoryRowClick}
              tableRows={tableRows}
            />
          )}
        </div>
      )}
    </>
  );
}
