import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { authActions } from "../../store/auth-slice";
import "./Sidebar.scss";

export default function Sidebar() {
  const { t } = useTranslation();

  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let {
    admin,
    vendor,
    category,
    branch,
    cashier,
    product,
    client,
    org,
    agent,
    job,
    post,
    service,
    join,
    ad,
    banner,
    notification,
    order,
    orderRequest,
    coupon,
    wishlist,
    cart,
    points,
    page,
    review,
    interest,
    profession,
    barcode,
    subscription,
    chat,
    setting,
  } = frontPermissions;

  const [sideMenuOpened, setSideMenuOpened] = useState(false);

  const dispatch = useDispatch();

  function logoutHandler() {
    dispatch(authActions.logout());
  }

  return (
    <>
      <div className="side">
        {sideMenuOpened ? (
          <i
            className="fa-solid fa-arrow-right click"
            onClick={() => {
              setSideMenuOpened(!sideMenuOpened);
            }}
          ></i>
        ) : (
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              setSideMenuOpened(!sideMenuOpened);
            }}
          ></i>
        )}

        <ul>
          {page.canView && (
            <li>
              <NavLink
                to="about"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("About")}</div>
              </NavLink>
            </li>
          )}

          {admin.canView && (
            <li>
              <NavLink
                to="/admins"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Admins")}</div>
              </NavLink>
            </li>
          )}

          {ad.canView && (
            <li>
              <NavLink
                to="/ads"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Ads")}</div>
              </NavLink>
            </li>
          )}

          {agent.canView && (
            <li>
              <NavLink
                to="/agents"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Agents")}</div>
              </NavLink>
            </li>
          )}

          {banner.canView && (
            <li>
              <NavLink
                to="banners"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Banners")}</div>
              </NavLink>
            </li>
          )}

          {barcode.canView && (
            <li>
              <NavLink
                to="/barcodes"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Barcodes")}</div>
              </NavLink>
            </li>
          )}

          {cart.canView && (
            <li>
              <NavLink
                to="carts"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Carts")}</div>
              </NavLink>
            </li>
          )}

          {category.canView && (
            <li>
              <NavLink
                to="/categories"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Categories")}</div>
              </NavLink>
            </li>
          )}

          {client.canView && (
            <li>
              <NavLink
                to="/clients"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Clients")}</div>
              </NavLink>
            </li>
          )}

          {page.canView && (
            <li>
              <NavLink
                to="faqs"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("FAQS")}</div>
              </NavLink>
            </li>
          )}

          {interest.canView && (
            <li>
              <NavLink
                to="interests"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Interests")}</div>
              </NavLink>
            </li>
          )}

          {job.canView && (
            <li>
              <NavLink
                to="jobs"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Jobs")}</div>
              </NavLink>
            </li>
          )}

          {chat.canView && (
            <li>
              <NavLink
                to="messages"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Messages")}</div>
              </NavLink>
            </li>
          )}

          {notification.canView && (
            <li>
              <NavLink
                to="notifications"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Notifications")}</div>
              </NavLink>
            </li>
          )}

          {order.canView && (
            <li>
              <NavLink
                to="orders"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Orders")}</div>
              </NavLink>
            </li>
          )}

          {orderRequest.canView && (
            <li>
              <NavLink
                to="order-requests"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Order Requests")}</div>
              </NavLink>
            </li>
          )}

          {org.canView && (
            <li>
              <NavLink
                to="/organizations"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Organizations")}</div>
              </NavLink>
            </li>
          )}

          {points.canView && (
            <li>
              <NavLink
                to="points"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Points")}</div>
              </NavLink>
            </li>
          )}

          {banner.canView && (
            <li>
              <NavLink
                to="popups"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("PopUps")}</div>
              </NavLink>
            </li>
          )}

          {profession.canView && (
            <li>
              <NavLink
                to="professions"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Professions")}</div>
              </NavLink>
            </li>
          )}

          {join.canView && (
            <li>
              <NavLink
                to="/requests"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Requests")}</div>
              </NavLink>
            </li>
          )}

          {review.canView && (
            <li>
              <NavLink
                to="reviews"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Reviews")}</div>
              </NavLink>
            </li>
          )}

          {service.canView && (
            <li>
              <NavLink
                to="services"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Services")}</div>
              </NavLink>
            </li>
          )}

          {category.canSort && (
            <li>
              <NavLink
                to="/sort-categories"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Sort Categories")}</div>
              </NavLink>
            </li>
          )}

          {product.canSort && (
            <li>
              <NavLink
                to="/sort-products"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Sort Products")}</div>
              </NavLink>
            </li>
          )}

          {vendor.canSort && (
            <li>
              <NavLink
                to="/sort-vendors"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Sort Vendors")}</div>
              </NavLink>
            </li>
          )}

          {subscription.canView && (
            <li>
              <NavLink
                to="/subscriptions"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Subscriptions")}</div>
              </NavLink>
            </li>
          )}

          {vendor.canView && (
            <li>
              <NavLink
                to="/vendors"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Vendors")}</div>
              </NavLink>
            </li>
          )}

          {coupon.canView && (
            <li>
              <NavLink
                to="/vouchers"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Vouchers")}</div>
              </NavLink>
            </li>
          )}

          {wishlist.canView && (
            <li>
              <NavLink
                to="wishlists"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Wishlists")}</div>
              </NavLink>
            </li>
          )}

          <li>
            <div className="line"></div>
          </li>
          {setting.canView && (
            <li>
              <NavLink
                to="settings"
                className={(navData) =>
                  navData.isActive ? "active" : "content"
                }
              >
                <div>{t("Settings")}</div>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to="logout"
              onClick={(e) => { e.preventDefault(); logoutHandler() }}
              className={(navData) => (navData.isActive ? "active" : "content")}
            >
              <div>{t("Log Out")}</div>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}
