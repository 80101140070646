import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReviewServices from "../../services/ReviewServices";
import toastPopup from "../../helpers/toastPopup";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import { useSelector } from "react-redux";
import "./Reviews.scss";

export default function Reviews() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { review: reviewPermissions } = frontPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalReviews, setTotalReviews] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllReviews() {
    try {
      const { data } = await ReviewServices.listAllReviews(
        filterValue === "All" ? "" : filterValue,
        currentPage
      );
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && !record?.client?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && !record?.client?.name?.en) {
          clientName = "لا يوجد أسم"
        }

        let vendorName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && !record?.vendor?.name?.ar) {
          vendorName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && !record?.vendor?.name?.en) {
          vendorName = "لا يوجد أسم"
        }
        let returnedObject = reviewPermissions.canDelete
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: clientName,
            Vendor: vendorName,
            Review: record?.review?.substring(0, 20),
            Rating: record?.rating,
            Type: record?.type,
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: clientName,
            Vendor: vendorName,
            Review: record?.review?.substring(0, 20),
            Rating: record?.rating,
            Type: record?.type,
          };

        return returnedObject;
      });

      setReviews(review => {
        if ((filterValue && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...review, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalReviews(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteReview(id) {
    setLoading(true);
    try {
      const { data } = await ReviewServices.deleteReview(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleReviewRowClick(id) {
    navigate(`/reviews/${id}`);
  }

  useEffect(() => {
    getAllReviews();
  }, [filterValue, currentPage]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>
              {t("Are you sure you want to delete")}
              {t("?")}
            </h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteReview(currentUser?._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Reviews"}
            text2={"Reviews"}
            total={totalReviews}
          />

          <div className="filter">
            <div className="items">
              <div>
                <input
                  defaultChecked
                  value="All"
                  onChange={(e) => {
                    setCurrentPage(1);
                    setFilterValue(e.target.value);
                  }}
                  type="radio"
                  name="filter"
                  id="all"
                />
                <label htmlFor="all">{t("All")}</label>
              </div>

              <div>
                <input
                  value="vendor"
                  onChange={(e) => {
                    setCurrentPage(1);
                    setFilterValue(e.target.value);
                  }}
                  type="radio"
                  name="filter"
                  id="vendor"
                />
                <label htmlFor="vendor">{t("Vendor")}</label>
              </div>

              <div>
                <input
                  value="product"
                  onChange={(e) => {
                    setCurrentPage(1);
                    setFilterValue(e.target.value);
                  }}
                  type="radio"
                  name="filter"
                  id="product"
                />
                <label htmlFor="product">{t("Product")}</label>
              </div>
            </div>
          </div>

          {totalReviews === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handleReviewRowClick} tableRows={reviews} />
          )}

          {
            reviews.length !== totalReviews &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
