import { t } from "i18next";
import React from "react";
import { useState } from "react";
import "./style.scss";
import { ReactComponent as EyeOPen } from "../../assets/VIP-ICON-SVG/eye_open.svg";
import { ReactComponent as EyeClose } from "../../assets/VIP-ICON-SVG/eye_close.svg";

export default function MainInput({
  name = "",
  type = "text",
  setState = () => { },
  state = {},
  required = false,
  disabled = false,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <div className="main-input-label">
        {type === "password" ? (
          showPassword ? (
            <EyeOPen
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
              className="show-password-icon"
            />
          ) : (
            <EyeClose
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
              className="show-password-icon"
            />
          )
        ) : null}

        <input
          disabled={disabled ? disabled : ""}
          value={state[name]}
          onChange={(e) => {
            setState({ ...state, [name]: e.target.value });
          }}
          required={required ? required : ""}
          className="main-input"
          type={!type === "password" ? type : showPassword ? "text" : type}
          name={name}
          id={name}
          placeholder=" "
        />

        <label className="main-label" htmlFor={name}>
          {t((name.charAt(0)).toUpperCase() + name.slice(1))}
        </label>
      </div>
    </div>
  );
}
