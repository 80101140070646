import React, { useRef, useState } from 'react'
import MainInput from '../../../../Components/MainInput'
import { useTranslation } from 'react-i18next';
import toastPopup from '../../../../helpers/toastPopup';
import { useNavigate, useParams } from 'react-router-dom';
import VendorServices from '../../../../services/VendorServices';
import COUNTRIES_CITIES from '../../../../locales/app-countries.json'
import i18n from "../../../../locales/i18n";
import './AddBranch.scss'

export default function AddBranch() {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [countryIndex, setCountryIndex] = useState('')
  const [cityIndex, setCityIndex] = useState('')
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadCover, setUploadCover] = useState(null);
  const [isActive, setIsActive] = useState(false)
  const [hasDelivery, setHasDelivery] = useState(false)

  const { t } = useTranslation();
  const navigate = useNavigate()
  let params = useParams()
  const lang = i18n.language;

  const [newBranch, setNewBranch] = useState({
    name_en: "",
    name_ar: "",
    branchManager: "",
    managerPhone: "",
    email: "",
    password: "",
    location_lat: "",
    location_long: "",
    address_en: "",
    address_ar: "",
    phone: "",
    link: ""
  });

  function resetInputs() {
    setNewBranch({
      name_en: "",
      name_ar: "",
      branchManager: "",
      managerPhone: "",
      email: "",
      password: "",
      location_lat: "",
      location_long: "",
      address_en: "",
      address_ar: "",
      phone: "",
      link: ""
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "branchManager", type: "text", required: false },
    { name: "managerPhone", type: "text", required: false },
    { name: "email", type: "email", required: true },
    { name: "password", type: "password", required: true },
    { name: "location_long", type: "text", required: false },
    { name: "location_lat", type: "text", required: false },
    { name: "address_en", type: "text", required: false },
    { name: "address_ar", type: "text", required: false },
    { name: "phone", type: "text", required: false },
    { name: "link", type: "text", required: false },
  ];

  async function addNewBranch(e) {
    e.preventDefault();
    setLoading(true);
    try {

      const currentDate = new Date(Date.now());
      const currentDateISO = new Date(currentDate).toISOString();

      const obj = {
        name: {
          en: newBranch.name_en,
          ar: newBranch.name_ar
        },
        vendor: params.id,
        joinDate: currentDateISO,
        branchManager: newBranch.branchManager,
        managerPhone: newBranch.managerPhone,
        email: newBranch.email,
        password: newBranch.password,
        location: {
          lat: parseFloat(newBranch.location_lat),
          long: parseFloat(newBranch.location_long)
        },
        address: {
          en: newBranch.address_en,
          ar: newBranch.address_ar
        },
        phone: newBranch.phone,
        country: {
          en: selectedCountry?.en,
          ar: selectedCountry?.ar,
          index: countryIndex,
        },
        city: {
          en: selectedCity?.en,
          ar: selectedCity?.ar,
          index: cityIndex,
        },
        link: newBranch.link,
        isActive: isActive,
        hasDelivery: hasDelivery
      }

      function isEmpty(value) {
        return (
          typeof value === 'string' && value.trim() === '' ||
          value === undefined ||
          value === null
        );
      }

      if (isEmpty(obj.name.en)) {
        delete obj.name.en;
      }

      if (isEmpty(obj.name.ar)) {
        delete obj.name.ar;
      }

      if (isEmpty(obj.location.lat) || newBranch.location_lat === "") {
        delete obj.location.lat;
      }

      if (isEmpty(obj.location.long) || newBranch.location_long === "") {
        delete obj.location.long;
      }

      if (isEmpty(obj.location.long) || newBranch.location_long === "" &&
        isEmpty(obj.location.lat) || newBranch.location_lat === "") {
        delete obj.location;
      }

      if (isEmpty(obj.country.en)) {
        delete obj.country.en;
      }

      if (isEmpty(obj.country.ar)) {
        delete obj.country.ar;
      }

      if (isEmpty(obj.country.index)) {
        delete obj.country.index;
      }

      if (isEmpty(obj.country.en) && isEmpty(obj.country.ar) && isEmpty(obj.country.index)) {
        delete obj.country;
      }

      if (isEmpty(obj.city.en)) {
        delete obj.city.en;
      }

      if (isEmpty(obj.city.ar)) {
        delete obj.city.ar;
      }

      if (isEmpty(obj.city.index)) {
        delete obj.city.index;
      }

      if (isEmpty(obj.city.en) && isEmpty(obj.city.ar) && isEmpty(obj.city.index)) {
        delete obj.city;
      }

      if (isEmpty(obj.address.en)) {
        delete obj.address.en;
      }

      if (isEmpty(obj.address.ar)) {
        delete obj.address.ar;
      }

      const finalObj = {};

      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }

      const { data } = await VendorServices.addBranch(finalObj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      let branchId = data.record._id
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadBranchImage(branchId, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      var coverFormData = new FormData();
      coverFormData.append("cover", uploadCover);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadBranchCover(branchId, coverFormData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/vendors/${params.id}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  const ref = useRef();
  const coverRef = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };
  const coverUploader = (e) => {
    coverRef.current.click();
  };

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(countryIndex))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(cityIndex))[0]

  return <>

    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate(`/vendors/${params.id}`) }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Branch")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}

      </div>
      <div className="main-image-label vendor-cover">
        {uploadCover && (
          <img
            src={uploadCover ? URL.createObjectURL(uploadCover) : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadCover ? URL.createObjectURL(uploadCover) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={coverRef}
          onChange={(e) => {
            setUploadCover(e.target.files[0]);
          }}
        />
        <label
          className="main-label-image"
          onClick={coverUploader}
          htmlFor="upload-img"
        >
          {t("Add Cover")}
        </label>
      </div>

      <div className="main-image-label vendor-image">
        {uploadImage && (
          <img
            src={uploadImage ? URL.createObjectURL(uploadImage) : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadImage ? URL.createObjectURL(uploadImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={ref}
          onChange={(e) => {
            setUploadImage(e.target.files[0]);
          }}
        />
        <label
          className={uploadImage ? "main-label-image-uploaded" : "main-label-image"}
          onClick={imageUploader}
          htmlFor="upload-img"
        >
          {t("Add Image")}
        </label>
      </div>
      <form className='form-grid' onSubmit={addNewBranch}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newBranch}
                setState={setNewBranch}
              />
            );
          })}

          <div className="main-input-select">
            <select
              selected={countryIndex}
              value={countryIndex}
              onChange={(e) => { setCountryIndex(e.target.value) }}
              className='main-input'
              id="country"
              name="country"
              title='country'>
              <option value=''>{t("Country")}</option>
              {
                COUNTRIES_CITIES?.map((country, index) => {
                  return <option key={index} value={country?.index}>{country?.[lang]}</option>
                })
              }
            </select>
            <label className="main-label">{t("Country")}</label>
          </div>

          <div className="main-input-select">
            <select
              selected={cityIndex}
              value={cityIndex}
              onChange={(e) => { setCityIndex(e.target.value) }}
              className='main-input'
              id="city"
              name="city"
              title='city'>
              <option value=''>{t("City")}</option>
              {
                selectedCountry?.cities?.map((state, index) => {
                  return <option key={index} value={state?.index}>{state?.[lang]}</option>
                })
              }
            </select>
            <label className="main-label">{t("City")}</label>
          </div>

          <div className='toggler'>
            <label htmlFor="productImage" className="word">{t("Is Active")}</label>
            <label className="switch form-group">
              <input onClick={(e) => { setIsActive(e.target.checked) }} type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className='toggler'>
            <label htmlFor="productImage" className="word">{t("Has Delivery")}</label>
            <label className="switch form-group">
              <input onClick={(e) => { setHasDelivery(e.target.checked) }} type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>


        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate(`/vendors/${params.id}`) }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Branch")
            )}
          </button>
        </div>
      </form>
    </div>

  </>
}
