import React, { useEffect, useState } from 'react'
import VendorServices from '../../../../services/VendorServices'
import i18n from '../../../../locales/i18n'
import ClientServices from '../../../../services/ClientServices'
import MainTable from '../../../../Components/MainTable/MainTable'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './WishList.scss'

export default function WishList() {

  const lang = i18n.language
  let params = useParams()
  const { t } = useTranslation();

  const [vendorName, setVendorName] = useState('');
  const [wishListItems, setWishListItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({})

  async function getVendor() {
    try {
      const { data } = await VendorServices.getVendor(currentUser.vendor)
      setVendorName(data.record[0].name[lang])
    }
    catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getClientWishlist() {
    try {
      const { data } = await ClientServices.getClientWishlist(params.id)
      setLoading(false);
      let tableData = data.record.items.map((item, index) => {
        return {
          Num: <div className='index'>{index + 1}</div>,
          id: item._id,
          Photo: <div className="product-image-container">
            <img
              src={`${item?.product?.image[0]?.Location}`}
              alt="Product Image"
              className="product-image" />
          </div>,
          Name: (item?.product?.name?.[lang]),
          Price: (item?.product?.price) + t(" EGP")
        }
      })
      setWishListItems(tableData)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getClientWishlist()
  }, [])

  return <>
    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}
    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (<div>
        {wishListItems.length > 0 ?
          <MainTable
            tableRows={wishListItems} />
          : <p className='no-results'>{t("Wishlist is currently empty!")}</p>}
      </div>)}
  </>
}
