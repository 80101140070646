import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import OrganizationServices from "../../../services/OrganizationServices";
import Clients from "./Clients/Clients";
import toastPopup from "../../../helpers/toastPopup";
import ClientServices from "../../../services/ClientServices";
import "./OrganizationDetails.scss";

export default function OrganizationDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { org: orgPermissions } = frontPermissions;
  let { client: clientPermissions } = frontPermissions;

  let canEditOrg = orgPermissions.canEdit;

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();

  const [organization, setOrganization] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [totalClients, setTotalClients] = useState(0);
  const [excel, setExcel] = useState({});
  const [generated, setGenerated] = useState(false);

  async function getOrganization() {
    setLoading(true);
    try {
      const { data } = await OrganizationServices.getOrganization(params.id);
      setLoading(false);
      setOrganization(data.record[0]);
      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getOrganizationClients() {
    setLoading(true);
    try {
      const { data } = await ClientServices.listAllOrganizationClientsWithoutPagination(params.id);
      setTotalClients(data?.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let orgName = ""
  if (localStorage.getItem("i18nextLng") === "en" && organization?.name?.en) {
    orgName = organization?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !organization?.name?.en && organization?.name?.ar) {
    orgName = organization?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && organization?.name?.ar) {
    orgName = organization?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !organization?.name?.ar && organization?.name?.en) {
    orgName = organization?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !organization?.name?.en && !organization?.name?.ar) {
    orgName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !organization?.name?.ar && !organization?.name?.en) {
    orgName = "لا يوجد أسم"
  }

  let organizationDetails = [
    {
      id: "english",
      title: "Name",
      type: "text",
      value: orgName,
    },
    { id: "email", title: "Email", type: "email", value: organization?.email },
    { id: "phone", title: "Phone", type: "text", value: organization?.phone },
    {
      id: "english_category",
      title: "English Category",
      type: "text",
      value: organization?.category?.name?.en,
    },
    {
      id: "arabic_category",
      title: "Arabic Category",
      type: "text",
      value: organization?.category?.name?.ar,
    },
    {
      id: "credit",
      title: "Credit",
      type: "number",
      value: organization?.credit,
    },
    {
      id: "totalClients",
      title: "Total Clients",
      type: "number",
      value: totalClients,
    },
  ];

  async function deleteOrganizationImage() {
    setLoading(true);
    try {
      const { data } = await OrganizationServices.deleteOrganizationImage(params.id);
      setLoading(false);
      setModalShow(false);
      getOrganization()
      setImage(false)
      toastPopup.success(t("Image deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function downloadExcelHandler() {
    setExcelLoading(true);
    try {
      const { data } = await OrganizationServices.generateExcel(params?.id);
      setExcel(data?.record);
      setExcelLoading(false);
      setGenerated(true)
    } catch (e) {
      setExcelLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getOrganization();
    getOrganizationClients()
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete this image?")}</h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteOrganizationImage()}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate(`/organizations`);
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {image ? (
                <>
                  <img
                    src={organization?.image?.Location}
                    alt="Organization Image"
                  />
                  <div className="delete-image" onClick={() => { setModalShow(true); }}>
                    <i className="fa-solid fa-trash"></i>
                  </div>
                </>
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Organization Image"
                />
              )}
            </div>
            <div className="client-datails">
              {canEditOrg && (
                <div className="edit">
                  <button
                    onClick={() => navigate(`/organizations/${params.id}/edit`)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                </div>
              )}
              <div className="data">
                {organizationDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Active")}
                  </label>
                  <label className="switch form-group">
                    <input
                      checked={organization?.isActive}
                      type="checkbox"
                      readOnly
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="count">
                  <a
                    href={excel?.clientsFile?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadExcelHandler()
                    }}
                    className="chat-with"
                  >
                    {generated ? "Download " : "Generate "}
                    {excelLoading ? <i className="fas fa-spinner fa-spin"></i> : <i className="fa-solid fa-cloud-arrow-down"></i>}
                  </a>
                </div>
              </div>
            </div>

            {clientPermissions.canView && (
              <div className="branch-cards-container">
                <Clients orgCredit={organization?.credit} />
              </div>
            )}
          </div>
        )
      )}
    </>
  );
}
