import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BarcodeServices from "../../../services/BarcodeServices";
import toastPopup from "../../../helpers/toastPopup";
import MainInput from "../../../Components/MainInput";
import OrganizationServices from "../../../services/OrganizationServices";
import i18n from "../../../locales/i18n";
import "./AddBarcode.scss";

export default function AddBarcode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [orgId, setOrgId] = useState("");
  const [organizations, setOrganizations] = useState([]);

  const [newBarcode, setNewBarcode] = useState({
    name: "",
    numberOfBarcodes: 0,
  });

  function resetInputs() {
    setNewBarcode({
      name: "",
      numberOfBarcodes: 0,
    });
  }

  let formData = [
    { name: "name", type: "text", required: true },
    { name: "numberOfBarcodes", type: "number", required: true },
  ];

  async function addPdfHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        count: newBarcode.numberOfBarcodes,
        name: newBarcode.name,
        org: params.orgID ? params.orgID : orgId,
      };

      const obj2 = {
        count: newBarcode.numberOfBarcodes,
        name: newBarcode.name,
      };
      if (newBarcode.numberOfBarcodes === 0) {
        toastPopup.error(t("Count cannot be 0"));
        setLoading(false);
      } else if (newBarcode.numberOfBarcodes > 1000) {
        toastPopup.error(t("Max number of barcodes is 100"));
        setLoading(false);
      } else {
        const { data } = await BarcodeServices.addPdf(
          orgId.length === 0 ? obj2 : obj
        );
        setLoading(false);
        resetInputs();
        toastPopup.success(t("Added"));
        navigate("/barcodes");
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function addExcelHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        count: newBarcode.numberOfBarcodes,
        name: newBarcode.name,
        org: params.orgID ? params.orgID : orgId,
      };

      const obj2 = {
        count: newBarcode.numberOfBarcodes,
        name: newBarcode.name,
      };
      if (newBarcode.numberOfBarcodes === 0) {
        toastPopup.error(t("Count cannot be 0"));
        setLoading(false);
      } else if (newBarcode.numberOfBarcodes > 100) {
        toastPopup.error(t("Max number of barcodes is 100"));
        setLoading(false);
      } else {
        const { data } = await BarcodeServices.addExcel(
          orgId.length === 0 ? obj2 : obj
        );
        setLoading(false);
        resetInputs();
        toastPopup.success(t("Added"));
        navigate("/barcodes");
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function getAllActiveOrganizations() {
    try {
      const { data } = await OrganizationServices.listAllActiveOrganizations();
      setLoading(false);
      setOrganizations(data.records);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getAllActiveOrganizations();
  }, []);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate("/barcodes");
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Generate Barcodes")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <form className="form-grid">
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newBarcode}
                  setState={setNewBarcode}
                />
              );
            })}
            <div className="main-input-select">
              <select
                onChange={(e) => {
                  setOrgId(e.target.value);
                }}
                className="main-input"
                id="Organization"
                name="Organization"
                title="Organization"
                value={params.orgID ? params.orgID : orgId}
              >
                <option value="">-- {t("Organization")} --</option>
                {organizations.map((org, index) => {
                  return (
                    <option key={index} value={org._id}>
                      {org?.name?.[lang]}
                    </option>
                  );
                })}
              </select>
              <label className="main-label">{t("Organization")}</label>
            </div>
          </div>
          <div className="button-grid">
            <button className="main-button-admin" onClick={addExcelHandler}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Generate Excel")
              )}
            </button>
            <button className="main-button-admin" onClick={addPdfHandler}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Generate PDF")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
