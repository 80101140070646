import Axios from "./Axios";

let SettingServices = {
  getSettings: async () => {
    const response = await Axios.get(`/admin/setting/list`);
    return response;
  },

  editSettings: async (obj) => {
    const response = await Axios.put(`/admin/setting/update`, obj);
    return response;
  },
};

export default SettingServices;
