import React, { useState } from "react";
import { ReactComponent as AdminLogin } from "../../assets/VIP-ICON-SVG/AdminLogin.svg";
import MainInput from "../../Components/MainInput";
import toastPopup from "../../helpers/toastPopup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { switchLang } from "../../helpers/lang";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import AuthServices from "../../services/AuthServices";
import "./Login.scss";

export default function Login() {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()

  function changeLang(lang) {
    i18n.changeLanguage(lang);
    switchLang(lang);
  }

  const [admin, setAdmin] = useState({
    email: "",
    password: "",
  });

  let formData = [
    { name: "email", type: "email", required: true },
    { name: "password", type: "password", required: true },
  ];

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await AuthServices.login(admin)
      setLoading(false);
      dispatch(
        authActions.login({
          AdminToken: data.token,
          frontPermissions: data?.record?.frontPermissions,
          role: data?.record?.role,
        })
      );
      navigate("/");
      toastPopup.success(t("Success"));
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  return (
    <>
      <div className="login-admin">
        <div className="login-admin-left">
          <div className="admin-login-div">
            <AdminLogin className="admin-login-logo" />
          </div>
        </div>

        <div className="login-admin-right">
          <div className="lang">
            {localStorage.getItem("i18nextLng") === "en" ? (
              <button onClick={() => changeLang("ar")}>العربية</button>
            ) : (
              <button onClick={() => changeLang("en")}>English</button>
            )}
          </div>
          <form onSubmit={login}>
            <div className="admin-login-box">
              <p>{t("Login")}</p>

              {errorMessage ? (
                <div className="err">
                  {errorMessage.includes("Admin")
                    ? t("Admin is not found!")
                    : t("Incorrect Password")}
                </div>
              ) : (
                ""
              )}

              {formData.map((formInput, index) => {
                return (
                  <MainInput
                    key={index}
                    name={formInput.name}
                    type={formInput.type}
                    required={formInput.required}
                    state={admin}
                    setState={setAdmin}
                  />
                );
              })}
              <button className="main-button">
                {loading ? (
                  <i className="fas fa-spinner fa-spin "></i>
                ) : (
                  t("Login")
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
