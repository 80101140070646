import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../locales/i18n";
import VendorServices from "../../services/VendorServices";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import toastPopup from "../../helpers/toastPopup";
import "./SortVendor.scss";
import {
  moveOneDown,
  moveOneUp,
  moveToEnd,
  moveToTop,
} from "../../helpers/arraySorting";

export default function SortVendor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  const params = useParams();

  const scrollToRef = useRef(null);

  const [vendor, setVendor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalVendors, setTotalVendors] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([])
  const [vendorCategory, setVendorCategory] = useState("")

  async function getVendors() {
    try {
      const { data } = await VendorServices.listAllVendors(
        vendorCategory
      );
      setLoading(false);
      let tableData = data.records.map((record) => {
        return {
          id: record._id,
          Name: (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.name?.[lang]}
            </div>
          ),
          Email: (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.email}
            </div>
          ),
          Phone: (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.phone}
            </div>
          ),
          "Join Date": (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {formatDate(new Date(record.joinDate))}
            </div>
          ),
        };
      });

      setVendor(tableData);
      setTotalVendors(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getVendorCategories() {
    try {
      const { data } = await VendorServices.getVendorCategories("vendor");
      setCategories(data?.records);
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleSortVendorRowClick(id) {
    navigate(`/sort-vendors/vendor-details/${id}`);
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [vendor])

  useEffect(() => {
    getVendorCategories()
  }, [])

  useEffect(() => {
    getVendors();
  }, [vendorCategory]);

  let tableRows = vendor.map((singleVendor, index) => {
    return {
      Num: (
        <div
          className={
            params.id === singleVendor.id ? "index to-be-sorted" : "index"
          }
          ref={params?.id === singleVendor.id ? scrollToRef : null}
        >
          {index + 1}
        </div>
      ),
      ...singleVendor,
      Arrange: (
        <div
          className={
            params.id === singleVendor.id ? " sorting to-be-sorted" : "sorting"
          }
        >
          <button
            disabled={index === 0}
            onClick={(e) => {
              e.stopPropagation();
              moveOneUp(index, vendor, setVendor);
            }}
            className={
              index === 0 ? "arrange-button-disabled" : "arrange-button"
            }
          >
            <i className="fa-solid fa-angle-up"></i>
          </button>
          <button
            disabled={index === 0}
            onClick={(e) => {
              e.stopPropagation();
              moveToTop(index, vendor, setVendor);
            }}
            className={
              index === 0 ? "arrange-button-disabled" : "arrange-button"
            }
          >
            <i className="fa-solid fa-angles-up"></i>
          </button>
          <button
            disabled={index === vendor.length - 1}
            onClick={(e) => {
              e.stopPropagation();
              moveOneDown(index, vendor, setVendor);
            }}
            className={
              index === vendor.length - 1
                ? "arrange-button-disabled"
                : "arrange-button"
            }
          >
            <i className="fa-solid fa-angle-down"></i>
          </button>

          <button
            disabled={index === vendor.length - 1}
            onClick={(e) => {
              e.stopPropagation();
              moveToEnd(index, vendor, setVendor);
            }}
            className={
              index === vendor.length - 1
                ? "arrange-button-disabled"
                : "arrange-button"
            }
          >
            <i className="fa-solid fa-angles-down"></i>
          </button>
        </div>
      ),
    };
  });

  async function applySortHandler() {
    let sortedArray = tableRows.map((row, index) => {
      return {
        _id: row.id,
        rank: index + 1,
      };
    });

    let finalSortedArray = {
      list: sortedArray,
    };

    setLoading(true);
    try {
      const { data } = await VendorServices.sortVendors(finalSortedArray);
      setLoading(false);
      toastPopup.success(t("Sorted Successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Vendors"}
            text2={"Sort Vendors"}
            total={totalVendors}
            btnText="Apply Sort"
            onBtnClick={applySortHandler}
          />

          <div className="form-search">
            <div className="filter-by-container">
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setVendorCategory(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="">
                    {t("Category")}
                  </option>
                  {categories.map((singleCategory) => {
                    return <option key={singleCategory?._id} value={singleCategory?._id}>{singleCategory?.name?.[lang]}</option>
                  })}
                </select>
              </div>
            </div>
          </div>

          {totalVendors === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable
              onRowClick={handleSortVendorRowClick}
              tableRows={tableRows}
            />
          )}
        </div>
      )}
    </>
  );
}
