import React, { useEffect, useState } from 'react'
import i18n from '../../../../../../locales/i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import VendorServices from '../../../../../../services/VendorServices';
import toastPopup from '../../../../../../helpers/toastPopup';
import Rating from 'react-rating';
import './ProductReviews.scss'

export default function ProductReviews() {

  const lang = i18n.language;
  const { t } = useTranslation();
  const navigate = useNavigate()
  let params = useParams();

  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [productReviews, setProductReviews] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  async function getProductReviews() {
    try {
      const { data } = await VendorServices.listAllProductReviews(params.productID);
      setLoading(false);
      setProductReviews(data.records);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteReview(id) {
    setLoading(true);
    try {
      const { data } = await VendorServices.deleteProductReview(id);
      setLoading(false);
      setModalShow(false);
      getProductReviews();
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getProductReviews();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">{t("the review ?")}</p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => {
                  deleteReview(currentUser._id);
                }}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : productReviews.length > 0 ? (
        productReviews.map((review) => {
          return (
            <div className="reviews-card" key={review._id}>
              <div className="image">
                {review?.product?.image ? (
                  <img
                    src={review?.product?.image[0]?.Location}
                    alt="Product Image"
                  />
                ) : (
                  <img
                    src="../../../../assets/images/img.png"
                    alt="Product Image"
                  />
                )}
              </div>
              <div className="data">
                <h3>
                  {review?.product?.name[lang]}
                </h3>
                <p>{review?.review}</p>
                <Rating
                  emptySymbol="fa-regular fa-star"
                  fullSymbol="fa-solid fa-star"
                  fractions={2}
                  start={0}
                  stop={5}
                  readonly
                  placeholderSymbol="fa-solid fa-star"
                  placeholderRating={review?.rating}
                  quiet
                />
                <p
                  className='by'
                  onClick={() => { navigate(`/clients/${review?.client?._id}`) }}>
                  {t("By")}: {review?.client?.name?.[lang]}
                </p>
              </div>
              <div className="delete">
                <button
                  onClick={() => {
                    setModalShow(true);
                    setCurrentUser(review);
                  }}
                  className="remove"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <p className="no-results">
          {t("This client didn't write any reviews yet!")}
        </p>
      )}
    </>
  );
}
