import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BarcodeServices from "../../services/BarcodeServices";
import toastPopup from "../../helpers/toastPopup";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import { useSelector } from "react-redux";
import "./Barcode.scss";

export default function Barcode() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { barcode: barcodePermissions } = frontPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [barcodes, setBarcodes] = useState([]);
  const [totalBarcodes, setTotalBarcodes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllBarcodes() {
    try {
      const { data } = await BarcodeServices.listAllBarcodes(currentPage);
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let returnedObject = barcodePermissions.canDelete
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: record?.name,
            "Number of barcodes": record?.barcodes?.length,
            Type:
              record?.excel && record?.pdf
                ? "Both"
                : record?.excel
                  ? "Excel"
                  : "PDF",
            Download:
              ((record?.excel) && (record?.pdf)) ? (
                <>
                  <a
                    href={record?.pdf?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    PDF <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>

                  <a
                    href={record?.excel?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    Excel <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>
                </>
              ) : (record?.excel) ? (
                <>
                  <a
                    href={record?.excel?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    Excel <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>
                  <a
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                      generatePDFHandler(record?._id);
                    }}
                    className="chat-with"
                  >
                    Generate PDF <i className="fa-solid fa-rotate"></i>
                  </a>
                </>
              ) : (
                <>
                  <a
                    href={record?.pdf?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    PDF <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>
                  <a
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                      generateExcelHandler(record?._id);
                    }}
                    className="chat-with"
                  >
                    Generate Excel <i className="fa-solid fa-rotate"></i>
                  </a>
                </>
              ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: record?.name,
            "Number of barcodes": record?.barcodes?.length,
            Type:
              record?.excel && record?.pdf
                ? "Both"
                : record?.excel
                  ? "Excel"
                  : "PDF",
            Download:
              record?.excel && record?.pdf ? (
                <>
                  <a
                    href={record?.pdf?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    PDF <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>

                  <a
                    href={record?.excel?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    Excel <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>
                </>
              ) : record?.excel ? (
                <>
                  <a
                    href={record?.excel?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    Excel <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>
                  <a
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                      generatePDFHandler(record?._id);
                    }}
                    className="chat-with"
                  >
                    Generate PDF <i className="fa-solid fa-rotate"></i>
                  </a>
                </>
              ) : (
                <>
                  <a
                    href={record?.pdf?.Location}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="chat-with"
                  >
                    PDF <i className="fa-solid fa-cloud-arrow-down"></i>
                  </a>
                  <a
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                      generateExcelHandler(record?._id);
                    }}
                    className="chat-with"
                  >
                    Generate Excel <i className="fa-solid fa-rotate"></i>
                  </a>
                </>
              ),
          };
        return returnedObject;
      });

      setBarcodes(bar => {
        return [...bar, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalBarcodes(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function generatePDFHandler(id) {
    setLoading(true);
    try {
      const { data } = await BarcodeServices.getBarcode(id);
      const obj = {
        barcodes: data?.record[0]?.barcodes,
        _id: data?.record[0]?._id,
      };
      const response = await BarcodeServices.addPdf(obj);
      setLoading(false);
      toastPopup.success(t("Generated PDF successfully"));
      navigate(0)
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function generateExcelHandler(id) {
    setLoading(true);
    try {
      const { data } = await BarcodeServices.getBarcode(id);
      const obj = {
        barcodes: data?.record[0]?.barcodes,
        _id: data?.record[0]?._id,
      };
      const response = await BarcodeServices.addExcel(obj);
      setLoading(false);
      toastPopup.success(t("Generated Excel successfully"));
      navigate(0)
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function deleteBarcode(id) {
    setLoading(true);
    try {
      const { data } = await BarcodeServices.deleteBarcode(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getAllBarcodes();
  }, [currentPage]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteBarcode(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Barcodes"}
            text2={"Barcodes"}
            total={totalBarcodes}
            btnText="Add Barcode"
            showBtn={barcodePermissions.canCreate}
            onBtnClick={() => {
              navigate("/barcodes/add-barcode");
            }}
          />

          {totalBarcodes === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable tableRows={barcodes} />
          )}
          {
            barcodes.length !== totalBarcodes &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
