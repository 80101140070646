import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import toastPopup from "../../helpers/toastPopup";
import i18n from "../../locales/i18n";
import VoucherServices from "../../services/VoucherServices";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import { useSelector } from "react-redux";
import "./Voucher.scss";

export default function Voucher() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { coupon: couponPermissions } = frontPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [activeVoucher, setActiveVoucher] = useState([]);
  const [inActiveVoucher, setInActiveVoucher] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalActiveVouchers, setTotalActiveVouchers] = useState(0);
  const [totalInActiveVouchers, setTotalInActiveVouchers] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [isActiveVoucher, setIsActiveVoucher] = useState(true)
  const [isInActiveVoucher, setIsInActiveVoucher] = useState(false)
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);

  async function getAllActiveVouchers() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveValue);
    let lang = result ? "ar" : "en";
    try {
      const { data } = await VoucherServices.listAllActiveVouchers(lang, searchActiveValue, activeCurrentPage);

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let voucherName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          voucherName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          voucherName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          voucherName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          voucherName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          voucherName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          voucherName = "لا يوجد أسم"
        }
        let returnedObject = couponPermissions.canDelete
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: voucherName,
            Code: record?.code,
            Quantity: record?.quantity,
            Used: record?.clients.length,
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: voucherName,
            Code: record?.code,
            Quantity: record?.quantity,
            Used: record?.clients.length,
          };
        return returnedObject;
      });

      setActiveVoucher(voucher => {
        if ((searchActiveValue && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...voucher, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveVouchers(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInActiveVouchers() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveValue);
    let lang = result ? "ar" : "en";
    try {
      const { data } = await VoucherServices.listAllInActiveVouchers(lang, searchInActiveValue, inActiveCurrentPage);

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let voucherName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          voucherName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          voucherName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          voucherName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          voucherName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          voucherName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          voucherName = "لا يوجد أسم"
        }
        let returnedObject = couponPermissions.canDelete
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: voucherName,
            Code: record?.code,
            Quantity: record?.quantity,
            Used: record?.clients.length,
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: voucherName,
            Code: record?.code,
            Quantity: record?.quantity,
            Used: record?.clients.length,
          };
        return returnedObject;
      });

      setInActiveVoucher(voucher => {
        if ((searchInActiveValue && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...voucher, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveVouchers(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteVoucher(id) {
    setLoading(true);
    try {
      const { data } = await VoucherServices.deleteVoucher(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleVoucherRowClick(id) {
    navigate(`/vouchers/${id}`);
  }

  useEffect(() => {
    if (isActiveVoucher) {
      getAllActiveVouchers();
      setInActiveVoucher([])
    }
  }, [searchActiveValue, activeCurrentPage, isActiveVoucher]);

  useEffect(() => {
    if (isInActiveVoucher) {
      getAllInActiveVouchers();
      setActiveVoucher([])
    }
  }, [searchInActiveValue, inActiveCurrentPage, isInActiveVoucher]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteVoucher(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveVoucher ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveVoucher(true); setIsInActiveVoucher(false) }}
                className='all-branches-btn'>
                <h2>{t("Active Vouchers")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveVoucher(true); setIsInActiveVoucher(false) }}
              className='all-branches-btn'>
              <h2>{t("Active Vouchers")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveVoucher ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveVoucher(false); setIsInActiveVoucher(true) }}
                className='all-branches-btn'>
                <h2>{t("Inactive Vouchers")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveVoucher(false); setIsInActiveVoucher(true) }}
              className='all-branches-btn'>
              <h2>{t("Inactive Vouchers")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveVoucher ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Active Vouchers"}
                text2={"Active Vouchers"}
                btnText="Add Voucher"
                showBtn={couponPermissions.canCreate}
                total={totalActiveVouchers}
                onBtnClick={() => navigate("/vouchers/addvoucher")}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setActiveCurrentPage(1);
                      setSearchActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>

              {isSearchEmpty || totalActiveVouchers === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleVoucherRowClick} tableRows={activeVoucher} />
              )}
              {
                activeVoucher.length !== totalActiveVouchers &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveVoucher ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Inactive Vouchers"}
                text2={"Inactive Vouchers"}
                total={totalInActiveVouchers}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setInActiveCurrentPage(1);
                      setSearchInActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>

              {isSearchEmpty || totalInActiveVouchers === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleVoucherRowClick} tableRows={inActiveVoucher} />
              )}
              {
                inActiveVoucher.length !== totalInActiveVouchers &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
