import React, { useEffect, useState } from "react";
import MainInput from "../../../MainInput";
import { useTranslation } from "react-i18next";
import toastPopup from "../../../../helpers/toastPopup";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../../../locales/i18n";
import VendorServices from "../../../../services/VendorServices";
import ImagesUpload from "../../../ImagesUpload/ImagesUpload";
import "./AddProduct.scss";

export default function AddProduct() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isHotDeal, setIsHotDeal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLimited, setIsLimited] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [categoryID, setCategoryID] = useState("");
  const [vendorProductCategories, setVendorProductCategories] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [branch, setBranch] = useState({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  let params = useParams();

  const [newProduct, setNewProduct] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    price: 0,
    originalPrice: 0,
  });

  function resetInputs() {
    setNewProduct({
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      price: 0,
      originalPrice: 0,
    });
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "description_en", type: "text", required: true },
    { name: "description_ar", type: "text", required: true },
    { name: "price", type: "number", required: true },
    { name: "originalPrice", type: "number", required: true },
  ];

  async function getBranch() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getBranch(
        params.id,
        params.branchID
      );
      setLoading(false);
      setBranch(data.record[0]);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getVendor() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendor(params.id);
      setLoading(false);
      setVendorProductCategories(data?.record[0]?.productCategories);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function addNewProduct(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newProduct.name_en,
          ar: newProduct.name_ar,
        },
        vendor: params.id,
        branches: [
          {
            _id: branch._id,
            name: {
              en: branch?.name?.en,
              ar: branch?.name?.ar,
            },
          },
        ],
        category: categoryID,
        description: {
          en: newProduct.description_en,
          ar: newProduct.description_ar,
        },
        price: newProduct.price,
        originalPrice: newProduct.originalPrice,
        isHotDeal: isHotDeal,
        isActive: isActive,
        isLimited: isLimited,
        isEditable: isEditable,
      };
      const { data } = await VendorServices.addProduct(obj);
      setLoading(false);
      resetInputs();
      toastPopup.success(t("Added"));

      let productID = data.record._id;
      var formData = new FormData();

      uploadImages.forEach((image) => {
        formData.append('image', image.file);
      });

      setLoading(true);
      try {
        const { data } = await VendorServices.uploadProductImage(
          productID,
          formData
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/vendors/${params.id}/branchdetails/${params.branchID}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  useEffect(() => {
    getVendor()
    getBranch();
  }, []);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(
                `/vendors/${params.id}/branchdetails/${params.branchID}`
              );
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Add Product")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <ImagesUpload
          type="upload"
          uploadedImagesList={uploadImages}
          setUploadedImagesList={setUploadImages}
        />

        <form className="form-grid" onSubmit={addNewProduct}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newProduct}
                  setState={setNewProduct}
                />
              );
            })}
            <div className="main-input-select">
              <select
                onChange={(e) => {
                  setCategoryID(e.target.value);
                }}
                className="main-input"
                id="Category"
                name="Category"
                title="Category"
              >
                <option defaultValue="category" selected disabled>
                  -- {t("Category")} --
                </option>
                {vendorProductCategories?.map((cat, index) => {
                  return (
                    <option key={index} value={cat?._id}>
                      {(cat?.name?.[lang])}
                    </option>
                  );
                })}
              </select>
              <label className="main-label">{t("Category")}</label>
            </div>
            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Hot Deal")}
              </label>
              <label className="switch form-group">
                <input
                  onClick={(e) => {
                    setIsHotDeal(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  onClick={(e) => {
                    setIsActive(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Limited")}
              </label>
              <label className="switch form-group">
                <input
                  onClick={(e) => {
                    setIsLimited(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Editable")}
              </label>
              <label className="switch form-group">
                <input
                  onClick={(e) => {
                    setIsEditable(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="button-grid">
            <button
              className="main-button-admin"
              onClick={() => {
                resetInputs();
                navigate(
                  `/vendors/${params.id}/branchdetails/${params.branchID}`
                );
              }}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Cancel")
              )}
            </button>
            <button className="main-button-admin">
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Add Product")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
