import React, { useEffect, useRef, useState } from 'react'
import { socket } from '../../../services/socket';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import VendorServices from '../../../services/VendorServices';
import { useNavigate } from 'react-router-dom';
import toastPopup from '../../../helpers/toastPopup';
import './VendorChat.scss'

export default function VendorChat() {

  const { t } = useTranslation();
  const lang = i18n.language
  const navigate = useNavigate()
  const chatRef = useRef(null);

  const [allChats, setAllChats] = useState([])
  const [filterBy, setFilterBy] = useState('');
  const [isClicked, setIsClicked] = useState('')
  const [errorMessage, setErrorMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false)
  const [chatData, setChatData] = useState([])
  const [chatInfo, setChatInfo] = useState({})
  const [otherChatterImage, setOtherChatterImage] = useState('')
  const [otherChatterName, setOtherChatterName] = useState('')

  let vendorChats = allChats?.filter((chat) => {
    return chat?.members?.vendor && !chat?.members?.admin && chat?.lastMessage
  })

  const uniqueVendors = [];

  vendorChats.forEach(obj => {
    const exists = uniqueVendors.some(item => item?.members?.vendor?._id === obj?.members?.vendor?._id);

    if (!exists) {
      uniqueVendors.push(obj);
    }
  });

  let chats = vendorChats.filter((chat) => {
    return chat?.members?.vendor?._id === filterBy
  })

  async function getUserInfo(chat) {
    // vendor branch client agent org
    const otherChatter = Object.keys(chat?.members).filter((item) => item !== 'vendor')[0];
    if (otherChatter === 'branch') {
      let vendorId = ""
      try {
        const { data } = await VendorServices.getBranchData(
          chat?.members?.[otherChatter]?._id
        );
        vendorId = data?.record[0]?.vendor?._id
      } catch (e) {
        setErrorMessage("Unexpected Error happened. Please try again later");
      }
      navigate(`/vendors/${vendorId}/branchdetails/${chat?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'client') {
      navigate(`/clients/${chat?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'agent') {
      navigate(`/agents/${chat?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'org') {
      navigate(`/organizations/${chat?.members?.[otherChatter]?._id}`);
    }
  }

  function blockChat() {
    socket.emit("blockRoom", { _id: isClicked }, (data) => {
      if (data.success) {
        setIsBlocked(true)
        setModalShow(false)
        toastPopup.success(t("Blocked successfully"));
      }
    });
  }

  function unblockChat() {
    socket.emit("unblockRoom", { _id: isClicked }, (data) => {
      if (data.success) {
        setIsBlocked(false)
        setModalShow(false)
        toastPopup.success(t("Unblocked successfully"));
      }
    });
  }

  useEffect(() => {
    if (chatRef && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatData]);

  useEffect(() => {
    if (isClicked !== '') {
      socket.emit("getRoom", {
        "_id": isClicked
      });
    }
  }, [isClicked])

  useEffect(() => {

    socket.on('connection', () => {
      console.log('Connected to server');
    });

    socket.connect();

    socket.emit('listRooms', {
      "members": {}
    });

    socket.on('listRooms', (data) => {
      setAllChats(data?.records)
    });

    socket.on('getRoom', (data) => {
      setChatData(data?.record?.messages)
    })

    return () => {
      socket.disconnect();
    }
  }, [])

  useEffect(() => {
    setIsBlocked(chatInfo?.isBlocked)
  }, [chatInfo])

  useEffect(() => {
    if (chatInfo?.members) {
      const otherChatter = Object?.keys(chatInfo?.members)?.filter((item) => item !== 'vendor')[0]
      setOtherChatterImage(chatInfo?.members?.[otherChatter]?.image?.Location)
      setOtherChatterName(chatInfo?.members?.[otherChatter]?.name)
    }
  }, [chatInfo])

  useEffect(() => {
    if (!filterBy) {
      setIsClicked('')
    }
  }, [filterBy])

  const currentDate = new Date();
  const targetDate = new Date(chatInfo?.lastUpdated);
  const timeDiff = currentDate.getTime() - targetDate.getTime();
  const daysBehind = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hoursBehind = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutesBehind = Math.floor(timeDiff / (1000 * 60));

  return <>

    {modalShow && (
      <div
        className={modalShow ? "overlay" : "overlay-remove-cancel"}
        id="overlay-remove"
      >
        <div className="add-admin-box">
          <h3>{isBlocked ? t("Are you sure you want to unblock chat?") : t("Are you sure you want to block chat?")}</h3>
          <div className="modal-buttons">
            <div
              onClick={() => setModalShow(false)}
              className="cancel-remove"
            >
              {t("Cancel")}
            </div>
            <div
              onClick={() => isBlocked ? unblockChat() : blockChat()}
              className="delete"
            >
              {isBlocked ? t("Unblock") : t("Block")}
            </div>
          </div>
        </div>
        <div className="cancel" onClick={() => setModalShow(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
      </div>
    )}

    <div className="form-search">
      <div className="filter-by-container">
        <div className="filter-by">
          <select
            onChange={(e) => {
              setFilterBy(e.target.value);
            }}
            className="search-by-input"
            id="searchBy"
            name="searchBy"
            title="searchBy"
          >
            <option value="">
              {t("Select vendor")}
            </option>
            {
              uniqueVendors.map((chat) => {
                return (
                  <option value={chat?.members?.vendor?._id}>{chat?.members?.vendor?.name?.[lang]}</option>
                )
              })
            }
          </select>
        </div>
      </div>
    </div>

    <div className="main-chat">
      <div className="active-chats">
        {chats?.map((chat) => {
          const otherChatter = Object.keys(chat?.members).filter((item) => item !== 'vendor')[0];
          if (chat?.members?.[otherChatter]) {
            return (
              <div onClick={() => { setIsClicked(chat?._id); setChatInfo(chat) }} className={chat?._id === isClicked ? 'avaliable-chat' : 'unavaliable-chat'}
                key={chat?._id}>
                <div className="image">
                  {chat?.members?.[otherChatter]?.image ?
                    <img
                      src={chat?.members?.[otherChatter]?.image?.Location}
                      className='chat-image'
                      alt="Chat Image"
                      onClick={(e) => {
                        e.stopPropagation(); getUserInfo(chat)
                      }} />
                    :
                    <FontAwesomeIcon
                      icon={faUserTie}
                      size="xl"
                      className="text-slate-800 icon-avatar"
                      onClick={(e) => {
                        e.stopPropagation(); getUserInfo(chat)
                      }}
                    />}

                </div>
                <div className="data">
                  <div className="name">
                    <p>
                      {localStorage.getItem("i18nextLng") === "en" && chat?.members?.[otherChatter]?.name?.en ?
                        chat?.members?.[otherChatter]?.name?.en?.slice(0, 30)
                        : localStorage.getItem("i18nextLng") === "en" && !chat?.members?.[otherChatter]?.name?.en && chat?.members?.[otherChatter]?.name?.ar ?
                          chat?.members?.[otherChatter]?.name?.ar?.slice(0, 30)
                          : localStorage.getItem("i18nextLng") === "ar" && chat?.members?.[otherChatter]?.name?.ar ?
                            chat?.members?.[otherChatter]?.name?.ar?.slice(0, 30)
                            : localStorage.getItem("i18nextLng") === "ar" && !chat?.members?.[otherChatter]?.name?.ar && chat?.members?.[otherChatter]?.name?.en ?
                              chat?.members?.[otherChatter]?.name?.en?.slice(0, 30)
                              : localStorage.getItem("i18nextLng") === "en" && !chat?.members?.[otherChatter]?.name?.en && !chat?.members?.[otherChatter]?.name?.ar ?
                                "No name"
                                : localStorage.getItem("i18nextLng") === "ar" && !chat?.members?.[otherChatter]?.name?.ar && !chat?.members?.[otherChatter]?.name?.en ?
                                  "لا يوجد أسم" : ""
                      }
                    </p>
                    <div className="last-message">
                      <p><i className="fa-solid fa-paper-plane"></i> {chat?.lastMessage?.text?.slice(0, 20)}</p>
                    </div>
                    <div className="last-updated">
                      <p><i className="fa-solid fa-clock"></i> {new Date(chat?.lastUpdated)?.toLocaleTimeString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>

      {isClicked ?
        <div className="chat">
          <div className="chat-messages">
            <div className="chat-bar">
              <div className="chat-data" onClick={() => getUserInfo(chatInfo)}>
                <div className="image">
                  {otherChatterImage ?
                    <img src={otherChatterImage} alt="Image" /> :
                    <FontAwesomeIcon
                      icon={faUserTie}
                      size="xl"
                      className="text-slate-800 icon-avatar"
                    />
                  }
                </div>
                <div className="name">
                  <p>
                    {
                      localStorage.getItem("i18nextLng") === "en" && otherChatterName?.en ?
                        otherChatterName?.en?.slice(0, 30)
                        : localStorage.getItem("i18nextLng") === "en" && !otherChatterName?.en && otherChatterName?.ar ?
                          otherChatterName?.ar?.slice(0, 30)
                          : localStorage.getItem("i18nextLng") === "ar" && otherChatterName?.ar ?
                            otherChatterName?.ar?.slice(0, 30)
                            : localStorage.getItem("i18nextLng") === "ar" && !otherChatterName?.ar && otherChatterName?.en ?
                              otherChatterName?.en?.slice(0, 30)
                              : localStorage.getItem("i18nextLng") === "en" && !otherChatterName?.en && !otherChatterName?.ar ?
                                "No name"
                                : localStorage.getItem("i18nextLng") === "ar" && !otherChatterName?.ar && !otherChatterName?.en ?
                                  "لا يوجد أسم" : ""
                    }</p>
                  <p className='time'>{daysBehind === 0 ? hoursBehind === 0 ? `${minutesBehind} minutes ago` : `${hoursBehind} hours ago` : `${daysBehind} days ago`}</p>
                </div>
              </div>
              <div className="block">
                <button onClick={() => { setModalShow(true) }}>{isBlocked ? t("Unblock") : "Block"}</button>
              </div>
            </div>
            <div className="chat-body" ref={chatRef}>
              {chatData?.map((message) => {
                if (message?.vendor) {
                  return (
                    <div className="sender" key={message?._id}>
                      <div className="message">
                        {message?.text}
                        <div className="date">
                          {new Date(message?.timestamp).toLocaleDateString()} - {new Date(message?.timestamp).toLocaleTimeString()}
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="receiver" key={message?._id}>
                      <div className="message">
                        {message?.text}
                        <div className="date">
                          {new Date(message?.timestamp).toLocaleDateString()} - {new Date(message?.timestamp).toLocaleTimeString()}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
            {isBlocked ?
              <div className="send-message">
                <p className='block-message'>{t("You can't send messages to this chat. Unblock chat if you want to send a message.")}</p>
              </div> : ""}
          </div>
        </div> : ""}

    </div>
  </>
}
