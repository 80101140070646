import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import toastPopup from '../../../../../helpers/toastPopup';
import VendorServices from '../../../../../services/VendorServices';
import MainInput from '../../../../MainInput';
import './AddCashier.scss'

export default function AddCashier() {

  const [isActive, setIsActive] = useState(false)
  const [loading, setLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  let params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation();

  const [newCashier, setNewCashier] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    password: "",
    phone: "",
  });

  function resetInputs() {
    setNewCashier({
      name_en: "",
      name_ar: "",
      email: "",
      password: "",
      phone: "",
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "email", type: "email", required: true },
    { name: "password", type: "password", required: true },
    { name: "phone", type: "text", required: true },
  ];

  async function addNewCashier(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newCashier.name_en,
          ar: newCashier.name_ar
        },
        vendor: params.id,
        branch: params.branchID,
        email: newCashier.email,
        password: newCashier.password,
        phone: newCashier.phone,
        isActive: isActive
      }
      const { data } = await VendorServices.addCashier(obj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      let branchId = data.record._id
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadCashierImage(branchId, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/vendors/${params.id}/branchdetails/${params.branchID}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  return <>

    <div className="add-client">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate(`/vendors/${params.id}/branchdetails/${params.branchID}`) }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Cashier")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}

      </div>
      <div className="main-image-label vendor-image">
        {uploadImage && (
          <img
            src={uploadImage ? URL.createObjectURL(uploadImage) : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadImage ? URL.createObjectURL(uploadImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={ref}
          onChange={(e) => {
            setUploadImage(e.target.files[0]);
          }}
        />
        <label
          className="main-label-image"
          onClick={imageUploader}
          htmlFor="upload-img"
        >
          {t("Add Image")}
        </label>
      </div>

      <form className='form-grid' onSubmit={addNewCashier}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newCashier}
                setState={setNewCashier}
              />
            );
          })}
          <div className='toggler'>
            <label htmlFor="productImage" className="word">{t("Is Active")}</label>
            <label className="switch form-group">
              <input onClick={(e) => { setIsActive(e.target.checked) }} type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate(`/vendors/${params.id}/branchdetails/${params.branchID}`) }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Cashier")
            )}
          </button>
        </div>
      </form>
    </div>

  </>
}
