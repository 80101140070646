import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import InfoInput from '../../../Components/InfoInput/InfoInput';
import i18n from '../../../locales/i18n';
import ServiceServices from '../../../services/ServiceServices';
import './ServiceDetails.scss'

export default function ServiceDetails() {

  const { t } = useTranslation();
  let params = useParams()
  const navigate = useNavigate()
  const lang = i18n.language

  const [service, setService] = useState({})
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false);

  async function getService() {
    setLoading(true)
    try {
      const { data } = await ServiceServices.getService(params.id)
      setLoading(false);
      setService(data.record[0])
      setActive(true)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let serviceName = ""
  if (localStorage.getItem("i18nextLng") === "en" && service?.serviceName?.en) {
    serviceName = service?.serviceName?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !service?.serviceName?.en && service?.serviceName?.ar) {
    serviceName = service?.serviceName?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && service?.serviceName?.ar) {
    serviceName = service?.serviceName?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !service?.serviceName?.ar && service?.serviceName?.en) {
    serviceName = service?.serviceName?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !service?.serviceName?.en && !service?.serviceName?.ar) {
    serviceName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !service?.serviceName?.ar && !service?.serviceName?.en) {
    serviceName = "لا يوجد أسم"
  }

  let providerName = ""
  if (localStorage.getItem("i18nextLng") === "en" && service?.providerName?.en) {
    providerName = service?.providerName?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !service?.providerName?.en && service?.providerName?.ar) {
    providerName = service?.providerName?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && service?.providerName?.ar) {
    providerName = service?.providerName?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !service?.providerName?.ar && service?.providerName?.en) {
    providerName = service?.providerName?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !service?.providerName?.en && !service?.providerName?.ar) {
    providerName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !service?.providerName?.ar && !service?.providerName?.en) {
    providerName = "لا يوجد أسم"
  }

  let serviceDetails = [
    { id: "serviceName", title: "Service Name", type: "text", value: serviceName },
    { id: "providerName", title: "Provider", type: "text", value: providerName },
    { id: "description", title: "Description", type: "text", value: service?.description?.[lang] },
    { id: "address", title: "Address", type: "text", value: service?.address?.[lang] },
    { id: "phone", title: "Phone", type: "text", value: service?.contacts?.phone },
    { id: "whatsapp", title: "Whatsapp", type: "text", value: service?.contacts?.whatsapp },
    { id: "telegram", title: "Telegram", type: "text", value: service?.contacts?.telegram },
    { id: "category", title: "Category", type: "text", value: service?.category?.map((cat) => cat?._id?.name?.[lang]) },
    { id: "publishDate", title: "Publish Date", type: "text", value: formatDate(new Date(service?.publishDate)) },
  ]

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getService()
  }, [])

  return <>

    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}
    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (active && <div className="main-service-details">
        <div className="back">
          <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/services') }}></i>
        </div>
        <div className="client-image vendor-image-details">
          <img src={"https://st2.depositphotos.com/1092019/6910/i/600/depositphotos_69108385-stock-photo-services-text-on-folder-register.jpg"} alt="Service Image" />
        </div>
        <div className="client-datails">
          <div className="data">
            {
              serviceDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                )
              })
            }

            <div className='toggler'>
              <label htmlFor="productImage" className="word">{t("Active")}</label>
              <label className="switch form-group">
                <input checked={service?.isActive} type="checkbox" readOnly />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>)}
  </>
}
