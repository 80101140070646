import Axios from "./Axios";

let AdsServices = {
  getAd: async (id) => {
    const response = await Axios.get(`/admin/ad/get?_id=${id}`);
    return response;
  },

  editAd: async (id, obj) => {
    const response = await Axios.put(`/admin/ad/update?_id=${id}`, obj);
    return response;
  },

  deleteAd: async (id) => {
    const response = await Axios.delete(`/admin/ad/remove?_id=${id}`);
    return response;
  },

  listAllAds: async (status, name, gender, adType, city, lang, ageFrom, ageTo, page, limit = 25) => {
    const response = await Axios.get(`/admin/ad/list?status=${status}${name ? `&name=${name}` : ""}${gender ? `&gender=${gender}` : ""}${adType ? `&type=${adType}` : ""}${city ? `&city.${lang}=${city}` : ""}${ageFrom ? `&age.from=${ageFrom}` : ""}${ageTo ? `&age.to=${ageTo}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default AdsServices;
