let backPermissions = {
  admin: {
    canCreate: ["/admin/create", "/admin/image"],
    canView: ["/admin/list", "/admin/get"],
    canEdit: ["/admin/update", "/admin/image"],
    canDelete: ["/admin/remove"],
    canChangePassword: ["/admin/changePassword"],
  },

  category: {
    canCreate: ["/admin/category/create", "/admin/category/image"],
    canView: ["/admin/category/list", "/admin/category/get"],
    canEdit: ["/admin/category/update", "/admin/category/image"],
    canSort: ["/admin/category/sort"],
    canDelete: ["/admin/category/remove"],
  },

  vendor: {
    canCreate: [
      "/admin/vendor/create",
      "/admin/vendor/image",
      "/admin/vendor/cover",
    ],
    canView: ["/admin/vendor/list", "/admin/vendor/get"],
    canEdit: [
      "/admin/vendor/update",
      "/admin/vendor/image",
      "/admin/vendor/cover",
    ],
    canDelete: ["/admin/vendor/remove"],
    canSort: ["/admin/vendor/sort"],
    canChangePassword: ["/admin/vendor/resetPassword"],
  },

  branch: {
    canCreate: [
      "/admin/branch/create",
      "/admin/branch/image",
      "/admin/branch/cover",
    ],
    canView: ["/admin/branch/list", "/admin/branch/get"],
    canEdit: [
      "/admin/branch/update",
      "/admin/branch/image",
      "/admin/branch/cover",
    ],
    canDelete: ["/admin/branch/remove"],
    canChangePassword: ["/admin/branch/resetPassword"],
  },

  cashier: {
    canCreate: ["/admin/cashier/create", "/admin/cashier/image"],

    canView: ["/admin/cashier/list", "/admin/cashier/get"],
    canEdit: ["/admin/cashier/update", "/admin/cashier/image"],
    canDelete: ["/admin/cashier/remove"],
    canChangePassword: ["/admin/cashier/resetPassword"],
  },

  product: {
    canCreate: ["/admin/product/create", "/admin/product/image"],
    canView: ["/admin/product/list", "/admin/product/get"],
    canEdit: [
      "/admin/product/update",
      "/admin/product/addImage",
      "/admin/product/removeImage",
      "/admin/product/image",
      // "/admin/product/sortInBranch",
    ],

    canSort: ["/admin/product/sort"],

    canDelete: ["/admin/product/remove"],
  },

  client: {
    canCreate: ["/admin/client/create", "/admin/client/image"],
    canView: ["/admin/client/list", "/admin/client/get"],
    canEdit: [
      "/admin/client/update",
      "/admin/client/image",
      "/admin/client/count",
    ],
    canDelete: ["/admin/client/remove"],
    canChangePassword: ["/admin/client/resetPassword"],
  },

  org: {
    canCreate: ["/admin/org/create", "/admin/org/image"],
    canView: ["/admin/org/list", "/admin/org/get"],
    canEdit: ["/admin/org/update", "/admin/org/image"],
    canDelete: ["/admin/org/remove"],
    canChangePassword: ["/admin/org/resetPassword"],
  },

  agent: {
    canCreate: ["/admin/agent/create", "/admin/agent/image"],
    canView: ["/admin/agent/list", "/admin/agent/get"],
    canEdit: [
      "/admin/agent/update",
      "/admin/agent/image",
      "/admin/agent/addToNetwork",
      "/admin/agent/removeFromNetwork",
      "/admin/agent/changeParent",
    ],
    canDelete: ["/admin/agent/remove"],
    canChangePassword: ["/admin/agent/resetPassword"],
  },

  job: {
    canCreate: ["/admin/job/create"],
    canView: ["/admin/job/list", "/admin/job/get"],
    canEdit: ["/admin/job/update"],
    canDelete: ["/admin/job/remove"],
  },

  post: {
    canCreate: ["/admin/post/create"],
    canView: ["/admin/post/list", "/admin/post/get"],
    canEdit: ["/admin/post/update"],
    canDelete: ["/admin/post/remove"],
  },

  service: {
    canCreate: ["/admin/service/create"],
    canView: ["/admin/service/list", "/admin/service/get"],
    canEdit: ["/admin/service/update"],
    canDelete: ["/admin/service/remove"],
  },

  join: {
    // canCreate: ["/admin/join/create"],
    canView: ["/admin/join/list", "/admin/join/get"],
    // canEdit: ["/admin/join/update"],
    canDelete: ["/admin/join/remove"],
  },

  ad: {
    canCreate: ["/admin/ad/create", "/admin/ad/image"],
    canView: ["/admin/ad/list", "/admin/ad/get"],
    canEdit: ["/admin/ad/update", "/admin/ad/image"],
    canDelete: ["/admin/ad/remove"],
  },

  banner: {
    canCreate: ["/admin/banner/create", "/admin/banner/image"],
    canView: ["/admin/banner/list", "/admin/banner/get"],
    canEdit: [
      "/admin/banner/update",
      "/admin/banner/image",
      "/admin/banner/active",
    ],
    canDelete: ["/admin/banner/remove"],
  },

  notification: {
    canCreate: ["/admin/notification/create", "/admin/notification/image"],
    canView: ["/admin/notification/list", "/admin/notification/get"],
    canEdit: [
      "/admin/notification/update",
      "/admin/notification/image",
      "/admin/notification/active",
    ],
    canDelete: ["/admin/notification/remove"],
  },

  order: {
    // canCreate: ["/admin/order/create"],
    canView: ["/admin/order/list", "/admin/order/get"],
    canEdit: ["/admin/order/update"],
    canDelete: ["/admin/order/remove"],
  },

  orderRequest: {
    canView: ["/admin/orderRequest/get", "/admin/orderRequest/list"],
  },

  coupon: {
    canCreate: ["/admin/coupon/create"],
    canView: ["/admin/coupon/list", "/admin/coupon/get"],
    canEdit: ["/admin/coupon/update"],
    canDelete: ["/admin/coupon/remove"],
  },

  wishlist: {
    // canCreate: ["/admin/wishlist/create"],
    canView: ["/admin/wishlist/get", "/admin/wishlist/list"],
    // canEdit: ["/admin/wishlist/update"],
    // canDelete: ["/admin/wishlist/remove"],
  },

  cart: {
    // canCreate: ["/admin/cart/create"],
    canView: ["/admin/cart/get", "/admin/cart/list"],
    // canEdit: ["/admin/cart/update"],
    // canDelete: ["/admin/cart/remove"],
  },

  points: {
    // canCreate: ["/admin/points/addPoints"],
    canView: ["/admin/points/list", "/admin/points/get"],
    canEdit: ["/admin/points/updateSystemPoints"],
    // canDelete: ["/admin/points/removePoints"],
  },

  page: {
    canCreate: ["/admin/page/create"],
    canView: ["/admin/page/list", "/admin/page/get"],
    canEdit: ["/admin/page/update"],
    canDelete: ["/admin/page/remove"],
  },

  review: {
    // canCreate: ["/admin/review/create"],
    canView: ["/admin/review/list", "/admin/review/get"],
    // canEdit: ["/admin/review/update"],
    canDelete: ["/admin/review/remove"],
  },

  interest: {
    canCreate: ["/admin/interest/create"],
    canView: ["/admin/interest/list", "/admin/interest/get"],
    canEdit: ["/admin/interest/update"],
    canDelete: ["/admin/interest/remove"],
  },

  profession: {
    canCreate: ["/admin/profession/create"],
    canView: ["/admin/profession/list", "/admin/profession/get"],
    canEdit: ["/admin/profession/update"],
    canDelete: ["/admin/profession/remove"],
  },

  barcode: {
    canCreate: ["/admin/barcode/pdf", "/admin/barcode/excel"],
    canView: ["/admin/barcode/list", "/admin/barcode/get"],
    canEdit: ["/admin/barcode/update"],
    canDelete: ["/admin/barcode/remove"],
  },

  subscription: {
    // canCreate: ["/admin/subscription/create"],
    canView: ["/admin/subscription/list", "/admin/subscription/get"],
    canEdit: ["/admin/subscription/update"],
    canDelete: ["/admin/subscription/remove"],
  },

  setting: {
    canEdit: ["/admin/setting/update"],
    canView: ["/admin/setting/list"],
  },
};

export function getMappeedPermissions(frontPermissions) {
  let mappedPermissions = {};

  let frontEndModules = Object.keys(frontPermissions);

  frontEndModules.forEach((frontEndModule) => {
    let backEquvelant = [];

    let frontEndModuleProps = frontPermissions[frontEndModule];
    let frontEndModulePropsKeys = Object.keys(frontEndModuleProps);

    frontEndModulePropsKeys.forEach((FEModulePropKey) => {
      if (frontEndModuleProps[FEModulePropKey] === true) {
        if (backPermissions?.[frontEndModule]?.[FEModulePropKey]) {
          backEquvelant = [
            ...backEquvelant,
            ...backPermissions?.[frontEndModule]?.[FEModulePropKey],
          ];
        }
      }
    });

    let uniqueBackEquvelant = [...new Set(backEquvelant)];

    mappedPermissions = {
      ...mappedPermissions,
      [frontEndModule]: [...uniqueBackEquvelant],
    };
  });

  delete mappedPermissions.chat;

  console.log(mappedPermissions);
  return mappedPermissions;
}
export default backPermissions;
