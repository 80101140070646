import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MainTable from "../../../../Components/MainTable/MainTable";
import toastPopup from "../../../../helpers/toastPopup";
import i18n from "../../../../locales/i18n";
import ClientServices from "../../../../services/ClientServices";
import OrganizationServices from "../../../../services/OrganizationServices";
import "./Clients.scss";

export default function Clients(props) {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { client: clientPermissions } = frontPermissions;

  let canCreateClient = clientPermissions.canCreate;
  let canDeleteClient = clientPermissions.canDelete;

  const { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams();
  const lang = i18n.language;

  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [isActiveOrganization, setIsActiveOrganization] = useState(false);
  const [totalClients, setTotalClients] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function getOrganizationClients() {
    setLoading(true);
    try {
      const { data } = await ClientServices.listAllOrganizationClients(
        params.id,
        currentPage
      );
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        let returnedObject = canDeleteClient
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: clientName,
            Email: record?.email,
            Phone: record?.phone,
            Barcode: record?.barcode,
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "client",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: clientName,
            Email: record?.email,
            Phone: record?.phone,
            Barcode: record?.barcode,
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "client",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
          };
        return returnedObject;
      });
      setClient(singleClient => {
        return [...singleClient, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalClients(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteClient(id) {
    setLoading(true);
    try {
      const { data } = await ClientServices.deleteClient(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function AddClientToOrgHandler() {
    if (props?.orgCredit > 0) {
      navigate(`/organizations/${params.id}/add-client`)
    } else {
      toastPopup.error(t("You don't have enough credit to add a client"))
    }
  }

  async function renewSubscriptionHandler(e) {
    e.preventDefault();

    const currentDate = new Date(Date.now());
    const currentDateISO = new Date(currentDate).toISOString();
    const nextYear = currentDate.setFullYear(currentDate.getFullYear() + 1);
    const nextYearISO = new Date(nextYear).toISOString();

    setLoading(true);
    try {
      const { data } = await OrganizationServices.renewAllOrgClients(params.id, {
        isSubscribed: true,
        isActive: true,
        subStartDate: currentDateISO,
        subEndDate: nextYearISO,
        isPending: false
      });
      setLoading(false);
      toastPopup.success(t("Subscribed successfully"))
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getOrganization() {
    setLoading(true);
    try {
      const { data } = await OrganizationServices.getOrganization(params.id);
      setLoading(false);
      setIsActiveOrganization(data?.record[0]?.isActive);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function addManyClients() {
    navigate(`/barcodes/add-barcode/${params.id}`)
  }

  function handleClientRowClick(id) {
    navigate(`/clients/${id}`);
  }

  useEffect(() => {
    getOrganization()
  }, []);

  useEffect(() => {
    getOrganizationClients()
  }, [currentPage]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteClient(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {canCreateClient && isActiveOrganization && (
        <div className="add-branch">
          <div className="button">
            <button
              className="main-button-add-sub-agent-renew"
              onClick={renewSubscriptionHandler}
            >
              {t("Renew Subscription")}
            </button>

            <button
              className="main-button-add-sub-agent-renew"
              onClick={addManyClients}
            >
              {t("Add Many Client")}
            </button>

            <button
              className="main-button-add-sub-agent"
              onClick={AddClientToOrgHandler}
            >
              {t("Add Client")}
            </button>
          </div>
        </div>
      )}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          {client?.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handleClientRowClick} tableRows={client} />
          )}
          {
            client.length !== totalClients &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
