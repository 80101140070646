import React, { useState } from 'react'
import AddClientByBarcode from './AddClientByBarcode/AddClientByBarcode';
import AddClientByForm from './AddClientByForm/AddClientByForm';
import { useTranslation } from 'react-i18next';
import './AddClient.scss'

export default function AddClient() {

  const { t } = useTranslation();

  const [addByForm, setAddByForm] = useState(true)
  const [addByBarcode, setBddByBarcode] = useState(false)

  return <>
    <div className="user">
      {
        addByForm ? (
          <div className='active-btn'>
            <button
              onClick={() => { setAddByForm(true); setBddByBarcode(false) }}
              className='all-branches-btn'>
              <h2>{t("Add by form")}</h2>
            </button>
          </div>
        ) : (<div className='all-branches'>
          <button
            onClick={() => { setAddByForm(true); setBddByBarcode(false) }}
            className='all-branches-btn'>
            <h2>{t("Add by form")}</h2>
          </button>
        </div>)
      }
      {
        addByBarcode ? (
          <div className='active-btn'>
            <button
              onClick={() => { setAddByForm(false); setBddByBarcode(true) }}
              className='all-branches-btn'>
              <h2>{t("Add by barcode")}</h2>
            </button>
          </div>
        ) : (<div className='all-branches'>
          <button
            onClick={() => { setAddByForm(false); setBddByBarcode(true) }}
            className='all-branches-btn'>
            <h2>{t("Add by barcode")}</h2>
          </button>
        </div>)
      }
    </div>

    {addByForm ? (
      <div className="branch-cards-container">
        <AddClientByForm />
      </div>
    ) : ""}

    {addByBarcode ? (
      <div className="branch-cards-container">
        <AddClientByBarcode />
      </div>
    ) : ""}
  </>
}
