import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import i18n from "../../../locales/i18n";
import BannerServices from "../../../services/BannerServices";
import ClientServices from "../../../services/ClientServices";
import { useSelector } from "react-redux";
import "./BannerDetails.scss";

export default function BannerDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { banner: bannerPermissions } = frontPermissions;

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [banner, setBanner] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");

  async function getBanner() {
    setLoading(true);
    try {
      const { data } = await BannerServices.getBanner(params.id);
      setLoading(false);
      setBanner(data.record[0]);
      setActive(true);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let bannerDetails = [
    { id: "size", title: "Size", type: "text", value: banner?.size },
    { id: "name", title: "Name", type: "text", value: banner?.name },
    { id: "link", title: "Link", type: "text", value: banner?.link },
    { id: "gender", title: "Gender", type: "text", value: banner?.gender },
    {
      id: "country",
      title: "Country",
      type: "text",
      value: banner?.country?.[lang],
    },
    {
      id: "city", title: "City", type: "text", value: banner?.city?.map((singliCity) => {
        return singliCity?.[lang] + " "
      })
    },
    {
      id: "ageFrom",
      title: "Age from",
      type: "text",
      value: banner?.age?.from,
    },
    { id: "ageTo", title: "Age to", type: "text", value: banner?.age?.to },
    {
      id: "startDate",
      title: "Start Date",
      type: "text",
      value: formatDate(new Date(banner?.startDate)),
    },
    {
      id: "endDate",
      title: "End Date",
      type: "text",
      value: formatDate(new Date(banner?.endDate)),
    },
    {
      id: "clicks",
      title: "Clicks",
      type: "text",
      value: banner?.clicks,
    },
    {
      id: "reach",
      title: "Reach",
      type: "text",
      value: banner?.reach,
    },
  ];

  async function countHandler() {
    setLoading(true);
    try {
      const { data } = await ClientServices.countClients(
        banner?.city?.[lang],
        banner?.gender === "both" ? "" : banner?.gender,
        banner?.age?.from,
        banner?.age?.to
      );
      setLoading(false);
      setCount(data.count);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        ""
      )}
      {active && (
        <div className="main-details">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/banners");
              }}
            ></i>
          </div>
          <div className="client-image vendor-image-details">
            <img src={banner?.image?.Location} alt="Banner Image" />
          </div>
          <div className="client-datails">
            <div className="edit">
              {bannerPermissions.canEdit && (
                <button onClick={() => navigate(`/banners/${banner._id}/edit`)}>
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              )}
            </div>

            <div className="data">
              {bannerDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}

              <div className="toggler">
                <label htmlFor="productImage" className="word">
                  {t("Active")}
                </label>
                <label className="switch form-group">
                  <input checked={banner?.isActive} type="checkbox" readOnly />
                  <span className="slider round"></span>
                </label>
              </div>

              <div className="count">
                <button onClick={countHandler}>
                  Count{" "}
                  {loading ? <i className="fas fa-spinner fa-spin"></i> : count}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
