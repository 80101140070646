import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PagesServices from '../../../services/PagesServices';
import toastPopup from '../../../helpers/toastPopup';
import './AddAbout.scss'

export default function AddAbout() {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [newAbout, setNewAbout] = useState({
    name_en: "",
    name_ar: "",
    content_en: "",
    content_ar: ""
  });

  function resetInputs() {
    setNewAbout({
      name_en: "",
      name_ar: "",
      content_en: "",
      content_ar: ""
    })
  }

  function getNewAboutData(e) {
    let newAboutData = { ...newAbout }
    newAboutData[e.target.name] = e.target.value
    setNewAbout(newAboutData)
  }

  async function addNewAbout(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newAbout.name_en,
          ar: newAbout.name_ar
        },
        content: {
          en: newAbout.content_en,
          ar: newAbout.content_ar
        },
        type: "faqs"
      }

      const { data } = await PagesServices.addPage(obj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      navigate(`/about/${data.record._id}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  return <>
    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/about') }}></i>
      </div>
      <div className="header">
        <h3>{t("Add About")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <form className='form-grid' onSubmit={addNewAbout}>
        <div className="input-grid">
          <div className="main-input-label">
            <textarea
              name="name_en"
              id="name_en"
              cols="90"
              rows="10"
              className="main-textarea-question"
              onChange={getNewAboutData}>
            </textarea>
            <label className="main-label" htmlFor="name_en">
              {t("English Name")}
            </label>
          </div>
          <div className="main-input-label">
            <textarea
              name="name_ar"
              id="name_ar"
              cols="90"
              rows="10"
              className="main-textarea-question"
              onChange={getNewAboutData}>
            </textarea>
            <label className="main-label" htmlFor="name_ar">
              {t("Arabic Name")}
            </label>
          </div>
          <div className="main-input-label">
            <textarea
              name="content_en"
              id="content_en"
              cols="90"
              rows="10"
              className="main-textarea-answer"
              onChange={getNewAboutData}>
            </textarea>
            <label className="main-label" htmlFor="content_en">
              {t("English Content")}
            </label>
          </div>
          <div className="main-input-label">
            <textarea
              name="content_ar"
              id="content_ar"
              cols="90"
              rows="10"
              className="main-textarea-answer"
              onChange={getNewAboutData}>
            </textarea>
            <label className="main-label" htmlFor="content_ar">
              {t("Arabic Content")}
            </label>
          </div>
        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate('/about') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add About")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
