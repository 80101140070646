import React, { useEffect, useState } from "react";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import { useTranslation } from "react-i18next";
import toastPopup from "../../helpers/toastPopup";
import { useNavigate } from "react-router-dom";
import ClientServices from "../../services/ClientServices";
import i18n from "../../locales/i18n";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import { useSelector } from "react-redux";
import "./Client.scss";

export default function Client() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { client: clientPermissions } = frontPermissions;

  let canDeleteClient = clientPermissions.canDelete;
  let canAddClient = clientPermissions.canCreate;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [activeClient, setActiveClient] = useState([]);
  const [totalActiveClients, setTotalActiveClients] = useState(0);
  const [inActiveClient, setInActiveClient] = useState([]);
  const [totalInActiveClients, setTotalInActiveClients] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");
  const [isActiveClient, setIsActiveClient] = useState(true)
  const [isInActiveClient, setIsInActiveClient] = useState(false)
  const [searchBy, setSearchBy] = useState("");
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState("");

  async function getAllActiveClients() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await ClientServices.listAllActiveClients(
        lang,
        searchActiveValue,
        searchBy === "Search by" ? "" : searchBy,
        activeCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteClient
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: clientName,
            Email: record?.email ? record?.email : t(`No Email`),
            Phone: record?.phone ? record?.phone : t(`No Phone`),
            Barcode: record?.barcode ? record?.barcode : t(`No Barcode`),
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "client",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: clientName,
            Email: record?.email ? record?.email : t(`No Email`),
            Phone: record?.phone ? record?.phone : t(`No Phone`),
            Barcode: record?.barcode ? record?.barcode : t(`No Barcode`),
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "client",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
          };
        return returnedRecord;
      });

      setActiveClient(client => {
        if ((searchBy && activeCurrentPage === 1) || (searchActiveValue && activeCurrentPage === 1) || (isSorted && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...client, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveClients(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInActiveClients() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await ClientServices.listAllInactiveClients(
        lang,
        searchInActiveValue,
        searchBy === "Search by" ? "" : searchBy,
        inActiveCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteClient
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: clientName,
            Email: record?.email ? record?.email : t(`No Email`),
            Phone: record?.phone ? record?.phone : t(`No Phone`),
            Barcode: record?.barcode ? record?.barcode : t(`No Barcode`),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: clientName,
            Email: record?.email ? record?.email : t(`No Email`),
            Phone: record?.phone ? record?.phone : t(`No Phone`),
            Barcode: record?.barcode ? record?.barcode : t(`No Barcode`),
          };
        return returnedRecord;
      });

      setInActiveClient(client => {
        if ((searchBy && inActiveCurrentPage === 1) || (searchInActiveValue && inActiveCurrentPage === 1) || (isSorted && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...client, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveClients(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteClient(id) {
    setLoading(true);
    try {
      const { data } = await ClientServices.deleteClient(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleClientRowClick(id) {
    navigate(`/clients/${id}`);
  }

  useEffect(() => {
    if (isActiveClient) {
      getAllActiveClients();
      setInActiveClient([])
    }
  }, [
    lang,
    searchActiveValue,
    searchBy,
    activeCurrentPage,
    isActiveClient,
    isSorted
  ]);

  useEffect(() => {
    if (isInActiveClient) {
      getAllInActiveClients();
      setActiveClient([])
    }
  }, [
    lang,
    searchInActiveValue,
    searchBy,
    inActiveCurrentPage,
    isInActiveClient,
    isSorted
  ]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.en} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteClient(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveClient ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveClient(true); setIsInActiveClient(false) }}
                className='all-branches-btn'>
                <h2>{t("Active Clients")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveClient(true); setIsInActiveClient(false) }}
              className='all-branches-btn'>
              <h2>{t("Active Clients")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveClient ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveClient(false); setIsInActiveClient(true) }}
                className='all-branches-btn'>
                <h2>{t("Inactive Clients")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveClient(false); setIsInActiveClient(true) }}
              className='all-branches-btn'>
              <h2>{t("Inactive Clients")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveClient ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Active Clients"}
                text2={"Active Clients"}
                total={totalActiveClients}
                showBtn={canAddClient}
                btnText="Add Client"
                onBtnClick={() => {
                  navigate("/clients/addclient");
                }}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setActiveCurrentPage(1);
                      setSearchActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    disabled={!searchBy.length > 0}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
                <div className="search-by">
                  <select
                    onChange={(e) => {
                      setActiveCurrentPage(1);
                      setSearchBy(e.target.value);
                    }}
                    className="search-by-input"
                    id="searchBy"
                    name="searchBy"
                    title="searchBy"
                  >
                    <option value="Search by">
                      {t("Search by")}
                    </option>
                    <option value={"name"}>{t("Name")}</option>
                    <option value={"email"}>{t("Email")}</option>
                    <option value={"barcode"}>{t("Barcode")}</option>
                    <option value={"phone"}>{t("Phone")}</option>
                  </select>
                </div>
              </div>

              <div className="alpha">
                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>

              {isSearchEmpty || totalActiveClients === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleClientRowClick} tableRows={activeClient} />
              )}
              {
                activeClient.length !== totalActiveClients &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveClient ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Inactive Clients"}
                text2={"Inactive Clients"}
                total={totalInActiveClients}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setInActiveCurrentPage(1);
                      setSearchInActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    disabled={!searchBy.length > 0}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
                <div className="search-by">
                  <select
                    onChange={(e) => {
                      setInActiveCurrentPage(1);
                      setSearchBy(e.target.value);
                    }}
                    className="search-by-input"
                    id="searchBy"
                    name="searchBy"
                    title="searchBy"
                  >
                    <option value="Search by">
                      {t("Search by")}
                    </option>
                    <option value={"name"}>{t("Name")}</option>
                    <option value={"email"}>{t("Email")}</option>
                    <option value={"barcode"}>{t("Barcode")}</option>
                    <option value={"phone"}>{t("Phone")}</option>
                  </select>
                </div>
              </div>

              <div className="alpha">
                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>

              {isSearchEmpty || totalInActiveClients === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleClientRowClick} tableRows={inActiveClient} />
              )}
              {
                inActiveClient.length !== totalInActiveClients &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
