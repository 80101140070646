import Axios from "./Axios";

let OrderRequestsServices = {
  getOrderRequest: async (id) => {
    const response = await Axios.get(`/admin/orderRequest/get?_id=${id}`);
    return response;
  },

  listAllOrderRequests: async (vendor, status, paymentMethod, page, limit = 25) => {
    const response = await Axios.get(`/admin/orderRequest/list?${vendor ? `&vendor=${vendor}` : ""}${status ? `&status=${status}` : ""}${paymentMethod ? `&paymentMethod=${paymentMethod}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default OrderRequestsServices;
