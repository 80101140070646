import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import MainInput from "../../../../../Components/MainInput";
import toastPopup from "../../../../../helpers/toastPopup";
import AgentServices from "../../../../../services/AgentServices";
import COUNTRIES_CITIES from '../../../../../locales/app-countries.json'
import i18n from "../../../../../locales/i18n";
import "./AddSubAgent.scss";

export default function AddSubAgent() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [gender, setGender] = useState("");
  const [countryIndex, setCountryIndex] = useState("");
  const [cityIndex, setCityIndex] = useState("");
  const [agentLevel, setAgentLevel] = useState(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams();
  const lang = i18n.language;

  const [newAgent, setNewAgent] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    password: "",
    phone: "",
    location_lat: "",
    location_long: "",
    address: "",
    credit: 0,
  });

  function resetInputs() {
    setNewAgent({
      name_en: "",
      name_ar: "",
      email: "",
      password: "",
      phone: "",
      location_lat: "",
      location_long: "",
      address: "",
      credit: 0,
    });
  }

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "email", type: "email", required: false },
    { name: "password", type: "password", required: false },
    { name: "phone", type: "text", required: false },
    { name: "location_long", type: "text", required: false },
    { name: "location_lat", type: "text", required: false },
    { name: "address", type: "text", required: false },
    { name: "credit", type: "number", required: false },
  ];

  async function getAgent() {
    setLoading(true);
    try {
      const { data } = await AgentServices.getAgent(params.id);
      setLoading(false);
      setAgentLevel(data?.record[0]?.level);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function addNewAgent(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newAgent.name_en,
          ar: newAgent.name_ar,
        },
        email: newAgent.email,
        password: newAgent.password,
        phone: newAgent.phone,
        location: {
          lat: parseFloat(newAgent.location_lat),
          long: parseFloat(newAgent.location_long),
        },
        address: newAgent.address,
        credit: parseFloat(newAgent.credit),
        gender: gender,
        country: {
          en: selectedCountry?.en,
          ar: selectedCountry?.ar,
          index: countryIndex,
        },
        city: {
          en: selectedCity?.en,
          ar: selectedCity?.ar,
          index: cityIndex,
        },
        isActive: isActive,
        parent: params.id,
        level: agentLevel + 1,
      };

      function isEmpty(value) {
        return (
          typeof value === 'string' && value.trim() === '' ||
          value === undefined ||
          value === null
        );
      }

      if (isEmpty(obj.name.en)) {
        delete obj.name.en;
      }

      if (isEmpty(obj.name.ar)) {
        delete obj.name.ar;
      }

      if (isEmpty(obj.location.lat) || newAgent.location_lat === "") {
        delete obj.location.lat;
      }

      if (isEmpty(obj.location.long) || newAgent.location_long === "") {
        delete obj.location.long;
      }

      if (isEmpty(obj.location.long) || newAgent.location_long === "" &&
        isEmpty(obj.location.lat) || newAgent.location_lat === "") {
        delete obj.location;
      }

      if (isEmpty(obj.country.en)) {
        delete obj.country.en;
      }

      if (isEmpty(obj.country.ar)) {
        delete obj.country.ar;
      }

      if (isEmpty(obj.country.index)) {
        delete obj.country.index;
      }

      if (isEmpty(obj.country.en) && isEmpty(obj.country.ar) && isEmpty(obj.country.index)) {
        delete obj.country;
      }

      if (isEmpty(obj.city.en)) {
        delete obj.city.en;
      }

      if (isEmpty(obj.city.ar)) {
        delete obj.city.ar;
      }

      if (isEmpty(obj.city.index)) {
        delete obj.city.index;
      }

      if (isEmpty(obj.city.en) && isEmpty(obj.city.ar) && isEmpty(obj.city.index)) {
        delete obj.city;
      }

      if (isEmpty(obj.credit) || newAgent.credit === "") {
        delete obj.credit;
      }

      const finalObj = {};

      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }
      const { data } = await AgentServices.addAgent(finalObj);
      setLoading(false);
      resetInputs();
      let agentID = data.record._id;
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } = await AgentServices.uploadAgentImage(
          agentID,
          formData
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      toastPopup.success(t("Added"));
      navigate(`/agents/${data.record._id}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(countryIndex))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(cityIndex))[0]

  useEffect(() => {
    getAgent();
  }, []);

  return (
    <>
      <div className="add-client">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate("/agents");
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Add Agent")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <div className="main-image-label vendor-image">
          {uploadImage && (
            <img
              src={uploadImage ? URL.createObjectURL(uploadImage) : null}
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadImage ? URL.createObjectURL(uploadImage) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          <label
            className="main-label-image"
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            {t("Add Image")}
          </label>
        </div>
        <form className="form-grid" onSubmit={addNewAgent}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newAgent}
                  setState={setNewAgent}
                />
              );
            })}
            <div className="main-input-select">
              <select
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                className="main-input"
                id="gender"
                name="gender"
                title="gender"
              >
                <option value="">
                  -- {t("Gender")} --
                </option>
                <option value={"male"}>{t("Male")}</option>
                <option value={"female"}>{t("Female")}</option>
              </select>
              <label className="main-label">{t("Gender")}</label>
            </div>

            <div className="main-input-select">
              <select
                selected={countryIndex}
                value={countryIndex}
                onChange={(e) => { setCountryIndex(e.target.value) }}
                className='main-input'
                id="country"
                name="country"
                title='country'>
                <option value=''>{t("Country")}</option>
                {
                  COUNTRIES_CITIES?.map((country, index) => {
                    return <option key={index} value={country?.index}>{country?.[lang]}</option>
                  })
                }
              </select>
              <label className="main-label">{t("Country")}</label>
            </div>

            <div className="main-input-select">
              <select
                selected={cityIndex}
                value={cityIndex}
                onChange={(e) => { setCityIndex(e.target.value) }}
                className='main-input'
                id="city"
                name="city"
                title='city'>
                <option value=''>{t("City")}</option>
                {
                  selectedCountry?.cities?.map((state, index) => {
                    return <option key={index} value={state?.index}>{state?.[lang]}</option>
                  })
                }
              </select>
              <label className="main-label">{t("City")}</label>
            </div>

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  onClick={(e) => {
                    setIsActive(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="button-grid">
            <button
              className="main-button-admin"
              onClick={() => {
                resetInputs();
                navigate("/agents");
              }}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Cancel")
              )}
            </button>
            <button className="main-button-admin">
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Add Sub Agent")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
