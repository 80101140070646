import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as SearchLogo } from "../../../../assets/VIP-ICON-SVG/SearchLogo.svg";
import toastPopup from "../../../../helpers/toastPopup";
import i18n from "../../../../locales/i18n";
import VendorServices from "../../../../services/VendorServices";
import MainTable from "../../../MainTable/MainTable";
import "./Cashier.scss";

export default function Cashier() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { cashier: cashierPermissions } = frontPermissions;

  let {
    canCreate: canCreateCashier,
    canView: canViewCashier,
    canEdit: canEditCashier,
    canDelete: canDeleteCashier,
  } = cashierPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams();
  const lang = i18n.language;

  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [activeCashier, setActiveCashier] = useState([]);
  const [inActiveCashier, setInActiveCashier] = useState([]);
  const [isActiveCashier, setIsActiveCashier] = useState(true);
  const [isInActiveCashier, setIsInActiveCashier] = useState(false);
  const [totalActiveCashiers, setTotalActiveCashiers] = useState(0);
  const [totalInActiveCashiers, setTotalInActiveCashiers] = useState(0);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);

  async function getBranchActiveCashiers() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await VendorServices.getBranchActiveCashiers(
        params.branchID,
        lang,
        searchActiveValue,
        activeCurrentPage
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let cashierName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          cashierName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          cashierName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          cashierName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          cashierName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          cashierName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          cashierName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteCashier
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: cashierName,
            Email: record.email,
            Phone: record.phone,
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: cashierName,
            Email: record.email,
            Phone: record.phone,
          };
        return returnedRecord;
      });

      setActiveCashier(cashier => {
        if ((searchActiveValue && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...cashier, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveCashiers(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getBranchInActiveCashiers() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await VendorServices.getBranchInActiveCashiers(
        params.branchID,
        lang,
        searchInActiveValue,
        inActiveCurrentPage
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let cashierName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          cashierName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          cashierName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          cashierName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          cashierName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          cashierName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          cashierName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteCashier
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: cashierName,
            Email: record.email,
            Phone: record.phone,
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: cashierName,
            Email: record.email,
            Phone: record.phone,
          };
        return returnedRecord;
      });

      setInActiveCashier(cashier => {
        if ((searchInActiveValue && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...cashier, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveCashiers(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteCashier(id) {
    setLoading(true);
    try {
      const { data } = await VendorServices.deleteCashier(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleCashierRowClick(id) {
    navigate(`/vendors/${params.id}/branchdetails/${params.branchID}/${id}`);
  }

  useEffect(() => {
    if (isActiveCashier) {
      getBranchActiveCashiers();
      setInActiveCashier([])
    }
  }, [params.branchID, lang, searchActiveValue, activeCurrentPage, isActiveCashier]);

  useEffect(() => {
    if (isInActiveCashier) {
      getBranchInActiveCashiers();
      setActiveCashier([])
    }
  }, [params.branchID, lang, searchInActiveValue, inActiveCurrentPage, isInActiveCashier]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteCashier(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveCashier ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveCashier(true); setIsInActiveCashier(false) }}
                className='all-branches-btn'>
                <h2>{t("Active Cashiers")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveCashier(true); setIsInActiveCashier(false) }}
              className='all-branches-btn'>
              <h2>{t("Active Cashiers")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveCashier ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveCashier(false); setIsInActiveCashier(true) }}
                className='all-branches-btn'>
                <h2>{t("Inactive Cashiers")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveCashier(false); setIsInActiveCashier(true) }}
              className='all-branches-btn'>
              <h2>{t("Inactive Cashiers")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveCashier ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <div className="add-branch">
                <div className="form-search">
                  <div className="form-input-search">
                    <input
                      onChange={(e) => {
                        setActiveCurrentPage(1);
                        setSearchActiveValue(prev => {
                          if (prev && e.target.value === '') {
                            navigate(0)
                          }
                          return e.target.value
                        });
                      }}
                      className="input-search"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={t("What Are You Looking For?")}
                    />
                    <label className="label-search">
                      <SearchLogo className="SearchLogo" />
                    </label>
                  </div>
                </div>
                {canCreateCashier && (
                  <div className="button">
                    <button
                      className="main-button-add"
                      onClick={() => {
                        navigate(
                          `/vendors/${params.id}/branchdetails/${params.branchID}/addcashier`
                        );
                      }}
                    >
                      {t("Add Cashier")}
                    </button>
                  </div>
                )}
              </div>
              {isSearchEmpty ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleCashierRowClick} tableRows={activeCashier} />
              )}
              {
                activeCashier.length !== totalActiveCashiers &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveCashier ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <div className="add-branch">
                <div className="form-search">
                  <div className="form-input-search">
                    <input
                      onChange={(e) => {
                        setInActiveCurrentPage(1);
                        setSearchInActiveValue(prev => {
                          if (prev && e.target.value === '') {
                            navigate(0)
                          }
                          return e.target.value
                        });
                      }}
                      className="input-search"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={t("What Are You Looking For?")}
                    />
                    <label className="label-search">
                      <SearchLogo className="SearchLogo" />
                    </label>
                  </div>
                </div>
              </div>
              {isSearchEmpty ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleCashierRowClick} tableRows={inActiveCashier} />
              )}
              {
                inActiveCashier.length !== totalInActiveCashiers &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
