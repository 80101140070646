import { t } from "i18next";
import "./PermissionIconWithText.scss";
export default function PermissionIconWithText({
  icon: Icon,
  text,
  onClick,
  notAvailable = false,
}) {
  return (
    <div
      className="permission-icon-with-text"
      style={{ color: notAvailable && "#9c9c9c" }}
    >
      {t(text)}
      <Icon
        className="permission-icon"
        onClick={() => {
          onClick();
        }}
      />
    </div>
  );
}
