import Axios from "./Axios";

let AdminServices = {
  addAdmin: async (obj) => {
    const response = await Axios.post(`/admin/create`, obj);
    return response;
  },

  getAdmin: async (id) => {
    const response = await Axios.get(`/admin/get?_id=${id}`);
    return response;
  },

  getChatAdmin: async () => {
    const response = await Axios.get(`/admin/get?role=chat`);
    return response;
  },

  uploadAdminImage: async (id, obj) => {
    const response = await Axios.post(`/admin/image?_id=${id}`, obj);
    return response;
  },

  deleteAdminImage: async (id) => {
    const response = await Axios.delete(`/admin/image?_id=${id}`);
    return response;
  },

  editAdmin: async (id, obj) => {
    const response = await Axios.put(`/admin/update?_id=${id}`, obj);
    return response;
  },

  changePassword: async (obj) => {
    const response = await Axios.put(`/admin/changePassword`, obj);
    return response;
  },

  deleteAdmin: async (id) => {
    const response = await Axios.delete(`/admin/remove?_id=${id}`);
    return response;
  },

  listAllAdmins: async (lang = "", search = "", page, limit = 25, role = "") => {
    const response = await Axios.get(
      `/admin/list?${role.length > 0 ? `&role=${role}` : ""
      }${search.length > 0 ? `&name.${lang}=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },
};

export default AdminServices;
