import Axios from "./Axios";

let ProductServices = {
  listAllProducts: async (categoryId, page, limit = 25, vendor, hotDeal) => {
    const response = await Axios.get(
      `/admin/product/list?${categoryId ? `&category=${categoryId}` : ""}&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${vendor ? `&vendor=${vendor}` : ""}${hotDeal ? `&isHotDeal=${hotDeal}` : ""}`
    );
    return response;
  },

  sortProducts: async (obj) => {
    const response = await Axios.post(`/admin/product/sort`, obj);
    return response;
  },

  sortProductsInVendor: async (obj) => {
    const response = await Axios.post(`/admin/product/sortInVendor`, obj);
    return response;
  },

  getProductPage: async (productId) => {
    const response = await Axios.get(`/admin/product/page?_id=${productId}`);
    return response;
  }
};

export default ProductServices;
