import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import AdminServices from "../../../services/AdminServices";
import toastPopup from "../../../helpers/toastPopup";
import { useTranslation } from "react-i18next";
import GrandPermissionsTable from "../../../Components/GrandPermissionsTable/GrandPermissionsTable";
import "./AdminDetails.scss";

export default function AdminDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { admin: adminPermissions } = frontPermissions;

  let canEditAdmin = adminPermissions.canEdit;

  let params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [admin, setAdmin] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  async function getAdmin() {
    setLoading(true);
    try {
      const { data } = await AdminServices.getAdmin(params.id);
      setLoading(true);
      setLoading(false);
      setAdmin(data.record[0]);
      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let adminDetails = [
    {
      id: "english_name",
      title: "English Name",
      type: "text",
      value: admin?.name?.en ? admin?.name?.en : "No Name",
    },
    {
      id: "arabic_name",
      title: "Arabic Name",
      type: "text",
      value: admin?.name?.ar ? admin?.name?.ar : "لا يوجد اسم",
    },
    { id: "email", title: "Email", type: "email", value: admin?.email }
  ];

  async function deleteAdminImage() {
    setLoading(true);
    try {
      const { data } = await AdminServices.deleteAdminImage(params.id);
      setLoading(true);
      setLoading(false);
      setModalShow(false);
      getAdmin()
      setImage(false)
      toastPopup.success(t("Image deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete this image?")}</h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteAdminImage()}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        ""
      )}
      {active && (
        <div className="main-details">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/admins");
              }}
            ></i>
          </div>
          <div className="client-image admin-image-details">
            {image ? (
              <>
                <img src={admin.image.Location} alt="Admin Image" />
                <div className="delete-image" onClick={() => { setModalShow(true); }}>
                  <i className="fa-solid fa-trash"></i>
                </div>
              </>
            ) : (
              <img
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgeRojQCaSZCVc3qj9TW_-2URYUpSq1AjwFw&usqp=CAU"
                }
                alt="Admin Image"
              />
            )}
          </div>
          <div className="client-datails">
            {canEditAdmin && (
              <div className="edit">
                <button onClick={() => navigate(`/admins/${admin._id}/edit`)}>
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </div>
            )}
            <div className="data">
              {adminDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {admin?.role === "admin" &&
        <GrandPermissionsTable
          permissionsObject={admin?.frontPermissions}
          viewOnly
        />
      }
    </>
  );
}
