import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import MainTable from '../../../../Components/MainTable/MainTable'
import i18n from '../../../../locales/i18n'
import ClientServices from '../../../../services/ClientServices'
import './PreviousOrders.scss'

export default function PreviousOrders() {

  let params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const lang = i18n.language

  const [clientOrders, setClientOrders] = useState([])
  const [orderDetails, setOrderDetails] = useState([])
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [cartTotal, setCartTotal] = useState(0)
  const [cartOriginalTotal, setCartOriginalTotal] = useState(0)
  const [cartPoints, setCartPoints] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [address, setAddress] = useState('')
  const [branchName, setBranchName] = useState('')

  async function getClientOrders() {
    try {
      const { data } = await ClientServices.getClientOrders(params.id)
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        return {
          Num: <div className='index' onClick={() => { setIsClicked(true) }}>{index + 1}</div>,
          id: record._id,
          "Payment Method": (record?.paymentMethod),
          "Total Price": (record?.total) + t(" EGP"),
        }
      })
      setClientOrders(tableData)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getOrderDetails() {
    try {
      const { data } = await ClientServices.getOrderDetails(params.orderID)
      setLoading(false);
      let tableData = data.records[0].items.map((record, index) => {
        return {
          Num: <div className='index'>{index + 1}</div>,
          id: record?.product?._id,
          Photo: <div className="product-image-container">
            <img
              src={`${record?.product?.image?.Location}`}
              alt="Product Image"
              className="product-image" />
          </div>,
          Name: (record?.product?.name?.[lang]),
          Price: (record?.product?.price) + t(" EGP"),
          Quantity: (record?.quantity),
          "Total Price": (record?.total) + t(" EGP")
        }
      })
      setOrderDetails(tableData)
      setCartTotal(data?.records[0]?.total)
      setCartOriginalTotal(data?.records[0]?.originalTotal)
      setCartPoints(data?.records[0]?.points)
      setBranchName(data?.records[0]?.items[0]?.product?.branches[0]?.name?.[lang])
      setPaymentMethod(data?.records[0]?.paymentMethod)
      setAddress(data?.records[0]?.shippingAddress?.area + ' ' + data?.records[0]?.shippingAddress?.city)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleOrderRowClick(id) {
    navigate(`/clients/${params.id}/orderdetails/${id}`)
  }

  useEffect(() => {
    getClientOrders()
  }, [])

  useEffect(() => {
    if (isClicked) {
      getOrderDetails()
    }
  }, [isClicked])

  return <>

    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}

    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (params?.orderID) ?
        (<> <div>
          <MainTable
            tableRows={orderDetails} />
        </div>
          <div className="totals">
            <div className="item">
              <span>{t("Branch Name")}</span>
              <div className="data">{branchName}</div>
            </div>
            <div className="item">
              <span>{t("Cart Total Price")}</span>
              <div className="data">{cartTotal} {t("EGP")}</div>
            </div>
            <div className="item">
              <span>{t("Cart Original Total Price")}</span>
              <div className="data">{cartOriginalTotal} {t("EGP")}</div>
            </div>
            <div className="item">
              <span>{t("Cart Points")}</span>
              <div className="data">{cartPoints}</div>
            </div>
            <div className="item">
              <span>{t("Payment Method")}</span>
              <div className="data">{paymentMethod}</div>
            </div>
            <div className="item">
              <span>{t("Address")}</span>
              <div className="data">{address}</div>
            </div>
          </div>

        </>) :
        (<div>
          {clientOrders.length > 0 ?
            <MainTable
              tableRows={clientOrders}
              onRowClick={handleOrderRowClick} />
            : <p className='no-results'>{t("This client didn't have any orders yet!")}</p>}
        </div>)}
  </>

}
