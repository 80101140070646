import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locales/i18n';
import { ReactComponent as SearchLogo } from "../../../assets/VIP-ICON-SVG/SearchLogo.svg";
import RequestServices from '../../../services/RequestServices';
import toastPopup from '../../../helpers/toastPopup';
import MainTable from '../../../Components/MainTable/MainTable';
import './PendingRequest.scss'

export default function PendingRequest() {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = i18n.language;

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [request, setRequest] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [totalRequests, setTotalRequests] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllPendingRequests() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await RequestServices.listAllPendingRequests(
        lang,
        searchValue,
        filterValue === 'All' ? '' : filterValue,
        currentPage
      );

      setLoading(false);
      let tableData = data?.records?.map((record, index) => {
        let requestName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          requestName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          requestName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          requestName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          requestName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          requestName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          requestName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?._id,
          Name: requestName,
          Email: record?.email,
          Phone: record?.phone,
          "Request Type": record?.type,
          Remove: (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setModalShow(true);
                setCurrentUser(record);
              }}
              className="remove"
            >
              <i className="fa-solid fa-trash"></i>
            </button>
          ),
        }
      });

      setRequest(req => {
        if ((filterValue && currentPage === 1) || (searchValue && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...req, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalRequests(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteRequest(id) {
    setLoading(true);
    try {
      const { data } = await RequestServices.deleteRequest(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleRequestRowClick(id) {
    navigate(`/requests/${id}`);
  }

  useEffect(() => {
    getAllPendingRequests();
  }, [
    lang,
    searchValue,
    filterValue,
    currentPage
  ]);

  return <>
    {modalShow && (
      <div
        className={modalShow ? "overlay" : "overlay-remove-cancel"}
        id="overlay-remove"
      >
        <div className="add-admin-box">
          <h3>{t("Are you sure you want to delete ")}</h3>
          <p className="admin-name">
            {(currentUser?.name?.[lang]).toUpperCase()} {t("?")}
          </p>
          <div className="modal-buttons">
            <div
              onClick={() => setModalShow(false)}
              className="cancel-remove"
            >
              {t("Cancel")}
            </div>
            <div
              onClick={() => deleteRequest(currentUser._id)}
              className="delete"
            >
              {t("Delete")}
            </div>
          </div>
        </div>
        <div className="cancel" onClick={() => setModalShow(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
      </div>
    )}

    {errorMessage ? <div className="err">{errorMessage}</div> : ""}

    {loading ? (
      <div className="loading-spinner">
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    ) : (
      <div>
        <div className="form-search">
          <div className="form-input-search">
            <input
              onChange={(e) => {
                setCurrentPage(1);
                setSearchValue(prev => {
                  if (prev && e.target.value === '') {
                    navigate(0)
                  }
                  return e.target.value
                });
              }}
              className="input-search"
              type="text"
              name="search"
              id="search"
              placeholder={t("What Are You Looking For?")}
            />
            <label className="label-search">
              <SearchLogo className="SearchLogo" />
            </label>
          </div>
        </div>
        <div className="filter">
          <div className="items">
            <div>
              <input
                defaultChecked
                value="All"
                onClick={(e) => {
                  setCurrentPage(1);
                  setFilterValue(e.target.value);
                }}
                type="radio"
                name="filter"
                id="all"
              />
              <label htmlFor="all">{t("All")}</label>
            </div>

            <div>
              <input
                value="vendor"
                onClick={(e) => {
                  setCurrentPage(1);
                  setFilterValue(e.target.value);
                }}
                type="radio"
                name="filter"
                id="vendor"
              />
              <label htmlFor="vendor">{t("Vendor")}</label>
            </div>

            <div>
              <input
                value="agent"
                onClick={(e) => {
                  setCurrentPage(1);
                  setFilterValue(e.target.value);
                }}
                type="radio"
                name="filter"
                id="agent"
              />
              <label htmlFor="agent">{t("Agent")}</label>
            </div>

            <div>
              <input
                value="org"
                onClick={(e) => {
                  setCurrentPage(1);
                  setFilterValue(e.target.value);
                }}
                type="radio"
                name="filter"
                id="org"
              />
              <label htmlFor="org">{t("Org")}</label>
            </div>
          </div>
        </div>

        {request.length === 0 ? (
          <p className="no-results">{t("No results found!")}</p>
        ) : (
          <MainTable onRowClick={handleRequestRowClick} tableRows={request} />
        )}
        {
          request.length !== totalRequests &&
          <div className="load">
            <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
              {t("Load more...")}
            </button>
          </div>
        }
      </div>
    )}
  </>
}
