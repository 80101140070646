import React from 'react'
import { useTranslation } from 'react-i18next';
import './InfoInput.scss'

export default function InfoInput(props) {

  const { t } = useTranslation();

  return <>
    <div className="input-label-disabled">
      <label
        className='disabled-label'
        htmlFor={props.id}>
        {t(props.title)}
      </label>
      <div className="input-div">
        <input
          id={props.id}
          className='disabled-input'
          type={props.type}
          value={props.value}
          disabled />
      </div>
    </div>
  </>
}
