import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Checked } from "../../../assets/SVG/dark-blue.svg";
import { ReactComponent as NotChecked } from "../../../assets/SVG/dark-blue-circle.svg";
import { ReactComponent as Edit } from "../../../assets/SVG/green-circle.svg";
import { ReactComponent as EditShaded } from "../../../assets/SVG/green-circle-shaded.svg";
import { ReactComponent as EditChecked } from "../../../assets/SVG/green-fill-circle.svg";

import "./GrandPermissionsTableRow.scss";
import PermissionIconWithText from "../PermissionIconWithText/PermissionIconWithText";

//#9c9c9c

export default function GrandPermissionsTableRow({
  tableRowKey,
  permissionsObject = {},
  setPermissionsObject = () => { },
  viewOnly = false,
}) {
  const { t } = useTranslation();

  let allPossiblePermissions = [
    "canView",
    "canCreate",
    "canEdit",
    "canDelete",
    "canChangePassword",
    "canSort",
  ];

  function rowHasProperty(tableRowObj, propertyKey) {
    return tableRowObj.hasOwnProperty(propertyKey);
  }
  const tableRow = permissionsObject[tableRowKey];

  function properyChecked(tableRowObj, propertyKey) {
    return tableRowObj[propertyKey] === true;
  }

  function checkPropertyHandler(boolean, propertyKey) {
    setPermissionsObject({
      ...permissionsObject,

      [tableRowKey]: {
        ...permissionsObject[tableRowKey],
        [propertyKey]: boolean,
      },
    });
  }

  function isRowCheked() {
    let rowKeysTruthyArray = Object.values(tableRow);
    return rowKeysTruthyArray.every((element) => element === true);
  }

  function checkRowHandler(boolean) {
    let rowProperties = permissionsObject[tableRowKey];
    let rowPropertiesArray = Object.keys(rowProperties);
    let rowPropertiesObject = {};
    rowPropertiesArray.forEach((property) => {
      rowPropertiesObject = { ...rowPropertiesObject, [property]: boolean };
    });

    setPermissionsObject({
      ...permissionsObject,
      [tableRowKey]: {
        ...rowPropertiesObject,
      },
    });
  }

  // function checkPermissionHandler(
  //   boolean,
  //   name,
  //   secondName,
  //   thirdName,
  //   fourthName
  // ) {
  //   setPermissionsObject({
  //     ...permissionsObject,

  //     [tableRowKey]: {
  //       ...permissionsObject[tableRowKey],
  //       [name]: boolean,
  //       [secondName]: boolean,
  //       [thirdName]: boolean,
  //       [fourthName]: boolean,
  //     },
  //   });
  // }

  return (
    <tr className="table-row">
      <td className="table-data-cell permission-cell">
        <div className="circle-label">
          {isRowCheked() ? (
            <Checked
              className="permission-icon"
              onClick={
                viewOnly
                  ? () => { }
                  : () => {
                    checkRowHandler(false);
                  }
              }
            />
          ) : (
            <NotChecked
              className="permission-icon"
              onClick={
                viewOnly
                  ? () => { }
                  : () => {
                    checkRowHandler(true);
                  }
              }
            />
          )}
        </div>
      </td>
      <td className="table-data-cell">{t(tableRowKey)}</td>
      <td className="table-data-cell permission-cell">
        <div className="permissions-container">
          {tableRow
            ? allPossiblePermissions.map((permKey, i) => {
              let returnedIcon = rowHasProperty(tableRow, permKey) ? (
                properyChecked(tableRow, permKey) ? (
                  <PermissionIconWithText
                    key={`${i}-${i}-${i}`}
                    text={permKey}
                    onClick={
                      viewOnly
                        ? () => { }
                        : () => {
                          checkPropertyHandler(false, permKey);
                        }
                    }
                    icon={EditChecked}
                  />
                ) : (
                  <PermissionIconWithText
                    key={`${i}-${i}-${i}`}
                    text={permKey}
                    onClick={
                      viewOnly
                        ? () => { }
                        : () => {
                          checkPropertyHandler(true, permKey);
                        }
                    }
                    icon={Edit}
                  />
                )
              ) : (
                <PermissionIconWithText
                  key={`${i}-${i}-${i}`}
                  text={permKey}
                  onClick={() => { }}
                  icon={EditShaded}
                  notAvailable
                />
              );
              return returnedIcon;
            })
            : null}
        </div>
      </td>
    </tr>
  );
}
