import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import i18n from "../../locales/i18n";
import ProductServices from "../../services/ProductServices";
import VendorServices from "../../services/VendorServices";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import toastPopup from "../../helpers/toastPopup";
import "./SortProduct.scss";
import {
  moveOneDown,
  moveOneUp,
  moveToEnd,
  moveToTop,
} from "../../helpers/arraySorting";

export default function SortProduct() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  const params = useParams();
  const location = useLocation();

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalProducts, setTotalProducts] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([])
  const [productCategory, setProductCategory] = useState("")
  const [isHotDeal, setIsHotDeal] = useState("")
  const [vendor, setVendor] = useState("")
  const [vendors, setVendors] = useState([])
  const [currentPage, setCurrentPage] = useState(location.state ? location.state : 1);

  const scrollToRef = useRef(null);

  async function getProducts() {
    try {
      const { data } = await ProductServices.listAllProducts(
        productCategory,
        currentPage,
        25,
        vendor,
        isHotDeal
      );
      setLoading(false);
      let tableData = data.records.map((record) => {
        return {
          id: record._id,
          Photo: (
            <div
              className={
                params.id === record._id
                  ? "product-image-container to-be-sorted"
                  : "product-image-container"
              }
            >
              <img
                src={`${record?.image?.[0]?.Location}`}
                alt="Product Image"
                className="product-image"
              />
            </div>
          ),
          Name: (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.name?.[lang]}
            </div>
          ),
          Vendor: (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.vendor?.name?.[lang]}
            </div>
          ),
          "Sale Price": (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.price + t(" EGP")}
            </div>
          ),
          "Full Price": (
            <div className={params.id === record._id ? "to-be-sorted" : ""}>
              {record?.originalPrice + t(" EGP")}
            </div>
          ),
        };
      });

      setProduct(singleProduct => {
        if ((productCategory && currentPage === 1) || (isHotDeal && currentPage === 1) || (vendor && currentPage === 1)) {
          return [...tableData]
        }
        if (location?.state > 1) {
          return [...tableData, ...singleProduct]
        }
        return [...singleProduct, ...tableData]
      })
      setTotalProducts(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllVendors() {
    try {
      const { data } = await VendorServices.listAllVendors();
      setVendors(data?.records);
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getProductCategories() {
    try {
      const { data } = await VendorServices.getVendorCategories("product");
      setCategories(data?.records);
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleSortProductRowClick(id) {
    navigate(`/sort-products/product-details/${id}`);
  }

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [product])

  useEffect(() => {
    getProductCategories()
    getAllVendors()
  }, [])

  useEffect(() => {
    getProducts();
  }, [productCategory, currentPage, isHotDeal, vendor]);

  useEffect(() => {
    if (location.state) {
      setCurrentPage(location.state)
    }
  }, [location])


  let tableRows = product.map((singleProduct, index) => {
    return {
      Num: (
        <div
          className={
            params.id === singleProduct.id ? "index to-be-sorted" : "index"
          }
          ref={params?.id === singleProduct.id ? scrollToRef : null}
        >
          {index + 1}
        </div>
      ),
      ...singleProduct,
      Arrange: (
        <div
          className={
            params.id === singleProduct.id ? " sorting to-be-sorted" : "sorting"
          }
        >
          <button
            disabled={index === 0}
            onClick={(e) => {
              e.stopPropagation();
              moveOneUp(index, product, setProduct);
            }}
            className={
              index === 0 ? "arrange-button-disabled" : "arrange-button"
            }
          >
            <i className="fa-solid fa-angle-up"></i>
          </button>
          <button
            disabled={index === 0}
            onClick={(e) => {
              e.stopPropagation();
              moveToTop(index, product, setProduct);
            }}
            className={
              index === 0 ? "arrange-button-disabled" : "arrange-button"
            }
          >
            <i className="fa-solid fa-angles-up"></i>
          </button>
          <button
            disabled={index === product.length - 1}
            onClick={(e) => {
              e.stopPropagation();
              moveOneDown(index, product, setProduct);
            }}
            className={
              index === product.length - 1
                ? "arrange-button-disabled"
                : "arrange-button"
            }
          >
            <i className="fa-solid fa-angle-down"></i>
          </button>
          <button
            disabled={index === product.length - 1}
            onClick={(e) => {
              e.stopPropagation();
              moveToEnd(index, product, setProduct);
            }}
            className={
              index === product.length - 1
                ? "arrange-button-disabled"
                : "arrange-button"
            }
          >
            <i className="fa-solid fa-angles-down"></i>
          </button>
        </div>
      ),
    };
  });

  async function applySortHandler() {
    let sortedArray = tableRows.map((row, index) => {
      return {
        _id: row.id,
        rank: index + 1,
      };
    });

    let finalSortedArray = {
      list: sortedArray,
    };

    setLoading(true);
    try {
      const { data } = vendor ? await ProductServices.sortProductsInVendor(finalSortedArray) : await ProductServices.sortProducts(finalSortedArray)
      setLoading(false);
      toastPopup.success(t("Sorted Successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Products"}
            text2={"Products"}
            total={totalProducts}
            btnText="Apply Sort"
            onBtnClick={applySortHandler}
          />

          <div className="form-search">
            <div className="filter-by-container">
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setProductCategory(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="">
                    {t("Category")}
                  </option>
                  {categories.map((singleCategory) => {
                    return <option key={singleCategory?._id} value={singleCategory?._id}>{singleCategory?.name?.[lang]}</option>
                  })}
                </select>
              </div>
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setVendor(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="">
                    {t("Vendor")}
                  </option>
                  {vendors.map((singleVendor) => {
                    return <option key={singleVendor?._id} value={singleVendor?._id}>{singleVendor?.name?.[lang]}</option>
                  })}
                </select>
              </div>
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setIsHotDeal(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="">
                    {t("Product Type")}
                  </option>
                  <option value="true">{t("Hot deal")}</option>
                  <option value="false">{t("Offer")}</option>
                </select>
              </div>
            </div>
          </div>

          {
            location?.state && location.state !== 1 && currentPage !== 1 &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev - 1) }}>
                {t("Prev...")}
              </button>
            </div>
          }

          {totalProducts === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable
              onRowClick={handleSortProductRowClick}
              tableRows={tableRows}
            />
          )}
          {
            product.length !== totalProducts &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
