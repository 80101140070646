import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PointServices from '../../../../services/PointServices';
import toastPopup from '../../../../helpers/toastPopup';
import MainInput from '../../../../Components/MainInput';
import './PointEdit.scss'

export default function PointEdit() {

  let params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [newPoints, setNewPoints] = useState({
    vipPoints: 0
  });

  let formData = [
    { name: "vipPoints", type: "number", required: false },
  ];

  async function addPointsHandler(e) {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await PointServices.addPoints(
        params.pointID,
        { vipPoints: parseInt(newPoints.vipPoints) }
      );
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      navigate(`/points/${params.pointID}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function removePointsHandler(e) {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await PointServices.removePoints(
        params.pointID,
        { vipPoints: parseInt(newPoints.vipPoints) }
      );
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      navigate(`/points/${params.pointID}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/points/${params.pointID}`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Points")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <form className="form-grid">
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newPoints}
                  setState={setNewPoints}
                />
              );
            })}
          </div>
          <div className="button-grid">
            <button
              className="main-button-admin"
              disabled={loading}
              onClick={addPointsHandler}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Add Points")
              )}
            </button>
            <button
              className="main-button-admin"
              disabled={loading}
              onClick={removePointsHandler}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Remove Points")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
