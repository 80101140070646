import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import toastPopup from '../../../../helpers/toastPopup';
import { useNavigate, useParams } from 'react-router-dom';
import ClientServices from '../../../../services/ClientServices';
import Barcode from 'react-barcode'
import ShortUniqueId from 'short-unique-id';
import OrganizationServices from '../../../../services/OrganizationServices';
import AgentServices from '../../../../services/AgentServices';
import './AddClientByBarcode.scss'

export default function AddClientByBarcode() {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [barcodeUUID, setBarcodeUUID] = useState('');

  const { t } = useTranslation();
  const navigate = useNavigate()
  const params = useParams()

  function generateBarcode() {
    const uid = new ShortUniqueId({ length: 10 });
    setBarcodeUUID(uid())
  }

  async function addNewClient(e) {
    e.preventDefault();
    setLoading(true);

    const currentDate = new Date(Date.now());
    const currentDateISO = new Date(currentDate).toISOString();
    const nextYear = currentDate.setFullYear(currentDate.getFullYear() + 1);
    const nextYearISO = new Date(nextYear).toISOString();

    if (window.location.href.includes('agents')) {
      try {
        const obj = {
          barcode: barcodeUUID,
          agent: params.id,
          isActive: true,
          isSubscribed: true,
          subStartDate: currentDateISO,
          subEndDate: nextYearISO
        }
        const { data } = await ClientServices.addClient(obj)
        setLoading(false);
        try {
          const { data } = await AgentServices.getAgent(params.id);
          setLoading(false);
          let agentCredit = data?.record[0]?.credit;
          try {
            const { data } = await AgentServices.editAgent(params.id
              , { credit: agentCredit - 1 }
            );
          } catch (e) {
            setLoading(false);
            setErrorMessage(e.response.data.error);
          }
        } catch (e) {
          setLoading(false);
          setErrorMessage("Unexpected Error happened. Please try again later");
        }
        toastPopup.success(t("Added"));
        navigate(`/clients/${data.record._id}`)
      } catch (e) {
        setLoading(false);
        setErrorMessage(e.response.data.error);
      }
    }
    else if (window.location.href.includes('organizations')) {
      try {
        const obj = {
          barcode: barcodeUUID,
          org: params.id,
          isActive: true,
          isSubscribed: true,
          subStartDate: currentDateISO,
          subEndDate: nextYearISO
        }
        const { data } = await ClientServices.addClient(obj)
        setLoading(false);
        try {
          const { data } = await OrganizationServices.getOrganization(params.id);
          setLoading(false);
          let organizationCredit = data?.record[0]?.credit;
          try {
            const { data } = await OrganizationServices.editOrganization(params.id
              , { credit: organizationCredit - 1 }
            );
          } catch (e) {
            setLoading(false);
            setErrorMessage(e.response.data.error);
          }
        } catch (e) {
          setLoading(false);
          setErrorMessage("Unexpected Error happened. Please try again later");
        }
        toastPopup.success(t("Added"));
        navigate(`/clients/${data.record._id}`)
      } catch (e) {
        setLoading(false);
        setErrorMessage(e.response.data.error);
      }
    }
    else {
      try {
        const obj = {
          barcode: barcodeUUID,
          isActive: true,
          isSubscribed: false
        }
        const { data } = await ClientServices.addClient(obj)
        setLoading(false);
        toastPopup.success(t("Added"));
        navigate(`/clients/${data.record._id}`)
      } catch (e) {
        setLoading(false);
        setErrorMessage(e.response.data.error);
      }
    }
  };

  return <>

    <div className="add-client">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate(`/clients`) }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Client")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      {barcodeUUID ? (<div className="main-image-label">
        <Barcode value={barcodeUUID} width={1} height={30} />
      </div>) : ""}
      <div className="generate">
        <button
          onClick={generateBarcode}>
          {t("Generate Barcode")}
          <i className="fa-solid fa-barcode"></i>
        </button>
      </div>

      <form className='form-grid' onSubmit={addNewClient}>
        <div className="button-grid">
          <button className="main-button-admin" onClick={() => { navigate('/clients') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Client")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
