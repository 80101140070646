import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PendingAds from "./PendingAds/PendingAds";
import AcceptedAds from "./AcceptedAds/AcceptedAds";
import RejectedAds from "./RejectedAds/RejectedAds";
import { useSelector } from "react-redux";
import "./Ads.scss";

export default function Ads() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { ad: adPermissions } = frontPermissions;

  const { t } = useTranslation();

  const [pendingAds, setPendingAds] = useState(true);
  const [acceptedAds, setAcceptedAds] = useState(false);
  const [rejectedAds, setRejectedAds] = useState(false);

  return (
    <>
      <div className="user">
        {pendingAds ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingAds(true);
                setAcceptedAds(false);
                setRejectedAds(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Pending Ads")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingAds(true);
                setAcceptedAds(false);
                setRejectedAds(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Pending Ads")}</h2>
            </button>
          </div>
        )}
        {acceptedAds ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingAds(false);
                setAcceptedAds(true);
                setRejectedAds(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Accepted Ads")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingAds(false);
                setAcceptedAds(true);
                setRejectedAds(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Accepted Ads")}</h2>
            </button>
          </div>
        )}
        {rejectedAds ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingAds(false);
                setAcceptedAds(false);
                setRejectedAds(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Rejected Ads")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingAds(false);
                setAcceptedAds(false);
                setRejectedAds(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Rejected Ads")}</h2>
            </button>
          </div>
        )}
      </div>

      {pendingAds ? (
        <div className="branch-cards-container">
          <PendingAds />
        </div>
      ) : (
        ""
      )}

      {acceptedAds ? (
        <div className="branch-cards-container">
          <AcceptedAds />
        </div>
      ) : (
        ""
      )}

      {rejectedAds ? (
        <div className="branch-cards-container">
          <RejectedAds />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
