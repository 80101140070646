import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import COUNTRIES_CITIES from '../../../locales/app-countries.json'
import BannerServices from "../../../services/BannerServices";
import toastPopup from "../../../helpers/toastPopup";
import MainInput from "../../../Components/MainInput";
import AdsServices from "../../../services/AdsServices";
import i18n from "../../../locales/i18n";
import Multiselect from "multiselect-react-dropdown";
import "./AddBanner.scss";

export default function AddBanner() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [countryIndex, setCountryIndex] = useState("");
  const [cityIndex, setCityIndex] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [image, setImage] = useState(null);
  const [gender, setGender] = useState("");
  const [size, setSize] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);

  const [newBanner, setNewBanner] = useState({
    name: "",
    link: "",
    startDate: "",
    endDate: "",
    ageFrom: 0,
    ageTo: 0,
  });

  async function getAd() {
    setLoading(true);
    try {
      const { data } = await AdsServices.getAd(params?.ad_id);
      setLoading(false);

      let StartDate = data?.record[0]?.startDate?.indexOf("T");
      let EndDate = data?.record[0]?.endDate?.indexOf("T");
      setNewBanner({
        link: data?.record[0]?.link,
        name: data?.record[0]?.name,
        startDate: data?.record[0]?.startDate?.slice(0, StartDate),
        endDate: data?.record[0]?.endDate?.slice(0, EndDate),
        ageFrom: data?.record[0]?.age?.from,
        ageTo: data?.record[0]?.age?.to,
      });
      setSize(data?.record[0]?.bannerSize);
      setGender(data?.record[0]?.gender);
      setCountryIndex(data?.record[0]?.country?.index);
      setSelectedCities(data?.record[0]?.city.map((city) => {
        return {
          index: city?.index,
          name: city?.[lang]
        }
      }))
      setImage(data?.record[0]?.image);
      setUploadImage(data?.record[0]?.image?.Location);
      setIsActive(data?.record[0]?.isActive);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function resetInputs() {
    setNewBanner({
      name: "",
      link: "",
      startDate: "",
      endDate: "",
      ageFrom: 0,
      ageTo: 0,
    });
  }

  let formData = [
    { name: "name", type: "text", required: false },
    { name: "link", type: "text", required: false },
    { name: "startDate", type: "Date", required: true },
    { name: "endDate", type: "Date", required: true },
    { name: "ageFrom", type: "number", required: true },
    { name: "ageTo", type: "number", required: true },
  ];

  async function addNewBanner(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        country: {
          en: selectedCountry?.en,
          ar: selectedCountry?.ar,
          index: countryIndex,
        },
        city: getFinalCities(),
        age: {
          from: newBanner.ageFrom,
          to: newBanner.ageTo,
        },
        size: size,
        gender: gender,
        link: newBanner.link,
        name: newBanner.name,
        startDate: newBanner.startDate,
        endDate: newBanner.endDate,
        isActive: isActive,
        isPopUp: false,
      };

      const finalObj = {};

      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }

      const { data } = await BannerServices.addBanner(finalObj);

      setLoading(false);
      resetInputs();
      toastPopup.success(t("Added"));
      let bannerID = data.record._id;
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } = await BannerServices.uploadBannerImage(
          bannerID,
          formData
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/banners/${bannerID}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function addNewBannerFromAds(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        country: {
          en: selectedCountry?.en,
          ar: selectedCountry?.ar,
          index: countryIndex,
        },
        city: getFinalCities(),
        age: {
          from: newBanner.ageFrom,
          to: newBanner.ageTo,
        },
        size: size,
        gender: gender,
        name: newBanner.name,
        link: newBanner.link,
        startDate: newBanner.startDate,
        endDate: newBanner.endDate,
        isActive: isActive,
        isPopUp: false,
        image: image,
        ad: params?.ad_id,
      };

      const finalObj = {};

      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }

      const { data } = await BannerServices.addBanner(finalObj);

      setLoading(false);
      resetInputs();
      toastPopup.success(t("Added"));
      try {
        const { data } = await AdsServices.editAd(params?.ad_id, {
          status: "accepted",
        });
        setLoading(false);
        toastPopup.success(t("Ad Accepted"));
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/banners/${data.record._id}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(countryIndex))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(cityIndex))[0]

  function toggleSelectedCityHandler(city) {
    if (selectedCities.includes(city)) {
      let oldSelectedCities = selectedCities;
      let newSelectedCities = oldSelectedCities.filter((singleCity) => {
        return singleCity !== city;
      });
      setSelectedCities(newSelectedCities);
    } else {
      setSelectedCities((prev) => {
        return [...prev, city];
      });
    }
  }

  function getFinalCities() {
    let finalCities = [];
    selectedCities.forEach((selectedCity) => {
      selectedCountry?.cities
        ?.filter((city) => city?.index === selectedCity?.index)
        ?.map((city) => {
          finalCities.push({
            en: city?.en,
            ar: city?.ar,
            index: city?.index
          });
        });
    });

    return finalCities;
  }

  let citiesOptions = selectedCountry?.cities?.map((city) => {
    return ({
      name: city?.[lang],
      index: city?.index
    })
  })

  function isSelectedCity(city) {
    let selectedCitiesIndex = selectedCities.map((selectedCity) => selectedCity?.index)
    return selectedCitiesIndex.includes(city?.index)
  }

  function toggleSelectedCitiesHandler(city) {
    if (isSelectedCity(city)) {
      let oldSelectedCities = selectedCities
      let newSelectedCities = oldSelectedCities.filter((singleCity) => { return singleCity !== city })
      setSelectedCities(newSelectedCities)
    } else {
      setSelectedCities((prev) => {
        return [...prev, city]
      })
    }
  }

  useEffect(() => {
    if (params?.ad_id) {
      getAd();
    }
  }, [params?.ad_id]);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/banners`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Add Banner")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>
        <div className="main-image-label vendor-cover">
          {uploadImage && (
            <img
              src={
                typeof uploadImage === "object"
                  ? URL.createObjectURL(uploadImage)
                  : uploadImage
                    ? uploadImage
                    : null
              }
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadImage ? URL.createObjectURL(uploadImage) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          <label
            className="main-label-image"
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            {t("Add Banner")}
          </label>
        </div>

        <form
          className="form-grid"
          onSubmit={params.ad_id ? addNewBannerFromAds : addNewBanner}
        >
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newBanner}
                  setState={setNewBanner}
                />
              );
            })}

            <div className="main-input-select">
              <select
                onChange={(e) => {
                  setSize(e.target.value);
                }}
                className="main-input"
                id="size"
                name="size"
                title="size"
                value={size}
              >
                <option value="">
                  -- {t("Size")} --
                </option>
                <option value={"large"}>{t("Large")}</option>
                <option value={"medium"}>{t("Medium")}</option>
                <option value={"small"}>{t("Small")}</option>
              </select>
              <label className="main-label">{t("Size")}</label>
            </div>

            <div className="main-input-select">
              <select
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                className="main-input"
                id="gender"
                name="gender"
                title="gender"
                value={gender}
              >
                <option value="">
                  -- {t("Gender")} --
                </option>
                <option value={"male"}>{t("Male")}</option>
                <option value={"female"}>{t("Female")}</option>
                <option value={"both"}>{t("Both")}</option>
              </select>
              <label className="main-label">{t("Gender")}</label>
            </div>

            <div className="main-input-select">
              <select
                selected={countryIndex}
                value={countryIndex}
                onChange={(e) => { setCountryIndex(e.target.value) }}
                className='main-input'
                id="country"
                name="country"
                title='country'>
                <option value=''>{t("Country")}</option>
                {
                  COUNTRIES_CITIES?.map((country, index) => {
                    return <option key={index} value={country?.index}>{country?.[lang]}</option>
                  })
                }
              </select>
              <label className="main-label">{t("Country")}</label>
            </div>

            {params?.ad_id ?
              <Multiselect
                displayValue="name"
                selectedValues={selectedCities}
                onKeyPressFn={function noRefCheck() { }}
                onRemove={function noRefCheck(selectedList, selectedItem) {
                  toggleSelectedCitiesHandler(selectedItem)
                }}
                onSearch={function noRefCheck() { }}
                onSelect={function noRefCheck(selectedList, selectedItem) {
                  toggleSelectedCitiesHandler(selectedItem)
                }}
                options={citiesOptions}
                showCheckbox
                placeholder={t("Cities")}
              />
              :
              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={function noRefCheck(selectedList, selectedItem) {
                  toggleSelectedCityHandler(selectedItem)
                }}
                onSearch={function noRefCheck() { }}
                onSelect={function noRefCheck(selectedList, selectedItem) {
                  toggleSelectedCityHandler(selectedItem)
                }}
                options={citiesOptions}
                showCheckbox
                placeholder={t("Cities")}
              />}

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  checked={isActive}
                  onChange={(e) => {
                    setIsActive(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="button-grid">
            <button
              className="main-button-admin"
              onClick={() => {
                resetInputs();
                navigate(`/banners`);
              }}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Cancel")
              )}
            </button>
            <button className="main-button-admin">
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Add Banner")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
