import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReviewServices from "../../../services/ReviewServices";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import toastPopup from "../../../helpers/toastPopup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./ReviewDetails.scss";

export default function ReviewDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { review: reviewPermissions } = frontPermissions;

  let params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [review, setReview] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  async function getReview() {
    setLoading(true);
    try {
      const { data } = await ReviewServices.getReview(params.reviewID);
      setLoading(false);
      setReview(data.record);
      setActive(true);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let clientName = ""
  if (localStorage.getItem("i18nextLng") === "en" && review?.client?.name?.en) {
    clientName = review?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !review?.client?.name?.en && review?.client?.name?.ar) {
    clientName = review?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && review?.client?.name?.ar) {
    clientName = review?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !review?.client?.name?.ar && review?.client?.name?.en) {
    clientName = review?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !review?.client?.name?.en && !review?.client?.name?.ar) {
    clientName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !review?.client?.name?.ar && !review?.client?.name?.en) {
    clientName = "لا يوجد أسم"
  }

  let vendorName = ""
  if (localStorage.getItem("i18nextLng") === "en" && review?.vendor?.name?.en) {
    vendorName = review?.vendor?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !review?.vendor?.name?.en && review?.vendor?.name?.ar) {
    vendorName = review?.vendor?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && review?.vendor?.name?.ar) {
    vendorName = review?.vendor?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !review?.vendor?.name?.ar && review?.vendor?.name?.en) {
    vendorName = review?.vendor?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !review?.vendor?.name?.en && !review?.vendor?.name?.ar) {
    vendorName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !review?.vendor?.name?.ar && !review?.vendor?.name?.en) {
    vendorName = "لا يوجد أسم"
  }

  let productName = ""
  if (localStorage.getItem("i18nextLng") === "en" && review?.product?.name?.en) {
    productName = review?.product?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !review?.product?.name?.en && review?.product?.name?.ar) {
    productName = review?.product?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && review?.product?.name?.ar) {
    productName = review?.product?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !review?.product?.name?.ar && review?.product?.name?.en) {
    productName = review?.product?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !review?.product?.name?.en && !review?.product?.name?.ar) {
    productName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !review?.product?.name?.ar && !review?.product?.name?.en) {
    productName = "لا يوجد أسم"
  }

  let reviewDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: clientName,
    },
    {
      id: "vendor",
      title: "Vendor",
      type: "text",
      value: vendorName,
    },
    {
      id: "product",
      title: "Product",
      type: "text",
      value: productName,
    },
    { id: "review", title: "Review", type: "text", value: review?.review },
    { id: "rating", title: "Rating", type: "text", value: review?.rating },
    { id: "type", title: "Type", type: "text", value: review?.type },
  ];

  async function deleteReview(id) {
    setLoading(true);
    try {
      const { data } = await ReviewServices.deleteReview(id);
      setLoading(false);
      setModalShow(false);
      navigate(`/reviews`);
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getReview();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>
              {t("Are you sure you want to delete")}
              {t("?")}
            </h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteReview(params?.reviewID)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate("/reviews");
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {review?.type === "product" ? (
                review?.product?.image ? (
                  <img
                    src={review?.product?.image[0]?.Location}
                    alt="Product Image"
                  />
                ) : (
                  <img
                    src="../../../assets/images/img.png"
                    alt="Product Image"
                  />
                )
              ) : review?.vendor?.image ? (
                <img src={review?.vendor?.image?.Location} alt="vendor Image" />
              ) : (
                <img src="../../../assets/images/img.png" alt="vendor Image" />
              )}
            </div>
            <div className="client-datails">
              <div className="edit">
                {reviewPermissions.canDelete && (
                  <button
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                )}
              </div>
              <div className="data">
                {reviewDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
