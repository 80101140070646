import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import OrderServices from '../../services/OrderServices';
import MainCard from '../../Components/MainCard/MainCard';
import MainTable from '../../Components/MainTable/MainTable';
import VendorServices from '../../services/VendorServices';
import './Order.scss'

export default function Order() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18next.language;

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalOrders, setTotalOrders] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [vendor, setVendor] = useState("");
  const [activeVendors, setActiveVendors] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllOrders() {
    try {
      const { data } = await OrderServices.listAllOrders(
        vendor === "Vendor" ? "" : vendor,
        status === "Status" ? "" : status,
        paymentMethod === "Payment Method" ? "" : paymentMethod,
        currentPage
      );
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && !record?.client?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && !record?.client?.name?.en) {
          clientName = "لا يوجد أسم"
        }

        let vendorName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && !record?.vendor?.name?.ar) {
          vendorName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && !record?.vendor?.name?.en) {
          vendorName = "لا يوجد أسم"
        }

        let branchName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.branch?.name?.en) {
          branchName = record?.branch?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.branch?.name?.en && record?.branch?.name?.ar) {
          branchName = record?.branch?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.branch?.name?.ar) {
          branchName = record?.branch?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.branch?.name?.ar && record?.branch?.name?.en) {
          branchName = record?.branch?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.branch?.name?.en && !record?.branch?.name?.ar) {
          branchName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.branch?.name?.ar && !record?.branch?.name?.en) {
          branchName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?._id,
          "Client name": (clientName)?.slice(0, 15),
          "Vendor name": (vendorName)?.slice(0, 15),
          "Branch name": (branchName)?.slice(0, 15),
          "Payment method": record?.paymentMethod,
          Status: record?.status,
        }
      });

      setOrders(order => {
        if ((vendor && currentPage === 1) || (status && currentPage === 1) || (paymentMethod && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...order, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalOrders(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllActiveVendors() {
    try {
      const { data } = await VendorServices.listAllVendors();
      setActiveVendors(data.records);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleOrderRowClick(id) {
    navigate(`/orders/${id}`);
  }

  useEffect(() => {
    getAllActiveVendors()
  }, [])

  useEffect(() => {
    getAllOrders();
  }, [vendor, status, paymentMethod, currentPage]);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Orders"}
            text2={"Orders"}
            total={totalOrders}
          />

          <div className="form-search">
            <div className="filter-by-container">
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setVendor(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Vendor">
                    {t("Vendor")}
                  </option>
                  {activeVendors.map((singleVendor) => {
                    return <option value={singleVendor?._id}>{singleVendor?.name?.[lang]}</option>
                  })}

                </select>
              </div>

              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setStatus(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Status">
                    {t("Status")}
                  </option>
                  <option value={"pending"}>{t("Pending")}</option>
                  <option value={"in progress"}>{t("In Progress")}</option>
                  <option value={"delivered"}>{t("Delivered")}</option>
                </select>
              </div>

              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setPaymentMethod(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Payment Method">
                    {t("Payment Method")}
                  </option>
                  <option value={"cash on delivery"}>{t("Cash on Delivery")}</option>
                  <option value={"visa"}>{t("Visa")}</option>
                  <option value={"branch"}>{t("Branch")}</option>
                </select>
              </div>

            </div>
          </div>

          {totalOrders === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handleOrderRowClick} tableRows={orders} />
          )}

          {
            orders.length !== totalOrders &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
