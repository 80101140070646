import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Barcode from "react-barcode";
import ClientServices from "../../../services/ClientServices";
import WishList from "./WishList/WishList";
import Cart from "./Cart/Cart";
import Reviews from "./ClientReviews/ClientReviews";
import PreviousOrders from "./PreviousOrders/PreviousOrders";
import Points from "./Points/Points";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import { useSelector } from "react-redux";
import i18n from "../../../locales/i18n";
import toastPopup from "../../../helpers/toastPopup";
import OrderRequests from "./OrderRequests/OrderRequests";
import "./ClientDetails.scss";

export default function ClientDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;
  let { client: clientPermissions } = frontPermissions;
  let { review: reviewPermissions } = frontPermissions;
  let { points: pointsPermissions } = frontPermissions;

  let { order: orderPermissions } = frontPermissions;
  let { wishlist: wishlistPermissions } = frontPermissions;
  let { cart: cartPermissions } = frontPermissions;

  let canEditClient = clientPermissions.canEdit;

  let viewPoints = pointsPermissions.canView;
  let viewWishlist = !pointsPermissions.canView && wishlistPermissions.canView;
  let viewCart =
    !pointsPermissions.canView &&
    !wishlistPermissions.canView &&
    cartPermissions.canView;

  let veiwReviews =
    !pointsPermissions.canView &&
    !wishlistPermissions.canView &&
    !cartPermissions.canView &&
    reviewPermissions.canView;

  let veiwOrders =
    !pointsPermissions.canView &&
    !wishlistPermissions.canView &&
    !cartPermissions.canView &&
    !reviewPermissions.canView &&
    orderPermissions.canView;

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18n.language

  const [client, setClient] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [points, setPoints] = useState(viewPoints ? true : false);
  const [wishList, setWishList] = useState(viewWishlist ? true : false);
  const [cart, setCart] = useState(viewCart ? true : false);

  const [reviews, setReviews] = useState(veiwReviews ? true : false);

  const [orders, setOrders] = useState(veiwOrders ? true : false);

  const [orderRequests, setOrderRequests] = useState(false);

  async function getClient() {
    setLoading(true);
    try {
      const { data } = await ClientServices.getClient(params.id);
      setLoading(false);
      setClient(data.record[0]);
      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let clientName = ""
  if (localStorage.getItem("i18nextLng") === "en" && client?.name?.en) {
    clientName = client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !client?.name?.en && client?.name?.ar) {
    clientName = client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && client?.name?.ar) {
    clientName = client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !client?.name?.ar && client?.name?.en) {
    clientName = client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !client?.name?.en && !client?.name?.ar) {
    clientName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !client?.name?.ar && !client?.name?.en) {
    clientName = "لا يوجد أسم"
  }

  let clientDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: clientName,
    },
    {
      id: "profession",
      title: "Profession",
      type: "text",
      value: client?.profession?.map((item) => item?.name?.[lang]),
    },
    {
      id: "email",
      title: "Email",
      type: "email",
      value: client?.email ? client?.email : t("No Email"),
    },
    {
      id: "age",
      title: "Age",
      type: "number",
      value: client?.age ? client?.age : t("No Age"),
    },
    {
      id: "dateOfBirth",
      title: "Date of birth",
      type: "text",
      value: formatDate(new Date(client?.dateOfBirth)),
    },
    {
      id: "gender",
      title: "Gender",
      type: "text",
      value: client?.gender ? client?.gender : t("No Gender"),
    },
    {
      id: "phone",
      title: "Phone",
      type: "text",
      value: client?.phone ? client?.phone : t("No Phone"),
    },
    {
      id: "country",
      title: "Country",
      type: "text",
      value: client?.country?.[lang]
    },
    {
      id: "city",
      title: "City",
      type: "text",
      value: client?.city?.[lang]
    },
    {
      id: "interests",
      title: "Interests",
      type: "text",
      value: client?.interests?.map((item) => item?.name?.[lang]),
    },
    {
      id: "join_date",
      title: "Join Date",
      type: "text",
      value: formatDate(new Date(client?.joinDate)),
    },
    {
      id: "credit",
      title: "Credit",
      type: "text",
      value: client?.credit,
    },
    {
      id: "subStartDate",
      title: "Subscription start date",
      type: "text",
      value: (client?.subStartDate) ? formatDate(new Date(client?.subStartDate)) : "Not Subscribed",
    },
    {
      id: "subEndDate",
      title: "Subscription end date",
      type: "text",
      value: (client?.subEndDate) ? formatDate(new Date(client?.subEndDate)) : "Not Subscribed",
    },
    {
      id: "referralCode",
      title: "Referral code",
      type: "text",
      value: client?.referralCode,
    },
    {
      id: "barcode",
      title: "Barcode",
      type: "text",
      value: client?.barcode,
    },
  ];

  async function deleteClientImage() {
    setLoading(true);
    try {
      const { data } = await ClientServices.deleteClientImage(params.id);
      setLoading(false);
      setModalShow(false);
      getClient()
      setImage(false)
      toastPopup.success(t("Image deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function togglePoints() {
    setPoints(true);
    setWishList(false);
    setCart(false);
    setReviews(false);
    setOrders(false);
    setOrderRequests(false);
    if (params?.orderID) {
      navigate(`/clients/${params.id}`);
    }
  }

  function toggleWishlist() {
    setPoints(false);
    setWishList(true);
    setCart(false);
    setReviews(false);
    setOrders(false);
    setOrderRequests(false);
    if (params?.orderID) {
      navigate(`/clients/${params.id}`);
    }
  }

  function toggleCart() {
    setPoints(false);
    setWishList(false);
    setCart(true);
    setReviews(false);
    setOrders(false);
    setOrderRequests(false);
    if (params?.orderID) {
      navigate(`/clients/${params.id}`);
    }
  }

  function toggleReviews() {
    setPoints(false);
    setWishList(false);
    setCart(false);
    setReviews(true);
    setOrders(false);
    setOrderRequests(false);
    if (params?.orderID) {
      navigate(`/clients/${params.id}`);
    }
  }

  function toggleOrders() {
    setPoints(false);
    setWishList(false);
    setCart(false);
    setReviews(false);
    setOrders(true);
    setOrderRequests(false);
    if (params?.orderID) {
      navigate(`/clients/${params.id}`);
    }
  }

  function toggleOrderRequests() {
    setPoints(false);
    setWishList(false);
    setCart(false);
    setReviews(false);
    setOrders(false);
    setOrderRequests(true);
    if (params?.orderID) {
      navigate(`/clients/${params.id}`);
    }
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getClient();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete this image?")}</h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteClientImage()}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate("/clients");
                }}
              ></i>
            </div>
            <div className="client-image admin-image-details">
              {image ? (
                <>
                  <img src={client.image.Location} alt="Admin Image" />
                  <div className="delete-image" onClick={() => { setModalShow(true); }}>
                    <i className="fa-solid fa-trash"></i>
                  </div>
                </>
              ) : (
                <img
                  src={
                    "https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/790027/grocery-store-clipart-md.png"
                  }
                  alt="Admin Image"
                />
              )}
            </div>
            <div className="client-datails">
              {canEditClient && (
                <div className="edit">
                  <button
                    onClick={() => navigate(`/clients/${client._id}/edit`)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                </div>
              )}
              <div className="data">
                {clientDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}

                <Barcode
                  value={client.barcode}
                  height={30}
                  width={1}
                  displayValue={false}
                  marginLeft={180}
                />

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Active")}
                  </label>
                  <label className="switch form-group">
                    <input
                      checked={client?.isActive}
                      type="checkbox"
                      readOnly
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Subscribed")}
                  </label>
                  <label className="switch form-group">
                    <input
                      checked={client?.isSubscribed}
                      type="checkbox"
                      readOnly
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Used free Trial")}
                  </label>
                  <label className="switch form-group">
                    <input
                      checked={client?.usedFreeTrial}
                      type="checkbox"
                      readOnly
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            {(orderPermissions.canView ||
              wishlistPermissions.canView ||
              cartPermissions.canView ||
              pointsPermissions.canView ||
              reviewPermissions.canView) && (
                <div className="user">
                  {points && pointsPermissions.canView ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          togglePoints();
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Points")}</h2>
                      </button>
                    </div>
                  ) : (
                    pointsPermissions.canView && (
                      <div className="all-branches">
                        <button
                          onClick={() => {
                            togglePoints();
                          }}
                          className="all-branches-btn"
                        >
                          <h2>{t("Points")}</h2>
                        </button>
                      </div>
                    )
                  )}
                  {wishList && wishlistPermissions.canView ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          toggleWishlist();
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Wishlist")}</h2>
                      </button>
                    </div>
                  ) : (
                    wishlistPermissions.canView && (
                      <div className="all-branches">
                        <button
                          onClick={() => {
                            toggleWishlist();
                          }}
                          className="all-branches-btn"
                        >
                          <h2>{t("Wishlist")}</h2>
                        </button>
                      </div>
                    )
                  )}
                  {cart && cartPermissions.canView ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          toggleCart();
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Cart")}</h2>
                      </button>
                    </div>
                  ) : (
                    cartPermissions.canView && (
                      <div className="all-branches">
                        <button
                          onClick={() => {
                            toggleCart();
                          }}
                          className="all-branches-btn"
                        >
                          <h2>{t("Cart")}</h2>
                        </button>
                      </div>
                    )
                  )}
                  {reviews && reviewPermissions.canView ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          toggleReviews();
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Reviews")}</h2>
                      </button>
                    </div>
                  ) : (
                    reviewPermissions.canView && (
                      <div className="all-branches">
                        <button
                          onClick={() => {
                            toggleReviews();
                          }}
                          className="all-branches-btn"
                        >
                          <h2>{t("Reviews")}</h2>
                        </button>
                      </div>
                    )
                  )}
                  {orders && orderPermissions.canView ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          toggleOrders();
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Orders")}</h2>
                      </button>
                    </div>
                  ) : (
                    orderPermissions.canView && (
                      <div className="all-branches">
                        <button
                          onClick={() => {
                            toggleOrders();
                          }}
                          className="all-branches-btn"
                        >
                          <h2>{t("Orders")}</h2>
                        </button>
                      </div>
                    )
                  )}
                  {orderRequests ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          toggleOrderRequests();
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Order Requests")}</h2>
                      </button>
                    </div>
                  ) : (
                    <div className="all-branches">
                      <button
                        onClick={() => {
                          toggleOrderRequests();
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Order Requests")}</h2>
                      </button>
                    </div>
                  )
                  }
                </div>
              )}
            {points && pointsPermissions.canView ? (
              <div className="wishlist-cards">
                <Points />
              </div>
            ) : (
              ""
            )}

            {wishList && wishlistPermissions.canView ? (
              <div className="wishlist-cards">
                <WishList />
              </div>
            ) : (
              ""
            )}
            {cart && cartPermissions.canView ? (
              <div className="wishlist-cards">
                <Cart />
              </div>
            ) : (
              ""
            )}
            {reviews && reviewPermissions.canView ? (
              <div className="wishlist-cards">
                <Reviews />
              </div>
            ) : (
              ""
            )}
            {orders && orderPermissions.canView ? (
              <div className="wishlist-cards">
                <PreviousOrders />
              </div>
            ) : (
              ""
            )}
            {orderRequests ? (
              <div className="wishlist-cards">
                <OrderRequests />
              </div>
            ) : (
              ""
            )}
          </div>
        )
      )}
    </>
  );
}
