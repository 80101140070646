import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toastPopup from "../../../../../../helpers/toastPopup";
import VendorServices from "../../../../../../services/VendorServices";
import MainInput from "../../../../../MainInput";
import "./CashierEdit.scss";

export default function CashierEdit() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { cashier: cashierPermissions } = frontPermissions;

  const navigate = useNavigate();
  let params = useParams();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isActiveVendor, setIsActiveVendor] = useState(true);
  const [isActiveBranch, setIsActiveBranch] = useState(true);

  const [cashier, setCashier] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    phone: "",
    isActive: false,
  });

  const [newCashier, setNewCashier] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    phone: "",
    isActive: false,
  });

  const [newPassword, setNewPassword] = useState({
    email: "",
    new_password: "",
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "email", type: "email", required: false },
    { name: "phone", type: "text", required: false },
  ];

  let newPasswordFormData = [
    { name: "email", type: "email", required: false, disabled: true },
    { name: "new_password", type: "password", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getCashier() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getCashier(params.cashierID);
      setLoading(false);
      setCashier({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        isActive: data?.record[0]?.isActive,
      });

      setNewCashier({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        isActive: data?.record[0]?.isActive,
      });

      setNewPassword({
        email: data?.record[0]?.email,
        new_password: "",
      });

      setUploadImage(data?.record[0]?.image?.Location);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editCashier(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    function isEmpty(value) {
      return (
        typeof value === 'string' && value.trim() === '' ||
        value === undefined ||
        value === null
      );
    }

    Object.keys(checkUpdatedFields(newCashier, cashier)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newCashier["name_en"], ar: newCashier["name_ar"] },
        };
        if (isEmpty(editedData.name.en)) {
          delete editedData.name.en;
        }

        if (isEmpty(editedData.name.ar)) {
          delete editedData.name.ar;
        }
      } else {
        editedData = {
          ...editedData,
          [key]: newCashier[key],
        };
      }
    });

    try {
      const { data } = await VendorServices.editCashier(
        params.cashierID,
        editedData
      );
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } =
          typeof uploadImage === "object" &&
          (await VendorServices.uploadCashierImage(params.cashierID, formData));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(
        `/vendors/${params.id}/branchdetails/${params.branchID}/${params.cashierID}`
      );
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function changePasswordHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        email: newPassword.email,
        newPassword: newPassword.new_password,
      };
      const { data } = await VendorServices.changePasswordCashier(obj);
      setLoading(false);
      toastPopup.success(t("Password changed successfully"));
      navigate(
        `/vendors/${params.id}/branchdetails/${params.branchID}/${params.cashierID}`
      );
    } catch (e) {
      setLoading(false);
      setPasswordErrorMessage(e.response.data.error);
    }
  }

  async function getVendor() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendor(params.id);
      setLoading(false);
      setIsActiveVendor(data?.record[0]?.isActive)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getBranch() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getBranch(
        params.id,
        params.branchID
      );
      setLoading(false);
      setIsActiveBranch(data?.record[0]?.isActive)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  useEffect(() => {
    getCashier();
    getVendor()
    getBranch()
  }, []);

  return (
    <>
      <div className="add-client">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(
                `/vendors/${params.id}/branchdetails/${params.branchID}/${params.cashierID}`
              );
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Cashier Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>
        <div className="main-image-label vendor-image">
          {uploadImage && (
            <img
              src={
                typeof uploadImage === "object"
                  ? URL.createObjectURL(uploadImage)
                  : uploadImage
                    ? uploadImage
                    : "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
              }
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadImage ? URL.createObjectURL(uploadImage) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          <label
            className="main-label-image"
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            {t("Add Image")}
          </label>
        </div>
        <form className="form-grid" onSubmit={editCashier}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newCashier}
                  setState={setNewCashier}
                />
              );
            })}
            {
              isActiveBranch && isActiveVendor && <div className="toggler">
                <label htmlFor="productImage" className="word">
                  {t("Is Active")}
                </label>
                <label className="switch form-group">
                  <input
                    checked={newCashier.isActive}
                    onChange={(e) => {
                      setNewCashier((prev) => {
                        return { ...prev, isActive: e.target.checked };
                      });
                    }}
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            }
          </div>
          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>

      {cashierPermissions.canChangePassword && (
        <div className="change-password">
          <div className="header">
            <h3>{t("Change Password")}</h3>
            {passwordErrorMessage ? (
              <div className="err">{t(passwordErrorMessage)}</div>
            ) : (
              ""
            )}
          </div>

          <form className="form-grid" onSubmit={changePasswordHandler}>
            <div className="input-grid">
              {newPasswordFormData.map((formInput, index) => {
                return (
                  <MainInput
                    className="input-edit-admin"
                    key={index}
                    name={formInput.name}
                    type={formInput.type}
                    required={formInput.required}
                    disabled={formInput.disabled}
                    state={newPassword}
                    setState={setNewPassword}
                  />
                );
              })}
            </div>
            <div className="button-grid">
              <button className="main-button-admin" disabled={loading}>
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  t("Submit")
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
