import React, { useEffect, useState } from 'react'
import { ReactComponent as NavbarLogo } from "../../assets/VIP-ICON-SVG/NavbarLogo.svg"
import { useTranslation } from 'react-i18next'
import { switchLang } from '../../helpers/lang'
import i18n from '../../locales/i18n'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import AdminServices from '../../services/AdminServices'
import { socket } from '../../services/socket'
import './Navbar.scss'
import toastPopup from '../../helpers/toastPopup'

export default function Navbar() {

  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [notification, setNotification] = useState([])
  const [isClicked, setIsClicked] = useState(false)
  const [numOfNotification, setNumOfNotification] = useState(0)

  let URL = window.location.href

  function changeLang(lang) {
    i18n.changeLanguage(lang)
    switchLang(lang)
  }
  const lang = i18n.language

  let decodedToken = jwtDecode(localStorage.getItem('AdminToken'))

  async function emitAdmin() {
    const { data } = await AdminServices.getChatAdmin()
    const adminID = data?.record[0]?._id
    socket.emit(
      "admin",
      {
        _id: adminID,
      },
      (res) => {
        console.log("res", res);
      }
    );
  }

  async function listNotification() {
    const { data } = await AdminServices.getChatAdmin()
    const adminID = data?.record[0]?._id

    socket.emit(
      "listNotifications",
      {
        _id: adminID,
      },
      (res) => {
        console.log(res);
      }
    );

    socket.on('listNotifications', (data) => {
      setNotification(data?.records);
      setNumOfNotification(data.counts);
    })
  }

  async function redirectTo(notification) {
    if (notification?.type === 'message') {
      navigate(`/messages/${notification?.sender?._id}`, {
        state: notification?.sender?.type,
      });

      setIsClicked(false);

      const { data } = await AdminServices.getChatAdmin()
      const adminID = data?.record[0]?._id

      socket.emit(
        "markAsRead",
        {
          roomId: notification?.redirectId,
          _id: adminID,
        },
        (res) => {
          console.log(res);
        }
      );
    } else if (notification?.type === "credit request") {
      navigate(`/agents/credit-requests/pending/${notification?.redirectId}`);

      setIsClicked(false);

      const { data } = await AdminServices.getChatAdmin()
      const adminID = data?.record[0]?._id

      socket.emit(
        "markAsRead",
        {
          roomId: notification?.redirectId,
          _id: adminID,
        },
        (res) => {
          console.log(res);
        }
      );
    }
  }

  useEffect(() => {
    socket.on("connection", () => {
      console.log("Connected to server");
    });

    socket.connect();
    emitAdmin();
    listNotification();

    socket.on('sendMessageToRoom', (data) => {
      if (!URL.includes("messages")) {
        setNumOfNotification((prev) => prev + 1)
        toastPopup.success(t("You have new message"));
      }
    })

    socket.on('newCreditRequest', (data) => {
      setNumOfNotification((prev) => prev + 1)
      toastPopup.success(t("You have new credit request"));
    })

  }, [URL]);

  return <>
    <nav className='nav'>
      <NavbarLogo className='logo' onClick={() => { navigate('/admins') }} />
      <div className='user-data'>{t("Welcome")} <span onClick={() => { navigate(`admins/${decodedToken?._id}`) }}>{decodedToken?.name?.[lang]}</span></div>
      {
        localStorage.getItem("i18nextLng") === "en" ? <button onClick={() => changeLang("ar")}>العربية</button> : <button onClick={() => changeLang("en")}>English</button>
      }
      <div className="bell" onClick={() => { setIsClicked((prev) => !prev); listNotification(); }}>
        <i className="fa-regular fa-bell"></i>
        <div className='dot'>{numOfNotification}</div>
      </div>
    </nav>
    {isClicked &&
      <div className="list">
        {notification?.map((item) => {
          return (
            <>
              <div key={item?._id} className="item" onClick={() => { redirectTo(item) }}>
                <div className="image">
                  {item?.image?.Location ?
                    <img src={item?.image?.Location} alt="Image" /> :
                    <img src="https://st3.depositphotos.com/17828278/33150/v/450/depositphotos_331503262-stock-illustration-no-image-vector-symbol-missing.jpg" alt="Image" />}
                </div>
                <div className="data">
                  <p>{item?.text}</p>
                </div>
                <div className="datetime">
                  <p>{new Date(item?.timestamp).toLocaleDateString()} - {new Date(item?.timestamp).toLocaleTimeString()}</p>
                </div>
              </div>
              <div key={item?._id} className="line"></div>
            </>
          )
        })}
      </div>}

  </>

}
