import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../../locales/i18n";
import NotificationServices from "../../../services/NotificationServices";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import ClientServices from "../../../services/ClientServices";
import "./NotificationDetails.scss";
import { useSelector } from "react-redux";

export default function NotificationDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { notification: notificationPermissions } = frontPermissions;

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [notification, setNotification] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");

  async function getNotification() {
    setLoading(true);
    try {
      const { data } = await NotificationServices.getNotification(
        params?.notification_id
      );
      setLoading(false);
      setNotification(data.record[0]);
      setActive(true);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let notificationDetails = [
    { id: "link", title: "Link", type: "text", value: notification?.link },
    {
      id: "gender",
      title: "Gender",
      type: "text",
      value: notification?.gender,
    },
    {
      id: "country",
      title: "Country",
      type: "text",
      value: notification?.country?.[lang],
    },
    {
      id: "city", title: "City", type: "text", value: notification?.city?.map((singliCity) => {
        return singliCity?.[lang] + " "
      })
    },
    {
      id: "ageFrom",
      title: "Age from",
      type: "text",
      value: notification?.age?.from,
    },
    {
      id: "ageTo",
      title: "Age to",
      type: "text",
      value: notification?.age?.to,
    },
    {
      id: "startDate",
      title: "Start Date",
      type: "text",
      value: formatDate(new Date(notification?.startDate)),
    },
    {
      id: "endDate",
      title: "End Date",
      type: "text",
      value: formatDate(new Date(notification?.endDate)),
    },
    {
      id: "seenBy",
      title: "Seen By",
      type: "text",
      value: notification?.seenBy?.length,
    },
    {
      id: "clicks",
      title: "Clicks",
      type: "text",
      value: notification?.clicks,
    },
    {
      id: "reach",
      title: "Reach",
      type: "text",
      value: notification?.reach,
    },
  ];

  async function countHandler() {
    setLoading(true);
    try {
      const { data } = await ClientServices.countClients(
        notification?.city?.[lang],
        notification?.gender === "both" ? "" : notification?.gender,
        notification?.age?.from,
        notification?.age?.to
      );
      setLoading(false);
      setCount(data.count);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        ""
      )}
      {active && (
        <div className="main-details">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/notifications");
              }}
            ></i>
          </div>
          <div className="client-image vendor-image-details">
            <img src={notification?.image?.Location} alt="Banner Image" />
          </div>
          <div className="client-datails">
            <div className="edit">
              {notificationPermissions.canEdit && (
                <button
                  onClick={() =>
                    navigate(`/notifications/${notification?._id}/edit`)
                  }
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              )}
            </div>

            <div className="data">
              <div className="input-label-disabled">
                <label className="disabled-label" htmlFor="notificationText">
                  {t("Text")}
                </label>
                <textarea
                  className="disabled-input"
                  id="notificationText"
                  cols="30"
                  rows="10"
                  value={notification?.text}
                  disabled
                ></textarea>
              </div>

              {notificationDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}

              <div className="count">
                <button onClick={countHandler}>
                  Count{" "}
                  {loading ? <i className="fas fa-spinner fa-spin"></i> : count}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
