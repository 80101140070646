import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import VendorServices from '../../../services/VendorServices';
import InfoInput from '../../../Components/InfoInput/InfoInput';
import i18next from 'i18next';
import './SortProductDetails.scss'

export default function SortProductDetails() {

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18next.language;

  const [product, setProduct] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);

  async function getSortProduct() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getProduct(params.id);
      setLoading(false);
      setProduct(data.record[0]);
      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let productDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: product?.name?.[lang],
    },
    {
      id: "description",
      title: "Description",
      type: "text",
      value: product?.description?.[lang],
    },
    {
      id: "vendor_name",
      title: "Vendor Name",
      type: "text",
      value: product?.vendor?.name?.[lang],
    },
    {
      id: "vendor_rank",
      title: "Vendor Rank",
      type: "number",
      value: product?.vendorRank,
    },
    {
      id: "category",
      title: "Category",
      type: "text",
      value: product?.category?.name?.[lang],
    },
    {
      id: "sale_price",
      title: "Sale Price",
      type: "number",
      value: product?.price
    },
    {
      id: "originalPrice",
      title: "Full Price",
      type: "number",
      value: product?.originalPrice
    },
    {
      id: "rate",
      title: "Rate",
      type: "text",
      value: product?.rate,
    },
    {
      id: "numberOFReviews",
      title: "Number of Reviews",
      type: "number",
      value: product?.numOfReviews,
    },
    {
      id: "rank",
      title: "Rank",
      type: "number",
      value: product?.rank,
    },
  ];

  useEffect(() => {
    getSortProduct();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate(`/sort-products`);
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {image ? (
                <img src={product?.image?.[0]?.Location} alt="Product Image" />
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Product Image"
                />
              )}
            </div>
            <div className="client-datails">
              <div className="data">
                {productDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Active")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isActive} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Hot Deal")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isHotDeal} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Limited")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isLimited} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Editable")}
                  </label>
                  <label className="switch form-group">
                    <input checked={product?.isEditable} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
