import React, { useState } from "react";
import { useSelector } from "react-redux";
import PendingRequest from "./PendingRequest/PendingRequest";
import AcceptedRequest from "./AcceptedRequest/AcceptedRequest";
import RejectedRequest from "./RejectedRequest/RejectedRequest";
import { useTranslation } from "react-i18next";
import "./Request.scss";

export default function Request() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { join: joinPermissions } = frontPermissions;

  const { t } = useTranslation();

  const [pendingRequests, setPendingRequests] = useState(true);
  const [acceptedRequests, setAcceptedRequests] = useState(false);
  const [rejectedRequests, setRejectedRequests] = useState(false);

  return (
    <>
      <div className="user">
        {pendingRequests ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingRequests(true);
                setAcceptedRequests(false);
                setRejectedRequests(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Pending Requests")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingRequests(true);
                setAcceptedRequests(false);
                setRejectedRequests(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Pending Requests")}</h2>
            </button>
          </div>
        )}
        {acceptedRequests ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingRequests(false);
                setAcceptedRequests(true);
                setRejectedRequests(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Accepted Requests")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingRequests(false);
                setAcceptedRequests(true);
                setRejectedRequests(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Accepted Requests")}</h2>
            </button>
          </div>
        )}
        {rejectedRequests ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingRequests(false);
                setAcceptedRequests(false);
                setRejectedRequests(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Rejected Requests")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingRequests(false);
                setAcceptedRequests(false);
                setRejectedRequests(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Rejected Requests")}</h2>
            </button>
          </div>
        )}
      </div>

      {pendingRequests ? (
        <div className="branch-cards-container">
          <PendingRequest />
        </div>
      ) : (
        ""
      )}

      {acceptedRequests ? (
        <div className="branch-cards-container">
          <AcceptedRequest />
        </div>
      ) : (
        ""
      )}

      {rejectedRequests ? (
        <div className="branch-cards-container">
          <RejectedRequest />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
