import Axios from "./Axios";

let BarcodeServices = {
  addPdf: async (obj) => {
    const response = await Axios.post(`/admin/barcode/pdf`, obj);
    return response;
  },

  addExcel: async (obj) => {
    const response = await Axios.post(`/admin/barcode/excel`, obj);
    return response;
  },

  getBarcode: async (id) => {
    const response = await Axios.get(`/admin/barcode/get?_id=${id}`);
    return response;
  },

  deleteBarcode: async (id) => {
    const response = await Axios.delete(`/admin/barcode/remove?_id=${id}`);
    return response;
  },

  listAllBarcodes: async (page, limit = 25) => {
    const response = await Axios.get(`/admin/barcode/list?${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default BarcodeServices;
