import Axios from "./Axios";

let CartServices = {
  getCart: async (id) => {
    const response = await Axios.get(`/admin/cart/get?client=${id}`);
    return response;
  },

  listAllCarts: async (page, limit = 25) => {
    const response = await Axios.get(`/admin/cart/list?${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllBranchCarts: async (id, page, limit = 25) => {
    const response = await Axios.get(`/admin/cart/list?branch=${id}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllVendorCarts: async (id, page, limit = 25) => {
    const response = await Axios.get(`/admin/cart/list?vendor=${id}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default CartServices;
