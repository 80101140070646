import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VendorServices from "../../../../services/VendorServices";
import toastPopup from "../../../../helpers/toastPopup";
import MainInput from "../../../MainInput";
import { useSelector } from "react-redux";
import COUNTRIES_CITIES from '../../../../locales/app-countries.json'
import i18n from "../../../../locales/i18n.js";
import "./BranchEdit.scss";

export default function BranchEdit() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { branch: branchPermissions } = frontPermissions;

  let params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [uploadCover, setUploadCover] = useState("");
  const [isActiveVendor, setIsActiveVendor] = useState(true);

  const [branch, setBranch] = useState({
    name_en: "",
    name_ar: "",
    branchManager: "",
    managerPhone: "",
    email: "",
    phone: "",
    location_lat: "",
    location_long: "",
    address_en: "",
    address_ar: "",
    link: "",
    hasDelivery: false,
    isActive: false,
    country_index: 0,
    city_index: 0,
  });

  const [newBranch, setNewBranch] = useState({
    name_en: "",
    name_ar: "",
    branchManager: "",
    managerPhone: "",
    email: "",
    phone: "",
    location_lat: "",
    location_long: "",
    address_en: "",
    address_ar: "",
    link: "",
    hasDelivery: false,
    isActive: false,
    country_index: 0,
    city_index: 0,
  });

  const [newPassword, setNewPassword] = useState({
    email: "",
    new_password: "",
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "branchManager", type: "text", required: false },
    { name: "managerPhone", type: "text", required: false },
    { name: "email", type: "email", required: false },
    { name: "phone", type: "text", required: false },
    { name: "location_long", type: "text", required: false },
    { name: "location_lat", type: "text", required: false },
    { name: "address_en", type: "text", required: false },
    { name: "address_ar", type: "text", required: false },
    { name: "link", type: "text", required: false },
  ];

  let newPasswordFormData = [
    { name: "email", type: "email", required: false, disabled: true },
    { name: "new_password", type: "password", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getBranch() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getBranch(
        params.id,
        params.branchID
      );
      setLoading(false);
      setBranch({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        branchManager: data?.record[0]?.branchManager,
        managerPhone: data?.record[0]?.managerPhone,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        location_long: data?.record[0]?.location?.coordinates?.[0],
        location_lat: data?.record[0]?.location?.coordinates?.[1],
        address_en: data?.record[0]?.address?.en,
        address_ar: data?.record[0]?.address?.ar,
        link: data?.record[0]?.link,
        isActive: data?.record[0]?.isActive,
        hasDelivery: data?.record[0]?.hasDelivery,
        country_index: data?.record[0]?.country?.index,
        city_index: data?.record[0]?.city?.index,
      });

      setNewBranch({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        branchManager: data?.record[0]?.branchManager,
        managerPhone: data?.record[0]?.managerPhone,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        location_long: data?.record[0]?.location?.coordinates?.[0],
        location_lat: data?.record[0]?.location?.coordinates?.[1],
        address_en: data?.record[0]?.address?.en,
        address_ar: data?.record[0]?.address?.ar,
        link: data?.record[0]?.link,
        isActive: data?.record[0]?.isActive,
        hasDelivery: data?.record[0]?.hasDelivery,
        country_index: data?.record[0]?.country?.index,
        city_index: data?.record[0]?.city?.index,
      });

      setNewPassword({
        email: data?.record[0]?.email,
        new_password: "",
      });

      setUploadImage(data?.record[0]?.image?.Location);
      setUploadCover(data?.record[0]?.cover?.Location);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editBranch(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newBranch, branch)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newBranch["name_en"], ar: newBranch["name_ar"] },
        };
      } else if (key === "location_long" || key === "location_lat") {
        editedData = {
          ...editedData,
          location: { long: parseFloat(newBranch["location_long"]), lat: parseFloat(newBranch["location_lat"]) },
        };
      } else if (key === "address_en" || key === "address_ar") {
        editedData = {
          ...editedData,
          address: { en: newBranch["address_en"], ar: newBranch["address_ar"] },
        };
      } else if (key === "country_index") {
        editedData = {
          ...editedData,
          country: {
            en: selectedCountry?.en,
            ar: selectedCountry?.ar,
            index: newBranch?.country_index,
          },
        };
      } else if (key === "city_index") {
        editedData = {
          ...editedData,
          city: {
            en: selectedCity?.en,
            ar: selectedCity?.ar,
            index: newBranch?.city_index,
          },
        };
      } else {
        editedData = {
          ...editedData,
          [key]: newBranch[key],
        };
      }
    });

    try {
      const { data } = await VendorServices.editBranch(
        params.branchID,
        editedData
      );
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } =
          typeof uploadImage === "object" &&
          (await VendorServices.uploadBranchImage(params.branchID, formData));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      var coverFormData = new FormData();
      coverFormData.append("cover", uploadCover);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadBranchCover(params.branchID, coverFormData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/vendors/${params.id}/branchdetails/${params.branchID}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function changePasswordHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        email: newPassword.email,
        newPassword: newPassword.new_password,
      };
      const { data } = await VendorServices.changePasswordBranch(obj);
      setLoading(false);
      toastPopup.success(t("Password changed successfully"));
      navigate(`/vendors/${params.id}/branchdetails/${params.branchID}`);
    } catch (e) {
      setLoading(false);
      setPasswordErrorMessage(e.response.data.error);
    }
  }

  async function getVendor() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendor(params.id);
      setLoading(false);
      setIsActiveVendor(data?.record[0]?.isActive);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  const ref = useRef();
  const coverRef = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  const coverUploader = (e) => {
    coverRef.current.click();
  };

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(newBranch?.country_index))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(newBranch?.city_index))[0]

  useEffect(() => {
    getVendor();
    getBranch();
  }, []);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(
                `/vendors/${params.id}/branchdetails/${params.branchID}`
              );
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Branch Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>
        <div className="main-image-label vendor-cover">
          {uploadCover && (
            <img
              src={typeof uploadCover === "object" ? URL.createObjectURL(uploadCover) : uploadCover ? uploadCover : null}
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadCover ? URL.createObjectURL(uploadCover) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={coverRef}
            onChange={(e) => {
              setUploadCover(e.target.files[0]);
            }}
          />
          <label
            className="main-label-image"
            onClick={coverUploader}
            htmlFor="upload-img"
          >
            {t("Add Cover")}
          </label>
        </div>

        <div className="main-image-label vendor-image">
          {uploadImage && (
            <img
              src={typeof uploadImage === "object" ? URL.createObjectURL(uploadImage) : uploadImage ? uploadImage : null}
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadImage ? URL.createObjectURL(uploadImage) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          <label
            className={uploadImage ? "main-label-image-uploaded" : "main-label-image"}
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            {t("Add Image")}
          </label>
        </div>
        <form className="form-grid" onSubmit={editBranch}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newBranch}
                  setState={setNewBranch}
                />
              );
            })}

            <div className="main-input-select">
              <select
                selected={newBranch.country_index}
                value={newBranch.country_index}
                onChange={(e) => {
                  setNewBranch((prev) => {
                    return { ...prev, country_index: e.target.value };
                  });
                }}
                className='main-input'
                id="country"
                name="country"
                title='country'>
                <option value=''>{t("Country")}</option>
                {
                  COUNTRIES_CITIES?.map((country, index) => {
                    return <option key={index} value={country?.index}>{country?.[lang]}</option>
                  })
                }
              </select>
              <label className="main-label">{t("Country")}</label>
            </div>

            <div className="main-input-select">
              <select
                selected={newBranch?.city_index}
                value={newBranch?.city_index}
                onChange={(e) => {
                  setNewBranch((prev) => {
                    return { ...prev, city_index: e.target.value };
                  });
                }}
                className='main-input'
                id="city"
                name="city"
                title='city'>
                <option value=''>{t("City")}</option>
                {
                  selectedCountry?.cities?.map((state, index) => {
                    return <option key={index} value={state?.index}>{state?.[lang]}</option>
                  })
                }
              </select>
              <label className="main-label">{t("City")}</label>
            </div>

            {isActiveVendor && <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newBranch.isActive}
                  onChange={(e) => {
                    setNewBranch((prev) => {
                      return { ...prev, isActive: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>}

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Has Delivery")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newBranch.hasDelivery}
                  onChange={(e) => {
                    setNewBranch((prev) => {
                      return { ...prev, hasDelivery: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>
      {branchPermissions.canChangePassword && (
        <div className="change-password">
          <div className="header">
            <h3>{t("Change Password")}</h3>
            {passwordErrorMessage ? (
              <div className="err">{t(passwordErrorMessage)}</div>
            ) : (
              ""
            )}
          </div>

          <form className="form-grid" onSubmit={changePasswordHandler}>
            <div className="input-grid">
              {newPasswordFormData.map((formInput, index) => {
                return (
                  <MainInput
                    className="input-edit-admin"
                    key={index}
                    name={formInput.name}
                    type={formInput.type}
                    required={formInput.required}
                    disabled={formInput.disabled}
                    state={newPassword}
                    setState={setNewPassword}
                  />
                );
              })}
            </div>
            <div className="button-grid">
              <button className="main-button-admin" disabled={loading}>
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  t("Submit")
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
