import Axios from "./Axios";

let CategoryServices = {
  addCategory: async (obj) => {
    const response = await Axios.post(`/admin/category/create`, obj);
    return response;
  },

  getCategory: async (id) => {
    const response = await Axios.get(`/admin/category/get?_id=${id}`);
    return response;
  },

  uploadCategoryImage: async (id, obj) => {
    const response = await Axios.post(`/admin/category/image?_id=${id}`, obj);
    return response;
  },

  deleteCategoryImage: async (id) => {
    const response = await Axios.delete(`/admin/category/image?_id=${id}`);
    return response;
  },

  editCategory: async (id, obj) => {
    const response = await Axios.put(`/admin/category/update?_id=${id}`, obj);
    return response;
  },

  deleteCategory: async (id) => {
    const response = await Axios.delete(`/admin/category/remove?_id=${id}`);
    return response;
  },

  listActiveCategories: async (lang = "", search = "", type = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/category/list?${search.length > 0 ? `name.${lang}=${search}` : ""
      }${type.length > 0 ? `&type=${type}` : ""}&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listInActiveCategories: async (lang = "", search = "", type = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/category/list?${search.length > 0 ? `name.${lang}=${search}` : ""
      }${type.length > 0 ? `&type=${type}` : ""}&isActive=false&isRequested=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listRequestedCategories: async (lang = "", search = "", type = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/category/list?${search.length > 0 ? `name.${lang}=${search}` : ""
      }${type.length > 0 ? `&type=${type}` : ""}&isActive=false&isRequested=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listAllCategories: async (type) => {
    const response = await Axios.get(
      `/admin/category/list?${type.length > 0 ? `&type=${type}` : ""}&isActive=true`
    );
    return response;
  },

  listCategoriesByType: async (type) => {
    const response = await Axios.get(`/admin/category/list?type=${type}`);
    return response;
  },

  listProductCategories: async () => {
    const response = await Axios.get(`/admin/category/list?type=product`);
    return response;
  },

  sortCategories: async (obj) => {
    const response = await Axios.post(`/admin/category/sort`, obj);
    return response;
  },
};

export default CategoryServices;
