import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import VendorServices from "../../../../../services/VendorServices";
import InfoInput from "../../../../InfoInput/InfoInput";
import toastPopup from "../../../../../helpers/toastPopup";
import "./CashierDetails.scss";

export default function CashierDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { cashier: cashierPermissions } = frontPermissions;

  let {
    canCreate: canCreateCashier,
    canView: canViewCashier,
    canEdit: canEditCashier,
    canDelete: canDeleteCashier,
  } = cashierPermissions;

  const navigate = useNavigate();
  let params = useParams();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [image, setImage] = useState(false);
  const [cashier, setCashier] = useState({});
  const [modalShow, setModalShow] = useState(false);

  async function getCashier() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getCashier(params.cashierID);
      setLoading(false);
      setCashier(data.record[0]);
      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let cashierName = ""
  if (localStorage.getItem("i18nextLng") === "en" && cashier?.name?.en) {
    cashierName = cashier?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cashier?.name?.en && cashier?.name?.ar) {
    cashierName = cashier?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && cashier?.name?.ar) {
    cashierName = cashier?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cashier?.name?.ar && cashier?.name?.en) {
    cashierName = cashier?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cashier?.name?.en && !cashier?.name?.ar) {
    cashierName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cashier?.name?.ar && !cashier?.name?.en) {
    cashierName = "لا يوجد أسم"
  }

  let cashierDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: cashierName,
    },
    { id: "email", title: "Email", type: "email", value: cashier?.email },
    { id: "phone", title: "Phone", type: "text", value: cashier?.phone },
  ];

  async function deleteCashierImage() {
    setLoading(true);
    try {
      const { data } = await VendorServices.deleteCashierImage(params.cashierID);
      setLoading(false);
      setModalShow(false);
      getCashier()
      setImage(false)
      toastPopup.success(t("Image deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getCashier();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete this image?")}</h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteCashierImage()}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate(
                    `/vendors/${params.id}/branchdetails/${params.branchID}`
                  );
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {image ? (
                <>
                  <img src={cashier?.image?.Location} alt="Admin Image" />
                  <div className="delete-image" onClick={() => { setModalShow(true); }}>
                    <i className="fa-solid fa-trash"></i>
                  </div>
                </>
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Branch Image"
                />
              )}
            </div>
            <div className="client-datails">
              {canEditCashier && (
                <div className="edit">
                  <button
                    onClick={() =>
                      navigate(
                        `/vendors/${params.id}/branchdetails/${params.branchID}/${params.cashierID}/edit`
                      )
                    }
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                </div>
              )}
              <div className="data">
                {cashierDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}
                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Active")}
                  </label>
                  <label className="switch form-group">
                    <input
                      checked={cashier?.isActive}
                      type="checkbox"
                      readOnly
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
