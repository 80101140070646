import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SettingServices from '../../../services/SettingServices';
import toastPopup from '../../../helpers/toastPopup';
import MainInput from '../../../Components/MainInput';

export default function SettingEdit() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [setting, setSetting] = useState({
    referralCredit: 0,
    pointsToCash: 0,
    walletNumber: "",
    facebook: "",
    twitter: "",
    instagram: "",
    subscriptionAvailable: false
  });

  const [newSetting, setNewSetting] = useState({
    referralCredit: 0,
    pointsToCash: 0,
    walletNumber: "",
    facebook: "",
    twitter: "",
    instagram: "",
    subscriptionAvailable: false
  });

  let formData = [
    { name: "referralCredit", type: "number", required: false },
    { name: "pointsToCash", type: "number", required: false },
    { name: "walletNumber", type: "text", required: false },
    { name: "facebook", type: "text", required: false },
    { name: "twitter", type: "text", required: false },
    { name: "instagram", type: "text", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getSetting() {
    setLoading(true);
    try {
      const { data } = await SettingServices.getSettings();
      setLoading(false);
      setSetting({
        referralCredit: data?.record?.referralCredit,
        pointsToCash: data?.record?.pointsToCash,
        walletNumber: data?.record?.walletNumber,
        facebook: data?.record?.facebook,
        twitter: data?.record?.twitter,
        instagram: data?.record?.instagram,
        subscriptionAvailable: data?.record?.subscriptionAvailable
      });

      setNewSetting({
        referralCredit: data?.record?.referralCredit,
        pointsToCash: data?.record?.pointsToCash,
        walletNumber: data?.record?.walletNumber,
        facebook: data?.record?.facebook,
        twitter: data?.record?.twitter,
        instagram: data?.record?.instagram,
        subscriptionAvailable: data?.record?.subscriptionAvailable
      });
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editSetting(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newSetting, setting)).forEach((key) => {
      editedData = {
        ...editedData,
        [key]: newSetting[key],
      };
    });

    editedData = {
      settings: editedData
    }

    try {
      const { data } = await SettingServices.editSettings(editedData);
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      navigate(`/settings`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  useEffect(() => {
    getSetting()
  }, []);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/settings`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Settings")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <form className="form-grid" onSubmit={editSetting}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newSetting}
                  setState={setNewSetting}
                />
              );
            })}

            <div className="toggler-settings">
              <label htmlFor="productImage" className="word">
                {t("Subscription Available")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newSetting.subscriptionAvailable}
                  onChange={(e) => {
                    setNewSetting((prev) => {
                      return { ...prev, subscriptionAvailable: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
