import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Admin from "../../pages/Admin/Admin";
import AddAdmin from "../../pages/Admin/AddAdmin/AddAdmin";
import AdminDetails from "../../pages/Admin/AdminDetails/AdminDetails";
import AdminEdit from "../../pages/Admin/AdminDetails/AdminEdit/AdminEdit";
import Vendor from "../../pages/Vendor/Vendor";
import AddVendor from "../../pages/Vendor/AddVendor/AddVendor";
import VendorDetails from "../../pages/Vendor/VendorDetails/VendorDetails";
import VendorEdit from "../../pages/Vendor/VendorDetails/VendorEdit/VendorEdit";
import AddBranch from "../../pages/Vendor/VendorDetails/AddBranch/AddBranch";
import BranchDetails from "../../Components/BranchCard/BranchDetails/BranchDetails";
import BranchEdit from "../../Components/BranchCard/BranchDetails/BranchEdit/BranchEdit";
import AddCashier from "../../Components/BranchCard/BranchDetails/Cashier/AddCashier/AddCashier";
import CashierDetails from "../../Components/BranchCard/BranchDetails/Cashier/CashierDetails/CashierDetails";
import CashierEdit from "../../Components/BranchCard/BranchDetails/Cashier/CashierDetails/CashierEdit/CashierEdit";
import AddProduct from "../../Components/BranchCard/BranchProducts/AddProduct/AddProduct";
import AddProductToMultipleBranches from "../../Components/BranchCard/BranchProducts/AddProductToMultipleBranches/AddProductToMultipleBranches";
import ProductDetails from "../../Components/BranchCard/BranchProducts/ProductCard/ProductDetails/ProductDetails";
import ProductEdit from "../../Components/BranchCard/BranchProducts/ProductCard/ProductEdit/ProductEdit";
import Client from "../../pages/Client/Client";
import AddClient from "../../pages/Client/AddClient/AddClient";
import ClientDetails from "../../pages/Client/ClientDetails/ClientDetails";
import ClientEdit from "../../pages/Client/ClientDetails/ClientEdit/ClientEdit";
import Barcode from "../../pages/Barcode/Barcode";
import AddBarcode from "../../pages/Barcode/AddBarcode/AddBarcode";
import Subscription from "../../pages/Subscription/Subscription";
import PendingSubscriptionDetails from "../../pages/Subscription/PendingSubscription/PendingSubscriptionDetails/PendingSubscriptionDetails";
import AcceptedSubscriptionDetails from "../../pages/Subscription/AcceptedSubscriptions/AcceptedSubscriptionDetails/AcceptedSubscriptionDetails";
import RejectedSubscriptionDetails from "../../pages/Subscription/RejectedSubscriptions/RejectedSubscriptionDetails/RejectedSubscriptionDetails";
import Category from "../../pages/Category/Category";
import AddCategory from "../../pages/Category/AddCategory/AddCategory";
import CategoryDetails from "../../pages/Category/CategoryDetails/CategoryDetails";
import CategoryEdit from "../../pages/Category/CategoryDetails/CategoryEdit/CategoryEdit";
import Agent from "../../pages/Agent/Agent";
import AddAgent from "../../pages/Agent/AddAgent/AddAgent";
import AgentDetails from "../../pages/Agent/AgentDetails/AgentDetails";
import AgentEdit from "../../pages/Agent/AgentDetails/AgentEdit/AgentEdit";
import AddSubAgent from "../../pages/Agent/AgentDetails/SubAgents/AddSubAgent/AddSubAgent";
import Organization from "../../pages/Organization/Organization";
import AddOrganization from "../../pages/Organization/AddOrganization/AddOrganization";
import OrganizationDetails from "../../pages/Organization/OrganizationDetails/OrganizationDetails";
import OrganizationEdit from "../../pages/Organization/OrganizationDetails/OrganizationEdit/OrganizationEdit";
import Voucher from "../../pages/Voucher/Voucher";
import AddVoucher from "../../pages/Voucher/AddVoucher/AddVoucher";
import VoucherDetails from "../../pages/Voucher/VoucherDetails/VoucherDetails";
import VoucherEdit from "../../pages/Voucher/VoucherDetails/VoucherEdit/VoucherEdit";
import Request from "../../pages/Request/Request";
import RequestDetails from "../../pages/Request/RequestDetails/RequestDetails";
import Ads from "../../pages/Ads/Ads";
import PendingAdsDetails from "../../pages/Ads/PendingAds/PendingAdsDetails/PendingAdsDetails";
import RejectedAdsDetails from "../../pages/Ads/RejectedAds/RejectedAdsDetails/RejectedAdsDetails";
import AcceptedAdsDetails from "../../pages/Ads/AcceptedAds/AcceptedAdsDetails/AcceptedAdsDetails";
import SortVendor from "../../pages/SortVendor/SortVendor";
import SortVendorDetails from "../../pages/SortVendor/SortVendorDetails/SortVendorDetails";
import SortProduct from "../../pages/SortProduct/SortProduct";
import SortProductDetails from "../../pages/SortProduct/SortProductDetails/SortProductDetails";
import SortCategory from "../../pages/SortCategory/SortCategory";
import SortCategoryDetails from "../../pages/SortCategory/SortCategoryDetails/SortCategoryDetails";
import Banner from "../../pages/Banner/Banner";
import AddBanner from "../../pages/Banner/AddBanner/AddBanner";
import BannerDetails from "../../pages/Banner/BannerDetails/BannerDetails";
import BannerEdit from "../../pages/Banner/BannerDetails/BannerEdit/BannerEdit";
import PopUp from "../../pages/PopUp/PopUp";
import AddPopUp from "../../pages/PopUp/AddPopUp/AddPopUp";
import PopUpDetails from "../../pages/PopUp/PopUpDetails/PopUpDetails";
import PopUpEdit from "../../pages/PopUp/PopUpDetails/PopUpEdit/PopUpEdit";
import Reviews from "../../pages/Reviews/Reviews";
import ReviewDetails from "../../pages/Reviews/ReviewDetails/ReviewDetails";
import Wishlist from "../../pages/Wishlist/Wishlist";
import WishlistDetails from "../../pages/Wishlist/WishlistDetails/WishlistDetails";
import Cart from "../../pages/Cart/Cart";
import CartDetails from "../../pages/Cart/CartDetails/CartDetails";
import Order from "../../pages/Order/Order";
import OrderDetails from "../../pages/Order/OrderDetails/OrderDetails";
import OrderRequest from "../../pages/OrderRequest/OrderRequest";
import OrderRequestDetails from "../../pages/OrderRequest/OrderRequestDetails/OrderRequestDetails";
import Point from "../../pages/Point/Point";
import PointDetails from "../../pages/Point/PointDetails/PointDetails";
import PointEdit from "../../pages/Point/PointDetails/PointEdit/PointEdit";
import Message from "../../pages/Message/Message";
import Notification from "../../pages/Notification/Notification";
import AddNotification from "../../pages/Notification/AddNotification/AddNotification";
import NotificationDetails from "../../pages/Notification/NotificationDetails/NotificationDetails";
import NotificationEdit from "../../pages/Notification/NotificationDetails/NotificationEdit/NotificationEdit";
import Job from "../../pages/Job/Job";
import JobDetails from "../../pages/Job/Jobs/JobDetails/JobDetails";
import EmployeeDetails from "../../pages/Job/Employees/EmployeeDetails/EmployeeDetails";
import Service from "../../pages/Service/Service";
import ServiceDetails from "../../pages/Service/ServiceDetails/ServiceDetails";
import Profession from "../../pages/Profession/Profession";
import AddProfession from "../../pages/Profession/AddProfession/AddProfession";
import ProfessionEdit from "../../pages/Profession/ProfessionEdit/ProfessionEdit";
import Interest from "../../pages/Interest/Interest";
import AddInterest from "../../pages/Interest/AddInterest/AddInterest";
import InterestEdit from "../../pages/Interest/InterestEdit/InterestEdit";
import About from "../../pages/About/About";
import AddAbout from "../../pages/About/AddAbout/AddAbout";
import AboutDetails from "../../pages/About/AboutDetails/AboutDetails";
import AboutEdit from "../../pages/About/AboutDetails/AboutEdit/AboutEdit";
import FAQS from "../../pages/FAQS/FAQS";
import AddFAQS from "../../pages/FAQS/AddFAQS/AddFAQS";
import FAQSDetails from "../../pages/FAQS/FAQSDetails/FAQSDetails";
import FAQSEdit from "../../pages/FAQS/FAQSDetails/FAQSEdit/FAQSEdit";
import Setting from "../../pages/Setting/Setting";
import SettingEdit from "../../pages/Setting/SettingEdit/SettingEdit";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../services/socket";
import jwtDecode from "jwt-decode";
import { authActions } from "../../store/auth-slice";
import PendingCreditRequestDetails from "../../pages/Agent/CreditRequests/PendingCreditRequests/PendingCreditRequestDetails/PendingCreditRequestDetails";
import AcceptedCreditRequestDetails from "../../pages/Agent/CreditRequests/AcceptedCreditRequests/AcceptedCreditRequestDetails/AcceptedCreditRequestDetails";
import RejectedCreditRequestDetails from "../../pages/Agent/CreditRequests/RejectedCreditRequests/RejectedCreditRequestDetails/RejectedCreditRequestDetails";
import "./Routes.scss";

export default function Home() {
  let auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  let frontPermissions = auth.frontPermissions;

  let {
    admin,
    vendor,
    branch,
    cashier,
    product,
    client,
    order,
    orderRequest,
    category,
    org,
    agent,
    job,
    post,
    service,
    join,
    ad,
    banner,
    notification,
    coupon,
    wishlist,
    cart,
    points,
    page,
    review,
    interest,
    profession,
    barcode,
    subscription,
    chat,
    setting,
  } = frontPermissions;

  console.log("frontPermissions", frontPermissions);
  let decodedToken = jwtDecode(localStorage.getItem("AdminToken"));

  function joinSocket() {
    socket.emit(
      "admin",
      {
        _id: decodedToken._id,
      },
      (res) => {
        console.log("res", res);
      }
    );
  }

  useEffect(() => {
    socket.on("connection", () => {
      console.log("Connected to server");
    });

    socket.connect();

    joinSocket();

    socket.on("permissions", (data) => {
      dispatch(authActions.logout());
    });
  }, []);

  return (
    <>
      <div className="base-layout">
        <Navbar />
        <Sidebar />
        <div className="content-container">
          <Routes>
            {/* <Route path="/" element={<Navigate replace to="/admins" />} /> */}

            {admin.canView && <Route path="/admins" element={<Admin />} />}
            {admin.canCreate && (
              <Route path="/admins/addadmin" element={<AddAdmin />} />
            )}

            {admin.canEdit && (
              <Route path="/admins/:id/edit" element={<AdminEdit />} />
            )}

            {admin.canView && (
              <Route path="/admins/:id" element={<AdminDetails />} />
            )}

            {vendor.canView && <Route path="/vendors" element={<Vendor />} />}

            {vendor.canCreate && (
              <Route path="/vendors/addvendor" element={<AddVendor />} />
            )}

            {vendor.canCreate && (
              <Route
                path="/vendors/addvendor/:request_Id"
                element={<AddVendor />}
              />
            )}

            {vendor.canView && (
              <Route path="/vendors/:id" element={<VendorDetails />} />
            )}

            {vendor.canEdit && (
              <Route path="/vendors/:id/edit" element={<VendorEdit />} />
            )}

            {branch.canView && (
              <Route
                path="/vendors/:id/branchdetails/:branchID"
                element={<BranchDetails />}
              />
            )}

            {branch.canEdit && (
              <Route
                path="/vendors/:id/branchdetails/:branchID/edit"
                element={<BranchEdit />}
              />
            )}

            {branch.canCreate && (
              <Route path="/vendors/:id/addbranch" element={<AddBranch />} />
            )}

            {product.canCreate && (
              <Route
                path="/vendors/:id/addproducttomultiplebranches"
                element={<AddProductToMultipleBranches />}
              />
            )}

            {cashier.canCreate && (
              <Route
                path="/vendors/:id/branchdetails/:branchID/addcashier"
                element={<AddCashier />}
              />
            )}
            {cashier.canView && (
              <Route
                path="/vendors/:id/branchdetails/:branchID/:cashierID"
                element={<CashierDetails />}
              />
            )}

            {cashier.canEdit && (
              <Route
                path="/vendors/:id/branchdetails/:branchID/:cashierID/edit"
                element={<CashierEdit />}
              />
            )}

            {product.canCreate && (
              <Route
                path="vendors/:id/branchdetails/:branchID/addproduct"
                element={<AddProduct />}
              />
            )}

            {product.canEdit && (
              <Route
                path="/vendors/:id/branchdetails/:branchID/productdetails/:productID/edit"
                element={<ProductEdit />}
              />
            )}

            {product.canView && (
              <Route
                path="/vendors/:id/branchdetails/:branchID/productdetails/:productID"
                element={<ProductDetails />}
              />
            )}

            {client.canView && <Route path="/clients" element={<Client />} />}

            {client.canCreate && (
              <Route path="/clients/addclient" element={<AddClient />} />
            )}

            {client.canView && (
              <Route path="/clients/:id" element={<ClientDetails />} />
            )}
            {order.canView && (
              <Route
                path="/clients/:id/orderdetails/:orderID"
                element={<ClientDetails />}
              />
            )}
            {client.canEdit && (
              <Route path="/clients/:id/edit" element={<ClientEdit />} />
            )}

            {barcode.canView && (
              <Route path="/barcodes" element={<Barcode />} />
            )}

            {barcode.canCreate && (
              <Route path="/barcodes/add-barcode" element={<AddBarcode />} />
            )}

            {barcode.canCreate && (
              <Route
                path="/barcodes/add-barcode/:orgID"
                element={<AddBarcode />}
              />
            )}

            {subscription.canView && (
              <Route path="/subscriptions" element={<Subscription />} />
            )}

            {subscription.canView && (
              <Route
                path="/subscriptions/pending/:subscriptionID"
                element={<PendingSubscriptionDetails />}
              />
            )}

            {subscription.canView && (
              <Route
                path="/subscriptions/accepted/:subscriptionID"
                element={<AcceptedSubscriptionDetails />}
              />
            )}

            {subscription.canView && (
              <Route
                path="/subscriptions/rejected/:subscriptionID"
                element={<RejectedSubscriptionDetails />}
              />
            )}
            {category.canView && (
              <Route path="/categories" element={<Category />} />
            )}

            {category.canCreate && (
              <Route path="/categories/addcategory" element={<AddCategory />} />
            )}

            {category.canView && (
              <Route path="/categories/:id" element={<CategoryDetails />} />
            )}

            {category.canEdit && (
              <Route path="/categories/:id/edit" element={<CategoryEdit />} />
            )}

            {agent.canView && <Route path="/agents" element={<Agent />} />}

            {agent.canCreate && (
              <Route path="/agents/addagent" element={<AddAgent />} />
            )}

            {agent.canCreate && (
              <Route
                path="/agents/addagent/:request_Id"
                element={<AddAgent />}
              />
            )}

            {agent.canView && (
              <Route path="/agents/:id" element={<AgentDetails />} />
            )}

            {agent.canEdit && (
              <Route path="/agents/:id/edit" element={<AgentEdit />} />
            )}

            {agent.canEdit && (
              <Route
                path="/agents/:id/add-sub-agent"
                element={<AddSubAgent />}
              />
            )}

            <Route
              path="/agents/credit-requests/pending/:reqId"
              element={<PendingCreditRequestDetails />}
            />

            <Route
              path="/agents/credit-requests/accepted/:reqId"
              element={<AcceptedCreditRequestDetails />}
            />

            <Route
              path="/agents/credit-requests/rejected/:reqId"
              element={<RejectedCreditRequestDetails />}
            />

            {agent.canEdit && (
              <Route path="/agents/:id/add-client" element={<AddClient />} />
            )}

            {org.canView && (
              <Route path="/organizations" element={<Organization />} />
            )}

            {org.canCreate && (
              <Route
                path="/organizations/add-organization"
                element={<AddOrganization />}
              />
            )}

            {org.canCreate && (
              <Route
                path="/organizations/add-organization/:request_Id"
                element={<AddOrganization />}
              />
            )}

            {org.canView && (
              <Route
                path="/organizations/:id"
                element={<OrganizationDetails />}
              />
            )}

            {client.canCreate && (
              <Route
                path="/organizations/:id/add-client"
                element={<AddClient />}
              />
            )}

            {org.canEdit && (
              <Route
                path="/organizations/:id/edit"
                element={<OrganizationEdit />}
              />
            )}

            {coupon.canView && <Route path="/vouchers" element={<Voucher />} />}

            {coupon.canCreate && (
              <Route path="/vouchers/addvoucher" element={<AddVoucher />} />
            )}

            {coupon.canView && (
              <Route path="/vouchers/:id" element={<VoucherDetails />} />
            )}

            {coupon.canEdit && (
              <Route path="/vouchers/:id/edit" element={<VoucherEdit />} />
            )}

            {join.canView && <Route path="/requests" element={<Request />} />}
            {join.canView && (
              <Route path="/requests/:requestId" element={<RequestDetails />} />
            )}

            {ad.canView && <Route path="/ads" element={<Ads />} />}
            {ad.canView && (
              <Route path="/ads/pending/:id" element={<PendingAdsDetails />} />
            )}

            {ad.canView && (
              <Route
                path="/ads/rejected/:id"
                element={<RejectedAdsDetails />}
              />
            )}

            {ad.canView && (
              <Route
                path="/ads/accepted/:id"
                element={<AcceptedAdsDetails />}
              />
            )}

            {vendor.canSort && (
              <Route path="/sort-vendors" element={<SortVendor />} />
            )}

            {vendor.canView && (
              <Route path="/sort-vendors/:id" element={<SortVendor />} />
            )}

            {vendor.canView && (
              <Route
                path="/sort-vendors/vendor-details/:id"
                element={<SortVendorDetails />}
              />
            )}

            {product.canSort && (
              <Route path="/sort-products" element={<SortProduct />} />
            )}

            {product.canView && (
              <Route path="/sort-products/:id" element={<SortProduct />} />
            )}

            {product.canView && (
              <Route
                path="/sort-products/product-details/:id"
                element={<SortProductDetails />}
              />
            )}

            {category.canSort && (
              <Route path="/sort-categories" element={<SortCategory />} />
            )}

            {category.canSort && (
              <Route path="/sort-categories/:id" element={<SortCategory />} />
            )}

            {category.canSort && (
              <Route
                path="/sort-categories/category-details/:id"
                element={<SortCategoryDetails />}
              />
            )}

            {banner.canView && <Route path="/banners" element={<Banner />} />}

            {banner.canCreate && (
              <Route path="/banners/addbanner" element={<AddBanner />} />
            )}

            {banner.canCreate && (
              <Route path="/banners/addbanner/:ad_id" element={<AddBanner />} />
            )}

            {banner.canView && (
              <Route path="/banners/:id" element={<BannerDetails />} />
            )}

            {banner.canEdit && (
              <Route path="/banners/:id/edit" element={<BannerEdit />} />
            )}

            {banner.canView && <Route path="/popups" element={<PopUp />} />}

            {banner.canCreate && (
              <Route path="/popups/addpopup" element={<AddPopUp />} />
            )}

            {banner.canCreate && (
              <Route path="/popups/addpopup/:popup_id" element={<AddPopUp />} />
            )}

            {banner.canView && (
              <Route path="/popups/:id" element={<PopUpDetails />} />
            )}

            {banner.canEdit && (
              <Route path="/popups/:id/edit" element={<PopUpEdit />} />
            )}

            {review.canView && <Route path="/reviews" element={<Reviews />} />}
            {review.canView && (
              <Route path="/reviews/:reviewID" element={<ReviewDetails />} />
            )}

            {wishlist.canView && (
              <Route path="/wishlists" element={<Wishlist />} />
            )}

            {wishlist.canView && (
              <Route
                path="/wishlists/:wishlistID"
                element={<WishlistDetails />}
              />
            )}

            {cart.canView && <Route path="/carts" element={<Cart />} />}
            {cart.canView && (
              <Route path="/carts/:cartID" element={<CartDetails />} />
            )}

            {order.canView && <Route path="/orders" element={<Order />} />}

            {order.canView && (
              <Route path="/orders/:orderID" element={<OrderDetails />} />
            )}

            {orderRequest.canView && (
              <Route path="/order-requests" element={<OrderRequest />} />
            )}
            {orderRequest.canView && (
              <Route
                path="/order-requests/:orderID"
                element={<OrderRequestDetails />}
              />
            )}

            {points.canView && <Route path="/points" element={<Point />} />}
            {points.canView && (
              <Route path="/points/:pointID" element={<PointDetails />} />
            )}
            {points.canEdit && (
              <Route path="/points/:pointID/edit" element={<PointEdit />} />
            )}
            {chat.canView && <Route path="/messages" element={<Message />} />}

            {chat.canView && (
              <Route path="/messages/:id" element={<Message />} />
            )}
            {notification.canView && (
              <Route path="/notifications" element={<Notification />} />
            )}

            {notification.canCreate && (
              <Route
                path="/notifications/add-notification"
                element={<AddNotification />}
              />
            )}

            {notification.canCreate && (
              <Route
                path="/notifications/add-notification/:ad_id"
                element={<AddNotification />}
              />
            )}

            {notification.canView && (
              <Route
                path="/notifications/:notification_id"
                element={<NotificationDetails />}
              />
            )}

            {notification.canEdit && (
              <Route
                path="/notifications/:notification_id/edit"
                element={<NotificationEdit />}
              />
            )}

            {job.canView && <Route path="/jobs" element={<Job />} />}

            {job.canView && <Route path="/jobs/:id" element={<JobDetails />} />}

            {job.canView && (
              <Route path="/jobs/employee/:id" element={<EmployeeDetails />} />
            )}

            {service.canView && <Route path="services" element={<Service />} />}

            {service.canView && (
              <Route path="services/:id" element={<ServiceDetails />} />
            )}

            {profession.canView && (
              <Route path="professions" element={<Profession />} />
            )}

            {profession.canCreate && (
              <Route
                path="professions/add-profession"
                element={<AddProfession />}
              />
            )}

            {profession.canEdit && (
              <Route
                path="professions/:profession_id/edit"
                element={<ProfessionEdit />}
              />
            )}

            {interest.canView && (
              <Route path="interests" element={<Interest />} />
            )}

            {interest.canCreate && (
              <Route path="interests/add-interest" element={<AddInterest />} />
            )}

            {interest.canEdit && (
              <Route
                path="interests/:interest_id/edit"
                element={<InterestEdit />}
              />
            )}

            {page.canView && <Route path="about" element={<About />} />}
            {page.canCreate && (
              <Route path="about/add-about" element={<AddAbout />} />
            )}
            {page.canView && (
              <Route path="about/:aboutID" element={<AboutDetails />} />
            )}

            {page.canEdit && (
              <Route path="about/:aboutID/edit" element={<AboutEdit />} />
            )}

            {page.canView && <Route path="faqs" element={<FAQS />} />}
            {page.canCreate && (
              <Route path="faqs/add-faqs" element={<AddFAQS />} />
            )}
            {page.canView && (
              <Route path="faqs/:faqsID" element={<FAQSDetails />} />
            )}
            {page.canEdit && (
              <Route path="faqs/:faqsID/edit" element={<FAQSEdit />} />
            )}

            {setting.canView && <Route path="settings" element={<Setting />} />}
            {setting.canEdit && (
              <Route path="settings/edit" element={<SettingEdit />} />
            )}

            {/* <Route path="/*" element={<Navigate replace to="/admins" />} /> */}
          </Routes>
        </div>
      </div>
    </>
  );
}
