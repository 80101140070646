import React from "react";
import { useNavigate } from "react-router-dom";

import "./TableRow.scss";

export default function TableRow({ tableRow, onRowClick = () => {} }) {
  return (
    <tr
      onClick={() => {
        onRowClick(tableRow.id);
      }}
      className="table-row"
    >
      {tableRow &&
        Object.keys(tableRow).map((dataCell, i) => {
          if (dataCell === "id") {
            return;
          }
          let cellKey = Object.keys(tableRow)[i];
          return (
            <td className="table-data-cell" key={`${cellKey}-${i}`}>
              {tableRow[dataCell]}
            </td>
          );
        })}
    </tr>
  );
}
