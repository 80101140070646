import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import i18n from "../../locales/i18n";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import AgentServices from "../../services/AgentServices";
import toastPopup from "../../helpers/toastPopup";
import { useSelector } from "react-redux";
import COUNTRIES_CITIES from '../../locales/app-countries.json'
import CreditRequests from "./CreditRequests/CreditRequests";
import "./Agent.scss";

export default function Agent() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { agent: agentPermissions } = frontPermissions;

  let canDeleteAgent = agentPermissions.canDelete;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [activeAgent, setActiveAgent] = useState([]);
  const [totalActiveAgents, setTotalActiveAgents] = useState(0);
  const [inActiveAgent, setInActiveAgent] = useState([]);
  const [totalInActiveAgents, setTotalInActiveAgents] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [countryIndex, setCountryIndex] = useState('')
  const [cityIndex, setCityIndex] = useState('')
  const [allLevels, setAllLevels] = useState([]);
  const [isActiveAgent, setIsActiveAgent] = useState(true)
  const [isInActiveAgent, setIsInActiveAgent] = useState(false)
  const [isCreditRequest, setIsCreditRequest] = useState(false)
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState("");

  async function getAllActiveAgents() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await AgentServices.listAllActiveAgents(
        lang,
        searchActiveValue,
        filterValue === 'All' ? '' : filterValue,
        cityIndex === '' ? "" : localStorage.getItem("i18nextLng") === "en" ? selectedCity?.en : selectedCity?.ar,
        localStorage.getItem("i18nextLng"),
        activeCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let agentName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          agentName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          agentName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          agentName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          agentName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          agentName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          agentName = "لا يوجد أسم"
        }

        let parentName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.parent?.name?.en) {
          parentName = record?.parent?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.parent?.name?.en && record?.parent?.name?.ar) {
          parentName = record?.parent?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.parent?.name?.ar) {
          parentName = record?.parent?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.parent?.name?.ar && record?.parent?.name?.en) {
          parentName = record?.parent?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.parent?.name?.en && !record?.parent?.name?.ar) {
          parentName = "No Parent"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.parent?.name?.ar && !record?.parent?.name?.en) {
          parentName = "لا يوجد مسؤول"
        }
        let returnedObject = canDeleteAgent
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: agentName,
            "Sub Agents": record?.children?.length,
            Level: record?.level,
            Parent: parentName,
            Credit: record?.credit,
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "agent",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: agentName,
            "Sub Agents": record?.children?.length,
            Level: record?.level,
            Parent: parentName,
            Credit: record?.credit,
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "agent",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
          };

        return returnedObject;
      });

      setActiveAgent(agent => {
        if ((filterValue && activeCurrentPage === 1) || (cityIndex && activeCurrentPage === 1) || (searchActiveValue && activeCurrentPage === 1) || (isSorted && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...agent, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveAgents(data.counts);
      allAgentsLevel();
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInActiveAgents() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await AgentServices.listAllInActiveAgents(
        lang,
        searchInActiveValue,
        filterValue === 'All' ? '' : filterValue,
        cityIndex === '' ? "" : localStorage.getItem("i18nextLng") === "en" ? selectedCity?.en : selectedCity?.ar,
        localStorage.getItem("i18nextLng"),
        inActiveCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let agentName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          agentName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          agentName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          agentName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          agentName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          agentName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          agentName = "لا يوجد أسم"
        }

        let parentName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.parent?.name?.en) {
          parentName = record?.parent?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.parent?.name?.en && record?.parent?.name?.ar) {
          parentName = record?.parent?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.parent?.name?.ar) {
          parentName = record?.parent?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.parent?.name?.ar && record?.parent?.name?.en) {
          parentName = record?.parent?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.parent?.name?.en && !record?.parent?.name?.ar) {
          parentName = "No Parent"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.parent?.name?.ar && !record?.parent?.name?.en) {
          parentName = "لا يوجد مسؤول"
        }
        let returnedObject = canDeleteAgent
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: agentName,
            "Sub Agents": record?.children?.length,
            Level: record?.level,
            Parent: parentName,
            Credit: record?.credit,
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: agentName,
            "Sub Agents": record?.children?.length,
            Level: record?.level,
            Parent: parentName,
            Credit: record?.credit,
          };

        return returnedObject;
      });

      setInActiveAgent(agent => {
        if ((filterValue && inActiveCurrentPage === 1) || (cityIndex && inActiveCurrentPage === 1) || (searchInActiveValue && inActiveCurrentPage === 1) || (isSorted && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...agent, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveAgents(data.counts);
      allAgentsLevel();
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function allAgentsLevel() {
    let levels = []
    for (let level = 1; level <= 5; level++) {
      levels.push(level)
    }
    setAllLevels(levels)
  }

  async function deleteAgent(id) {
    setLoading(true);
    try {
      const { data } = await AgentServices.deleteAgent(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleAgentRowClick(id) {
    navigate(`/agents/${id}`);
  }

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(countryIndex))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(cityIndex))[0]

  useEffect(() => {
    if (isActiveAgent) {
      getAllActiveAgents();
      setInActiveAgent([])
    }
  }, [
    isActiveAgent,
    lang,
    searchActiveValue,
    filterValue,
    cityIndex,
    activeCurrentPage,
    isSorted
  ]);

  useEffect(() => {
    if (isInActiveAgent) {
      getAllInActiveAgents();
      setActiveAgent([])
    }
  }, [
    isInActiveAgent,
    lang,
    searchInActiveValue,
    filterValue,
    cityIndex,
    inActiveCurrentPage,
    isSorted
  ]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteAgent(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveAgent ? (
            <div className='active-btn'>
              <button
                onClick={() => {
                  setIsActiveAgent(true);
                  setIsInActiveAgent(false)
                  setIsCreditRequest(false)
                }}
                className='all-branches-btn'>
                <h2>{t("Active Agents")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => {
                setIsActiveAgent(true);
                setIsInActiveAgent(false)
                setIsCreditRequest(false)
              }}
              className='all-branches-btn'>
              <h2>{t("Active Agents")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveAgent ? (
            <div className='active-btn'>
              <button
                onClick={() => {
                  setIsActiveAgent(false);
                  setIsInActiveAgent(true);
                  setIsCreditRequest(false)
                }}
                className='all-branches-btn'>
                <h2>{t("Inactive Agents")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => {
                setIsActiveAgent(false);
                setIsInActiveAgent(true);
                setIsCreditRequest(false)
              }}
              className='all-branches-btn'>
              <h2>{t("Inactive Agents")}</h2>
            </button>
          </div>)
        }
        {
          isCreditRequest ? (
            <div className='active-btn'>
              <button
                onClick={() => {
                  setIsActiveAgent(false);
                  setIsInActiveAgent(false);
                  setIsCreditRequest(true)
                }}
                className='all-branches-btn'>
                <h2>{t("Credit Requests")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => {
                setIsActiveAgent(false);
                setIsInActiveAgent(false);
                setIsCreditRequest(true)
              }}
              className='all-branches-btn'>
              <h2>{t("Credit Requests")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveAgent ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Active Agents"}
                text2={"Active Agents"}
                total={totalActiveAgents}
                showBtn={agentPermissions.canCreate}
                btnText="Add Agent"
                onBtnClick={() => {
                  navigate("/agents/addagent");
                }}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setActiveCurrentPage(1);
                      setSearchActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
                <div className="filter-by-container">
                  <div className="filter-by">
                    <select
                      selected={countryIndex}
                      value={countryIndex}
                      onChange={(e) => {
                        setActiveCurrentPage(1);
                        setCountryIndex(e.target.value)
                      }}
                      className='search-by-input'
                      id="country"
                      name="country"
                      title='country'>
                      <option value=''>{t("Country")}</option>
                      {
                        COUNTRIES_CITIES?.map((country, index) => {
                          return <option key={index} value={country?.index}>{country?.[lang]}</option>
                        })
                      }
                    </select>
                  </div>

                  <div className="filter-by">
                    <select
                      selected={cityIndex}
                      value={cityIndex}
                      onChange={(e) => {
                        setActiveCurrentPage(1);
                        setCityIndex(e.target.value)
                      }}
                      className='search-by-input'
                      id="city"
                      name="city"
                      title='city'>
                      <option value=''>{t("City")}</option>
                      {
                        selectedCountry?.cities?.map((state, index) => {
                          return <option key={index} value={state?.index}>{state?.[lang]}</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="alpha">
                    <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
                  </div>
                </div>
              </div>
              <div className="filter">
                <div className="items">
                  <div>
                    <input
                      defaultChecked
                      value="All"
                      onClick={(e) => {
                        setActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="all"
                    />
                    <label htmlFor="all">{t("All")}</label>
                  </div>

                  {allLevels?.map((level) => {
                    return (
                      <div key={level}>
                        <input
                          value={level}
                          onClick={(e) => {
                            setActiveCurrentPage(1);
                            setFilterValue(e.target.value);
                          }}
                          type="radio"
                          name="filter"
                          id={level}
                        />
                        <label htmlFor={level}>
                          {t("Level")} {level}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>

              {isSearchEmpty || totalActiveAgents === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleAgentRowClick} tableRows={activeAgent} />
              )}
              {
                activeAgent.length !== totalActiveAgents &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveAgent ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Inactive Agents"}
                text2={"Inactive Agents"}
                total={totalInActiveAgents}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setInActiveCurrentPage(1);
                      setSearchInActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
                <div className="filter-by-container">
                  <div className="filter-by">
                    <select
                      selected={countryIndex}
                      value={countryIndex}
                      onChange={(e) => {
                        setInActiveCurrentPage(1);
                        setCountryIndex(e.target.value)
                      }}
                      className='search-by-input'
                      id="country"
                      name="country"
                      title='country'>
                      <option value=''>{t("Country")}</option>
                      {
                        COUNTRIES_CITIES?.map((country, index) => {
                          return <option key={index} value={country?.index}>{country?.[lang]}</option>
                        })
                      }
                    </select>
                  </div>

                  <div className="filter-by">
                    <select
                      selected={cityIndex}
                      value={cityIndex}
                      onChange={(e) => {
                        setInActiveCurrentPage(1);
                        setCityIndex(e.target.value)
                      }}
                      className='search-by-input'
                      id="city"
                      name="city"
                      title='city'>
                      <option value=''>{t("City")}</option>
                      {
                        selectedCountry?.cities?.map((state, index) => {
                          return <option key={index} value={state?.index}>{state?.[lang]}</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="alpha">
                    <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
                  </div>
                </div>
              </div>
              <div className="filter">
                <div className="items">
                  <div>
                    <input
                      defaultChecked
                      value=""
                      onClick={(e) => {
                        setInActiveCurrentPage(1);
                        setFilterValue(e.target.value);
                      }}
                      type="radio"
                      name="filter"
                      id="all"
                    />
                    <label htmlFor="all">{t("All")}</label>
                  </div>

                  {allLevels?.map((level) => {
                    return (
                      <div key={level}>
                        <input
                          value={level}
                          onClick={(e) => {
                            setInActiveCurrentPage(1);
                            setFilterValue(e.target.value);
                          }}
                          type="radio"
                          name="filter"
                          id={level}
                        />
                        <label htmlFor={level}>
                          {t("Level")} {level}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>

              {isSearchEmpty || totalInActiveAgents === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleAgentRowClick} tableRows={inActiveAgent} />
              )}
              {
                inActiveAgent.length !== totalInActiveAgents &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isCreditRequest ? (
        <div className="branch-cards-container">
          <CreditRequests />
        </div>
      ) : ""}
    </>
  );
}
