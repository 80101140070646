import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import toastPopup from "../../helpers/toastPopup";
import BannerServices from "../../services/BannerServices";
import { useSelector } from "react-redux";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import "./PopUp.scss";

export default function PopUp() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;
  let { banner: bannerPermissions } = frontPermissions;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [activePopUps, setActivePopUps] = useState([]);
  const [inActivePopUps, setInActivePopUps] = useState([]);
  const [isActivePopUp, setIsActivePopUp] = useState(true);
  const [isInActivePopUp, setIsInActivePopUp] = useState(false);
  const [totalActivePopUp, setTotalActivePopUp] = useState(0);
  const [totalInActivePopUp, setTotalInActivePopUp] = useState(0);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");

  async function getAllActivePopUps() {
    setLoading(true);
    try {
      const { data } = await BannerServices.listAllActivePopUps(activeCurrentPage, searchActiveValue);
      setLoading(false);
      setActivePopUps(popUp => {
        if ((searchActiveValue && activeCurrentPage === 1)) {
          return [...data.records]
        }
        return [...popUp, ...data.records]
      })
      setTotalActivePopUp(data?.counts)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInActivePopUps() {
    setLoading(true);
    try {
      const { data } = await BannerServices.listAllInActivePopUps(inActiveCurrentPage, searchInActiveValue);
      setLoading(false);
      setInActivePopUps(popUp => {
        if ((inActiveCurrentPage && inActiveCurrentPage === 1)) {
          return [...data.records]
        }
        return [...popUp, ...data.records]
      })
      setTotalInActivePopUp(data?.counts)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deletePopUp(id) {
    setLoading(true);
    try {
      const { data } = await BannerServices.deleteBanner(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handlePopUpClick(id) {
    navigate(`/popups/${id}`);
  }

  useEffect(() => {
    if (isActivePopUp) {
      getAllActivePopUps();
      setInActivePopUps([])
    }
  }, [activeCurrentPage, searchActiveValue, isActivePopUp]);

  useEffect(() => {
    if (isInActivePopUp) {
      getAllInActivePopUps();
      setActivePopUps([])
    }
  }, [inActiveCurrentPage, searchInActiveValue, isInActivePopUp]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>
              {t("Are you sure you want to delete ")}
              {t("?")}
            </h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deletePopUp(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {isActivePopUp ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setIsActivePopUp(true);
                setIsInActivePopUp(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Active Pop-Ups")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setIsActivePopUp(true);
                setIsInActivePopUp(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Active Pop-Ups")}</h2>
            </button>
          </div>
        )}
        {isInActivePopUp ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setIsActivePopUp(false);
                setIsInActivePopUp(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Inactive Pop-Ups")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setIsActivePopUp(false);
                setIsInActivePopUp(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Inactive Pop-Ups")}</h2>
            </button>
          </div>
        )}
      </div>

      {isActivePopUp ? (
        <div className="branch-cards-container">
          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setActiveCurrentPage(1);
                  setSearchActiveValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
          </div>
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div className="main-popup-card">
              {bannerPermissions.canCreate && (
                <div className="add-popup">
                  <div className="button">
                    <button
                      className="main-button-add"
                      onClick={() => {
                        navigate(`/popups/addpopup`);
                      }}
                    >
                      {t("Add Pop-Up")}
                    </button>
                  </div>
                </div>
              )}
              {activePopUps.length === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                activePopUps.map((popUp) => {
                  return (
                    <div className="small-banner" key={popUp?._id}>
                      <img
                        src={popUp?.image?.Location}
                        alt="imag-viewer"
                        className="uploaded-img"
                        onClick={() => {
                          handlePopUpClick(popUp?._id);
                        }}
                      />
                      {bannerPermissions.canDelete && (
                        <button
                          onClick={(e) => {
                            setModalShow(true);
                            setCurrentUser(popUp);
                          }}
                          className="remove"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                    </div>
                  );
                })
              )}
              {
                activePopUps.length !== totalActivePopUp &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      {isInActivePopUp ? (
        <div className="branch-cards-container">
          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setInActiveCurrentPage(1);
                  setSearchInActiveValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
          </div>
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div className="main-popup-card">
              <div className="add-popup">
                <div className="button">
                  <button
                    className="main-button-add"
                    onClick={() => {
                      navigate(`/popups/addpopup`);
                    }}
                  >
                    {t("Add Pop-Up")}
                  </button>
                </div>
              </div>
              {inActivePopUps.length === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                inActivePopUps.map((popUp) => {
                  return (
                    <div className="small-banner" key={popUp?._id}>
                      <img
                        src={popUp?.image?.Location}
                        alt="imag-viewer"
                        className="uploaded-img"
                        onClick={() => {
                          handlePopUpClick(popUp?._id);
                        }}
                      />
                      {bannerPermissions.canDelete && (
                        <button
                          onClick={(e) => {
                            setModalShow(true);
                            setCurrentUser(popUp);
                          }}
                          className="remove"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                    </div>
                  );
                })
              )}
              {
                inActivePopUps.length !== totalInActivePopUp &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
