import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MainTable from '../../../../Components/MainTable/MainTable';
import toastPopup from '../../../../helpers/toastPopup';
import i18n from '../../../../locales/i18n';
import ClientServices from '../../../../services/ClientServices'
import './Clients.scss'

export default function Clients(props) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams()
  const lang = i18n.language

  const [client, setClient] = useState([])
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({})
  const [modalShow, setModalShow] = useState(false)
  const [isActiveAgent, setIsActiveAgent] = useState(false);
  const [totalClients, setTotalClients] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function getAgentClients() {
    setLoading(true)
    try {
      const { data } = await ClientServices.listAllAgentClients(params?.id, currentPage)
      setLoading(false);

      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          clientName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          clientName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        return {
          Num: <div className='index'>{index + 1}</div>,
          id: record._id,
          Name: clientName,
          Email: record?.email,
          Phone: record?.phone,
          Barcode: record?.barcode,
          Chat: <button onClick={(e) => {
            e.stopPropagation()
            navigate(`/messages/${record._id}`, {
              state: 'client'
            })
          }} className='chat-with'><i className="fa-solid fa-message"></i></button>,
          Remove: <button onClick={(e) => {
            e.stopPropagation()
            setModalShow(true)
            setCurrentUser(record)
          }} className='remove'><i className="fa-solid fa-trash"></i></button>,
        }
      })
      setClient(client => {
        return [...client, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setIsActiveAgent(props?.agent?.isActive)
      setTotalClients(data.counts)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteClient(id) {
    setLoading(true)
    try {
      const { data } = await ClientServices.deleteClient(id)
      setLoading(false);
      setModalShow(false)
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function AddClientToAgentHandler() {
    if (props?.agentCredit > 0) {
      navigate(`/agents/${params.id}/add-client`)
    } else {
      toastPopup.error(t("You don't have enough credit to add a client"))
    }
  }

  function handleClientRowClick(id) {
    navigate(`/clients/${id}`)
  }

  useEffect(() => {
    getAgentClients()
  }, [currentPage])

  return <>

    {modalShow && <div className={modalShow ? "overlay" : "overlay-remove-cancel"} id='overlay-remove'>
      <div className="add-admin-box">
        <h3>{t("Are you sure you want to delete ")}</h3>
        <p className='admin-name'>{currentUser?.name?.[lang]} {t("?")}</p>
        <div className="modal-buttons">
          <div
            onClick={() => setModalShow(false)}
            className='cancel-remove'>
            {t("Cancel")}
          </div>
          <div
            onClick={() => deleteClient(currentUser._id)}
            className='delete'>
            {t("Delete")}
          </div>
        </div>
      </div>
      <div
        className='cancel'
        onClick={() => setModalShow(false)}>
        <i className="fa-solid fa-xmark"></i>
      </div>
    </div>}

    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}

    {isActiveAgent && <div className='add-branch'>
      <div className="button">
        <button
          className="main-button-add-sub-agent"
          onClick={AddClientToAgentHandler}>
          {t("Add Client")}
        </button>
      </div>
    </div>}

    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (<div>


        {client?.length === 0 ?
          <p className='no-results'>{t("No results found!")}</p> :
          <MainTable
            onRowClick={handleClientRowClick}
            tableRows={client} />}
      </div>)}

    {
      client.length !== totalClients &&
      <div className="load">
        <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
          {t("Load more...")}
        </button>
      </div>
    }
  </>
}
