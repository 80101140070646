import Axios from "./Axios";

let EmployeeServices = {
  getEmployee: async (id) => {
    const response = await Axios.get(`/admin/post/get?_id=${id}`);
    return response;
  },

  deleteEmployee: async (id) => {
    const response = await Axios.delete(`/admin/post/remove?_id=${id}`);
    return response;
  },

  listAllEmployees: async (lang = "", search = "", categoryId = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/post/list?${search.length > 0 ? `&jobTitle.${lang}=${search}` : ""
      }${categoryId ? `&category._id=${categoryId}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },
};

export default EmployeeServices;
