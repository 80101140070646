import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InterestServices from '../../../services/InterestServices';
import toastPopup from '../../../helpers/toastPopup';
import MainInput from '../../../Components/MainInput';
import './AddInterest.scss'

export default function AddInterest() {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [newInterest, setNewInterest] = useState({
    name_en: "",
    name_ar: ""
  });

  function resetInputs() {
    setNewInterest({
      name_en: "",
      name_ar: ""
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true }
  ];

  async function addNewInterest(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newInterest?.name_en,
          ar: newInterest?.name_ar
        }
      }

      const { data } = await InterestServices.addInterest(obj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      navigate(`/interests`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  return <>

    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/interests') }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Interest")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <form className='form-grid' onSubmit={addNewInterest}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newInterest}
                setState={setNewInterest}
              />
            );
          })}

        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate('/interests') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Interest")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
