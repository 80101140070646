import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ClientServices from '../../../../services/ClientServices'
import MainTable from '../../../../Components/MainTable/MainTable'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../locales/i18n'
import './Cart.scss'

export default function Cart() {

  const params = useParams()
  const { t } = useTranslation();
  const lang = i18n.language

  const [errorMessage, setErrorMessage] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0)
  const [cartOriginalTotal, setCartOriginalTotal] = useState(0)
  const [cartPoints, setCartPoints] = useState(0)
  const [branchName, setBranchName] = useState('')
  const [loading, setLoading] = useState(false);

  async function getClientCart() {
    try {
      const { data } = await ClientServices.getClientCart(params.id)
      setLoading(false);
      let tableData = data.record.items.map((item, index) => {
        return {
          Num: <div className='index'>{index + 1}</div>,
          id: item?.product?._id,
          Photo: <div className="product-image-container">
            <img
              src={`${item?.product?.image[0]?.Location}`}
              alt="Product Image"
              className="product-image" />
          </div>,
          Name: (item?.product?.name?.[lang]),
          Price: (item?.product?.price) + t(" EGP"),
          Quantity: (item?.quantity),
          "Total Price": (item?.total) + t(" EGP")
        }
      })
      setCartItems(tableData)
      setCartTotal(data?.record?.total)
      setCartOriginalTotal(data?.record?.originalTotal)
      setCartPoints(data?.record?.points)
      setBranchName(data?.record?.items[0]?.product?.branches[0]?.name?.[lang])
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getClientCart()
  }, [])

  return <>
    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}
    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (<div>
        {cartItems.length > 0 ?
          <MainTable
            tableRows={cartItems} />
          : <p className='no-results'>{t("Cart is currently empty!")}</p>}
      </div>)}

    <div className="totals">
      <div className="item">
        <span>{t("Branch Name")}</span>
        <div className="data">{branchName}</div>
      </div>
      <div className="item">
        <span>{t("Cart Total Price")}</span>
        <div className="data">{cartTotal} {t("EGP")}</div>
      </div>
      <div className="item">
        <span>{t("Cart Original Total Price")}</span>
        <div className="data">{cartOriginalTotal} {t("EGP")}</div>
      </div>
      <div className="item">
        <span>{t("Cart Points")}</span>
        <div className="data">{cartPoints}</div>
      </div>
    </div>
  </>
}
