import Axios from "./Axios";

let CreditRequestServices = {
  getCreditRequests: async (id) => {
    const response = await Axios.get(`/admin/request/get?_id=${id}`);
    return response;
  },

  editCreditRequests: async (id, obj) => {
    const response = await Axios.put(`/admin/request/update?_id=${id}`, obj);
    return response;
  },

  acceptToSendCredit: async (id) => {
    const response = await Axios.put(`/admin/request/acceptToSendCredit?_id=${id}`);
    return response;
  },

  acceptToWithdrawCredit: async (id) => {
    const response = await Axios.put(`/admin/request/acceptToWithdrawCredit?_id=${id}`);
    return response;
  },

  listAllCreditRequestsWithAdmin: async (status, type, agent, page, limit = 25) => {
    const response = await Axios.get(`/admin/request/list?toAdmin=true&status=${status}${type ? `&type=${type}` : ""}${agent ? `&from=${agent}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllCreditRequestsWithAgents: async (status, type, fromTo, agent, page, limit = 25) => {
    const response = await Axios.get(`/admin/request/list?toAdmin=false${status ? `&status=${status}` : ""}${type ? `&type=${type}` : ""}${agent ? `&${fromTo}=${agent}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default CreditRequestServices;
