import Axios from "./Axios";

let VendorServices = {
  addVendor: async (obj) => {
    const response = await Axios.post(`/admin/vendor/create`, obj);
    return response;
  },

  getVendor: async (id) => {
    const response = await Axios.get(`/admin/vendor/get?_id=${id}`);
    return response;
  },

  getVendorCategories: async (type) => {
    const response = await Axios.get(`/admin/category/list?type=${type}&isActive=true`);
    return response;
  },

  getVendorActiveBranches: async (id, lang = "", search = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/branch/list?vendor=${id}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  getVendorInActiveBranches: async (id, lang = "", search = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/branch/list?vendor=${id}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  getVendorActiveProducts: async (id, lang = "", search = "", page, limit = 25, isSorted, sortLang, hotDeal) => {
    const response = await Axios.get(
      `/admin/product/list?vendor=${id}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}${hotDeal ? `&isHotDeal=${hotDeal}` : ""}`
    );
    return response;
  },

  getVendorInActiveProducts: async (id, lang = "", search = "", page, limit = 25, isSorted, sortLang, hotDeal) => {
    const response = await Axios.get(
      `/admin/product/list?vendor=${id}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}${hotDeal ? `&isHotDeal=${hotDeal}` : ""}`
    );
    return response;
  },

  editVendor: async (id, obj) => {
    const response = await Axios.put(`/admin/vendor/update?_id=${id}`, obj);
    return response;
  },

  changePasswordVendor: async (obj) => {
    const response = await Axios.put(`/admin/vendor/resetPassword`, obj);
    return response;
  },

  uploadVendorImage: async (id, obj) => {
    const response = await Axios.post(`/admin/vendor/image?_id=${id}`, obj);
    return response;
  },

  deleteVendorImage: async (id) => {
    const response = await Axios.delete(`/admin/vendor/image?_id=${id}`);
    return response;
  },

  uploadVendorCover: async (id, obj) => {
    const response = await Axios.post(`/admin/vendor/cover?_id=${id}`, obj);
    return response;
  },

  deleteVendorCover: async (id) => {
    const response = await Axios.delete(`/admin/vendor/cover?_id=${id}`);
    return response;
  },

  deleteVendor: async (id) => {
    const response = await Axios.delete(`/admin/vendor/remove?_id=${id}`);
    return response;
  },

  listAllVendors: async (categoryId) => {
    const response = await Axios.get(`/admin/vendor/list?${categoryId ? `&category=${categoryId}` : ""}&isActive=true`);
    return response;
  },

  listAllActiveVendors: async (lang = "", search = "", categoryId = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/vendor/list?${search.length > 0 ? `&name.${lang}=${search}` : ""
      }${categoryId ? `&category=${categoryId}` : ""}&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listAllInActiveVendors: async (lang = "", search = "", categoryId = "", page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/vendor/list?${search.length > 0 ? `&name.${lang}=${search}` : ""
      }${categoryId ? `&category=${categoryId}` : ""}&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listAllVendorReviews: async (vendorId, page, limit = 25) => {
    const response = await Axios.get(`/admin/review/list?vendor=${vendorId}&type=vendor${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  deleteVendorReview: async (id) => {
    const response = await Axios.delete(`/admin/review/remove?_id=${id}`);
    return response;
  },

  addBranch: async (obj) => {
    const response = await Axios.post(`/admin/branch/create`, obj);
    return response;
  },

  getBranch: async (id, branchID) => {
    const response = await Axios.get(
      `/admin/branch/get?vendor=${id}&_id=${branchID}`
    );
    return response;
  },

  getBranchData: async (branchID) => {
    const response = await Axios.get(
      `/admin/branch/get?_id=${branchID}`
    );
    return response;
  },

  getBranchActiveProducts: async (branchID, lang = "", search = "", page, limit = 25, isSorted, sortLang, hotDeal) => {
    const response = await Axios.get(
      `/admin/product/list?branches=${branchID}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}${hotDeal ? `&isHotDeal=${hotDeal}` : ""}`
    );
    return response;
  },

  getBranchInActiveProducts: async (branchID, lang = "", search = "", page, limit = 25, isSorted, sortLang, hotDeal) => {
    const response = await Axios.get(
      `/admin/product/list?branches=${branchID}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}${hotDeal ? `&isHotDeal=${hotDeal}` : ""}`
    );
    return response;
  },

  editBranch: async (id, obj) => {
    const response = await Axios.put(`/admin/branch/update?_id=${id}`, obj);
    return response;
  },

  changePasswordBranch: async (obj) => {
    const response = await Axios.put(`/admin/branch/resetPassword`, obj);
    return response;
  },

  uploadBranchImage: async (id, obj) => {
    const response = await Axios.post(`/admin/branch/image?_id=${id}`, obj);
    return response;
  },

  deleteBranchImage: async (id) => {
    const response = await Axios.delete(`/admin/branch/image?_id=${id}`);
    return response;
  },

  uploadBranchCover: async (id, obj) => {
    const response = await Axios.post(`/admin/branch/cover?_id=${id}`, obj);
    return response;
  },

  deleteBranchCover: async (id) => {
    const response = await Axios.delete(`/admin/branch/cover?_id=${id}`);
    return response;
  },

  deleteBranch: async (id) => {
    const response = await Axios.delete(`/admin/branch/remove?_id=${id}`);
    return response;
  },

  addCashier: async (obj) => {
    const response = await Axios.post(`/admin/cashier/create`, obj);
    return response;
  },

  getCashier: async (id) => {
    const response = await Axios.get(`/admin/cashier/get?_id=${id}`);
    return response;
  },

  editCashier: async (id, obj) => {
    const response = await Axios.put(`/admin/cashier/update?_id=${id}`, obj);
    return response;
  },

  changePasswordCashier: async (obj) => {
    const response = await Axios.put(`/admin/cashier/resetPassword`, obj);
    return response;
  },

  getBranchActiveCashiers: async (branchID, lang = "", search = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/cashier/list?branch=${branchID}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  getBranchInActiveCashiers: async (branchID, lang = "", search = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/cashier/list?branch=${branchID}${search.length > 0 ? `&name.${lang}=${search}` : ""
      }&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  uploadCashierImage: async (id, obj) => {
    const response = await Axios.post(`/admin/cashier/image?_id=${id}`, obj);
    return response;
  },

  deleteCashierImage: async (id) => {
    const response = await Axios.delete(`/admin/cashier/image?_id=${id}`);
    return response;
  },

  deleteCashier: async (id) => {
    const response = await Axios.delete(`/admin/cashier/remove?_id=${id}`);
    return response;
  },

  addProduct: async (obj) => {
    const response = await Axios.post(`/admin/product/create`, obj);
    return response;
  },

  getProduct: async (id) => {
    const response = await Axios.get(`/admin/product/get?_id=${id}`);
    return response;
  },

  editProduct: async (id, obj) => {
    const response = await Axios.put(`/admin/product/update?_id=${id}`, obj);
    return response;
  },

  deleteProduct: async (id) => {
    const response = await Axios.delete(`/admin/product/remove?_id=${id}`);
    return response;
  },

  uploadProductImage: async (id, obj) => {
    const response = await Axios.post(`/admin/product/image?_id=${id}`, obj);
    return response;
  },

  addProductImage: async (id, obj) => {
    const response = await Axios.post(`/admin/product/addImage?_id=${id}`, obj);
    return response;
  },

  removeProductImage: async (id, obj) => {
    const response = await Axios.delete(`/admin/product/removeImage?_id=${id}`, obj);
    return response;
  },

  listAllProductReviews: async (productId) => {
    const response = await Axios.get(`/admin/review/list?product=${productId}&type=product`);
    return response;
  },

  deleteProductReview: async (id) => {
    const response = await Axios.delete(`/admin/review/remove?_id=${id}`);
    return response;
  },

  sortVendors: async (obj) => {
    const response = await Axios.post(`/admin/vendor/sort`, obj);
    return response;
  },
};

export default VendorServices;
