import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AboutDetails from './AboutDetails/AboutDetails';
import './About.scss'

export default function About() {

  const { t } = useTranslation();
  const navigate = useNavigate()

  function isAbout(about) {
    if (about > 0) {
      return true;
    } else {
      return false;
    }
  }

  return <>

    {isAbout() &&
      <div className='add-notification'>
        <button
          className="main-button-add"
          onClick={() => { navigate(`/about/add-about`) }}
        >
          {t("Add About")}
        </button>
      </div>
    }

    <AboutDetails isAbout={isAbout} />
  </>
}
