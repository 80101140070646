import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VendorServices from "../../../../services/VendorServices";
import toastPopup from "../../../../helpers/toastPopup";
import i18n from "../../../../locales/i18n";
import MainInput from "../../../../Components/MainInput";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import "./VendorEdit.scss";

export default function VendorEdit() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { vendor: vendorPermissions } = frontPermissions;

  let params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [category, setCategory] = useState([]);
  const [uploadImage, setUploadImage] = useState("");
  const [uploadCover, setUploadCover] = useState("");
  const [productCategories, setProductCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const [vendor, setVendor] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    email: "",
    phone: "",
    category: "",
    link: "",
    isActive: false,
    hasDelivery: false,
    hasOnlinePayment: false
  });

  const [newVendor, setNewVendor] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    email: "",
    phone: "",
    category: "",
    link: "",
    isActive: false,
    hasDelivery: false,
    hasOnlinePayment: false
  });

  const [newPassword, setNewPassword] = useState({
    email: "",
    new_password: "",
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "description_en", type: "text", required: false },
    { name: "description_ar", type: "text", required: false },
    { name: "email", type: "email", required: false },
    { name: "phone", type: "text", required: false },
    { name: "link", type: "text", required: false },
  ];

  let newPasswordFormData = [
    { name: "email", type: "email", required: false, disabled: true },
    { name: "new_password", type: "password", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getVendor() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendor(params.id);
      setLoading(false);
      setVendor({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        description_en: data?.record[0]?.description?.en,
        description_ar: data?.record[0]?.description?.ar,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        category: data?.record[0]?.category._id,
        link: data?.record[0]?.link,
        isActive: data?.record[0]?.isActive,
        hasDelivery: data?.record[0]?.hasDelivery,
        hasOnlinePayment: data?.record[0]?.hasOnlinePayment
      });

      setNewVendor({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        description_en: data?.record[0]?.description?.en,
        description_ar: data?.record[0]?.description?.ar,
        email: data?.record[0]?.email,
        phone: data?.record[0]?.phone,
        category: data?.record[0]?.category._id,
        link: data?.record[0]?.link,
        isActive: data?.record[0]?.isActive,
        hasDelivery: data?.record[0]?.hasDelivery,
        hasOnlinePayment: data?.record[0]?.hasOnlinePayment
      });

      setNewPassword({
        email: data?.record[0]?.email,
        new_password: "",
      });

      setSelectedCategories(data?.record[0]?.productCategories.map((category) => {
        return {
          id: category?._id,
          name: category?.name?.[lang]

        }
      }))
      setUploadImage(data?.record[0]?.image?.Location);
      setUploadCover(data?.record[0]?.cover?.Location);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getVendorCategory() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendorCategories('vendor');
      setLoading(false);
      setCategory(data.records);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getProductCategory() {
    setLoading(true);
    try {
      const { data } = await VendorServices.getVendorCategories('product');
      setLoading(false);
      setProductCategories(data.records);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editVendor(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newVendor, vendor)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newVendor["name_en"], ar: newVendor["name_ar"] },
        };
      } else if (key === "description_en" || key === "description_ar") {
        editedData = {
          ...editedData,
          description: {
            en: newVendor["description_en"],
            ar: newVendor["description_ar"],
          },
        };
      } else {
        editedData = {
          ...editedData,
          [key]: newVendor[key],
        };
      }
    });

    let finalProductCategories = selectedCategories.map((cat) => {
      return {
        _id: cat.id
      }
    })

    editedData = { ...editedData, productCategories: finalProductCategories }

    try {
      const { data } = await VendorServices.editVendor(params.id, editedData);

      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } =
          typeof uploadImage === "object" &&
          (await VendorServices.uploadVendorImage(params.id, formData));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      var coverFormData = new FormData();
      coverFormData.append("cover", uploadCover);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadVendorCover(params.id, coverFormData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/vendors/${params.id}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function changePasswordHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        email: newPassword.email,
        newPassword: newPassword.new_password,
      };
      const { data } = await VendorServices.changePasswordVendor(obj);
      setLoading(false);
      toastPopup.success(t("Password changed successfully"));
      navigate(`/vendors/${params.id}`);
    } catch (e) {
      setLoading(false);
      setPasswordErrorMessage(e.response.data.error);
    }
  }

  let categoriesOptions = productCategories.map((category) => {
    return ({
      name: category?.name?.[lang],
      id: category._id
    })
  })

  function isSelectedCategory(categoreyId) {
    let selectedCateoriesIds = selectedCategories.map((selectedCategory) => selectedCategory.id)
    return selectedCateoriesIds.includes(categoreyId)
  }

  function toggleSelectedCategoriesHandler(category) {
    if (isSelectedCategory(category.id)) {
      let oldSelectedCategories = selectedCategories
      let newSelectedCategories = oldSelectedCategories.filter((cat) => { return cat.id !== category.id })
      setSelectedCategories(newSelectedCategories)
    } else {
      setSelectedCategories((prev) => {
        return [...prev, category]
      })
    }
  }

  const ref = useRef();
  const coverRef = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  const coverUploader = (e) => {
    coverRef.current.click();
  };

  useEffect(() => {
    getVendor();
    getProductCategory()
    getVendorCategory();
  }, []);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/vendors/${params.id}`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Vendor Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>
        <div className="main-image-label vendor-cover">
          {uploadCover && (
            <img
              src={typeof uploadCover === "object" ? URL.createObjectURL(uploadCover) : uploadCover ? uploadCover : null}
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadCover ? URL.createObjectURL(uploadCover) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={coverRef}
            onChange={(e) => {
              setUploadCover(e.target.files[0]);
            }}
          />
          <label
            className="main-label-image"
            onClick={coverUploader}
            htmlFor="upload-img"
          >
            {t("Add Cover")}
          </label>
        </div>

        <div className="main-image-label vendor-image">
          {uploadImage && (
            <img
              src={typeof uploadImage === "object" ? URL.createObjectURL(uploadImage) : uploadImage ? uploadImage : null}
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadImage ? URL.createObjectURL(uploadImage) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          <label
            className={uploadImage ? "main-label-image-uploaded" : "main-label-image"}
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            {t("Add Image")}
          </label>
        </div>
        <form className="form-grid" onSubmit={editVendor}>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newVendor}
                  setState={setNewVendor}
                />
              );
            })}
            <div className="main-input-select">
              <select
                value={newVendor.category}
                onChange={(e) => {
                  setNewVendor((prev) => {
                    return { ...prev, category: e.target.value };
                  });
                }}
                className="main-input"
                id="Category"
                name="Category"
                title="Category"
              >
                <option value="category">-- {t("Category")} --</option>
                {category.map((cat, index) => {
                  return (
                    <option key={index} value={cat._id}>
                      {(cat?.name?.[lang]).charAt(0).toUpperCase() +
                        (cat?.name?.[lang]).slice(1)}
                    </option>
                  );
                })}
              </select>
              <label className="main-label">{t("Category")}</label>
            </div>

            <Multiselect
              displayValue="name"
              selectedValues={selectedCategories}
              onKeyPressFn={function noRefCheck() { }}
              onRemove={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedCategoriesHandler({ id: selectedItem.id, name: selectedItem.name })
              }}
              onSearch={function noRefCheck() { }}
              onSelect={function noRefCheck(selectedList, selectedItem) {
                toggleSelectedCategoriesHandler({ id: selectedItem.id, name: selectedItem.name })
              }}
              options={categoriesOptions}
              showCheckbox
              placeholder={t("Product Category")}
            />

            <div className="toggler-settings">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newVendor.isActive}
                  onChange={(e) => {
                    setNewVendor((prev) => {
                      return { ...prev, isActive: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggler-settings">
              <label htmlFor="productImage" className="word">
                {t("Has Delivery")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newVendor.hasDelivery}
                  onChange={(e) => {
                    setNewVendor((prev) => {
                      return { ...prev, hasDelivery: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggler-settings">
              <label htmlFor="productImage" className="word">
                {t("Has Online Payment")}
              </label>
              <label className="switch form-group">
                <input
                  checked={newVendor.hasOnlinePayment}
                  onChange={(e) => {
                    setNewVendor((prev) => {
                      return { ...prev, hasOnlinePayment: e.target.checked };
                    });
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>
      {vendorPermissions.canChangePassword && (
        <div className="change-password">
          <div className="header">
            <h3>{t("Change Password")}</h3>
            {passwordErrorMessage ? (
              <div className="err">{t(passwordErrorMessage)}</div>
            ) : (
              ""
            )}
          </div>

          <form className="form-grid" onSubmit={changePasswordHandler}>
            <div className="input-grid">
              {newPasswordFormData.map((formInput, index) => {
                return (
                  <MainInput
                    className="input-edit-admin"
                    key={index}
                    name={formInput.name}
                    type={formInput.type}
                    required={formInput.required}
                    disabled={formInput.disabled}
                    state={newPassword}
                    setState={setNewPassword}
                  />
                );
              })}
            </div>
            <div className="button-grid">
              <button className="main-button-admin" disabled={loading}>
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  t("Submit")
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
