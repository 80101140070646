import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PagesServices from '../../../../services/PagesServices';
import toastPopup from '../../../../helpers/toastPopup';
import './AboutEdit.scss'

export default function AboutEdit() {

  let params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [about, setAbout] = useState({
    name_en: "",
    name_ar: "",
    content_en: "",
    content_ar: ""
  });

  const [newAbout, setNewAbout] = useState({
    name_en: "",
    name_ar: "",
    content_en: "",
    content_ar: ""
  });

  function getNewAboutData(e) {
    let newAboutData = { ...newAbout }
    newAboutData[e.target.name] = e.target.value
    setNewAbout(newAboutData)
  }

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getAbout() {
    setLoading(true);
    try {
      const { data } = await PagesServices.getPage(params.aboutID);
      setLoading(true);
      setLoading(false);
      setAbout({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        content_en: data?.record[0]?.content?.en,
        content_ar: data?.record[0]?.content?.ar
      });

      setNewAbout({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        content_en: data?.record[0]?.content?.en,
        content_ar: data?.record[0]?.content?.ar
      });
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editAbout(e) {
    e.preventDefault();
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newAbout, about)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newAbout["name_en"], ar: newAbout["name_ar"] },
        };
      } else if (key === "content_en" || key === "content_ar") {
        editedData = {
          ...editedData,
          content: {
            en: newAbout["content_en"],
            ar: newAbout["content_ar"],
          },
        };
      }
    });

    try {
      const { data } = await PagesServices.editPage(params.aboutID, editedData);
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      navigate(`/about`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <>
      <div className="add-product">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/about`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit About Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <form className="form-grid" onSubmit={editAbout}>
          <div className="input-grid">
            <div className="main-input-label">
              <textarea
                name="name_en"
                id="name_en"
                cols="90"
                rows="10"
                className="main-textarea-question"
                value={newAbout.name_en}
                onChange={getNewAboutData}>
              </textarea>
              <label className="main-label" htmlFor="name_en">
                {t("English Name")}
              </label>
            </div>
            <div className="main-input-label">
              <textarea
                name="name_ar"
                id="name_ar"
                cols="90"
                rows="10"
                className="main-textarea-question"
                value={newAbout.name_ar}
                onChange={getNewAboutData}>
              </textarea>
              <label className="main-label" htmlFor="name_ar">
                {t("Arabic Name")}
              </label>
            </div>
            <div className="main-input-label">
              <textarea
                name="content_en"
                id="content_en"
                cols="90"
                rows="10"
                className="main-textarea-answer"
                value={newAbout.content_en}
                onChange={getNewAboutData}>
              </textarea>
              <label className="main-label" htmlFor="content_en">
                {t("English Content")}
              </label>
            </div>
            <div className="main-input-label">
              <textarea
                name="content_ar"
                id="content_ar"
                cols="90"
                rows="10"
                className="main-textarea-answer"
                value={newAbout.content_ar}
                onChange={getNewAboutData}>
              </textarea>
              <label className="main-label" htmlFor="content_ar">
                {t("Arabic Content")}
              </label>
            </div>
          </div>

          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
