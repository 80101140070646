import Axios from "./Axios";

let VoucherServices = {
  addVoucher: async (obj) => {
    const response = await Axios.post(`/admin/coupon/create`, obj);
    return response;
  },

  getVoucher: async (id) => {
    const response = await Axios.get(`/admin/coupon/get?_id=${id}`);
    return response;
  },

  editVoucher: async (id, obj) => {
    const response = await Axios.put(`/admin/coupon/update?_id=${id}`, obj);
    return response;
  },

  listAllActiveVouchers: async (lang = "", search = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/coupon/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  listAllInActiveVouchers: async (lang = "", search = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/coupon/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  deleteVoucher: async (id) => {
    const response = await Axios.delete(`/admin/coupon/remove?_id=${id}`);
    return response;
  },
};

export default VoucherServices;
