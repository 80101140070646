import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CartServices from '../../../../services/CartServices';
import MainTable from '../../../../Components/MainTable/MainTable';
import './VendorCarts.scss'

export default function VendorCarts() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams()

  const [carts, setCarts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalCarts, setTotalCarts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllVendorCarts() {
    try {
      const { data } = await CartServices.listAllVendorCarts(params.id, currentPage);
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && !record?.client?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && !record?.client?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?.client?._id,
          "Client name": (clientName)?.slice(0, 15),
          "Number of products": record?.items?.length,
          "Price": (record?.total) + t(" EGP"),
          "Full price": (record?.originalTotal) + t(" EGP"),
          "Points": record?.points,
        }
      });

      setCarts(cart => {
        return [...cart, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalCarts(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleCartRowClick(id) {
    navigate(`/carts/${id}`);
  }

  useEffect(() => {
    getAllVendorCarts();
  }, [currentPage]);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          {carts.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handleCartRowClick} tableRows={carts} />
          )}
          {
            carts.length !== totalCarts &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
