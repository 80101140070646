import Axios from "./Axios";

let SubscriptionServices = {
  getSubscription: async (id) => {
    const response = await Axios.get(`/admin/subscription/get?client=${id}`);
    return response;
  },

  editSubscription: async (id, obj) => {
    const response = await Axios.put(`/admin/subscription/update?_id=${id}`, obj);
    return response;
  },

  deleteSubscription: async (id) => {
    const response = await Axios.delete(`/admin/subscription/remove?client=${id}`);
    return response;
  },

  listAllPendingSubscriptions: async (method, page, limit = 25) => {
    const response = await Axios.get(`/admin/subscription/list?status=pending${method.length > 0 ? `&subscriptionMethod=${method}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllAcceptedSubscriptions: async (method, page, limit = 25) => {
    const response = await Axios.get(`/admin/subscription/list?status=approved${method.length > 0 ? `&subscriptionMethod=${method}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllRejectedSubscriptions: async (method, page, limit = 25) => {
    const response = await Axios.get(`/admin/subscription/list?status=rejected${method.length > 0 ? `&subscriptionMethod=${method}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default SubscriptionServices;
