import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import InfoInput from '../../../../Components/InfoInput/InfoInput';
import MainTable from '../../../../Components/MainTable/MainTable';
import i18n from '../../../../locales/i18n';
import JobServices from '../../../../services/JobServices';
import './JobDetails.scss'

export default function JobDetails() {

  const { t } = useTranslation();
  let params = useParams()
  const navigate = useNavigate()
  const lang = i18n.language

  const [job, setJob] = useState({})
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false);
  const [applicants, setApplicants] = useState([]);

  async function getJob() {
    setLoading(true)
    try {
      const { data } = await JobServices.getJob(params.id)
      setLoading(false);
      setJob(data.record[0])
      setActive(true)
      let tableData = data?.record[0]?.applicants?.map((record, index) => {
        let applicantName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          applicantName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          applicantName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          applicantName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          applicantName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          applicantName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          applicantName = "لا يوجد أسم"
        }
        return {
          Num: <div className='index'>{index + 1}</div>,
          id: record?._id,
          Name: applicantName,
          CV: <a onClick={(e) => {
            e.stopPropagation()
          }} href={record?.link}
            target='_blank'
            className='view'>{t("View CV")}</a>,
        }
      })
      setApplicants(tableData)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let companyName = ""
  if (localStorage.getItem("i18nextLng") === "en" && job?.companyName?.en) {
    companyName = job?.companyName?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !job?.companyName?.en && job?.companyName?.ar) {
    companyName = job?.companyName?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && job?.companyName?.ar) {
    companyName = job?.companyName?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !job?.companyName?.ar && job?.companyName?.en) {
    companyName = job?.companyName?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !job?.companyName?.en && !job?.companyName?.ar) {
    companyName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !job?.companyName?.ar && !job?.companyName?.en) {
    companyName = "لا يوجد أسم"
  }

  let jobTitle = ""
  if (localStorage.getItem("i18nextLng") === "en" && job?.jobTitle?.en) {
    jobTitle = job?.jobTitle?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !job?.jobTitle?.en && job?.jobTitle?.ar) {
    jobTitle = job?.jobTitle?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && job?.jobTitle?.ar) {
    jobTitle = job?.jobTitle?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !job?.jobTitle?.ar && job?.jobTitle?.en) {
    jobTitle = job?.jobTitle?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !job?.jobTitle?.en && !job?.jobTitle?.ar) {
    jobTitle = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !job?.jobTitle?.ar && !job?.jobTitle?.en) {
    jobTitle = "لا يوجد أسم"
  }

  let jobDetails = [
    { id: "recruiter", title: "Recruiter", type: "text", value: ((job?.client) ? (job?.client?.name?.[lang]) : (job?.vendor) ? (job?.vendor?.name?.[lang]) : (job?.branch?.name?.[lang])) },
    { id: "companyName", title: "Company Name", type: "text", value: companyName },
    { id: "jobTitle", title: "Job Title", type: "text", value: jobTitle },
    { id: "description", title: "Description", type: "text", value: job?.description?.[lang] },
    { id: "address", title: "Address", type: "text", value: job?.address?.[lang] },
    { id: "phone", title: "Phone", type: "text", value: job?.contacts?.phone },
    { id: "whatsapp", title: "Whatsapp", type: "text", value: job?.contacts?.whatsapp },
    { id: "telegram", title: "Telegram", type: "text", value: job?.contacts?.telegram },
    { id: "category", title: "Category", type: "text", value: job?.category?.map((cat) => cat?._id?.name?.[lang]) },
    { id: "publishDate", title: "Publish Date", type: "text", value: formatDate(new Date(job?.publishDate)) },
    { id: "applicants", title: "Applicants", type: "number", value: job?.applicants?.length },
  ]

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getJob()
  }, [])

  return <>

    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}
    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (active && <div className="main-details">
        <div className="back">
          <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/jobs') }}></i>
        </div>
        <div className="client-image vendor-image-details">
          <img src={"https://cdn.searchenginejournal.com/wp-content/uploads/2017/06/shutterstock_268688447.jpg"} alt="Job Image" />
        </div>
        <div className="client-datails">
          <div className="data">
            {
              jobDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                )
              })
            }

            <div className='toggler'>
              <label htmlFor="productImage" className="word">{t("Active")}</label>
              <label className="switch form-group">
                <input checked={job?.isActive} type="checkbox" readOnly />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>)}
    {applicants.length === 0 ?
      <p className='no-results'>{t("No applicants to this job!")}</p>
      : <MainTable tableRows={applicants} />}
  </>
}
