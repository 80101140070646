import React, { useState, useEffect } from "react";
import VendorServices from "../../../../services/VendorServices";
import toastPopup from "../../../../helpers/toastPopup";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as SearchLogo } from "../../../../assets/VIP-ICON-SVG/SearchLogo.svg";
import MainTable from "../../../MainTable/MainTable";
import i18n from "../../../../locales/i18n";
import { useSelector } from "react-redux";
import "./ProductCard.scss";

export default function ProductCard() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { product: productPermissions } = frontPermissions;

  let {
    canCreate: canCreateProduct,
    canView: canViewProduct,
    canEdit: canEditProduct,
    canDelete: canDeleteProduct,
  } = productPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const lang = i18n.language;

  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeProducts, setActiveProducts] = useState([]);
  const [inActiveProducts, setInActiveProducts] = useState([]);
  const [searchActiveProductValue, setSearchActiveProductValue] = useState("");
  const [searchInActiveProductValue, setSearchInActiveProductValue] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [isActiveProduct, setIsActiveProduct] = useState(true);
  const [isInActiveProduct, setIsInActiveProduct] = useState(false);
  const [totalActiveProducts, setTotalActiveProducts] = useState(0);
  const [totalInActiveProducts, setTotalInActiveProducts] = useState(0);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState("");
  const [isHotDeal, setIsHotDeal] = useState("")

  async function getVendorActiveProducts() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveProductValue);
    let lang = result ? "ar" : "en";
    try {
      const { data } = await VendorServices.getVendorActiveProducts(
        params.id,
        lang,
        searchActiveProductValue,
        activeCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
        isHotDeal
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let productName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          productName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          productName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          productName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          productName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          productName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          productName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteProduct
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Photo: (
              <div className="product-image-container">
                <img
                  src={`${record?.image?.[0]?.Location}`}
                  alt="Product Image"
                  className="product-image"
                />
              </div>
            ),
            Name: productName,
            "Sale Price": record?.price + t(" EGP"),
            "Full Price": record?.originalPrice + t(" EGP"),
            Editable: (
              <div className="check">
                <input
                  checked={record?.isEditable}
                  type="checkbox"
                  name="isEditable"
                  id="isEditable"
                  readOnly
                />
                <label></label>
              </div>
            ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Photo: (
              <div className="product-image-container">
                <img
                  src={`${record?.image?.[0]?.Location}`}
                  alt="Product Image"
                  className="product-image"
                />
              </div>
            ),
            Name: productName,
            "Sale Price": record?.price + t(" EGP"),
            "Full Price": record?.originalPrice + t(" EGP"),
            Editable: (
              <div className="check">
                <input
                  checked={record?.isEditable}
                  type="checkbox"
                  name="isEditable"
                  id="isEditable"
                  readOnly
                />
                <label></label>
              </div>
            ),
          };
        return returnedRecord;
      });

      setActiveProducts(product => {
        if ((searchActiveProductValue && activeCurrentPage === 1) || (isSorted && activeCurrentPage === 1) || (isHotDeal && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...product, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveProducts(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getVendorInActiveProducts() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveProductValue);
    let lang = result ? "ar" : "en";
    try {
      const { data } = await VendorServices.getVendorInActiveProducts(
        params.id,
        lang,
        searchInActiveProductValue,
        inActiveCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
        isHotDeal
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let productName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          productName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          productName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          productName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          productName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          productName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          productName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteProduct
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Photo: (
              <div className="product-image-container">
                <img
                  src={`${record?.image?.[0]?.Location}`}
                  alt="Product Image"
                  className="product-image"
                />
              </div>
            ),
            Name: productName,
            "Sale Price": record?.price + t(" EGP"),
            "Full Price": record?.originalPrice + t(" EGP"),
            Editable: (
              <div className="check">
                <input
                  checked={record?.isEditable}
                  type="checkbox"
                  name="isEditable"
                  id="isEditable"
                  readOnly
                />
                <label></label>
              </div>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Photo: (
              <div className="product-image-container">
                <img
                  src={`${record?.image?.[0]?.Location}`}
                  alt="Product Image"
                  className="product-image"
                />
              </div>
            ),
            Name: productName,
            "Sale Price": record?.price + t(" EGP"),
            "Full Price": record?.originalPrice + t(" EGP"),
            Editable: (
              <div className="check">
                <input
                  checked={record?.isEditable}
                  type="checkbox"
                  name="isEditable"
                  id="isEditable"
                  readOnly
                />
                <label></label>
              </div>
            ),
          };
        return returnedRecord;
      });

      setInActiveProducts(product => {
        if ((searchInActiveProductValue && inActiveCurrentPage === 1) || (isSorted && inActiveCurrentPage === 1) || (isHotDeal && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...product, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveProducts(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteProduct(id) {
    setLoading(true);
    try {
      const { data } = await VendorServices.deleteProduct(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleProductRowClick(id) {
    navigate(
      `/vendors/${params.id}/branchdetails/${params.branchID}/productdetails/${id}`
    );
  }

  useEffect(() => {
    if (isActiveProduct) {
      getVendorActiveProducts();
      setInActiveProducts([])
    }
  }, [params.id, lang, searchActiveProductValue, activeCurrentPage, isActiveProduct, isSorted, isHotDeal]);

  useEffect(() => {
    if (isInActiveProduct) {
      getVendorInActiveProducts();
      setActiveProducts([])
    }
  }, [params.id, lang, searchInActiveProductValue, inActiveCurrentPage, isInActiveProduct, isSorted, isHotDeal]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteProduct(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveProduct ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveProduct(true); setIsInActiveProduct(false) }}
                className='all-branches-btn'>
                <h2>{t("Active Products")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveProduct(true); setIsInActiveProduct(false) }}
              className='all-branches-btn'>
              <h2>{t("Active Products")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveProduct ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveProduct(false); setIsInActiveProduct(true) }}
                className='all-branches-btn'>
                <h2>{t("Inactive Products")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveProduct(false); setIsInActiveProduct(true) }}
              className='all-branches-btn'>
              <h2>{t("Inactive Products")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveProduct ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <div className="add-branch">
                <div className="form-search">
                  <div className="form-input-search">
                    <input
                      onChange={(e) => {
                        setActiveCurrentPage(1);
                        setSearchActiveProductValue(prev => {
                          if (prev && e.target.value === '') {
                            navigate(0)
                          }
                          return e.target.value
                        });
                      }}
                      className="input-search"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={t("What Are You Looking For?")}
                    />
                    <label className="label-search">
                      <SearchLogo className="SearchLogo" />
                    </label>
                  </div>
                </div>
                {canCreateProduct && (
                  <div className="button">
                    <button
                      className="main-button-add"
                      onClick={() => {
                        navigate(
                          `/vendors/${params.id}/addproducttomultiplebranches`
                        );
                      }}
                    >
                      {t("Add Product")}
                    </button>
                  </div>
                )}
              </div>

              <div className="alpha">
                <div className="filter-by">
                  <select
                    onChange={(e) => {
                      setIsHotDeal(e.target.value);
                    }}
                    className="search-by-input"
                    id="searchBy"
                    name="searchBy"
                    title="searchBy"
                  >
                    <option value="">
                      {t("Product Type")}
                    </option>
                    <option value="true">{t("Hot deal")}</option>
                    <option value="false">{t("Offer")}</option>
                  </select>
                </div>

                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>

              {isSearchEmpty ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable
                  onRowClick={handleProductRowClick}
                  tableRows={activeProducts}
                />
              )}
              {
                activeProducts.length !== totalActiveProducts &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveProduct ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <div className="add-branch">
                <div className="form-search">
                  <div className="form-input-search">
                    <input
                      onChange={(e) => {
                        setInActiveCurrentPage(1);
                        setSearchInActiveProductValue(prev => {
                          if (prev && e.target.value === '') {
                            navigate(0)
                          }
                          return e.target.value
                        });
                      }}
                      className="input-search"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={t("What Are You Looking For?")}
                    />
                    <label className="label-search">
                      <SearchLogo className="SearchLogo" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="alpha">
                <div className="filter-by">
                  <select
                    onChange={(e) => {
                      setIsHotDeal(e.target.value);
                    }}
                    className="search-by-input"
                    id="searchBy"
                    name="searchBy"
                    title="searchBy"
                  >
                    <option value="">
                      {t("Product Type")}
                    </option>
                    <option value="true">{t("Hot deal")}</option>
                    <option value="false">{t("Offer")}</option>
                  </select>
                </div>

                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>

              {isSearchEmpty ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable
                  onRowClick={handleProductRowClick}
                  tableRows={inActiveProducts}
                />
              )}
              {
                inActiveProducts.length !== totalInActiveProducts &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
