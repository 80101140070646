import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import WishlistServices from '../../../services/WishlistServices';
import MainTable from '../../../Components/MainTable/MainTable';
import InfoInput from '../../../Components/InfoInput/InfoInput';
import './WishlistDetails.scss'

export default function WishlistDetails() {

  const { t } = useTranslation();
  const lang = i18next.language;
  const params = useParams()
  const navigate = useNavigate()

  const [Wishlist, setWishlist] = useState({});
  const [WishlistProducts, setWishlistProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(false);

  async function getWishlist() {
    try {
      const { data } = await WishlistServices.getWishlist(params.wishlistID);
      setLoading(false);
      setWishlist(data.record);
      setActive(true);
      if (data?.record?.client?.image) {
        setImage(true);
      }
      let tableData = data.record.items.map((record, index) => {
        let productName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.product?.name?.en) {
          productName = record?.product?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.product?.name?.en && record?.product?.name?.ar) {
          productName = record?.product?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.product?.name?.ar) {
          productName = record?.product?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.product?.name?.ar && record?.product?.name?.en) {
          productName = record?.product?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.product?.name?.en && !record?.product?.name?.ar) {
          productName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.product?.name?.ar && !record?.product?.name?.en) {
          productName = "لا يوجد أسم"
        }

        let vendorName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && !record?.vendor?.name?.ar) {
          vendorName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && !record?.vendor?.name?.en) {
          vendorName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?.product?._id,
          Photo: (
            <div className="product-image-container">
              <img
                src={`${record?.product?.image?.[0]?.Location}`}
                alt="Product Image"
                className="product-image"
              />
            </div>
          ),
          "Product name": (productName).slice(0, 15),
          "Vendor name": vendorName,
          "Sale Price": record?.product?.price + t(" EGP"),
          "Full Price": record?.product?.originalPrice + t(" EGP")
        }
      });
      setWishlistProducts(tableData);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let clientName = ""
  if (localStorage.getItem("i18nextLng") === "en" && Wishlist?.client?.name?.en) {
    clientName = Wishlist?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !Wishlist?.client?.name?.en && Wishlist?.client?.name?.ar) {
    clientName = Wishlist?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && Wishlist?.client?.name?.ar) {
    clientName = Wishlist?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !Wishlist?.client?.name?.ar && Wishlist?.client?.name?.en) {
    clientName = Wishlist?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !Wishlist?.client?.name?.en && !Wishlist?.client?.name?.ar) {
    clientName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !Wishlist?.client?.name?.ar && !Wishlist?.client?.name?.en) {
    clientName = "لا يوجد أسم"
  }

  let wishlistDetails = [
    {
      id: "client_name",
      title: "Client name",
      type: "text",
      value: clientName,
    },
    {
      id: "number_of_products",
      title: "Number of products",
      type: "text",
      value: Wishlist?.items?.length
    },
  ];

  useEffect(() => {
    getWishlist();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate(`/wishlists`);
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {image ? (
                <img
                  src={Wishlist?.client?.image?.Location}
                  alt="Client Image"
                />
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Client Image"
                />
              )}
            </div>
            <div className="client-datails">
              <div className="data">
                {wishlistDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}
              </div>
            </div>

            <div className="branch-cards-container">
              <MainTable tableRows={WishlistProducts} />
            </div>
          </div>
        )
      )}
    </>
  );
}
