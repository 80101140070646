import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import PointServices from "../../../services/PointServices";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import MainTable from "../../../Components/MainTable/MainTable";
import { useSelector } from "react-redux";
import "./PointDetails.scss";

export default function PointDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { points: pointsPermissions } = frontPermissions;

  const lang = i18next.language;
  const params = useParams();
  const navigate = useNavigate();

  const [points, setPoints] = useState({});
  const [vendorPoints, setVendorPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(false);

  async function getPoints() {
    try {
      const { data } = await PointServices.getPoint(params.pointID);
      setLoading(false);
      setPoints(data.record);
      setActive(true);
      if (data?.record?.client?.image) {
        setImage(true);
      }
      let tableData = data.record.vendorPoints.map((record, index) => {
        let vendorName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.vendor?.name?.ar) {
          vendorName = record?.vendor?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && record?.vendor?.name?.en) {
          vendorName = record?.vendor?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.vendor?.name?.en && !record?.vendor?.name?.ar) {
          vendorName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.vendor?.name?.ar && !record?.vendor?.name?.en) {
          vendorName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?._id,
          "Vendor name": vendorName,
          "Available points": record?.availablePoints,
          "Used points": record?.usedPoints,
        };
      });
      setVendorPoints(tableData);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let clientName = ""
  if (localStorage.getItem("i18nextLng") === "en" && points?.client?.name?.en) {
    clientName = points?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !points?.client?.name?.en && points?.client?.name?.ar) {
    clientName = points?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && points?.client?.name?.ar) {
    clientName = points?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !points?.client?.name?.ar && points?.client?.name?.en) {
    clientName = points?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !points?.client?.name?.en && !points?.client?.name?.ar) {
    clientName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !points?.client?.name?.ar && !points?.client?.name?.en) {
    clientName = "لا يوجد أسم"
  }

  let pointsDetails = [
    {
      id: "client_name",
      title: "Client name",
      type: "text",
      value: clientName,
    },
    {
      id: "vip_points",
      title: "VIP points",
      type: "text",
      value: points?.vipPoints,
    },
    {
      id: "total_points",
      title: "Total points",
      type: "text",
      value: points?.totalPoints,
    },
  ];

  useEffect(() => {
    getPoints();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate(`/points`);
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {image ? (
                <img src={points?.client?.image?.Location} alt="Client Image" />
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Client Image"
                />
              )}
            </div>
            <div className="client-datails">
              <div className="edit">
                {pointsPermissions.canEdit && (
                  <button
                    onClick={() => navigate(`/points/${params.pointID}/edit`)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                )}
              </div>
              <div className="data">
                {pointsDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}
              </div>
            </div>

            <div className="branch-cards-container">
              <MainTable tableRows={vendorPoints} />
            </div>
          </div>
        )
      )}
    </>
  );
}
