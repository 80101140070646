import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PendingSubscription from "./PendingSubscription/PendingSubscription";
import AcceptedSubscriptions from "./AcceptedSubscriptions/AcceptedSubscriptions";
import RejectedSubscriptions from "./RejectedSubscriptions/RejectedSubscriptions";
import "./Subscription.scss";
import { useSelector } from "react-redux";

export default function Subscription() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { barcode: barcodePermissions } = frontPermissions;

  const { t } = useTranslation();

  const [pendingSubscriptions, setPendingSubscriptions] = useState(true);
  const [acceptedSubscriptions, setAcceptedSubscriptions] = useState(false);
  const [rejectedSubscriptions, setRejectedSubscriptions] = useState(false);

  return (
    <>
      <div className="user">
        {pendingSubscriptions ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingSubscriptions(true);
                setAcceptedSubscriptions(false);
                setRejectedSubscriptions(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Pending Subscriptions")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingSubscriptions(true);
                setAcceptedSubscriptions(false);
                setRejectedSubscriptions(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Pending Subscriptions")}</h2>
            </button>
          </div>
        )}
        {acceptedSubscriptions ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingSubscriptions(false);
                setAcceptedSubscriptions(true);
                setRejectedSubscriptions(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Accepted Subscriptions")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingSubscriptions(false);
                setAcceptedSubscriptions(true);
                setRejectedSubscriptions(false);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Accepted Subscriptions")}</h2>
            </button>
          </div>
        )}
        {rejectedSubscriptions ? (
          <div className="active-btn">
            <button
              onClick={() => {
                setPendingSubscriptions(false);
                setAcceptedSubscriptions(false);
                setRejectedSubscriptions(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Rejected Subscriptions")}</h2>
            </button>
          </div>
        ) : (
          <div className="all-branches">
            <button
              onClick={() => {
                setPendingSubscriptions(false);
                setAcceptedSubscriptions(false);
                setRejectedSubscriptions(true);
              }}
              className="all-branches-btn"
            >
              <h2>{t("Rejected Subscriptions")}</h2>
            </button>
          </div>
        )}
      </div>

      {pendingSubscriptions ? (
        <div className="branch-cards-container">
          <PendingSubscription />
        </div>
      ) : (
        ""
      )}

      {acceptedSubscriptions ? (
        <div className="branch-cards-container">
          <AcceptedSubscriptions />
        </div>
      ) : (
        ""
      )}

      {rejectedSubscriptions ? (
        <div className="branch-cards-container">
          <RejectedSubscriptions />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
