import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import toastPopup from "../../helpers/toastPopup";
import i18n from "../../locales/i18n";
import OrganizationServices from "../../services/OrganizationServices";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import { useSelector } from "react-redux";
import "./Organization.scss";

export default function Organization() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { org: orgPermissions } = frontPermissions;

  let { canCreate: canCreateOrg, canDelete: canDeleteOrg } = orgPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [activeOrganization, setActiveOrganization] = useState([]);
  const [totalActiveOrganizations, setTotalActiveOrganizations] = useState(0);
  const [inActiveOrganization, setInActiveOrganization] = useState([]);
  const [totalInActiveOrganizations, setTotalInActiveOrganizations] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");
  const [isActiveOrganization, setIsActiveOrganization] = useState(true)
  const [isInActiveOrganization, setIsInActiveOrganization] = useState(false)
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState("");

  async function getAllActiveOrganizations() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveValue);
    let lang = result ? "ar" : "en";
    try {
      const { data } = await OrganizationServices.listAllActiveOrganizations(
        lang,
        searchActiveValue,
        activeCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let orgName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          orgName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          orgName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          orgName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          orgName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          orgName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          orgName = "لا يوجد أسم"
        }
        let returnedObject = canDeleteOrg
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: orgName,
            Email: record?.email,
            Phone: record?.phone,
            Credit: record?.credit,
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "org",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: orgName,
            Email: record?.email,
            Phone: record?.phone,
            Credit: record?.credit,
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "org",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
          };

        return returnedObject;
      });

      setActiveOrganization(org => {
        if ((searchActiveValue && activeCurrentPage === 1) || (isSorted && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...org, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveOrganizations(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInActiveOrganizations() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveValue);
    let lang = result ? "ar" : "en";
    try {
      const { data } = await OrganizationServices.listAllInActiveOrganizations(
        lang,
        searchInActiveValue,
        inActiveCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let orgName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          orgName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          orgName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          orgName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          orgName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          orgName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          orgName = "لا يوجد أسم"
        }
        let returnedObject = canDeleteOrg
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: orgName,
            Email: record?.email,
            Phone: record?.phone,
            Credit: record?.credit,
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: orgName,
            Email: record?.email,
            Phone: record?.phone,
            Credit: record?.credit,
          };

        return returnedObject;
      });

      setInActiveOrganization(org => {
        if ((searchActiveValue && activeCurrentPage === 1) || (isSorted && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...org, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveOrganizations(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteOrganization(id) {
    setLoading(true);
    try {
      const { data } = await OrganizationServices.deleteOrganization(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleOrganizationRowClick(id) {
    navigate(`/organizations/${id}`);
  }

  useEffect(() => {
    if (isActiveOrganization) {
      getAllActiveOrganizations();
      setInActiveOrganization([])
    }
  }, [searchActiveValue, activeCurrentPage, isSorted]);

  useEffect(() => {
    if (isInActiveOrganization) {
      getAllInActiveOrganizations();
      setActiveOrganization([])
    }
  }, [searchInActiveValue, inActiveCurrentPage, isSorted]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteOrganization(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveOrganization ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveOrganization(true); setIsInActiveOrganization(false) }}
                className='all-branches-btn'>
                <h2>{t("Active Organizations")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveOrganization(true); setIsInActiveOrganization(false) }}
              className='all-branches-btn'>
              <h2>{t("Active Organizations")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveOrganization ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveOrganization(false); setIsInActiveOrganization(true) }}
                className='all-branches-btn'>
                <h2>{t("Inactive Organizations")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveOrganization(false); setIsInActiveOrganization(true) }}
              className='all-branches-btn'>
              <h2>{t("Inactive Organizations")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveOrganization ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Active Organizations"}
                text2={"Active Organizations"}
                total={totalActiveOrganizations}
                showBtn={canCreateOrg}
                btnText="Add Organization"
                onBtnClick={() => {
                  navigate("/organizations/add-organization");
                }}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setActiveCurrentPage(1);
                      setSearchActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>

              <div className="alpha">
                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>

              {isSearchEmpty || totalActiveOrganizations === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable
                  onRowClick={handleOrganizationRowClick}
                  tableRows={activeOrganization}
                />
              )}
              {
                activeOrganization.length !== totalActiveOrganizations &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveOrganization ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <MainCard
                text1={"Total Inactive Organizations"}
                text2={"Inactive Organizations"}
                total={totalInActiveOrganizations}
                showBtn={canCreateOrg}
                btnText="Add Organization"
                onBtnClick={() => {
                  navigate("/organizations/add-organization");
                }}
              />

              <div className="form-search">
                <div className="form-input-search">
                  <input
                    onChange={(e) => {
                      setInActiveCurrentPage(1);
                      setSearchInActiveValue(prev => {
                        if (prev && e.target.value === '') {
                          navigate(0)
                        }
                        return e.target.value
                      });
                    }}
                    className="input-search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t("What Are You Looking For?")}
                  />
                  <label className="label-search">
                    <SearchLogo className="SearchLogo" />
                  </label>
                </div>
              </div>

              <div className="alpha">
                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>

              {isSearchEmpty || totalInActiveOrganizations === 0 ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable
                  onRowClick={handleOrganizationRowClick}
                  tableRows={inActiveOrganization}
                />
              )}
              {
                inActiveOrganization.length !== totalInActiveOrganizations &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
