import React, { useRef, useEffect, useState } from 'react'
import MainInput from '../../../Components/MainInput'
import { useTranslation } from 'react-i18next';
import toastPopup from '../../../helpers/toastPopup';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../locales/i18n';
import VendorServices from '../../../services/VendorServices';
import Multiselect from 'multiselect-react-dropdown';
import RequestServices from '../../../services/RequestServices';
import './AddVendor.scss'

export default function AddVendor() {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [category, setCategory] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [categoryID, setCategoryID] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [hasDelivery, setHasDelivery] = useState(false)
  const [hasOnlinePayment, setHasOnlinePayment] = useState(false)
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadCoverImage, setUploadCoverImage] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate()
  const lang = i18n.language
  const params = useParams()

  const [newVendor, setNewVendor] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    email: "",
    password: "",
    phone: "",
    link: ""
  });

  async function getRequest() {
    setLoading(true)
    try {
      const { data } = await RequestServices.getRequest(params?.request_Id)
      setLoading(false);
      setNewVendor({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        description_en: data?.record[0]?.description?.en,
        description_ar: data?.record[0]?.description?.ar,
        email: data?.record[0]?.email,
        password: data?.record[0]?.password,
        phone: data?.record[0]?.phone,
      });
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function resetInputs() {
    setNewVendor({
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      email: "",
      password: "",
      phone: "",
      link: ""
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "description_en", type: "text", required: true },
    { name: "description_ar", type: "text", required: true },
    { name: "email", type: "email", required: true },
    { name: "password", type: "password", required: true },
    { name: "phone", type: "text", required: true },
    { name: "link", type: "text", required: false },
  ];

  async function addNewVendor(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newVendor.name_en,
          ar: newVendor.name_ar
        },
        description: {
          en: newVendor.description_en,
          ar: newVendor.description_ar
        },
        email: newVendor.email,
        password: newVendor.password,
        phone: newVendor.phone,
        category: categoryID,
        link: newVendor.link,
        isActive: isActive,
        hasDelivery: hasDelivery,
        hasOnlinePayment: hasOnlinePayment,
        productCategories: getFinalCategories()
      }

      const { data } = await VendorServices.addVendor(obj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      let vendorID = data.record._id
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadVendorImage(vendorID, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      var coverFormData = new FormData();
      coverFormData.append("cover", uploadCoverImage);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadVendorCover(vendorID, coverFormData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/vendors/${data.record._id}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  async function addNewVendorFromRequest(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newVendor.name_en,
          ar: newVendor.name_ar
        },
        description: {
          en: newVendor.description_en,
          ar: newVendor.description_ar
        },
        email: newVendor.email,
        password: newVendor.password,
        phone: newVendor.phone,
        category: categoryID,
        link: newVendor.link,
        isActive: isActive,
        hasDelivery: hasDelivery,
        hasOnlinePayment: hasOnlinePayment,
        productCategories: getFinalCategories()
      }

      const { data } = await VendorServices.addVendor(obj)
      setLoading(false);
      resetInputs()
      let vendorID = data.record._id
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadVendorImage(vendorID, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      var coverFormData = new FormData();
      coverFormData.append("cover", uploadCoverImage);
      setLoading(true)
      try {
        const { data } = await VendorServices.uploadVendorCover(vendorID, coverFormData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      try {
        const { data } = await RequestServices.editRequest(params?.request_Id, {
          status: "accepted",
        });
        setLoading(false);
        toastPopup.success(t("Request Accepted"));
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      toastPopup.success(t("Added"));
      navigate(`/vendors/${data.record._id}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  async function getVendorCategory() {
    setLoading(true)
    try {
      const { data } = await VendorServices.getVendorCategories('vendor')
      setLoading(false);
      setCategory(data.records)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getProductCategory() {
    setLoading(true)
    try {
      const { data } = await VendorServices.getVendorCategories('product')
      setLoading(false);
      setProductCategories(data.records)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function toggleSelectedCategoriesHandler(categoryId) {
    if (selectedCategories.includes(categoryId)) {
      let oldSelectedCategories = selectedCategories
      let newSelectedCategories = oldSelectedCategories.filter((category) => { return category !== categoryId })
      setSelectedCategories(newSelectedCategories)
    } else {
      setSelectedCategories((prev) => { return [...prev, categoryId] })
    }
  }

  function getFinalCategories() {
    let finalBrandCategories = []
    selectedCategories.forEach((selectedCategory) => {
      productCategories.filter(category => category._id === selectedCategory).map((category) => {
        finalBrandCategories.push({ _id: category._id })
      })
    })

    return finalBrandCategories
  }

  let categoriesOptions = productCategories.map((category) => {
    return ({
      name: category?.name?.[lang],
      id: category._id
    })
  })

  const ref = useRef();
  const coverRef = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  const coverUploader = (e) => {
    coverRef.current.click();
  };

  useEffect(() => {
    getVendorCategory()
    getProductCategory()
  }, [])

  useEffect(() => {
    if (params?.request_Id) {
      getRequest();
    }
  }, [params?.request_Id]);

  return <>

    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/vendors') }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Vendor")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="main-image-label vendor-cover">
        {uploadCoverImage && (
          <img
            src={typeof uploadCoverImage === "object" ? URL.createObjectURL(uploadCoverImage) : uploadCoverImage ? uploadCoverImage : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadCoverImage ? URL.createObjectURL(uploadCoverImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={coverRef}
          onChange={(e) => {
            setUploadCoverImage(e.target.files[0]);
          }}
        />
        <label
          className="main-label-image"
          onClick={coverUploader}
          htmlFor="upload-img"
        >
          {t("Add Cover")}
        </label>
      </div>

      <div className="main-image-label vendor-image">
        {uploadImage && (
          <img
            src={typeof uploadImage === "object" ? URL.createObjectURL(uploadImage) : uploadImage ? uploadImage : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadImage ? URL.createObjectURL(uploadImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={ref}
          onChange={(e) => {
            setUploadImage(e.target.files[0]);
          }}
        />
        <label
          className={uploadImage ? "main-label-image-uploaded" : "main-label-image"}
          onClick={imageUploader}
          htmlFor="upload-img"
        >
          {t("Add Image")}
        </label>
      </div>

      <form className='form-grid' onSubmit={(params.request_Id) ? addNewVendorFromRequest : addNewVendor}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newVendor}
                setState={setNewVendor}
              />
            );
          })}
          <div className="main-input-select">
            <select
              onChange={(e) => { setCategoryID(e.target.value) }}
              className='main-input'
              id="Category"
              name="Category"
              title='Category'
              value={categoryID}>
              <option defaultValue='category'>-- {t("Category")} --</option>
              {category.map((cat, index) => {
                return (
                  <option key={index}
                    value={cat._id}>{((cat?.name?.[lang]).charAt(0)).toUpperCase() + (cat?.name?.[lang]).slice(1)}
                  </option>
                )
              })}
            </select>
            <label className='main-label'>{t("Category")}</label>
          </div>

          <Multiselect
            displayValue="name"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedCategoriesHandler(selectedItem.id)
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedCategoriesHandler(selectedItem.id)
            }}
            options={categoriesOptions}
            showCheckbox
            placeholder={t("Product Category")}
          />

          <div className='toggler-settings'>
            <label
              htmlFor="productImage"
              className="word">
              {t("Is Active")}
            </label>
            <label className="switch form-group">
              <input
                checked={isActive}
                onChange={(e) => { setIsActive(e.target.checked) }}
                type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className='toggler-settings'>
            <label
              htmlFor="productImage"
              className="word">
              {t("Has Delivery")}
            </label>
            <label className="switch form-group">
              <input
                checked={hasDelivery}
                onChange={(e) => { setHasDelivery(e.target.checked) }}
                type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className='toggler-settings'>
            <label
              htmlFor="productImage"
              className="word">
              {t("Has Online Payment")}
            </label>
            <label className="switch form-group">
              <input
                checked={hasOnlinePayment}
                onChange={(e) => { setHasOnlinePayment(e.target.checked) }}
                type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate('/vendors') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Vendor")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
