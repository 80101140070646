import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ClientServices from "../../../../services/ClientServices";
import i18next from "i18next";
import "./Points.scss";

export default function Points() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;
  let { points: pointsPermissions } = frontPermissions;

  let params = useParams();
  const { t } = useTranslation();
  const lang = i18next.language;

  const [clientPoints, setClientPoints] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [totalPoints, setTotalPoints] = useState(true);
  const [vipPoints, setVipPoints] = useState(false);
  const [vendorPoints, setVendorPoints] = useState(false);
  const [updatePointsValue, setUpdatePointsValue] = useState(0);

  async function getClientPoints() {
    try {
      const { data } = await ClientServices.getClientPoints(params.id);
      setClientPoints(data?.records[0]);
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function addClientPoints() {
    try {
      let obj = {
        vipPoints: parseInt(updatePointsValue),
      };
      const { data } = await ClientServices.addClientPoints(params.id, obj);
      getClientPoints();
      setUpdatePointsValue("");
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteClientPoints() {
    try {
      let obj = {
        vipPoints: parseInt(updatePointsValue),
      };
      const { data } = await ClientServices.deleteClientPoints(params.id, obj);
      getClientPoints();
      setUpdatePointsValue("");
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    getClientPoints();
  }, []);

  return (
    <>
      <div className="filter">
        <div className="items">
          <div>
            <input
              defaultChecked
              value="All Points"
              onClick={(e) => {
                setTotalPoints(true);
                setVipPoints(false);
                setVendorPoints(false);
              }}
              type="radio"
              name="filter"
              id="All_Points"
            />
            <label htmlFor="All_Points">{t("All Points")}</label>
          </div>

          <div>
            <input
              value="Vip Points"
              onClick={(e) => {
                setVipPoints(true);
                setTotalPoints(false);
                setVendorPoints(false);
              }}
              type="radio"
              name="filter"
              id="Vip_Points"
            />
            <label htmlFor="Vip_Points">{t("Vip Points")}</label>
          </div>

          <div>
            <input
              value="Vendor Points"
              onClick={(e) => {
                setVendorPoints(true);
                setTotalPoints(false);
                setVipPoints(false);
              }}
              type="radio"
              name="filter"
              id="Vendor_Points"
            />
            <label htmlFor="Vendor_Points">{t("Vendor Points")}</label>
          </div>
        </div>
      </div>

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="points-card">
        {totalPoints ? (
          <div className="data">
            <h3>
              {t("Total Points")} <span>{clientPoints?.totalPoints}</span>
            </h3>
          </div>
        ) : (
          ""
        )}

        {vipPoints ? (
          <div className="data">
            <h3>
              {t("Vip Points")} <span>{clientPoints?.vipPoints}</span>
            </h3>

            {pointsPermissions.canEdit && (
              <>
                <input
                  className="vip_points"
                  type="number"
                  name="vip_points"
                  id="vip_points"
                  placeholder="Points"
                  value={updatePointsValue}
                  onChange={(e) => {
                    setUpdatePointsValue(e.target.value);
                  }}
                />
                <div className="update-btn">
                  <button
                    className="update"
                    onClick={() => {
                      addClientPoints();
                    }}
                  >
                    {t("Add")}
                  </button>
                  <button
                    className="update"
                    onClick={() => {
                      deleteClientPoints();
                    }}
                  >
                    {t("Remove")}
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {vendorPoints ? (
        clientPoints.vendorPoints.length > 0 ? (
          clientPoints?.vendorPoints?.map((point, index) => {
            return (
              <div className="points-card" key={index}>
                <div className="data">
                  <h3>
                    <span>{point?.availablePoints + point?.usedPoints}</span>{" "}
                    {t("Point from")} {point?.vendor?.name?.[lang]}
                  </h3>
                  {isHidden ? (
                    <button
                      onClick={() => {
                        setIsHidden(!isHidden);
                      }}
                    >
                      {t("see less...")}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setIsHidden(!isHidden);
                      }}
                    >
                      {t("see more...")}
                    </button>
                  )}
                </div>
                {isHidden ? (
                  <div className="hidden-content">
                    <p>
                      {t("Avaliable Points")}{" "}
                      <span>{point?.availablePoints}</span>
                    </p>
                    <p>
                      {t("Used Points")} <span>{point?.usedPoints}</span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
        ) : (
          <p className="no-results">{t("No vendor points avaliable!")}</p>
        )
      ) : (
        ""
      )}
    </>
  );
}
