import Axios from "./Axios";

let InterestServices = {
  addInterest: async (obj) => {
    const response = await Axios.post(`/admin/interest/create`, obj);
    return response;
  },

  getInterest: async (id) => {
    const response = await Axios.get(`/admin/interest/get?_id=${id}`);
    return response;
  },

  editInterest: async (id, obj) => {
    const response = await Axios.put(`/admin/interest/update?_id=${id}`, obj);
    return response;
  },

  deleteInterest: async (id) => {
    const response = await Axios.delete(`/admin/interest/remove?_id=${id}`);
    return response;
  },

  listAllInterests: async (lang = "", search = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/interest/list?${search.length > 0 ? `&name.${lang}=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },
};

export default InterestServices;
