import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import WishlistServices from '../../../../services/WishlistServices';
import MainTable from '../../../../Components/MainTable/MainTable';
import './VendorWishlists.scss'

export default function VendorWishlists() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams()

  const [Wishlists, setWishlists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalWishlists, setTotalWishlists] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllVendorWishlists() {
    try {
      const { data } = await WishlistServices.listAllVendorWishlists(params.id, currentPage);
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && !record?.client?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && !record?.client?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?.client?._id,
          "Client name": clientName,
          "Number of products": record?.items?.length,
        }
      });

      setWishlists(wishlist => {
        return [...wishlist, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalWishlists(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleWishlistRowClick(id) {
    navigate(`/wishlists/${id}`);
  }

  useEffect(() => {
    getAllVendorWishlists();
  }, [currentPage]);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          {Wishlists.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handleWishlistRowClick} tableRows={Wishlists} />
          )}
          {
            Wishlists.length !== totalWishlists &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
