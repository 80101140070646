import Axios from "./Axios";

let AgentServices = {
  addAgent: async (obj) => {
    const response = await Axios.post(`/admin/agent/create`, obj);
    return response;
  },

  getAgent: async (id) => {
    const response = await Axios.get(`/admin/agent/get?_id=${id}`);
    return response;
  },

  getAgentTree: async (id) => {
    const response = await Axios.get(`/admin/agent/count?_id=${id}`);
    return response;
  },

  uploadAgentImage: async (id, obj) => {
    const response = await Axios.post(`/admin/agent/image?_id=${id}`, obj);
    return response;
  },

  deleteAgentImage: async (id) => {
    const response = await Axios.delete(`/admin/agent/image?_id=${id}`);
    return response;
  },

  editAgent: async (id, obj) => {
    const response = await Axios.put(`/admin/agent/update?_id=${id}`, obj);
    return response;
  },

  changePassword: async (obj) => {
    const response = await Axios.put(`/admin/agent/resetPassword`, obj);
    return response;
  },

  deleteAgent: async (id) => {
    const response = await Axios.delete(`/admin/agent/remove?_id=${id}`);
    return response;
  },

  listAllActiveAgents: async (lang = "", search = "", level, city, cityLang, page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/agent/list?${search.length > 0 ? `&name.${lang}=${search}` : ""
      }${level ? `&level=${level}` : ""}${city ? `&city.${cityLang}=${city}` : ""}&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted?.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listAllAgents: async (sortLang) => {
    const response = await Axios.get(
      `/admin/agent/list?isActive=true&alpha=${sortLang}`
    );
    return response;
  },

  listAllInActiveAgents: async (lang = "", search = "", level, city, cityLang, page, limit = 25, isSorted, sortLang) => {
    const response = await Axios.get(
      `/admin/agent/list?${search.length > 0 ? `&name.${lang}=${search}` : ""
      }${level ? `&level=${level}` : ""}${city ? `&city.${cityLang}=${city}` : ""}&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  addToNetwork: async (parent, child) => {
    const response = await Axios.patch(
      `/admin/agent/addToNetwork?parent=${parent}&child=${child}`
    );
    return response;
  },

  removeFromNetwork: async (parent, child) => {
    const response = await Axios.patch(
      `/admin/agent/removeFromNetwork?parent=${parent}&child=${child}`
    );
    return response;
  },

  changeParent: async (oldParent, newParent, child) => {
    const response = await Axios.patch(
      `/admin/agent/changeParent?oldParent=${oldParent}&newParent=${newParent}&child=${child}`
    );
    return response;
  },
};

export default AgentServices;
