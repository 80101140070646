import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import toastPopup from '../../../helpers/toastPopup';
import OrganizationServices from '../../../services/OrganizationServices';
import MainInput from '../../../Components/MainInput/index'
import i18n from '../../../locales/i18n';
import RequestServices from '../../../services/RequestServices';
import './AddOrganization.scss'

export default function AddOrganization() {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const lang = i18n.language
  const params = useParams()

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isActive, setIsActive] = useState(false)
  const [uploadImage, setUploadImage] = useState(null);
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([])
  const [categoryID, setCategoryID] = useState(0)

  const [newOrganization, setNewOrganization] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    password: "",
    phone: "",
    credit: ""
  });

  async function getRequest() {
    setLoading(true)
    try {
      const { data } = await await RequestServices.getRequest(params.request_Id)
      setLoading(false);
      setNewOrganization({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        email: data?.record[0]?.email,
        password: data?.record[0]?.password,
        phone: data?.record[0]?.phone,
        credit: data?.record[0]?.credit
      });
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function resetInputs() {
    setNewOrganization({
      name_en: "",
      name_ar: "",
      email: "",
      password: "",
      phone: "",
      credit: ""
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "email", type: "email", required: true },
    { name: "password", type: "password", required: true },
    { name: "phone", type: "text", required: false },
    { name: "credit", type: "number", required: false }
  ];

  async function getOrganizationCategory() {
    setLoading(true)
    try {
      const { data } = await OrganizationServices.getOrganizationCategories('org')
      setLoading(false);
      setCategories(data.records)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function addNewOrganization(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newOrganization.name_en,
          ar: newOrganization.name_ar
        },
        email: newOrganization.email,
        password: newOrganization.password,
        phone: newOrganization.phone,
        credit: parseFloat(newOrganization.credit),
        category: categoryID,
        isActive: isActive
      }

      function isEmpty(value) {
        return (
          typeof value === 'string' && value.trim() === '' ||
          value === undefined ||
          value === null
        );
      }

      if (isEmpty(obj.name.en)) {
        delete obj.name.en;
      }

      if (isEmpty(obj.name.ar)) {
        delete obj.name.ar;
      }

      if (isEmpty(obj.credit) || newOrganization.credit === "" || newOrganization.credit === undefined) {
        delete obj.credit;
      }

      const finalObj = {};

      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }

      const { data } = await OrganizationServices.addOrganization(finalObj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      let organizationID = data.record._id
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true)
      try {
        const { data } = await OrganizationServices.uploadOrganizationImage(organizationID, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/organizations/${data.record._id}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  async function addNewOrganizationFromRequest(e) {
    e.preventDefault();

    setLoading(true);
    try {
      const obj = {
        name: {
          en: newOrganization.name_en,
          ar: newOrganization.name_ar
        },
        email: newOrganization.email,
        password: newOrganization.password,
        phone: newOrganization.phone,
        credit: parseFloat(newOrganization.credit),
        category: categoryID,
        isActive: isActive
      }

      function isEmpty(value) {
        return (
          typeof value === 'string' && value.trim() === '' ||
          value === undefined ||
          value === null
        );
      }

      if (isEmpty(obj.name.en)) {
        delete obj.name.en;
      }

      if (isEmpty(obj.name.ar)) {
        delete obj.name.ar;
      }

      if (isEmpty(obj.credit) || newOrganization.credit === "" || newOrganization.credit === undefined) {
        delete obj.credit;
      }

      const finalObj = {};

      for (const [key, value] of Object.entries(obj)) {
        if (value !== "") {
          finalObj[key] = value;
        }
      }

      const { data } = await OrganizationServices.addOrganization(finalObj)
      setLoading(false);
      resetInputs()
      let organizationID = data.record._id
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true)
      try {
        const { data } = await OrganizationServices.uploadOrganizationImage(organizationID, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      try {
        const { data } = await RequestServices.editRequest(params?.request_Id, {
          status: "accepted",
        });
        setLoading(false);
        toastPopup.success(t("Request Accepted"));
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      toastPopup.success(t("Added"));
      navigate(`/organizations/${data.record._id}`)
    }
    catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  useEffect(() => {
    getOrganizationCategory()
  }, [])

  useEffect(() => {
    if (params?.request_Id) {
      getRequest();
    }
  }, [params?.request_Id]);


  return <>

    <div className="add-client">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/organizations') }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Organization")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="main-image-label vendor-image">
        {uploadImage && (
          <img
            src={typeof uploadImage === "object" ? URL.createObjectURL(uploadImage) : uploadImage ? uploadImage : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadImage ? URL.createObjectURL(uploadImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={ref}
          onChange={(e) => {
            setUploadImage(e.target.files[0]);
          }}
        />
        <label
          className="main-label-image"
          onClick={imageUploader}
          htmlFor="upload-img"
        >
          {t("Add Image")}
        </label>
      </div>
      <form className='form-grid' onSubmit={(params.request_Id) ? addNewOrganizationFromRequest : addNewOrganization}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newOrganization}
                setState={setNewOrganization}
              />
            );
          })}

          <div className="main-input-select">
            <select
              onChange={(e) => { setCategoryID(e.target.value) }}
              className='main-input'
              id="Category"
              name="Category"
              title='Category'
              value={categoryID}>
              <option defaultValue='category'>-- {t("Category")} --</option>
              {categories.map((cat, index) => {
                return (
                  <option key={index}
                    value={cat._id}>{((cat?.name?.[lang]).charAt(0)).toUpperCase() + (cat?.name?.[lang]).slice(1)}
                  </option>
                )
              })}
            </select>
            <label className='main-label'>{t("Category")}</label>
          </div>

          <div className='toggler'>
            <label
              htmlFor="productImage"
              className="word">
              {t("Is Active")}
            </label>
            <label className="switch form-group">
              <input
                checked={isActive}
                onChange={(e) => { setIsActive(e.target.checked) }}
                type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>

        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate('/organizations') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Organization")
            )}
          </button>
        </div>
      </form>
    </div>

  </>
}
