import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MainTable from '../../../../Components/MainTable/MainTable';
import toastPopup from '../../../../helpers/toastPopup';
import i18n from '../../../../locales/i18n';
import AgentServices from '../../../../services/AgentServices';
import './SubAgents.scss'

export default function SubAgents(props) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams()
  const lang = i18n.language

  const [modalShow, setModalShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({})
  const [subAgent, setSubAgent] = useState([])
  const [isActiveAgent, setIsActiveAgent] = useState(false);

  function getSubAgents() {
    setIsActiveAgent(props?.agent?.isActive)
    let tableData = props?.agent?.children?.map((child, index) => {
      let agentName = ""
      if (localStorage.getItem("i18nextLng") === "en" && child?.name?.en) {
        agentName = child?.name?.en
      } else if (localStorage.getItem("i18nextLng") === "en" && !child?.name?.en && child?.name?.ar) {
        agentName = child?.name?.ar
      } else if (localStorage.getItem("i18nextLng") === "ar" && child?.name?.ar) {
        agentName = child?.name?.ar
      } else if (localStorage.getItem("i18nextLng") === "ar" && !child?.name?.ar && child?.name?.en) {
        agentName = child?.name?.en
      } else if (localStorage.getItem("i18nextLng") === "en" && !child?.name?.en && !child?.name?.ar) {
        agentName = "No name"
      } else if (localStorage.getItem("i18nextLng") === "ar" && !child?.name?.ar && !child?.name?.en) {
        agentName = "لا يوجد أسم"
      }
      return {
        Num: <div className='index'>{index + 1}</div>,
        id: child._id,
        Name: agentName,
        "Sub Agents": child?.children?.length,
        Level: child?.level,
        Credit: child?.credit,
        Chat: <button onClick={(e) => {
          e.stopPropagation()
          navigate(`/messages/${child._id}`, {
            state: 'agent'
          })
        }} className='chat-with'><i className="fa-solid fa-message"></i></button>,
        Remove: <button onClick={(e) => {
          e.stopPropagation()
          setModalShow(true)
          setCurrentUser(child)
        }} className='remove'><i className="fa-solid fa-trash"></i></button>,
      }
    })

    setSubAgent(tableData)
  }

  async function deleteSubAgent(id) {
    setLoading(true)
    try {
      const { data } = await AgentServices.deleteAgent(id)
      setLoading(false);
      setModalShow(false)
      getSubAgents()
      window.location.reload();
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function AddSubAgentToAgentHandler() {
    navigate(`/agents/${params.id}/add-sub-agent`)
  }

  function handleSubAgentRowClick(id) {
    props.getAgent(id)
  }

  useEffect(() => {
    getSubAgents()
  }, [])

  return <>
    {modalShow && <div className={modalShow ? "overlay" : "overlay-remove-cancel"} id='overlay-remove'>
      <div className="add-admin-box">
        <h3>{t("Are you sure you want to delete ")}</h3>
        <p className='admin-name'>{(currentUser?.name?.[lang])} {t("?")}</p>
        <div className="modal-buttons">
          <div
            onClick={() => setModalShow(false)}
            className='cancel-remove'>{t("Cancel")}
          </div>
          <div
            onClick={() => deleteSubAgent(currentUser._id)}
            className='delete'>{t("Delete")}
          </div>
        </div>
      </div>
      <div
        className='cancel'
        onClick={() => setModalShow(false)}>
        <i className="fa-solid fa-xmark">
        </i>
      </div>
    </div>}

    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}

    {isActiveAgent && <div className='add-branch'>
      <div className="button">
        <button
          className="main-button-add-sub-agent"
          onClick={AddSubAgentToAgentHandler}>
          {t("Add Sub Agent")}
        </button>
      </div>
    </div>}

    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      (<div>
        {props?.agent?.children?.length === 0 ?
          <p className='no-results'>{t("No results found!")}</p>
          : <MainTable onRowClick={handleSubAgentRowClick} tableRows={subAgent} />}
      </div>)}


  </>
}
