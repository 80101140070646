import React, { useState } from "react";
import MainInput from "../../../Components/MainInput";
import { useTranslation } from "react-i18next";
import toastPopup from "../../../helpers/toastPopup";
import { useNavigate } from "react-router-dom";
import CategoryServices from "../../../services/CategoryServices";
import { useRef } from "react";
import "./AddCategory.scss";

export default function AddCategory() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [newCategory, setNewCategory] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
  });

  function resetInputs() {
    setNewCategory({
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
    });
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "description_en", type: "text", required: true },
    { name: "description_ar", type: "text", required: true },
  ];

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  async function addNewCategory(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newCategory.name_en,
          ar: newCategory.name_ar,
        },
        description: {
          en: newCategory.description_en,
          ar: newCategory.description_ar,
        },
        type: type,
        isActive: isActive,
      };
      const { data } = await CategoryServices.addCategory(obj);

      setLoading(false);
      resetInputs();
      toastPopup.success(t("Added"));
      let categoryID = data.record._id;
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        const { data } = await CategoryServices.uploadCategoryImage(
          categoryID,
          formData
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/categories/${data.record._id}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  return (
    <>
      <div className="add-client">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate("/categories");
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Add Category")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>

        <div className="main-image-label">
          {uploadImage && (
            <img
              src={uploadImage ? URL.createObjectURL(uploadImage) : null}
              alt="imag-viewer"
              className="uploaded-img"
              onClick={() => {
                window.open(
                  uploadImage ? URL.createObjectURL(uploadImage) : null
                );
              }}
            />
          )}
          <input
            className="main-input-image"
            type="file"
            name="upload-img"
            ref={ref}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
          <label
            className="main-label-image"
            onClick={imageUploader}
            htmlFor="upload-img"
          >
            {t("Add Image")}
          </label>
        </div>

        <form className="form-grid" onSubmit={addNewCategory}>
          <div className="input-grid">
            <div className="main-input-select">
              <select
                onChange={(e) => {
                  setType(e.target.value);
                }}
                className="main-input"
                id="type"
                name="type"
                title="type"
              >
                <option value="">
                  -- {t("Type")} --
                </option>
                <option value={"vendor"}>{t("Vendor")}</option>
                <option value={"org"}>{t("Organization")}</option>
                <option value={"product"}>{t("Product")}</option>
                <option value={"job"}>{t("Job")}</option>
                <option value={"service"}>{t("Service")}</option>
              </select>
              <label className="main-label">{t("Type")}</label>
            </div>

            {formData.map((formInput, index) => {
              return (
                <MainInput
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newCategory}
                  setState={setNewCategory}
                />
              );
            })}

            <div className="toggler">
              <label htmlFor="productImage" className="word">
                {t("Is Active")}
              </label>
              <label className="switch form-group">
                <input
                  checked={isActive}
                  onChange={(e) => {
                    setIsActive(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="button-grid">
            <button
              className="main-button-admin"
              onClick={() => {
                resetInputs();
                navigate("/categories");
              }}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Cancel")
              )}
            </button>
            <button className="main-button-admin">
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Add Category")
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
