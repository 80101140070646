import React from "react";
import { useTranslation } from "react-i18next";

import "./TableHead.scss";
export default function TableHead({ tableRows = [] }) {
  const { t } = useTranslation();

  let tableHeaders = tableRows.length > 0 ? Object.keys(tableRows[0]) : [];

  return (
    <thead className="table-header">
      <tr>
        {tableHeaders.length > 0 &&
          tableHeaders.map((tableHeader) => {
            if (tableHeader === "id") {
              return
            }
            return (
              <th key={tableHeader} className="table-header-data">
                {t(tableHeader)}
              </th>
            );
          })}
      </tr>
    </thead>
  );
}
