// Function to move an item to the top of the array
export function moveToTop(index, arr, setNewArr) {
  let items = [...arr];

  if (index > 0) {
    items.splice(0, 0, items.splice(index, 1)[0]);
  }

  setNewArr(items);
}

// Function to move an item one position up in the array

export function moveOneUp(index, arr, setNewArr) {
  let items = [...arr];

  if (index > 0) {
    const item = items.splice(index, 1)[0];
    items.splice(index - 1, 0, item);
  }

  setNewArr(items);
}

// Function to move an item one position down in the array
export function moveOneDown(index, arr, setNewArr) {
  let items = [...arr];
  if (index < items.length - 1) {
    const item = items.splice(index, 1)[0];
    items.splice(index + 1, 0, item);
  }
  setNewArr(items);
}

// Function to move an item to the end of the array
export function moveToEnd(index, arr, setNewArr) {
  let items = [...arr];
  if (index < items.length - 1) {
    items.push(items.splice(index, 1)[0]);
  }
  setNewArr(items);
}
