import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreditRequestServices from '../../../../../services/CreditRequestServices';
import toastPopup from '../../../../../helpers/toastPopup';
import InfoInput from '../../../../../Components/InfoInput/InfoInput';
import './PendingCreditRequestDetails.scss'
import { socket } from '../../../../../services/socket';

export default function PendingCreditRequestDetails() {

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();

  const [creditRequest, setCreditRequest] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getCreditRequest() {
    setLoading(true);
    try {
      const { data } = await CreditRequestServices.getCreditRequests(params?.reqId);
      setLoading(false);
      setCreditRequest(data?.record);
      setActive(true);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let agentName = ""
  if (localStorage.getItem("i18nextLng") === "en" && creditRequest?.from?.name?.en) {
    agentName = creditRequest?.from?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !creditRequest?.from?.name?.en && creditRequest?.from?.name?.ar) {
    agentName = creditRequest?.from?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && creditRequest?.from?.name?.ar) {
    agentName = creditRequest?.from?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !creditRequest?.from?.name?.ar && creditRequest?.from?.name?.en) {
    agentName = creditRequest?.from?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !creditRequest?.from?.name?.en && !creditRequest?.from?.name?.ar) {
    agentName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !creditRequest?.from?.name?.ar && !creditRequest?.from?.name?.en) {
    agentName = "لا يوجد أسم"
  }

  let creditRequestDetails = [
    { id: "name", title: "Name", type: "text", value: agentName },
    { id: "Status", title: "Status", type: "text", value: creditRequest?.status, },
    { id: "timestamp", title: "Timestamp", type: "text", value: formatDate(new Date(creditRequest?.timestamp)) },
    { id: "type", title: "Type", type: "text", value: creditRequest?.type },
  ];

  async function onApprove() {
    if (creditRequest?.type === 'request') {
      try {
        const { data } = await CreditRequestServices.acceptToSendCredit(params?.reqId);
        setLoading(false);
        try {
          const { data } = await CreditRequestServices.editCreditRequests(params?.reqId, {
            status: "accepted",
          });
          setLoading(false);
          toastPopup.success(t("Credit Request Accepted"));
          navigate(`/agents`);
        } catch (error) {
          setLoading(false);
          setErrorMessage(error);
        }
        socket.emit(
          "creditRequesUpdate",
          {
            requestId: params?.reqId,
            receiverId: creditRequest?.from?._id,
          },
          (res) => {
            console.log(res);
          }
        );
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
    } else if (creditRequest?.type === 'return') {
      try {
        const { data } = await CreditRequestServices.acceptToWithdrawCredit(params?.reqId);
        setLoading(false);
        try {
          const { data } = await CreditRequestServices.editCreditRequests(params?.reqId, {
            status: "accepted",
          });
          setLoading(false);
          toastPopup.success(t("Credit Request Accepted"));
          navigate(`/agents`);
        } catch (error) {
          setLoading(false);
          setErrorMessage(error);
        }
        socket.emit(
          "creditRequesUpdate",
          {
            requestId: params?.reqId,
            receiverId: creditRequest?.from?._id,
          },
          (res) => {
            console.log(res);
          }
        );
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
    }
  }

  async function onDecline() {
    try {
      const { data } = await CreditRequestServices.editCreditRequests(params?.reqId, {
        status: "rejected",
      });
      setLoading(false);
      toastPopup.success(t("Credit Request Rejected"));
      navigate(`/agents`);
      socket.emit(
        "creditRequesUpdate",
        {
          requestId: params?.reqId,
          receiverId: creditRequest?.from?._id,
        },
        (res) => {
          console.log(res);
        }
      );
    } catch (error) {
      setLoading(false);
      setErrorMessage(error);
    }
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getCreditRequest()
  }, [])

  useEffect(() => {
    socket.on("connection", () => {
      console.log("Connected to server");
    });
    socket.connect();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        ""
      )}

      {active && (
        <div className="main-details">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/agents");
              }}
            ></i>
          </div>
          <div className="client-image vendor-image-details">
            {creditRequest?.from?.image ?
              <img src={creditRequest?.from?.image?.Location} alt="Banner Image" />
              :
              <img src='https://clipart-library.com/images/rijrBGj8T.jpg' alt="Banner Image" />
            }
          </div>
          <div className="client-datails">
            <div className="actions">
              <button
                className="approve"
                disabled={loading}
                onClick={onApprove}
              >
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  t("Approve")
                )}
              </button>

              <button
                className="decline"
                disabled={loading}
                onClick={onDecline}
              >
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  t("Decline")
                )}
              </button>
            </div>

            <div className="data">
              {creditRequestDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
