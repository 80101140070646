import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AllNotification from "./AllNotification/AllNotification";
import "./Notification.scss";
import { useSelector } from "react-redux";

export default function Notification() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { notification: notificationPermissions } = frontPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [allNotification, setAllNotification] = useState(true);
  const [activeNotification, setActiveNotification] = useState(false);

  return (
    <>
      {allNotification ? (
        <div className="branch-cards-container">
          <AllNotification text="list" />
        </div>
      ) : (
        ""
      )}

      {activeNotification ? (
        <div className="branch-cards-container">
          <AllNotification text="active" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
