import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Large from "./Large/Large";
import Medium from "./Medium/Medium";
import Small from "./Small/Small";
import "./Banner.scss";
import { useSelector } from "react-redux";

export default function Banner() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { banner: bannerPermissions } = frontPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [largeBanner, setLargeBanner] = useState(true);
  const [mediumBanner, setMediumBanner] = useState(false);
  const [smallBanner, setSmallBanner] = useState(false);

  function toggleLarge() {
    setLargeBanner(true);
    setMediumBanner(false);
    setSmallBanner(false);
  }

  function toggleMedium() {
    setLargeBanner(false);
    setMediumBanner(true);
    setSmallBanner(false);
  }

  function toggleSmall() {
    setLargeBanner(false);
    setMediumBanner(false);
    setSmallBanner(true);
  }

  return (
    <>
      <div className="main-details">
        <div className="user">
          {largeBanner ? (
            <div className="active-btn">
              <button
                onClick={() => {
                  toggleLarge();
                }}
                className="all-branches-btn"
              >
                <h2>{t("Large")}</h2>
              </button>
            </div>
          ) : (
            <div className="all-branches">
              <button
                onClick={() => {
                  toggleLarge();
                }}
                className="all-branches-btn"
              >
                <h2>{t("Large")}</h2>
              </button>
            </div>
          )}
          {mediumBanner ? (
            <div className="active-btn">
              <button
                onClick={() => {
                  toggleMedium();
                }}
                className="all-branches-btn"
              >
                <h2>{t("Medium")}</h2>
              </button>
            </div>
          ) : (
            <div className="all-branches">
              <button
                onClick={() => {
                  toggleMedium();
                }}
                className="all-branches-btn"
              >
                <h2>{t("Medium")}</h2>
              </button>
            </div>
          )}
          {smallBanner ? (
            <div className="active-btn">
              <button
                onClick={() => {
                  toggleSmall();
                }}
                className="all-branches-btn"
              >
                <h2>{t("Small")}</h2>
              </button>
            </div>
          ) : (
            <div className="all-branches">
              <button
                onClick={() => {
                  toggleSmall();
                }}
                className="all-branches-btn"
              >
                <h2>{t("Small")}</h2>
              </button>
            </div>
          )}
        </div>
        {bannerPermissions.canCreate && (
          <div className="add-branch">
            <div className="button">
              <button
                className="main-button-add"
                onClick={() => {
                  navigate(`/banners/addbanner`);
                }}
              >
                {t("Add Banner")}
              </button>
            </div>
          </div>
        )}

        {largeBanner ? (
          <div className="wishlist-cards">
            <Large />
          </div>
        ) : (
          ""
        )}

        {mediumBanner ? (
          <div className="wishlist-cards">
            <Medium />
          </div>
        ) : (
          ""
        )}

        {smallBanner ? (
          <div className="wishlist-cards">
            <Small />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
