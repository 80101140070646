import COUNTRIES_CITIES from '../../../../locales/app-countries.json'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MainInput from '../../../../Components/MainInput';
import toastPopup from '../../../../helpers/toastPopup';
import BannerServices from '../../../../services/BannerServices';
import ClientServices from '../../../../services/ClientServices';
import i18n from "../../../../locales/i18n.js";
import Multiselect from "multiselect-react-dropdown";
import './PopUpEdit.scss'

export default function PopUpEdit() {

  let params = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadImage, setUploadImage] = useState('');
  const [count, setCount] = useState("");

  const [popUp, setPopUp] = useState({
    country_index: 0,
    ageFrom: 0,
    ageTo: 0,
    gender: "",
    name: "",
    link: "",
    startDate: "",
    endDate: "",
    isActive: false,
    isPopUp: true
  });

  const [newPopUp, setNewPopUp] = useState({
    country_index: 0,
    ageFrom: 0,
    ageTo: 0,
    gender: "",
    name: "",
    link: "",
    startDate: "",
    endDate: "",
    isActive: false,
    isPopUp: true
  });

  const [selectedCities, setSelectedCities] = useState([]);

  let formData = [
    { name: "name", type: "text", required: false },
    { name: "link", type: "text", required: false },
    { name: "startDate", type: "Date", required: false },
    { name: "endDate", type: "Date", required: false },
    { name: "ageFrom", type: "number", required: false },
    { name: "ageTo", type: "number", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {}

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = { ...finalEditiedData, [oldDataKey]: newData[oldDataKey] }
      }
    })
    return finalEditiedData
  }

  async function getPopUp() {
    setLoading(true)
    try {
      const { data } = await BannerServices.getBanner(params.id)
      setLoading(false);
      let StartDate = (data.record[0].startDate).indexOf('T');
      let EndtDate = (data.record[0].endDate).indexOf('T');
      setPopUp({
        country_index: data?.record[0]?.country?.index,
        city_index: data?.record[0]?.city?.index,
        ageFrom: data?.record[0]?.age?.from,
        ageTo: data?.record[0]?.age?.to,
        gender: data?.record[0]?.gender,
        name: data?.record[0]?.name,
        link: data?.record[0]?.link,
        startDate: (data?.record[0]?.startDate).slice(0, StartDate),
        endDate: (data?.record[0]?.endDate).slice(0, EndtDate),
        isActive: data?.record[0]?.isActive,
        isPopUp: data?.record[0]?.isPopUp
      })

      setNewPopUp({
        country_index: data?.record[0]?.country?.index,
        city_index: data?.record[0]?.city?.index,
        ageFrom: data?.record[0]?.age?.from,
        ageTo: data?.record[0]?.age?.to,
        gender: data?.record[0]?.gender,
        name: data?.record[0]?.name,
        link: data?.record[0]?.link,
        startDate: (data?.record[0]?.startDate).slice(0, StartDate),
        endDate: (data?.record[0]?.endDate).slice(0, EndtDate),
        isActive: data?.record[0]?.isActive,
        isPopUp: data?.record[0]?.isPopUp
      })

      setSelectedCities(data?.record[0]?.city.map((city) => {
        return {
          index: city?.index,
          name: city?.[lang]
        }
      }))

      setUploadImage(data?.record[0]?.image?.Location)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editPopUp(e) {
    e.preventDefault()
    setLoading(true);

    let editedData = {};

    Object.keys(checkUpdatedFields(newPopUp, popUp)).forEach((key) => {
      if (key === "country_index") {
        editedData = {
          ...editedData,
          country: {
            en: selectedCountry?.en,
            ar: selectedCountry?.ar,
            index: newPopUp?.country_index,
          },
        };
      } else if (key === "city_index") {
        editedData = {
          ...editedData,
          city: {
            en: selectedCity?.en,
            ar: selectedCity?.ar,
            index: newPopUp?.city_index,
          },
        };
      } else if (key === "ageFrom" || key === "ageTo") {
        editedData = {
          ...editedData,
          age: { from: newPopUp["ageFrom"], to: newPopUp["ageTo"] }
        }
      } else {
        editedData = {
          ...editedData,
          [key]: newPopUp[key]
        }
      }
    })

    function getFinalCities() {
      let finalCities = [];
      selectedCities.forEach((selectedCity) => {
        selectedCountry?.cities
          ?.filter((city) => city?.index === selectedCity?.index)
          ?.map((city) => {
            finalCities.push({
              en: city?.en,
              ar: city?.ar,
              index: city?.index
            });
          });
      });

      return finalCities;
    }

    editedData = { ...editedData, city: getFinalCities() }

    try {
      const { data } = await BannerServices.editBanner(params.id, editedData)
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true)
      try {
        const { data } = typeof uploadImage === "object" && await BannerServices.uploadBannerImage(params.id, formData)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/popups/${params.id}`)
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  async function countHandler() {
    setLoading(true);
    try {
      const { data } = await ClientServices.countClients(
        selectedCity?.en,
        newPopUp?.gender === "both" ? "" : newPopUp?.gender,
        newPopUp?.ageFrom,
        newPopUp?.ageTo
      );
      setLoading(false);
      setCount(data.count);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(newPopUp?.country_index))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(newPopUp?.city_index))[0]

  let citiesOptions = selectedCountry?.cities?.map((city) => {
    return ({
      name: city?.[lang],
      index: city?.index
    })
  })

  function isSelectedCity(city) {
    let selectedCitiesIndex = selectedCities.map((selectedCity) => selectedCity?.index)
    return selectedCitiesIndex.includes(city?.index)
  }

  function toggleSelectedCitiesHandler(city) {
    if (isSelectedCity(city)) {
      let oldSelectedCities = selectedCities
      let newSelectedCities = oldSelectedCities.filter((singleCity) => { return singleCity !== city })
      setSelectedCities(newSelectedCities)
    } else {
      setSelectedCities((prev) => {
        return [...prev, city]
      })
    }
  }

  useEffect(() => {
    getPopUp()
  }, [])

  return <>
    <div className="add-client">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate(`/popups/${params.id}`) }}></i>
      </div>
      <div className="header">
        <h3>{t("Edit Pop-Up Info")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="main-image-label vendor-image">
        {uploadImage && (
          <img
            src={typeof uploadImage === "object" ? URL.createObjectURL(uploadImage) : uploadImage ? uploadImage : "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadImage ? URL.createObjectURL(uploadImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={ref}
          onChange={(e) => {
            setUploadImage(e.target.files[0]);
          }}
        />
        <label
          className="main-label-image"
          onClick={imageUploader}
          htmlFor="upload-img"
        >
          {t("Add Image")}
        </label>
      </div>
      <form className='form-grid' onSubmit={editPopUp}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                className='input-edit-admin'
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newPopUp}
                setState={setNewPopUp}
              />
            );
          })}

          <div className="main-input-select">
            <select
              selected={newPopUp.gender}
              value={newPopUp.gender}
              onChange={(e) => { setNewPopUp((prev) => { return { ...prev, gender: e.target.value } }) }}
              className='main-input'
              id="gender"
              name="gender"
              title='gender'>
              <option value=''>-- {t("Gender")} --</option>
              <option value={"male"}>{t("Male")}</option>
              <option value={"female"}>{t("Female")}</option>
              <option value={"both"}>{t("Both")}</option>
            </select>
            <label className='main-label'>{t("Gender")}</label>
          </div>

          <div className="main-input-select">
            <select
              selected={newPopUp.country_index}
              value={newPopUp.country_index}
              onChange={(e) => {
                setNewPopUp((prev) => {
                  return { ...prev, country_index: e.target.value };
                });
              }}
              className='main-input'
              id="country"
              name="country"
              title='country'>
              <option value=''>{t("Country")}</option>
              {
                COUNTRIES_CITIES?.map((country, index) => {
                  return <option key={index} value={country?.index}>{country?.[lang]}</option>
                })
              }
            </select>
            <label className="main-label">{t("Country")}</label>
          </div>

          <Multiselect
            displayValue="name"
            selectedValues={selectedCities}
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedCitiesHandler(selectedItem)
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedCitiesHandler(selectedItem)
            }}
            options={citiesOptions}
            showCheckbox
            placeholder={t("Cities")}
          />

          <div className='toggler'>
            <label htmlFor="productImage" className="word">{t("Is Active")}</label>
            <label className="switch form-group">
              <input checked={newPopUp.isActive} onChange={(e) => { setNewPopUp((prev) => { return { ...prev, isActive: e.target.checked } }) }} type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            type='button'
            onClick={countHandler}>
            Count {loading ? <i className="fas fa-spinner fa-spin"></i> : count}
          </button>
          <button className="main-button-admin" disabled={loading}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Submit")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
