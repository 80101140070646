import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import AgentServices from "../../../services/AgentServices";
import SubAgents from "./SubAgents/SubAgents";
import Clients from "./Clients/Clients";
import { useSelector } from "react-redux";
import toastPopup from "../../../helpers/toastPopup";
import i18n from "../../../locales/i18n";
import "./AgentDetails.scss";

export default function AgentDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { agent: agentPermissions } = frontPermissions;

  let { canEdit: canEditAgent } = agentPermissions;

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [agent, setAgent] = useState({});
  const [agentTree, setAgentTree] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [allSubAgents, setAllSubAgents] = useState(true);
  const [allClients, setAllClients] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  async function getAgent(id = params.id) {
    setLoading(true);
    try {
      const { data } = await AgentServices.getAgent(id);
      setLoading(false);
      setAgent(data.record[0]);

      setActive(true);
      if (data.record[0].image) {
        setImage(true);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }


  async function getAgentTree(id = params.id) {
    setLoading(true);
    try {
      const { data } = await AgentServices.getAgentTree(id);
      setLoading(false);
      setAgentTree(data?.count);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let agentName = ""
  if (localStorage.getItem("i18nextLng") === "en" && agent?.name?.en) {
    agentName = agent?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !agent?.name?.en && agent?.name?.ar) {
    agentName = agent?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && agent?.name?.ar) {
    agentName = agent?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !agent?.name?.ar && agent?.name?.en) {
    agentName = agent?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !agent?.name?.en && !agent?.name?.ar) {
    agentName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !agent?.name?.ar && !agent?.name?.en) {
    agentName = "لا يوجد أسم"
  }

  let agentDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: agentName,
    },
    { id: "email", title: "Email", type: "email", value: agent?.email },
    { id: "phone", title: "Phone", type: "text", value: agent?.phone },
    { id: "gender", title: "Gender", type: "text", value: agent?.gender },
    {
      id: "join_date",
      title: "Join Date",
      type: "text",
      value: formatDate(new Date(agent?.joinDate)),
    },
    {
      id: "location_long",
      title: "Location Longitude",
      type: "text",
      value: agent?.location?.coordinates?.[0],
    },
    {
      id: "location_lat",
      title: "Location Latitude",
      type: "text",
      value: agent?.location?.coordinates?.[1],
    },
    { id: "address", title: "Address", type: "text", value: agent?.address },
    { id: "credit", title: "Credit", type: "number", value: agent?.credit },
    { id: "level", title: "Level", type: "number", value: agent?.level },
    {
      id: "children",
      title: "Children",
      type: "number",
      value: agent?.children?.length,
    },
    {
      id: "number_of_agent_tree",
      title: "Number of agent's tree",
      type: "text",
      value: agentTree,
    },
  ];

  let englishCountryCity = [
    {
      id: "country_en",
      title: "English Country",
      type: "text",
      value: agent?.country?.en,
    },
    {
      id: "city_en",
      title: "English City",
      type: "text",
      value: agent?.city?.en,
    },
  ];

  let arabicCountryCity = [
    {
      id: "country_ar",
      title: "Arabic Country",
      type: "text",
      value: agent?.country?.ar,
    },
    {
      id: "city_ar",
      title: "Arabic City",
      type: "text",
      value: agent?.city?.ar,
    },
  ];

  async function deleteAgentImage() {
    setLoading(true);
    try {
      const { data } = await AgentServices.deleteAgentImage(params.id);
      setLoading(false);
      setModalShow(false);
      getAgent()
      setImage(false)
      toastPopup.success(t("Image deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getAgent();
    getAgentTree();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete this image?")}</h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteAgentImage()}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate(`/agents`);
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {image ? (
                <>
                  <img src={agent?.image?.Location} alt="Agent Image" />
                  <div className="delete-image" onClick={() => { setModalShow(true); }}>
                    <i className="fa-solid fa-trash"></i>
                  </div>
                </>
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Agent Image"
                />
              )}
            </div>
            <div className="client-datails">
              {canEditAgent && (
                <div className="edit">
                  <button onClick={() => navigate(`/agents/${params.id}/edit`)}>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                </div>
              )}
              <div className="data">
                {agentDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}

                {localStorage.getItem("lang") === "en"
                  ? englishCountryCity.map((item, index) => {
                    return (
                      <InfoInput
                        key={index}
                        id={item.id}
                        title={item.title}
                        type={item.type}
                        value={item.value}
                      />
                    );
                  })
                  : arabicCountryCity.map((item, index) => {
                    return (
                      <InfoInput
                        key={index}
                        id={item.id}
                        title={item.title}
                        type={item.type}
                        value={item.value}
                      />
                    );
                  })}

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Active")}
                  </label>
                  <label className="switch form-group">
                    <input checked={agent?.isActive} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="toggler">
                  <label htmlFor="productImage" className="word">
                    {t("Can give credit")}
                  </label>
                  <label className="switch form-group">
                    <input checked={agent?.canGiveCashBack} type="checkbox" readOnly />
                    <span className="slider round"></span>
                  </label>
                </div>

                {agent.level > 1 && <div className="view-parent">
                  <p>{t("Parent")}:</p>
                  <p className="parent">{agent?.parent?.name?.[lang]}</p>
                  <button
                    onClick={() => { getAgent(agent?.parent?._id); }}>{t("View Parent")}</button>
                </div>}
              </div>
            </div>
            {canEditAgent && (
              <>
                <div className="user">
                  {allSubAgents ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          setAllSubAgents(true);
                          setAllClients(false);
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Sub Agents")}</h2>
                      </button>
                    </div>
                  ) : (
                    <div className="all-branches">
                      <button
                        onClick={() => {
                          setAllSubAgents(true);
                          setAllClients(false);
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Sub Agents")}</h2>
                      </button>
                    </div>
                  )}
                  {allClients ? (
                    <div className="active-btn">
                      <button
                        onClick={() => {
                          setAllSubAgents(false);
                          setAllClients(true);
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Clients")}</h2>
                      </button>
                    </div>
                  ) : (
                    <div className="all-branches">
                      <button
                        onClick={() => {
                          setAllSubAgents(false);
                          setAllClients(true);
                        }}
                        className="all-branches-btn"
                      >
                        <h2>{t("Clients")}</h2>
                      </button>
                    </div>
                  )}
                </div>

                {allSubAgents ? (
                  <div className="branch-cards-container">
                    <SubAgents agent={agent} getAgent={getAgent} />
                  </div>
                ) : (
                  ""
                )}

                {allClients ? (
                  <div className="branch-cards-container">
                    <Clients agentCredit={agent?.credit} agent={agent} />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        )
      )}
    </>
  );
}
