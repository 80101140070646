import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import RequestServices from "../../../services/RequestServices";
import toastPopup from "../../../helpers/toastPopup";
import "./RequestDetails.scss";

export default function RequestDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { join: joinPermissions } = frontPermissions;
  let { vendor: vendorPermissions } = frontPermissions;
  let { agent: agentPermissions } = frontPermissions;
  let { org: orgPermissions } = frontPermissions;

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();

  const [request, setRequest] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getRequest() {
    setLoading(true);
    try {
      const { data } = await RequestServices.getRequest(params.requestId);
      setLoading(false);
      setRequest(data.record[0]);
      setActive(true);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let vendorDetails = [];
  let agentDetails = [];
  let orgDetails = [];

  if (request?.type === "vendor") {
    vendorDetails = [
      { id: "name_en", title: "English Name", type: "text", value: request?.name?.en },
      { id: "name_ar", title: "Arabic Name", type: "text", value: request?.name?.ar },
      {
        id: "description_en",
        title: "English Description",
        type: "text",
        value: request?.description?.en,
      },
      {
        id: "description_ar",
        title: "Arabic Description",
        type: "text",
        value: request?.description?.ar,
      },
      { id: "email", title: "Email", type: "text", value: request?.email },
      { id: "phone", title: "Phone", type: "text", value: request?.phone },
      { id: "country_en", title: "English Country", type: "text", value: request?.country?.en },
      { id: "country_ar", title: "Arabic Country", type: "text", value: request?.country?.ar },
      { id: "city_en", title: "English City", type: "text", value: request?.city?.en },
      { id: "city_ar", title: "Arabic City", type: "text", value: request?.city?.ar },
      { id: "type", title: "Type", type: "text", value: request?.type },
      { id: "timestamp", title: "Request Date", type: "text", value: formatDate(new Date(request?.timestamp)) },
      { id: "status", title: "Status", type: "text", value: request?.status },
    ];
  }

  if (request?.type === "agent") {
    agentDetails = [
      { id: "name_en", title: "English Name", type: "text", value: request?.name?.en },
      { id: "name_ar", title: "Arabic Name", type: "text", value: request?.name?.ar },
      { id: "email", title: "Email", type: "text", value: request?.email },
      { id: "phone", title: "Phone", type: "text", value: request?.phone },
      { id: "country_en", title: "English Country", type: "text", value: request?.country?.en },
      { id: "country_ar", title: "Arabic Country", type: "text", value: request?.country?.ar },
      { id: "city_en", title: "English City", type: "text", value: request?.city?.en },
      { id: "city_ar", title: "Arabic City", type: "text", value: request?.city?.ar },
      { id: "type", title: "Type", type: "text", value: request?.type },
      { id: "timestamp", title: "Request Date", type: "text", value: formatDate(new Date(request?.timestamp)) },
      { id: "status", title: "Status", type: "text", value: request?.status },
    ];
  }

  if (request?.type === "org") {
    orgDetails = [
      { id: "name_en", title: "English Name", type: "text", value: request?.name?.en },
      { id: "name_ar", title: "Arabic Name", type: "text", value: request?.name?.ar },
      { id: "email", title: "Email", type: "text", value: request?.email },
      { id: "phone", title: "Phone", type: "text", value: request?.phone },
      { id: "country_en", title: "English Country", type: "text", value: request?.country?.en },
      { id: "country_ar", title: "Arabic Country", type: "text", value: request?.country?.ar },
      { id: "city_en", title: "English City", type: "text", value: request?.city?.en },
      { id: "city_ar", title: "Arabic City", type: "text", value: request?.city?.ar },
      { id: "type", title: "Type", type: "text", value: request?.type },
      { id: "timestamp", title: "Request Date", type: "text", value: formatDate(new Date(request?.timestamp)) },
      { id: "status", title: "Status", type: "text", value: request?.status },
    ];
  }

  function onApproveVendor() {
    navigate(`/vendors/addvendor/${request?._id}`);
  }

  function onApproveAgent() {
    navigate(`/agents/addagent/${request?._id}`);
  }

  function onApproveOrg() {
    navigate(`/organizations/add-organization/${request?._id}`);
  }

  async function onDecline() {
    try {
      const { data } = await RequestServices.editRequest(request?._id, {
        status: "rejected",
      });
      setLoading(false);
      toastPopup.success(t("Request Rejected"));
      navigate(`/requests`)
    } catch (error) {
      setLoading(false);
      setErrorMessage(error);
    }
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getRequest();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        ""
      )}

      {request.type === "vendor" && active && (
        <div className="vendor-datails">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/requests");
              }}
            ></i>
          </div>

          <div className="client-datails">
            <div className="actions">
              {vendorPermissions.canCreate && (
                <>
                  {(request.status === 'pending' || request.status === 'rejected') && <button
                    className="approve"
                    disabled={loading}
                    onClick={onApproveVendor}
                  >
                    {loading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      t("Approve")
                    )}
                  </button>}
                  {request.status === 'pending' && <button className='decline' disabled={loading} onClick={onDecline}>
                    {loading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      t("Decline")
                    )}</button>}
                </>
              )}
            </div>

            <div className="data">
              {vendorDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {request.type === "agent" && active && (
        <div className="vendor-datails">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/requests");
              }}
            ></i>
          </div>

          <div className="client-datails">
            <div className="actions">
              {agentPermissions.canCreate && (
                <>
                  {(request.status === 'pending' || request.status === 'rejected') && <button
                    className="approve"
                    disabled={loading}
                    onClick={onApproveAgent}
                  >
                    {loading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      t("Approve")
                    )}
                  </button>}
                  {request.status === 'pending' && <button className='decline' disabled={loading} onClick={onDecline}>
                    {loading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      t("Decline")
                    )}</button>}
                </>
              )}
            </div>

            <div className="data">
              {agentDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {request.type === "org" && active && (
        <div className="vendor-datails">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/requests");
              }}
            ></i>
          </div>

          <div className="client-datails">
            {
              <div className="actions">
                {orgPermissions.canCreate && (
                  <>
                    {(request.status === 'pending' || request.status === 'rejected') && <button
                      className="approve"
                      disabled={loading}
                      onClick={onApproveOrg}
                    >
                      {loading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        t("Approve")
                      )}
                    </button>}
                    {request.status === 'pending' && <button className='decline' disabled={loading} onClick={onDecline}>
                      {loading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        t("Decline")
                      )}</button>}
                  </>
                )}
              </div>
            }
            <div className="data">
              {orgDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
