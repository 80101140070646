import Axios from "./Axios";

let WishlistServices = {
  getWishlist: async (id) => {
    const response = await Axios.get(`/admin/wishlist/get?client=${id}`);
    return response;
  },

  listAllWishlists: async (page, limit = 25) => {
    const response = await Axios.get(`/admin/wishlist/list?${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllVendorWishlists: async (id, page, limit = 25) => {
    const response = await Axios.get(`/admin/wishlist/list?items.vendor=${id}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default WishlistServices;
