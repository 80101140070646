import Axios from "./Axios";

let NotificationServices = {
  addNotification: async (obj) => {
    const response = await Axios.post(`/admin/notification/create`, obj);
    return response;
  },

  getNotification: async (id) => {
    const response = await Axios.get(`/admin/notification/get?_id=${id}`);
    return response;
  },

  uploadNotificationImage: async (id, obj) => {
    const response = await Axios.post(
      `/admin/notification/image?_id=${id}`,
      obj
    );
    return response;
  },

  editNotification: async (id, obj) => {
    const response = await Axios.put(
      `/admin/notification/update?_id=${id}`,
      obj
    );
    return response;
  },

  deleteNotification: async (id) => {
    const response = await Axios.delete(`/admin/notification/remove?_id=${id}`);
    return response;
  },

  listAllNotification: async (text = "list", search = "", page, limit = 25) => {
    const response = await Axios.get(`/admin/notification/${text}?${search.length > 0 ? `&text=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default NotificationServices;
