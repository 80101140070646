import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Routes from "../Routes/Routes";

export default function ProtectedRoute({ children }) {
  const auth = useSelector((state) => state.auth);

  if (auth.AdminToken) {
    return <Routes />;
  } else {
    return <Navigate to="/login" />;
  }
}
