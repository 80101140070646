import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Checked } from "../../../assets/SVG/dark-blue.svg";
import { ReactComponent as NotChecked } from "../../../assets/SVG/dark-blue-circle.svg";

import "./GrandPermissionsTableHead.scss";
export default function GrandPermissionsTableHead({
  permissionsObject = {},
  setPermissionsObject = () => { },
  viewOnly = false,
}) {
  const { t } = useTranslation();
  let tableHeaders = [t("moduleName"), t("permissions")];

  const modules = Object.keys(permissionsObject);

  function isTableChecked() {
    let modulesTruthyArray = [];
    modules.forEach((module) => {
      let tableRow = permissionsObject[module];
      modulesTruthyArray.push(isRowCheked(tableRow));
    });

    return modulesTruthyArray.every((element) => element === true);
  }

  function isRowCheked(tableRow) {
    let rowKeysTruthyArray = Object.values(tableRow);
    return rowKeysTruthyArray.every((element) => element === true);
  }

  function checkTableHandler(boolean) {
    let newPermissions = {};
    modules.forEach((module) => {
      let rowProperties = permissionsObject[module];
      let rowPropertiesArray = Object.keys(rowProperties);

      rowPropertiesArray.forEach((property) => {
        newPermissions = {
          ...newPermissions,
          [module]: { ...newPermissions[module], [property]: boolean },
        };
      });
    });

    setPermissionsObject({
      ...newPermissions,
    });
  }

  return (
    <thead className="table-header">
      <tr>
        <th className="table-header-data">
          <div className="circle-label">
            {isTableChecked() ? (
              <Checked
                className="permission-icon"
                onClick={
                  viewOnly
                    ? () => { }
                    : () => {
                      checkTableHandler(false);
                    }
                }
              />
            ) : (
              <NotChecked
                className="permission-icon"
                onClick={
                  viewOnly
                    ? () => { }
                    : () => {
                      checkTableHandler(true);
                    }
                }
              />
            )}
          </div>
        </th>
        {tableHeaders &&
          tableHeaders.map((tableHeader) => {
            return (
              <th key={tableHeader} className="table-header-data">
                {t(`${tableHeader}`)}
              </th>
            );
          })}
      </tr>
    </thead>
  );
}
