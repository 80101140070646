import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PagesServices from "../../services/PagesServices";
import i18n from "../../locales/i18n";
import toastPopup from "../../helpers/toastPopup";
import MainTable from "../../Components/MainTable/MainTable";
import "./FAQS.scss";
import { useSelector } from "react-redux";

export default function FAQS() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { page: pagePermissions } = frontPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [faqs, setFaqs] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  async function getAllFaqs() {
    setLoading(true);
    try {
      const { data } = await PagesServices.listAllFaqs();
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let returnedObject = pagePermissions.canDelete
          ? {
              Num: <div className="index">{index + 1}</div>,
              id: record?._id,
              Question: (record?.name?.[lang]).slice(0, 50),
              Remove: (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalShow(true);
                    setCurrentUser(record);
                  }}
                  className="remove"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              ),
            }
          : {
              Num: <div className="index">{index + 1}</div>,
              id: record?._id,
              Question: (record?.name?.[lang]).slice(0, 50),
            };

        return returnedObject;
      });
      setFaqs(tableData);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deletefaqs(id) {
    setLoading(true);
    try {
      const { data } = await PagesServices.deletePage(id);
      setLoading(false);
      setModalShow(false);
      getAllFaqs();
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleFaqsRowClick(id) {
    navigate(`/faqs/${id}`);
  }

  useEffect(() => {
    getAllFaqs();
  }, []);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>
              {t("Are you sure you want to delete")}
              {t("?")}
            </h3>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deletefaqs(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      <div className="add-notification">
        {pagePermissions.canCreate && (
          <button
            className="main-button-add"
            onClick={() => {
              navigate(`/faqs/add-faqs`);
            }}
          >
            {t("Add FAQS")}
          </button>
        )}
      </div>

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          {faqs?.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handleFaqsRowClick} tableRows={faqs} />
          )}
        </div>
      )}
    </>
  );
}
