import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import i18n from "../../locales/i18n";
import toastPopup from "../../helpers/toastPopup";
import { useTranslation } from "react-i18next";
import VendorServices from "../../services/VendorServices";
import MainTable from "../MainTable/MainTable";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import { useSelector } from "react-redux";
import "./BranchCard.scss";

export default function BranchCard() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { branch: branchPermissions } = frontPermissions;

  let {
    canCreate: canCreateBranch,
    canView: canViewBranch,
    canEdit: canEditBranch,
    canDelete: canDeleteBranch,
  } = branchPermissions;

  const navigate = useNavigate();
  const { t } = useTranslation();
  let params = useParams();
  const lang = i18n.language;

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [activeBranch, setActiveBranch] = useState([]);
  const [inActiveBranch, setInActiveBranch] = useState([]);
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [searchActiveValue, setSearchActiveValue] = useState("");
  const [searchInActiveValue, setSearchInActiveValue] = useState("");
  const [isActiveBranch, setIsActiveBranch] = useState(true);
  const [isInActiveBranch, setIsInActiveBranch] = useState(false);
  const [totalActiveBranches, setTotalActiveBranches] = useState(0);
  const [totalInActiveBranches, setTotalInActiveBranches] = useState(0);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [inActiveCurrentPage, setInActiveCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState("");

  async function getVendorActiveBranches() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await VendorServices.getVendorActiveBranches(
        params.id,
        lang,
        searchActiveValue,
        activeCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let branchName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          branchName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          branchName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          branchName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          branchName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          branchName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          branchName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteBranch
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: branchName,
            Email: record.email,
            Phone: record.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "branch",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: branchName,
            Email: record.email,
            Phone: record.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
            Chat: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/messages/${record._id}`, {
                    state: "branch",
                  });
                }}
                className="chat-with"
              >
                <i className="fa-solid fa-message"></i>
              </button>
            ),
          };
        return returnedRecord;
      });

      setActiveBranch(branch => {
        if ((searchActiveValue && activeCurrentPage === 1) || (isSorted && activeCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...branch, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalActiveBranches(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getVendorInActiveBranches() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchInActiveValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await VendorServices.getVendorInActiveBranches(
        params.id,
        lang,
        searchInActiveValue,
        inActiveCurrentPage,
        25,
        isSorted,
        localStorage.getItem("i18nextLng"),
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let branchName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.name?.en) {
          branchName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && record?.name?.ar) {
          branchName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.name?.ar) {
          branchName = record?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && record?.name?.en) {
          branchName = record?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.name?.en && !record?.name?.ar) {
          branchName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.name?.ar && !record?.name?.en) {
          branchName = "لا يوجد أسم"
        }
        let returnedRecord = canDeleteBranch
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: branchName,
            Email: record.email,
            Phone: record.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record._id,
            Name: branchName,
            Email: record.email,
            Phone: record.phone,
            "Join Date": formatDate(new Date(record.joinDate)),
          };
        return returnedRecord;
      });

      setInActiveBranch(branch => {
        if ((searchInActiveValue && inActiveCurrentPage === 1) || (isSorted && inActiveCurrentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...branch, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalInActiveBranches(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteBranch(id) {
    setLoading(true);
    try {
      const { data } = await VendorServices.deleteBranch(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleBranchRowClick(id) {
    navigate(`/vendors/${params.id}/branchdetails/${id}`);
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (isActiveBranch) {
      getVendorActiveBranches();
      setInActiveBranch([])
    }
  }, [params.id, searchActiveValue, lang, activeCurrentPage, isActiveBranch, isSorted]);

  useEffect(() => {
    if (isInActiveBranch) {
      getVendorInActiveBranches();
      setActiveBranch([])
    }
  }, [params.id, searchInActiveValue, lang, inActiveCurrentPage, isInActiveBranch, isSorted]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {(currentUser?.name?.[lang]).toUpperCase()} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteBranch(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      <div className="user">
        {
          isActiveBranch ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveBranch(true); setIsInActiveBranch(false) }}
                className='all-branches-btn'>
                <h2>{t("Active Branches")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveBranch(true); setIsInActiveBranch(false) }}
              className='all-branches-btn'>
              <h2>{t("Active Branches")}</h2>
            </button>
          </div>)
        }
        {
          isInActiveBranch ? (
            <div className='active-btn'>
              <button
                onClick={() => { setIsActiveBranch(false); setIsInActiveBranch(true) }}
                className='all-branches-btn'>
                <h2>{t("Inactive Branches")}</h2>
              </button>
            </div>
          ) : (<div className='all-branches'>
            <button
              onClick={() => { setIsActiveBranch(false); setIsInActiveBranch(true) }}
              className='all-branches-btn'>
              <h2>{t("Inactive Branches")}</h2>
            </button>
          </div>)
        }
      </div>

      {isActiveBranch ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <div className="add-branch">
                <div className="form-search">
                  <div className="form-input-search">
                    <input
                      onChange={(e) => {
                        setActiveCurrentPage(1);
                        setSearchActiveValue(prev => {
                          if (prev && e.target.value === '') {
                            navigate(0)
                          }
                          return e.target.value
                        });
                      }}
                      className="input-search"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={t("What Are You Looking For?")}
                    />
                    <label className="label-search">
                      <SearchLogo className="SearchLogo" />
                    </label>
                  </div>
                </div>
                {canCreateBranch && (
                  <div className="button">
                    <button
                      className="main-button-add"
                      onClick={() => {
                        navigate(`/vendors/${params.id}/addbranch`);
                      }}
                    >
                      {t("Add Branch")}
                    </button>
                  </div>
                )}
              </div>
              <div className="alpha">
                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>
              {isSearchEmpty ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleBranchRowClick} tableRows={activeBranch} />
              )}
              {
                activeBranch.length !== totalActiveBranches &&
                <div className="load">
                  <button onClick={() => { setActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}

      {isInActiveBranch ? (
        <div className="branch-cards-container">
          {loading ? (
            <div className="loading-spinner">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <div>
              <div className="add-branch">
                <div className="form-search">
                  <div className="form-input-search">
                    <input
                      onChange={(e) => {
                        setInActiveCurrentPage(1);
                        setSearchInActiveValue(prev => {
                          if (prev && e.target.value === '') {
                            navigate(0)
                          }
                          return e.target.value
                        });
                      }}
                      className="input-search"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={t("What Are You Looking For?")}
                    />
                    <label className="label-search">
                      <SearchLogo className="SearchLogo" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="alpha">
                <button onClick={() => { setIsSorted("alpha") }}>{t("Sort alphabetically")}</button>
              </div>

              {isSearchEmpty ? (
                <p className="no-results">{t("No results found!")}</p>
              ) : (
                <MainTable onRowClick={handleBranchRowClick} tableRows={inActiveBranch} />
              )}
              {
                inActiveBranch.length !== totalInActiveBranches &&
                <div className="load">
                  <button onClick={() => { setInActiveCurrentPage((prev) => prev + 1) }}>
                    {t("Load more...")}
                  </button>
                </div>
              }
            </div>
          )}
        </div>
      ) : ""}
    </>
  );
}
