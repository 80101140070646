import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ClientServices from '../../../../services/ClientServices';
import toastPopup from '../../../../helpers/toastPopup';
import COUNTRIES_CITIES from '../../../../locales/app-countries.json'
import MainInput from '../../../../Components/MainInput';
import ShortUniqueId from 'short-unique-id';
import Barcode from 'react-barcode/lib/react-barcode';
import Multiselect from 'multiselect-react-dropdown';
import i18n from '../../../../locales/i18n';
import OrganizationServices from '../../../../services/OrganizationServices';
import AgentServices from '../../../../services/AgentServices';
import './AddClientByForm.scss'

export default function AddClientByForm() {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const params = useParams()
  const lang = i18n.language

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isActive, setIsActive] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [uploadImage, setUploadImage] = useState(null);
  const [gender, setGender] = useState("");
  const [countryIndex, setCountryIndex] = useState('')
  const [cityIndex, setCityIndex] = useState('')
  const [barcodeUUID, setBarcodeUUID] = useState('');
  const [allProfessions, setAllProfessions] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [allInterests, setAllInterests] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);

  const [newClient, setNewClient] = useState({
    name_en: "",
    name_ar: "",
    email: "",
    password: "",
    phone: "",
    age: 0,
    dateOfBirth: "",
    location_lat: "",
    location_long: "",
    credit: 0
  });

  function resetInputs() {
    setNewClient({
      name_en: "",
      name_ar: "",
      email: "",
      password: "",
      phone: "",
      age: 0,
      dateOfBirth: "",
      location_lat: "",
      location_long: "",
      credit: 0
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "email", type: "email", required: false },
    { name: "password", type: "password", required: false },
    { name: "phone", type: "text", required: false },
    { name: "age", type: "number", required: false },
    { name: "dateOfBirth", type: "Date", required: false },
    { name: "location_long", type: "text", required: false },
    { name: "location_lat", type: "text", required: false },
    { name: "credit", type: "number", required: false }
  ];

  async function addNewClient(e) {
    e.preventDefault();
    setLoading(true);

    const currentDate = new Date(Date.now());
    const currentDateISO = new Date(currentDate).toISOString();
    const nextYear = currentDate.setFullYear(currentDate.getFullYear() + 1);
    const nextYearISO = new Date(nextYear).toISOString();

    if (window.location.href.includes('agents')) {
      try {
        const obj = {
          name: {
            en: newClient.name_en,
            ar: newClient.name_ar
          },
          email: newClient.email,
          password: newClient.password,
          phone: newClient.phone,
          age: parseInt(newClient.age),
          dateOfBirth: newClient.dateOfBirth,
          location: {
            lat: parseFloat(newClient.location_lat),
            long: parseFloat(newClient.location_long)
          },
          credit: parseInt(newClient.credit),
          gender: gender,
          country: {
            en: selectedCountry?.en,
            ar: selectedCountry?.ar,
            index: countryIndex,
          },
          city: {
            en: selectedCity?.en,
            ar: selectedCity?.ar,
            index: cityIndex,
          },
          barcode: barcodeUUID,
          profession: getFinalProfessions(),
          interests: getFinalInterests(),
          isActive: isActive,
          isSubscribed: true,
          subStartDate: currentDateISO,
          subEndDate: nextYearISO,
          agent: params.id,
        }

        function isEmpty(value) {
          return (
            typeof value === 'string' && value.trim() === '' ||
            value === undefined ||
            value === null
          );
        }

        if (isEmpty(obj.name.en)) {
          delete obj.name.en;
        }

        if (isEmpty(obj.name.ar)) {
          delete obj.name.ar;
        }

        if (isEmpty(obj.location.lat) || newClient.location_lat === "") {
          delete obj.location.lat;
        }

        if (isEmpty(obj.location.long) || newClient.location_long === "") {
          delete obj.location.long;
        }

        if (isEmpty(obj.location.long) || newClient.location_long === "" &&
          isEmpty(obj.location.lat) || newClient.location_lat === "") {
          delete obj.location;
        }

        if (isEmpty(obj.country.en)) {
          delete obj.country.en;
        }

        if (isEmpty(obj.country.ar)) {
          delete obj.country.ar;
        }

        if (isEmpty(obj.country.index)) {
          delete obj.country.index;
        }

        if (isEmpty(obj.country.en) && isEmpty(obj.country.ar) && isEmpty(obj.country.index)) {
          delete obj.country;
        }

        if (isEmpty(obj.city.en)) {
          delete obj.city.en;
        }

        if (isEmpty(obj.city.ar)) {
          delete obj.city.ar;
        }

        if (isEmpty(obj.city.index)) {
          delete obj.city.index;
        }

        if (isEmpty(obj.city.en) && isEmpty(obj.city.ar) && isEmpty(obj.city.index)) {
          delete obj.city;
        }

        if (isEmpty(obj.credit) || newClient.credit === "") {
          delete obj.credit;
        }

        const finalObj = {};

        for (const [key, value] of Object.entries(obj)) {
          if (value !== "") {
            finalObj[key] = value;
          }
        }

        const { data } = await ClientServices.addClient(finalObj)
        setLoading(false);
        try {
          const { data } = await AgentServices.getAgent(params.id);
          setLoading(false);
          let agentCredit = data?.record[0]?.credit;
          try {
            const { data } = await AgentServices.editAgent(params.id
              , { credit: agentCredit - 1 }
            );
          } catch (e) {
            setLoading(false);
            setErrorMessage(e.response.data.error);
          }
        } catch (e) {
          setLoading(false);
          setErrorMessage("Unexpected Error happened. Please try again later");
        }
        let clientID = data.record._id
        var formData = new FormData();
        formData.append("image", uploadImage);
        setLoading(true)
        try {
          const { data } = await ClientServices.uploadClientImage(clientID, formData)
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setErrorMessage(error);
        }
        toastPopup.success(t("Added"));
        navigate(`/clients/${data.record._id}`)
      } catch (e) {
        setLoading(false);
        setErrorMessage(e.response.data.error);
      }
    } else if (window.location.href.includes('organizations')) {
      try {
        const obj = {

          name: {
            en: newClient.name_en,
            ar: newClient.name_ar
          },
          email: newClient.email,
          password: newClient.password,
          phone: newClient.phone,
          age: parseInt(newClient.age),
          dateOfBirth: newClient.dateOfBirth,
          location: {
            lat: parseFloat(newClient.location_lat),
            long: parseFloat(newClient.location_long)
          },
          credit: parseInt(newClient.credit),
          gender: gender,
          country: {
            en: selectedCountry?.en,
            ar: selectedCountry?.ar,
            index: countryIndex,
          },
          city: {
            en: selectedCity?.en,
            ar: selectedCity?.ar,
            index: cityIndex,
          },
          barcode: barcodeUUID,
          profession: getFinalProfessions(),
          interests: getFinalInterests(),
          isActive: isActive,
          isSubscribed: true,
          subStartDate: currentDateISO,
          subEndDate: nextYearISO,
          org: params.id,
        }

        function isEmpty(value) {
          return (
            typeof value === 'string' && value.trim() === '' ||
            value === undefined ||
            value === null
          );
        }

        if (isEmpty(obj.name.en)) {
          delete obj.name.en;
        }

        if (isEmpty(obj.name.ar)) {
          delete obj.name.ar;
        }

        if (isEmpty(obj.location.lat) || newClient.location_lat === "") {
          delete obj.location.lat;
        }

        if (isEmpty(obj.location.long) || newClient.location_long === "") {
          delete obj.location.long;
        }

        if (isEmpty(obj.location.long) || newClient.location_long === "" &&
          isEmpty(obj.location.lat) || newClient.location_lat === "") {
          delete obj.location;
        }

        if (isEmpty(obj.country.en)) {
          delete obj.country.en;
        }

        if (isEmpty(obj.country.ar)) {
          delete obj.country.ar;
        }

        if (isEmpty(obj.country.index)) {
          delete obj.country.index;
        }

        if (isEmpty(obj.country.en) && isEmpty(obj.country.ar) && isEmpty(obj.country.index)) {
          delete obj.country;
        }

        if (isEmpty(obj.city.en)) {
          delete obj.city.en;
        }

        if (isEmpty(obj.city.ar)) {
          delete obj.city.ar;
        }

        if (isEmpty(obj.city.index)) {
          delete obj.city.index;
        }

        if (isEmpty(obj.city.en) && isEmpty(obj.city.ar) && isEmpty(obj.city.index)) {
          delete obj.city;
        }

        if (isEmpty(obj.credit) || newClient.credit === "") {
          delete obj.credit;
        }

        const finalObj = {};

        for (const [key, value] of Object.entries(obj)) {
          if (value !== "") {
            finalObj[key] = value;
          }
        }
        const { data } = await ClientServices.addClient(finalObj)
        setLoading(false);
        try {
          const { data } = await OrganizationServices.getOrganization(params.id);
          setLoading(false);
          let organizationCredit = data?.record[0]?.credit;
          try {
            const { data } = await OrganizationServices.editOrganization(params.id
              , { credit: organizationCredit - 1 }
            );
          } catch (e) {
            setLoading(false);
            setErrorMessage(e.response.data.error);
          }
        } catch (e) {
          setLoading(false);
          setErrorMessage("Unexpected Error happened. Please try again later");
        }
        let clientID = data.record._id
        var formData = new FormData();
        formData.append("image", uploadImage);
        setLoading(true)
        try {
          const { data } = await ClientServices.uploadClientImage(clientID, formData)
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setErrorMessage(error);
        }
        toastPopup.success(t("Added"));
        navigate(`/clients/${data?.record?._id}`)
      } catch (e) {
        setLoading(false);
        setErrorMessage(e.response.data.error);
      }
    } else {
      if (isSubscribed) {
        try {
          const obj = {
            name: {
              en: newClient.name_en,
              ar: newClient.name_ar
            },
            email: newClient.email,
            password: newClient.password,
            phone: newClient.phone,
            age: parseInt(newClient.age),
            dateOfBirth: newClient.dateOfBirth,
            location: {
              lat: parseFloat(newClient.location_lat),
              long: parseFloat(newClient.location_long)
            },
            credit: parseInt(newClient.credit),
            gender: gender,
            country: {
              en: selectedCountry?.en,
              ar: selectedCountry?.ar,
              index: countryIndex,
            },
            city: {
              en: selectedCity?.en,
              ar: selectedCity?.ar,
              index: cityIndex,
            },
            barcode: barcodeUUID,
            profession: getFinalProfessions(),
            interests: getFinalInterests(),
            isActive: isActive,
            isSubscribed: true,
            subStartDate: currentDateISO,
            subEndDate: nextYearISO
          }

          function isEmpty(value) {
            return (
              typeof value === 'string' && value.trim() === '' ||
              value === undefined ||
              value === null
            );
          }

          if (isEmpty(obj.name.en)) {
            delete obj.name.en;
          }

          if (isEmpty(obj.name.ar)) {
            delete obj.name.ar;
          }

          if (isEmpty(obj.location.lat) || newClient.location_lat === "") {
            delete obj.location.lat;
          }

          if (isEmpty(obj.location.long) || newClient.location_long === "") {
            delete obj.location.long;
          }

          if (isEmpty(obj.location.long) || newClient.location_long === "" &&
            isEmpty(obj.location.lat) || newClient.location_lat === "") {
            delete obj.location;
          }

          if (isEmpty(obj.country.en)) {
            delete obj.country.en;
          }

          if (isEmpty(obj.country.ar)) {
            delete obj.country.ar;
          }

          if (isEmpty(obj.country.index)) {
            delete obj.country.index;
          }

          if (isEmpty(obj.country.en) && isEmpty(obj.country.ar) && isEmpty(obj.country.index)) {
            delete obj.country;
          }

          if (isEmpty(obj.city.en)) {
            delete obj.city.en;
          }

          if (isEmpty(obj.city.ar)) {
            delete obj.city.ar;
          }

          if (isEmpty(obj.city.index)) {
            delete obj.city.index;
          }

          if (isEmpty(obj.city.en) && isEmpty(obj.city.ar) && isEmpty(obj.city.index)) {
            delete obj.city;
          }

          if (isEmpty(obj.credit) || newClient.credit === "") {
            delete obj.credit;
          }

          const finalObj = {};

          for (const [key, value] of Object.entries(obj)) {
            if (value !== "") {
              finalObj[key] = value;
            }
          }

          const { data } = await ClientServices.addClient(finalObj)
          setLoading(false);
          let clientID = data.record._id
          var formData = new FormData();
          formData.append("image", uploadImage);
          setLoading(true)
          try {
            const { data } = await ClientServices.uploadClientImage(clientID, formData)
            setLoading(false);
          } catch (error) {
            setLoading(false);
            setErrorMessage(error);
          }
          toastPopup.success(t("Added"));
          navigate(`/clients/${data.record._id}`)
        } catch (e) {
          setLoading(false);
          setErrorMessage(e.response.data.error);
        }
      } else {
        try {
          const obj = {
            name: {
              en: newClient.name_en,
              ar: newClient.name_ar
            },
            email: newClient.email,
            password: newClient.password,
            phone: newClient.phone,
            age: parseInt(newClient.age),
            dateOfBirth: newClient.dateOfBirth,
            location: {
              lat: parseFloat(newClient.location_lat),
              long: parseFloat(newClient.location_long)
            },
            credit: parseInt(newClient.credit),
            gender: gender,
            country: {
              en: selectedCountry?.en,
              ar: selectedCountry?.ar,
              index: countryIndex,
            },
            city: {
              en: selectedCity?.en,
              ar: selectedCity?.ar,
              index: cityIndex,
            },
            barcode: barcodeUUID,
            profession: getFinalProfessions(),
            interests: getFinalInterests(),
            isActive: isActive,
            isSubscribed: false
          }

          function isEmpty(value) {
            return (
              typeof value === 'string' && value.trim() === '' ||
              value === undefined ||
              value === null
            );
          }

          if (isEmpty(obj.name.en)) {
            delete obj.name.en;
          }

          if (isEmpty(obj.name.ar)) {
            delete obj.name.ar;
          }

          if (isEmpty(obj.location.lat) || newClient.location_lat === "") {
            delete obj.location.lat;
          }

          if (isEmpty(obj.location.long) || newClient.location_long === "") {
            delete obj.location.long;
          }

          if (isEmpty(obj.location.long) || newClient.location_long === "" &&
            isEmpty(obj.location.lat) || newClient.location_lat === "") {
            delete obj.location;
          }

          if (isEmpty(obj.country.en)) {
            delete obj.country.en;
          }

          if (isEmpty(obj.country.ar)) {
            delete obj.country.ar;
          }

          if (isEmpty(obj.country.index)) {
            delete obj.country.index;
          }

          if (isEmpty(obj.country.en) && isEmpty(obj.country.ar) && isEmpty(obj.country.index)) {
            delete obj.country;
          }

          if (isEmpty(obj.city.en)) {
            delete obj.city.en;
          }

          if (isEmpty(obj.city.ar)) {
            delete obj.city.ar;
          }

          if (isEmpty(obj.city.index)) {
            delete obj.city.index;
          }

          if (isEmpty(obj.city.en) && isEmpty(obj.city.ar) && isEmpty(obj.city.index)) {
            delete obj.city;
          }

          if (isEmpty(obj.credit) || newClient.credit === "") {
            delete obj.credit;
          }

          const finalObj = {};

          for (const [key, value] of Object.entries(obj)) {
            if (value !== "") {
              finalObj[key] = value;
            }
          }

          const { data } = await ClientServices.addClient(finalObj)

          setLoading(false);
          resetInputs()
          let clientID = data.record._id
          var formData = new FormData();
          formData.append("image", uploadImage);
          setLoading(true)
          try {
            const { data } = await ClientServices.uploadClientImage(clientID, formData)
            setLoading(false);
          } catch (error) {
            setLoading(false);
            setErrorMessage(error);
          }
          toastPopup.success(t("Added"));
          navigate(`/clients/${data?.record?._id}`)
        }
        catch (e) {
          setLoading(false);
          setErrorMessage(e.response.data.error);
        }
      }
    }
  };

  function generateBarcode() {
    const uid = new ShortUniqueId({ length: 10 });
    setBarcodeUUID(uid())
  }

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  async function getAllProfessions() {
    setLoading(true)
    try {
      const { data } = await ClientServices.listAllProfessions()
      setLoading(false);
      setAllProfessions(data.records)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllInterests() {
    setLoading(true)
    try {
      const { data } = await ClientServices.listAllInterests()
      setLoading(false);
      setAllInterests(data.records)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function toggleSelectedProfessionsHandler(professionId) {
    if (selectedProfessions.includes(professionId)) {
      let oldSelectedProfessions = selectedProfessions
      let newSelectedProfessions = oldSelectedProfessions.filter((profession) => { return profession !== professionId })
      setSelectedProfessions(newSelectedProfessions)
    } else {
      setSelectedProfessions((prev) => { return [...prev, professionId] })
    }
  }

  function getFinalProfessions() {
    let finalProfessions = []
    selectedProfessions.forEach((selectedProfession) => {
      allProfessions.filter(profession => profession._id === selectedProfession).map((profession) => {
        finalProfessions.push(profession)
      })
    })

    return finalProfessions
  }

  let professionsOptions = allProfessions.map((profession) => {
    return ({
      name: profession?.name?.[lang],
      id: profession._id
    })
  })

  function toggleSelectedInterestsHandler(interestId) {
    if (selectedInterests.includes(interestId)) {
      let oldSelectedInterests = selectedInterests
      let newSelectedInterests = oldSelectedInterests.filter((interest) => { return interest !== interestId })
      setSelectedInterests(newSelectedInterests)
    } else {
      setSelectedInterests((prev) => { return [...prev, interestId] })
    }
  }

  function getFinalInterests() {
    let finalInterests = []
    selectedInterests.forEach((selectedInterest) => {
      allInterests.filter(interest => interest._id === selectedInterest).map((interest) => {
        finalInterests.push(interest)
      })
    })

    return finalInterests
  }

  let interestsOptions = allInterests.map((interest) => {
    return ({
      name: interest?.name?.[lang],
      id: interest._id
    })
  })

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(countryIndex))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(cityIndex))[0]

  useEffect(() => {
    getAllProfessions()
    getAllInterests()
  }, [])

  return <>

    <div className="add-client">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/clients') }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Client")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="main-image-label vendor-image">
        {uploadImage && (
          <img
            src={typeof uploadImage === "object" ? URL.createObjectURL(uploadImage) : uploadImage ? uploadImage : null}
            alt="imag-viewer"
            className="uploaded-img"
            onClick={() => {
              window.open(
                uploadImage ? URL.createObjectURL(uploadImage) : null
              );
            }}
          />
        )}
        <input
          className="main-input-image"
          type="file"
          name="upload-img"
          ref={ref}
          onChange={(e) => {
            setUploadImage(e.target.files[0]);
          }}
        />
        <label
          className="main-label-image"
          onClick={imageUploader}
          htmlFor="upload-img"
        >
          {t("Add Image")}
        </label>
      </div>

      <form className='form-grid' onSubmit={addNewClient}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newClient}
                setState={setNewClient}
              />
            );
          })}

          <div className="main-input-select">
            <select
              onChange={(e) => { setGender(e.target.value) }}
              className='main-input'
              id="gender"
              name="gender"
              title='gender'
              value={gender}>
              <option value=''>-- {t("Gender")} --</option>
              <option value={"male"}>{t("Male")}</option>
              <option value={"female"}>{t("Female")}</option>
            </select>
            <label className='main-label'>{t("Gender")}</label>
          </div>

          <div className="main-input-select">
            <select
              selected={countryIndex}
              value={countryIndex}
              onChange={(e) => { setCountryIndex(e.target.value) }}
              className='main-input'
              id="country"
              name="country"
              title='country'>
              <option value=''>{t("Country")}</option>
              {
                COUNTRIES_CITIES?.map((country, index) => {
                  return <option key={index} value={country?.index}>{country?.[lang]}</option>
                })
              }
            </select>
            <label className="main-label">{t("Country")}</label>
          </div>

          <div className="main-input-select">
            <select
              selected={cityIndex}
              value={cityIndex}
              onChange={(e) => { setCityIndex(e.target.value) }}
              className='main-input'
              id="city"
              name="city"
              title='city'>
              <option value=''>{t("City")}</option>
              {
                selectedCountry?.cities?.map((state, index) => {
                  return <option key={index} value={state?.index}>{state?.[lang]}</option>
                })
              }
            </select>
            <label className="main-label">{t("City")}</label>
          </div>

          <div className="generate-barcode">
            <div className="generate">
              <button
                type='button'
                onClick={generateBarcode}>
                {t("Generate Barcode")}
                <i className="fa-solid fa-barcode"></i>
              </button>
            </div>
            <div className="main-image-label barcode">
              <Barcode value={barcodeUUID} width={1} height={30} />
            </div>
          </div>

          <Multiselect
            displayValue="name"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedProfessionsHandler(selectedItem.id)
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedProfessionsHandler(selectedItem.id)
            }}
            options={professionsOptions}
            showCheckbox
            placeholder={t("Professions")}
          />

          <Multiselect
            displayValue="name"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedInterestsHandler(selectedItem.id)
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(selectedList, selectedItem) {
              toggleSelectedInterestsHandler(selectedItem.id)
            }}
            options={interestsOptions}
            showCheckbox
            placeholder={t("Interests")}
          />

          <div className='toggler'>
            <label
              htmlFor="productImage"
              className="word">
              {t("Is Active")}
            </label>
            <label className="switch form-group">
              <input
                checked={isActive}
                onChange={(e) => { setIsActive(e.target.checked) }}
                type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          {window.location.href.includes('agents') || window.location.href.includes('organizations') ?
            "" :
            (
              <div className='toggler'>
                <label
                  htmlFor="productImage"
                  className="word">
                  {t("Is Subscribed")}
                </label>
                <label className="switch form-group">
                  <input
                    checked={isSubscribed}
                    onChange={(e) => { setIsSubscribed(e.target.checked) }}
                    type="checkbox" />
                  <span className="slider round"></span>
                </label>
              </div>
            )
          }
        </div>

        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate('/clients') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Client")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
