import Axios from "./Axios";

let OrderServices = {
  getOrder: async (id) => {
    const response = await Axios.get(`/admin/order/get?_id=${id}`);
    return response;
  },

  listAllOrders: async (vendor, status, paymentMethod, page, limit = 25) => {
    const response = await Axios.get(`/admin/order/list?${vendor ? `&vendor=${vendor}` : ""}${status ? `&status=${status}` : ""}${paymentMethod ? `&paymentMethod=${paymentMethod}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllBranchOrders: async (id, page, limit = 25) => {
    const response = await Axios.get(`/admin/order/list?branch=${id}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllVendorOrders: async (id, page, limit = 25) => {
    const response = await Axios.get(`/admin/order/list?vendor=${id}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },
};

export default OrderServices;
