import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import PendingCreditRequests from './PendingCreditRequests/PendingCreditRequests';
import AcceptedCreditRequests from './AcceptedCreditRequests/AcceptedCreditRequests';
import RejectedCreditRequests from './RejectedCreditRequests/RejectedCreditRequests';
import AgentsCreditRequests from './AgentsCreditRequests/AgentsCreditRequests';
import './CreditRequests.scss'

export default function CreditRequests() {

  const { t } = useTranslation();

  const [pending, setPending] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [agents, setAgents] = useState(false);

  function togglePending() {
    setPending(true);
    setAccepted(false);
    setRejected(false);
    setAgents(false);
  }

  function toggleAccepted() {
    setPending(false);
    setAccepted(true);
    setRejected(false);
    setAgents(false);
  }

  function toggleRejected() {
    setPending(false);
    setAccepted(false);
    setRejected(true);
    setAgents(false);
  }

  function toggleAgents() {
    setPending(false);
    setAccepted(false);
    setRejected(false);
    setAgents(true);
  }

  return <>

    <div className="user">
      {pending ? (
        <div className="active-btn">
          <button
            onClick={() => {
              togglePending();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Pending")}</h2>
          </button>
        </div>
      ) : (
        <div className="all-branches">
          <button
            onClick={() => {
              togglePending();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Pending")}</h2>
          </button>
        </div>
      )}

      {accepted ? (
        <div className="active-btn">
          <button
            onClick={() => {
              toggleAccepted();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Accepted")}</h2>
          </button>
        </div>
      ) : (
        <div className="all-branches">
          <button
            onClick={() => {
              toggleAccepted();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Accepted")}</h2>
          </button>
        </div>
      )}

      {rejected ? (
        <div className="active-btn">
          <button
            onClick={() => {
              toggleRejected();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Rejected")}</h2>
          </button>
        </div>
      ) : (
        <div className="all-branches">
          <button
            onClick={() => {
              toggleRejected();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Rejected")}</h2>
          </button>
        </div>
      )}

      {agents ? (
        <div className="active-btn">
          <button
            onClick={() => {
              toggleAgents();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Agents")}</h2>
          </button>
        </div>
      ) : (
        <div className="all-branches">
          <button
            onClick={() => {
              toggleAgents();
            }}
            className="all-branches-btn"
          >
            <h2>{t("Agents")}</h2>
          </button>
        </div>
      )}
    </div>

    {pending ? (
      <div className="wishlist-cards">
        <PendingCreditRequests />
      </div>
    ) : (
      ""
    )}

    {accepted ? (
      <div className="wishlist-cards">
        <AcceptedCreditRequests />
      </div>
    ) : (
      ""
    )}

    {rejected ? (
      <div className="wishlist-cards">
        <RejectedCreditRequests />
      </div>
    ) : (
      ""
    )}

    {agents ? (
      <div className="wishlist-cards">
        <AgentsCreditRequests />
      </div>
    ) : (
      ""
    )}

  </>
}
