import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import toastPopup from "../../../helpers/toastPopup";
import AdsServices from "../../../services/AdsServices";
import { ReactComponent as SearchLogo } from "../../../assets/VIP-ICON-SVG/SearchLogo.svg";
import MainTable from "../../../Components/MainTable/MainTable";
import i18n from "../../../locales/i18n";
import { useSelector } from "react-redux";
import COUNTRIES_CITIES from '../../../locales/app-countries.json'
import "./RejectedAds.scss";

export default function RejectedAds() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { ad: adPermissions } = frontPermissions;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = i18n.language;

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [rejectedAds, setRejectedAds] = useState([]);
  const [gender, setGender] = useState("");
  const [countryIndex, setCountryIndex] = useState('')
  const [cityIndex, setCityIndex] = useState('')
  const [ageFrom, setAgeFrom] = useState('')
  const [ageTo, setAgeTo] = useState('')
  const [ages, setAges] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [adType, setAdType] = useState('')
  const [totalAds, setTotalAds] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllRejectedAds() {
    try {
      const { data } = await AdsServices.listAllAds(
        "rejected",
        searchValue,
        gender === "Gender" ? "" : gender,
        adType === "Ad type" ? "" : adType,
        cityIndex === '' ? "" : localStorage.getItem("i18nextLng") === "en" ? selectedCity?.en : selectedCity?.ar,
        localStorage.getItem("i18nextLng"),
        ageFrom === "Age from" ? "" : ageFrom,
        ageTo === "Age To" ? "" : ageTo,
        currentPage
      );

      setLoading(false);
      let tableData = data?.records?.map((record, index) => {
        let returnedObjects = adPermissions.canDelete
          ? {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: record?.name
              ? record?.name?.slice(0, 50)
              : (record?.notification?.[lang])?.slice(0, 50),
            "Ad Type": record?.type,
            Gender: record?.gender,
            "Age From": record?.age?.from,
            "Age To": record?.age?.to,
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : {
            Num: <div className="index">{index + 1}</div>,
            id: record?._id,
            Name: record?.name
              ? record?.name?.slice(0, 50)
              : (record?.notification?.[lang])?.slice(0, 50),
            "Ad Type": record?.type,
            Gender: record?.gender,
            "Age From": record?.age?.from,
            "Age To": record?.age?.to,
          };

        return returnedObjects;
      });

      setRejectedAds(ad => {
        if ((searchValue && currentPage === 1) || (gender && currentPage === 1) || (adType && currentPage === 1) || (cityIndex && currentPage === 1) || (ageFrom && currentPage === 1) || (ageTo && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...ad, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalAds(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteRejectedAd(id) {
    setLoading(true);
    try {
      const { data } = await AdsServices.deleteAd(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function ageRange() {
    let allAges = []
    for (let i = 12; i <= 70; i++) {
      allAges.push(i);
    }
    setAges(allAges)
  }

  function handleAdRowClick(id) {
    navigate(`/ads/rejected/${id}`);
  }

  let selectedCountry = COUNTRIES_CITIES?.filter((country) => country?.index === parseInt(countryIndex))[0]
  let selectedCity = selectedCountry?.cities?.filter((city) => city?.index === parseInt(cityIndex))[0]

  useEffect(() => {
    getAllRejectedAds();
  }, [
    gender,
    adType,
    searchValue,
    cityIndex,
    ageFrom,
    ageTo,
    currentPage
  ]);

  useEffect(() => {
    ageRange()
  }, [])

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name
                ? (currentUser?.name).toUpperCase()
                : currentUser?.notification?.[lang]}{" "}
              {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteRejectedAd(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
            <div className="filter-by-container">
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setGender(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Gender">
                    {t("Gender")}
                  </option>
                  <option value={"male"}>{t("Male")}</option>
                  <option value={"female"}>{t("Female")}</option>
                  <option value={"both"}>{t("Both")}</option>
                </select>
              </div>

              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setAdType(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Ad type">
                    {t("Ad type")}
                  </option>
                  <option value={"banner"}>{t("Banner")}</option>
                  <option value={"popup"}>{t("Popup")}</option>
                  <option value={"notification"}>{t("Notification")}</option>
                  <option value={"promotion"}>{t("Promotion")}</option>
                  <option value={"sort"}>{t("Sort")}</option>
                </select>
              </div>

              <div className="filter-by">
                <select
                  selected={countryIndex}
                  value={countryIndex}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setCountryIndex(e.target.value)
                  }}
                  className='search-by-input'
                  id="country"
                  name="country"
                  title='country'>
                  <option value=''>{t("Country")}</option>
                  {
                    COUNTRIES_CITIES?.map((country, index) => {
                      return <option key={index} value={country?.index}>{country?.[lang]}</option>
                    })
                  }
                </select>
              </div>

              <div className="filter-by">
                <select
                  selected={cityIndex}
                  value={cityIndex}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setCityIndex(e.target.value)
                  }}
                  className='search-by-input'
                  id="city"
                  name="city"
                  title='city'>
                  <option value=''>{t("City")}</option>
                  {
                    selectedCountry?.cities?.map((state, index) => {
                      return <option key={index} value={state?.index}>{state?.[lang]}</option>
                    })
                  }
                </select>
              </div>

              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setAgeFrom(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Age from">{t("Age from")}</option>
                  {ages.map((age) => {
                    return <option key={age} value={age}>{age}</option>
                  })}
                </select>
              </div>

              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setAgeTo(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="Age To">{t("Age To")}</option>
                  {ages.map((age) => {
                    return <option key={age} value={age}>{age}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          {rejectedAds.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handleAdRowClick} tableRows={rejectedAds} />
          )}
          {
            rejectedAds.length !== totalAds &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
