import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MainInput from '../../../Components/MainInput';
import toastPopup from '../../../helpers/toastPopup';
import VoucherServices from '../../../services/VoucherServices';
import './AddVoucher.scss'

export default function AddVoucher() {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isActive, setIsActive] = useState(false)

  const [newVoucher, setNewVoucher] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    value: 0,
    quantity: 0
  });

  function resetInputs() {
    setNewVoucher({
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      value: 0,
      quantity: 0
    })
  }

  let formData = [
    { name: "name_en", type: "text", required: true },
    { name: "name_ar", type: "text", required: true },
    { name: "description_en", type: "text", required: true },
    { name: "description_ar", type: "text", required: true },
    { name: "value", type: "number", required: true },
    { name: "quantity", type: "number", required: true },
  ];

  async function addVoucherHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        name: {
          en: newVoucher?.name_en,
          ar: newVoucher?.name_ar
        },
        description: {
          en: newVoucher.description_en,
          ar: newVoucher.description_ar
        },
        value: newVoucher.value,
        quantity: newVoucher.quantity,
        isActive: isActive
      }
      const { data } = await VoucherServices.addVoucher(obj)
      setLoading(false);
      resetInputs()
      toastPopup.success(t("Added"));
      navigate(`/vouchers/${data.record._id}`)
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  };

  return <>

    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate('/vouchers') }}></i>
      </div>
      <div className="header">
        <h3>{t("Add Voucher")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <form className='form-grid' onSubmit={addVoucherHandler}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newVoucher}
                setState={setNewVoucher}
              />
            );
          })}

          <div className='toggler'>
            <label htmlFor="productImage" className="word">{t("Is Active")}</label>
            <label className="switch form-group">
              <input onClick={(e) => { setIsActive(e.target.checked) }} type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="button-grid">
          <button
            className="main-button-admin"
            onClick={() => { resetInputs(); navigate('/vouchers') }}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Cancel")
            )}
          </button>
          <button className="main-button-admin">
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Add Voucher")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
