import Axios from "./Axios";

let BannerServices = {
  addBanner: async (obj) => {
    const response = await Axios.post(`/admin/banner/create`, obj);
    return response;
  },

  getBanner: async (id) => {
    const response = await Axios.get(`/admin/banner/get?_id=${id}`);
    return response;
  },

  uploadBannerImage: async (id, obj) => {
    const response = await Axios.post(`/admin/banner/image?_id=${id}`, obj);
    return response;
  },

  editBanner: async (id, obj) => {
    const response = await Axios.put(`/admin/banner/update?_id=${id}`, obj);
    return response;
  },

  deleteBanner: async (id) => {
    const response = await Axios.delete(`/admin/banner/remove?_id=${id}`);
    return response;
  },

  listAllActiveBannersBySize: async (size, search, page, limit = 25) => {
    const response = await Axios.get(
      `/admin/banner/list?isPopUp=false&size=${size}&isActive=true${search?.length > 0 ? `&name=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  listAllInActiveBannersBySize: async (size, search, page, limit = 25) => {
    const response = await Axios.get(
      `/admin/banner/list?isPopUp=false&size=${size}&isActive=false${search?.length > 0 ? `&name=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  listAllActivePopUps: async (page, search, limit = 25) => {
    const response = await Axios.get(`/admin/banner/list?isPopUp=true&isActive=true${search?.length > 0 ? `&name=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllInActivePopUps: async (page, search, limit = 25) => {
    const response = await Axios.get(`/admin/banner/list?isPopUp=true&isActive=false${search?.length > 0 ? `&name=${search}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`);
    return response;
  },

  listAllBanners: async () => {
    const response = await Axios.get(`/admin/banner/list?isPopUp=false`);
    return response;
  },
};

export default BannerServices;
