import React, { useEffect, useState } from "react";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import { useTranslation } from "react-i18next";
import toastPopup from "../../helpers/toastPopup";
import { useNavigate } from "react-router-dom";
import AdminServices from "../../services/AdminServices";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import i18n from "../../locales/i18n";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import "./Admin.scss";

export default function Admin() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { admin: adminPermissions } = frontPermissions;

  let canDeleteAdmin = adminPermissions.canDelete;
  let canAddAdmin = adminPermissions.canCreate;

  const [admin, setAdmin] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  let decodedToken = jwtDecode(localStorage.getItem('AdminToken'))

  async function getAdmins() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchValue);
    let lang = result ? "ar" : "en";
    try {
      const { data } = await AdminServices.listAllAdmins(
        lang,
        searchValue,
        currentPage
      );

      setLoading(false);

      let adminsOnly = data?.records?.filter((record) => record?.role === 'admin')
      let tableData = []

      if (decodedToken?.role === 'admin') {
        tableData = adminsOnly.map((record, index) => {
          let returnedRecord = canDeleteAdmin
            ? {
              Num: <div className="index">{index + 1}</div>,
              id: record._id,
              Name_en: record?.name?.en ? record?.name?.en : "No Name",
              Name_ar: record?.name?.ar ? record?.name?.ar : "لا يوجد اسم",
              Email: record.email,
              Role: record.role,
              Remove: decodedToken?._id === record._id ? "" :
                (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalShow(true);
                      setCurrentUser(record);
                    }}
                    className="remove"
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                ),
            }
            : {
              Num: <div className="index">{index + 1}</div>,
              id: record._id,
              Name_en: record?.name?.en ? record?.name?.en : "No Name",
              Name_ar: record?.name?.ar ? record?.name?.ar : "لا يوجد اسم",
              Email: record.email,
              Role: record.role,
            };

          return returnedRecord;
        });
      } else {
        tableData = data?.records?.map((record, index) => {
          let returnedRecord = canDeleteAdmin
            ? {
              Num: <div className="index">{index + 1}</div>,
              id: record._id,
              Name_en: record?.name?.en ? record?.name?.en : "No Name",
              Name_ar: record?.name?.ar ? record?.name?.ar : "لا يوجد اسم",
              Email: record.email,
              Role: record.role,
              Remove: decodedToken?._id === record._id ? "" :
                (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalShow(true);
                      setCurrentUser(record);
                    }}
                    className="remove"
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                ),
            }
            : {
              Num: <div className="index">{index + 1}</div>,
              id: record._id,
              Name_en: record?.name?.en ? record?.name?.en : "No Name",
              Name_ar: record?.name?.ar ? record?.name?.ar : "لا يوجد اسم",
              Email: record.email,
              Role: record.role,
            };

          return returnedRecord;
        });
      }

      setAdmin(singleAdmin => {
        if ((searchValue && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...singleAdmin, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })

      if (decodedToken?.role === 'admin') {
        setTotalAdmins(data.counts - 1);
      } else {
        setTotalAdmins(data.counts);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteAdmin(id) {
    setLoading(true);
    try {
      const { data } = await AdminServices.deleteAdmin(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleAdminRowClick(id) {
    navigate(`/admins/${id}`);
  }

  useEffect(() => {
    getAdmins();
  }, [
    lang,
    searchValue,
    currentPage
  ]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {currentUser?.name?.[lang]} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteAdmin(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Admins"}
            text2={"Admins"}
            btnText="Add Admin"
            total={totalAdmins}
            showBtn={canAddAdmin}
            onBtnClick={() => navigate("/admins/addadmin")}
          />
          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
          </div>

          {totalAdmins === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            admin &&
            admin.length > 0 && (
              <MainTable onRowClick={handleAdminRowClick} tableRows={admin} />
            )
          )}
          {
            admin.length !== totalAdmins &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
