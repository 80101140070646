import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../locales/i18n';
import ClientServices from '../../../../services/ClientServices';
import MainTable from '../../../../Components/MainTable/MainTable';
import './OrderRequests.scss'

export default function OrderRequests() {

  let params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const lang = i18n.language

  const [clientOrderRequests, setClientOrderRequests] = useState([])
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function getClientOrderRequests() {
    try {
      const { data } = await ClientServices.getClientOrderRequests(params?.id)
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        return {
          Num: <div className='index'>{index + 1}</div>,
          id: record?._id,
          "Vendor": record?.vendor?.name?.[lang],
          "Branch": record?.branch?.name?.[lang],
          "Payment Method": record?.paymentMethod,
          Status: record?.status,
        }
      })
      setClientOrderRequests(tableData)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleOrderRequestRowClick(id) {
    navigate(`/order-requests/${id}`)
  }

  useEffect(() => {
    getClientOrderRequests()
  }, [])

  return <>

    {errorMessage ? (<div className="err">{errorMessage}</div>) : ("")}

    {loading ? (<div className='loading-spinner'><i className="fas fa-spinner fa-spin"></i></div>) :
      <div>
        {clientOrderRequests.length > 0 ?
          <MainTable
            tableRows={clientOrderRequests}
            onRowClick={handleOrderRequestRowClick} />
          : <p className='no-results'>{t("This client didn't have any orders yet!")}</p>}
      </div>}
  </>
}
