import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingServices from "../../services/SettingServices";
import InfoInput from "../../Components/InfoInput/InfoInput";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Setting() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { setting: settingPermissions } = frontPermissions;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [setting, setSetting] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function getSettings() {
    setLoading(true);
    try {
      const { data } = await SettingServices.getSettings();
      setLoading(false);
      setSetting(data.record);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let settingDetails = [
    {
      id: "referralCredit",
      title: "Referral credit",
      type: "number",
      value: setting?.referralCredit,
    },
    {
      id: "pointsToCash",
      title: "Points to cash",
      type: "number",
      value: setting?.pointsToCash,
    },
    {
      id: "walletNumber",
      title: "Wallet number",
      type: "text",
      value: setting?.walletNumber,
    },
    {
      id: "facebook",
      title: "Facebook",
      type: "text",
      value: setting?.facebook,
    },
    {
      id: "twitter",
      title: "Twitter",
      type: "text",
      value: setting?.twitter,
    },
    {
      id: "instagram",
      title: "Instagram",
      type: "text",
      value: setting?.instagram,
    },
  ];

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div className="vendor-datails">
          <div className="client-datails">
            <div className="edit">
              {settingPermissions.canEdit && (
                <button onClick={() => navigate(`/settings/edit`)}>
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              )}
            </div>

            <div className="data">
              {settingDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}

              <div className="toggler">
                <label htmlFor="productImage" className="word">
                  {t("Subscription Available")}
                </label>
                <label className="switch form-group">
                  <input
                    checked={setting?.subscriptionAvailable}
                    type="checkbox"
                    readOnly
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
