import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CartServices from '../../../services/CartServices';
import i18next from 'i18next';
import InfoInput from '../../../Components/InfoInput/InfoInput';
import MainTable from '../../../Components/MainTable/MainTable';
import './CartDetails.scss'

export default function CartDetails() {

  const { t } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const lang = i18next.language;

  const [cart, setCart] = useState({});
  const [cartProducts, setCartProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);

  async function getCart() {
    setLoading(true);
    try {
      const { data } = await CartServices.getCart(params.cartID);
      setLoading(false);
      setCart(data.record);
      setActive(true);
      if (data?.record?.client?.image) {
        setImage(true);
      }
      let tableData = data.record.items.map((item, index) => {
        let productName = ""
        if (localStorage.getItem("i18nextLng") === "en" && item?.product?.name?.en) {
          productName = item?.product?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !item?.product?.name?.en && item?.product?.name?.ar) {
          productName = item?.product?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && item?.product?.name?.ar) {
          productName = item?.product?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !item?.product?.name?.ar && item?.product?.name?.en) {
          productName = item?.product?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !item?.product?.name?.en && !item?.product?.name?.ar) {
          productName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !item?.product?.name?.ar && !item?.product?.name?.en) {
          productName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: item?.product?._id,
          Photo: (
            <div className="product-image-container">
              <img
                src={`${item?.product?.image?.[0]?.Location}`}
                alt="Product Image"
                className="product-image"
              />
            </div>
          ),
          "Product name": (productName).slice(0, 15),
          "Sale Price": item?.product?.price + t(" EGP"),
          "Full Price": item?.product?.originalPrice + t(" EGP"),
          Quantity: item?.quantity,
          "Total Price": item?.total + t(" EGP"),
        }
      });
      setCartProducts(tableData)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  let clientName = ""
  if (localStorage.getItem("i18nextLng") === "en" && cart?.client?.name?.en) {
    clientName = cart?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cart?.client?.name?.en && cart?.client?.name?.ar) {
    clientName = cart?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && cart?.client?.name?.ar) {
    clientName = cart?.client?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cart?.client?.name?.ar && cart?.client?.name?.en) {
    clientName = cart?.client?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cart?.client?.name?.en && !cart?.client?.name?.ar) {
    clientName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cart?.client?.name?.ar && !cart?.client?.name?.en) {
    clientName = "لا يوجد أسم"
  }

  let vendorName = ""
  if (localStorage.getItem("i18nextLng") === "en" && cart?.vendor?.name?.en) {
    vendorName = cart?.vendor?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cart?.vendor?.name?.en && cart?.vendor?.name?.ar) {
    vendorName = cart?.vendor?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && cart?.vendor?.name?.ar) {
    vendorName = cart?.vendor?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cart?.vendor?.name?.ar && cart?.vendor?.name?.en) {
    vendorName = cart?.vendor?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cart?.vendor?.name?.en && !cart?.vendor?.name?.ar) {
    vendorName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cart?.vendor?.name?.ar && !cart?.vendor?.name?.en) {
    vendorName = "لا يوجد أسم"
  }

  let branchName = ""
  if (localStorage.getItem("i18nextLng") === "en" && cart?.branch?.name?.en) {
    branchName = cart?.branch?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cart?.branch?.name?.en && cart?.branch?.name?.ar) {
    branchName = cart?.branch?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && cart?.branch?.name?.ar) {
    branchName = cart?.branch?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cart?.branch?.name?.ar && cart?.branch?.name?.en) {
    branchName = cart?.branch?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !cart?.branch?.name?.en && !cart?.branch?.name?.ar) {
    branchName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !cart?.branch?.name?.ar && !cart?.branch?.name?.en) {
    branchName = "لا يوجد أسم"
  }

  let cartDetails = [
    {
      id: "client_name",
      title: "Client name",
      type: "text",
      value: clientName,
    },
    {
      id: "vendor_name",
      title: "Vendor name",
      type: "text",
      value: vendorName,
    },
    {
      id: "branch_name",
      title: "Branch name",
      type: "text",
      value: branchName,
    },
    {
      id: "number_of_products",
      title: "Number of products",
      type: "text",
      value: cart?.items?.length
    },
    {
      id: "total_price",
      title: "Price",
      type: "text",
      value: cart?.total
    },
    {
      id: "original_total_price",
      title: "Total price",
      type: "text",
      value: cart?.originalTotal
    },
    {
      id: "points",
      title: "Total points",
      type: "text",
      value: cart?.points
    },
    {
      id: "vip_points",
      title: "VIP points",
      type: "text",
      value: (cart?.usedPoints?.systemPoints) ? cart?.usedPoints?.systemPoints : "No used points"
    },
    {
      id: "vendorPoints",
      title: "Vendor points",
      type: "text",
      value: (cart?.usedPoints?.vendorPoints) ? cart?.usedPoints?.vendorPoints : "No used points"
    },
    {
      id: "pointsValue",
      title: "Point value",
      type: "text",
      value: (cart?.usedPoints?.pointsValue) ? cart?.usedPoints?.pointsValue : "No used points"
    },
    {
      id: "discountPoints",
      title: "Discount points",
      type: "text",
      value: (cart?.usedPoints?.discount) ? cart?.usedPoints?.discount : "No used points"
    },
    {
      id: "coupon_name",
      title: "Coupon name",
      type: "text",
      value: (cart?.coupon) ? (cart?.coupon?.name?.[lang]) : t("No Coupon")
    },
    {
      id: "coupon_code",
      title: "Coupon code",
      type: "text",
      value: (cart?.coupon) ? (cart?.coupon?.code) : t("No Coupon")
    },
    {
      id: "coupon_value",
      title: "Coupon value",
      type: "text",
      value: (cart?.coupon) ? (cart?.coupon?.value) : t("No Coupon")
    },
  ];

  useEffect(() => {
    getCart();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        active && (
          <div className="main-details">
            <div className="back">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => {
                  navigate(`/carts`);
                }}
              ></i>
            </div>
            <div className="client-image vendor-image-details">
              {image ? (
                <img
                  src={cart?.client?.image?.Location}
                  alt="Client Image"
                />
              ) : (
                <img
                  src={
                    "https://cdn.dribbble.com/users/108464/screenshots/5086914/artboard_1_4x.png"
                  }
                  alt="Client Image"
                />
              )}
            </div>
            <div className="client-datails">
              <div className="data">
                {cartDetails.map((detail, index) => {
                  return (
                    <InfoInput
                      key={index}
                      id={detail.id}
                      title={detail.title}
                      type={detail.type}
                      value={detail.value}
                    />
                  );
                })}
              </div>
            </div>

            <div className="branch-cards-container">
              <MainTable tableRows={cartProducts} />
            </div>
          </div>
        )
      )}
    </>
  );
}
