import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProfessionServices from "../../services/ProfessionServices";
import toastPopup from "../../helpers/toastPopup";
import i18n from "../../locales/i18n";
import MainCard from "../../Components/MainCard/MainCard";
import MainTable from "../../Components/MainTable/MainTable";
import { ReactComponent as SearchLogo } from "../../assets/VIP-ICON-SVG/SearchLogo.svg";
import { useSelector } from "react-redux";
import "./Profession.scss";

export default function Profession() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { profession: professionPermissions } = frontPermissions;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;

  const [profession, setProfession] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalProfessions, setTotalProfessions] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  async function listAllProfessions() {
    var pattern = /[\u0600-\u06FF]/;
    let result = pattern.test(searchValue);
    let lang = result ? "ar" : "en";

    try {
      const { data } = await ProfessionServices.listAllProfessions(
        lang,
        searchValue,
        currentPage
      );

      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let returnedobject = {
          Num: <div className="index">{index + 1}</div>,
          id: record?._id,
          "English Name": record?.name?.en,
          "Arabic Name": record?.name?.ar,
        };

        returnedobject = professionPermissions.canEdit
          ? {
            ...returnedobject,
            Edit: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/professions/${record?._id}/edit`);
                }}
                className="edit"
              >
                <i className="fa-solid fa-pen-to-square"></i>
              </button>
            ),
          }
          : returnedobject;

        returnedobject = professionPermissions.canDelete
          ? {
            ...returnedobject,
            Remove: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                  setCurrentUser(record);
                }}
                className="remove"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            ),
          }
          : returnedobject;

        return returnedobject;
      });

      setProfession(singleProfession => {
        if ((searchValue && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...singleProfession, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalProfessions(data?.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function deleteProfession(id) {
    setLoading(true);
    try {
      const { data } = await ProfessionServices.deleteProfession(id);
      setLoading(false);
      setModalShow(false);
      navigate(0)
      toastPopup.success(t("Deleted successfully"));
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  useEffect(() => {
    listAllProfessions();
  }, [lang, searchValue, currentPage]);

  return (
    <>
      {modalShow && (
        <div
          className={modalShow ? "overlay" : "overlay-remove-cancel"}
          id="overlay-remove"
        >
          <div className="add-admin-box">
            <h3>{t("Are you sure you want to delete ")}</h3>
            <p className="admin-name">
              {(currentUser?.name?.[lang]).toUpperCase()} {t("?")}
            </p>
            <div className="modal-buttons">
              <div
                onClick={() => setModalShow(false)}
                className="cancel-remove"
              >
                {t("Cancel")}
              </div>
              <div
                onClick={() => deleteProfession(currentUser._id)}
                className="delete"
              >
                {t("Delete")}
              </div>
            </div>
          </div>
          <div className="cancel" onClick={() => setModalShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}

      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Professions"}
            text2={"Professions"}
            total={totalProfessions}
            btnText="Add Profession"
            showBtn={professionPermissions.canCreate}
            onBtnClick={() => {
              navigate("/professions/add-profession");
            }}
          />

          <div className="form-search">
            <div className="form-input-search">
              <input
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearchValue(prev => {
                    if (prev && e.target.value === '') {
                      navigate(0)
                    }
                    return e.target.value
                  });
                }}
                className="input-search"
                type="text"
                name="search"
                id="search"
                placeholder={t("What Are You Looking For?")}
              />
              <label className="label-search">
                <SearchLogo className="SearchLogo" />
              </label>
            </div>
          </div>

          {totalProfessions === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable tableRows={profession} />
          )}
          {
            profession.length !== totalProfessions &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
