import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MainInput from '../../../../Components/MainInput';
import toastPopup from '../../../../helpers/toastPopup';
import VoucherServices from '../../../../services/VoucherServices';
import './VoucherEdit.scss'

export default function VoucherEdit() {

  let params = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [voucher, setVoucher] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    value: 0,
    quantity: 0,
    isActive: false
  })

  const [newVoucher, setNewVoucher] = useState({
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    value: 0,
    quantity: 0,
    isActive: false
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "description_en", type: "text", required: false },
    { name: "description_ar", type: "text", required: false },
    { name: "value", type: "number", required: false },
    { name: "quantity", type: "number", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {}

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = { ...finalEditiedData, [oldDataKey]: newData[oldDataKey] }
      }
    })
    return finalEditiedData
  }

  async function getVoucher() {
    setLoading(true)
    try {
      const { data } = await VoucherServices.getVoucher(params.id)
      setLoading(false);
      setVoucher({
        name_en: data?.record?.name?.en,
        name_ar: data?.record?.name?.ar,
        description_en: data?.record?.description?.ar,
        description_ar: data?.record?.description?.ar,
        value: data?.record?.value,
        quantity: data?.record?.quantity,
        isActive: data?.record?.isActive,
      })

      setNewVoucher({
        name_en: data?.record?.name?.en,
        name_ar: data?.record?.name?.ar,
        description_en: data?.record?.description?.ar,
        description_ar: data?.record?.description?.ar,
        value: data?.record?.value,
        quantity: data?.record?.quantity,
        isActive: data?.record?.isActive,
      })
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function editVoucher(e) {
    e.preventDefault()
    setLoading(true);

    let editedData = {};

    function isEmpty(value) {
      return (
        typeof value === 'string' && value.trim() === '' ||
        value === undefined ||
        value === null
      );
    }

    Object.keys(checkUpdatedFields(newVoucher, voucher)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newVoucher["name_en"], ar: newVoucher["name_ar"] }
        }
        if (isEmpty(editedData.name.en)) {
          delete editedData.name.en;
        }

        if (isEmpty(editedData.name.ar)) {
          delete editedData.name.ar;
        }
      } else if (key === "description_en" || key === "description_ar") {
        editedData = {
          ...editedData,
          description: { en: newVoucher["description_en"], ar: newVoucher["description_ar"] }
        }
        if (isEmpty(editedData.description.en)) {
          delete editedData.description.en;
        }

        if (isEmpty(editedData.description.ar)) {
          delete editedData.description.ar;
        }
      } else {
        editedData = {
          ...editedData,
          [key]: newVoucher[key]
        }
      }
    })

    try {
      const { data } = await VoucherServices.editVoucher(params.id, editedData)
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      navigate(`/vouchers/${params.id}`)
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
    }
  }

  useEffect(() => {
    getVoucher()
  }, [])

  return <>
    <div className="add-product">
      <div className="back">
        <i className="fa-solid fa-arrow-left" onClick={() => { navigate(`/vouchers/${params.id}`) }}></i>
      </div>
      <div className="header">
        <h3>{t("Edit Voucher Info")}</h3>
        {errorMessage ? (
          <div className="err">
            {t(errorMessage)}
          </div>
        ) : (
          ""
        )}
      </div>

      <form className='form-grid' onSubmit={editVoucher}>
        <div className="input-grid">
          {formData.map((formInput, index) => {
            return (
              <MainInput
                className='input-edit-admin'
                key={index}
                name={formInput.name}
                type={formInput.type}
                required={formInput.required}
                state={newVoucher}
                setState={setNewVoucher}
              />
            );
          })}
          <div className='toggler'>
            <label htmlFor="productImage" className="word">{t("Is Active")}</label>
            <label className="switch form-group">
              <input
                checked={newVoucher.isActive}
                onChange={(e) => { setNewVoucher((prev) => { return { ...prev, isActive: e.target.checked } }) }}
                type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="button-grid">
          <button className="main-button-voucher" disabled={loading}>
            {loading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              t("Submit")
            )}
          </button>
        </div>
      </form>
    </div>
  </>
}
