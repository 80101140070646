import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { socket } from '../../services/socket';
import Chat from './Chat/Chat';
import AdminServices from '../../services/AdminServices';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VendorServices from '../../services/VendorServices';
import { useTranslation } from 'react-i18next';
import VendorChat from './VendorChat/VendorChat';
import BranchChat from './BranchChat/BranchChat';
import ClientChat from './ClientChat/ClientChat';
import AgentChat from './AgentChat/AgentChat';
import './Message.scss'

export default function Message() {

  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation();

  const location = useLocation();

  const [isClicked, setIsClicked] = useState('')
  const [chatInfo, setChatInfo] = useState({})
  const [admin, setAdmin] = useState({})
  const [errorMessage, setErrorMessage] = useState("");
  const [listChats, setListChats] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [admins, setAdmins] = useState(true)
  const [vendors, setVendors] = useState(false)
  const [branches, setBranches] = useState(false)
  const [clients, setClients] = useState(false)
  const [agents, setAgents] = useState(false)

  async function getChatAdmin() {
    try {
      const { data } = await AdminServices.getChatAdmin()
      setAdmin(data.record[0])
      const adminID = data?.record[0]?._id
      socket.emit('listRooms', {
        "members": {
          "admin": adminID
        }
      });
    } catch (e) {
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function createRoomHandler(chat) {
    if (params.id && location.state) {
      const { data } = await AdminServices.getChatAdmin()
      if (data.success && data.code === 200) {
        const adminID = data?.record[0]?._id
        const members = {
          "admin": adminID,
          [location.state]: params.id
        }
        socket.emit('createRoom', { members });
        navigate(`/messages`)
      } else {
        setErrorMessage("Unexpected Error happened. Please try again later");
      }
    }
    else {
      setIsClicked(chat?._id)
      const otherChatter = Object.keys(chat?.members).filter((item) => item !== 'admin')[0];
      if (admin?._id) {
        const members = {
          "admin": admin?._id,
          [otherChatter]: chat?.members?.[otherChatter]?._id
        }
        socket.emit('createRoom', { members });
      } else {
        setErrorMessage("Unexpected Error happened. Please try again later");
      }
    }
  }

  async function getUserInfo(chat) {
    // vendor branch client agent org
    const otherChatter = Object.keys(chat?.members).filter((item) => item !== 'admin')[0];
    if (otherChatter === 'vendor') {
      navigate(`/vendors/${chat?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'branch') {
      let vendorId = ""
      try {
        const { data } = await VendorServices.getBranchData(
          chat?.members?.[otherChatter]?._id
        );
        vendorId = data?.record[0]?.vendor?._id
      } catch (e) {
        setErrorMessage("Unexpected Error happened. Please try again later");
      }
      navigate(`/vendors/${vendorId}/branchdetails/${chat?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'client') {
      navigate(`/clients/${chat?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'agent') {
      navigate(`/agents/${chat?.members?.[otherChatter]?._id}`);
    } else if (otherChatter === 'org') {
      navigate(`/organizations/${chat?.members?.[otherChatter]?._id}`);
    }
  }

  let activeChats = []
  if (filterBy === 'vendor') {
    activeChats = listChats?.filter((chat) => {
      return chat?.members?.vendor && chat?.members?.admin && chat?.lastMessage
    })
  } else if (filterBy === 'branch') {
    activeChats = listChats?.filter((chat) => {
      return chat?.members?.branch && chat?.members?.admin && chat?.lastMessage
    })
  } else if (filterBy === 'client') {
    activeChats = listChats?.filter((chat) => {
      return chat?.members?.client && chat?.members?.admin && chat?.lastMessage
    })
  } else if (filterBy === 'agent') {
    activeChats = listChats?.filter((chat) => {
      return chat?.members?.agent && chat?.members?.admin && chat?.lastMessage
    })
  } else if (filterBy === 'org') {
    activeChats = listChats?.filter((chat) => {
      return chat?.members?.org && chat?.members?.admin && chat?.lastMessage
    })
  } else {
    activeChats = listChats?.filter((chat) => {
      return chat?.lastMessage && chat?.members?.admin
    })
  }

  function toggleAdmins() {
    setAdmins(true)
    setVendors(false)
    setBranches(false)
    setClients(false)
    setAgents(false)
  }

  function toggleVendors() {
    setAdmins(false)
    setVendors(true)
    setBranches(false)
    setClients(false)
    setAgents(false)
  }

  function toggleBranches() {
    setAdmins(false)
    setVendors(false)
    setBranches(true)
    setClients(false)
    setAgents(false)
  }

  function toggleClients() {
    setAdmins(false)
    setVendors(false)
    setBranches(false)
    setClients(true)
    setAgents(false)
  }

  function toggleAgents() {
    setAdmins(false)
    setVendors(false)
    setBranches(false)
    setClients(false)
    setAgents(true)
  }

  useEffect(() => {

    getChatAdmin()
    socket.on('connection', () => {
      console.log('Connected to server');
    });

    socket.connect();

    socket.on('listRooms', (data) => {
      setListChats(data?.records)
    });

    if (params.id && location.state) {
      createRoomHandler()
    }

    socket.on('createRoom', (data) => {
      setIsClicked(data?.record?._id)
      setChatInfo(data.record)
    });

    return () => {
      socket.disconnect();
    }
  }, [])

  return <>

    <div className="user">
      {
        admins ? (
          <div className='active-btn'>
            <button
              onClick={() => { toggleAdmins() }}
              className='all-branches-btn'>
              <h2>{t("Admin")}</h2>
            </button>
          </div>
        ) : (<div className='all-branches'>
          <button
            onClick={() => { toggleAdmins() }}
            className='all-branches-btn'>
            <h2>{t("Admin")}</h2>
          </button>
        </div>)
      }
      {
        vendors ? (
          <div className='active-btn'>
            <button
              onClick={() => { toggleVendors() }}
              className='all-branches-btn'>
              <h2>{t("Vendor")}</h2>
            </button>
          </div>
        ) : (<div className='all-branches'>
          <button
            onClick={() => { toggleVendors() }}
            className='all-branches-btn'>
            <h2>{t("Vendor")}</h2>
          </button>
        </div>)
      }
      {
        branches ? (
          <div className='active-btn'>
            <button
              onClick={() => { toggleBranches() }}
              className='all-branches-btn'>
              <h2>{t("Branch")}</h2>
            </button>
          </div>
        ) : (<div className='all-branches'>
          <button
            onClick={() => { toggleBranches() }}
            className='all-branches-btn'>
            <h2>{t("Branch")}</h2>
          </button>
        </div>)
      }
      {
        clients ? (
          <div className='active-btn'>
            <button
              onClick={() => { toggleClients() }}
              className='all-branches-btn'>
              <h2>{t("Client")}</h2>
            </button>
          </div>
        ) : (<div className='all-branches'>
          <button
            onClick={() => { toggleClients() }}
            className='all-branches-btn'>
            <h2>{t("Client")}</h2>
          </button>
        </div>)
      }
      {
        agents ? (
          <div className='active-btn'>
            <button
              onClick={() => { toggleAgents() }}
              className='all-branches-btn'>
              <h2>{t("Agent")}</h2>
            </button>
          </div>
        ) : (<div className='all-branches'>
          <button
            onClick={() => { toggleAgents() }}
            className='all-branches-btn'>
            <h2>{t("Agent")}</h2>
          </button>
        </div>)
      }
    </div>

    {admins ? (
      <div className="branch-cards-container">
        <div className="form-search">
          <div className="filter-by-container">
            <div className="filter-by">
              <select
                onChange={(e) => {
                  setFilterBy(e.target.value);
                }}
                className="search-by-input"
                id="searchBy"
                name="searchBy"
                title="searchBy"
              >
                <option value="">
                  {t("All")}
                </option>
                <option value={"vendor"}>{t("Vendor")}</option>
                <option value={"branch"}>{t("Branch")}</option>
                <option value={"client"}>{t("Client")}</option>
                <option value={"agent"}>{t("Agent")}</option>
                <option value={"org"}>{t("Organization")}</option>
              </select>
            </div>
          </div>
        </div>

        <div className="main-chat">
          <div className="active-chats">
            {activeChats?.map((chat) => {
              const otherChatter = Object.keys(chat?.members).filter((item) => item !== 'admin')[0];
              if (chat?.members?.[otherChatter]) {
                return (
                  <div className={chat?._id === isClicked ? 'avaliable-chat' : 'unavaliable-chat'}
                    key={chat?._id} onClick={() => { createRoomHandler(chat) }}>
                    <div className="image">
                      {chat?.members?.[otherChatter]?.image ?
                        <img
                          src={chat?.members?.[otherChatter]?.image?.Location}
                          className='chat-image'
                          alt="Chat Image"
                          onClick={(e) => { e.stopPropagation(); getUserInfo(chat) }} />
                        :
                        <FontAwesomeIcon
                          icon={faUserTie}
                          size="xl"
                          className="text-slate-800 icon-avatar"
                          onClick={(e) => { e.stopPropagation(); getUserInfo(chat) }}
                        />}

                    </div>
                    <div className="data">
                      <div className="name">
                        <p>
                          {localStorage.getItem("i18nextLng") === "en" && chat?.members?.[otherChatter]?.name?.en ?
                            chat?.members?.[otherChatter]?.name?.en?.slice(0, 30)
                            : localStorage.getItem("i18nextLng") === "en" && !chat?.members?.[otherChatter]?.name?.en && chat?.members?.[otherChatter]?.name?.ar ?
                              chat?.members?.[otherChatter]?.name?.ar?.slice(0, 30)
                              : localStorage.getItem("i18nextLng") === "ar" && chat?.members?.[otherChatter]?.name?.ar ?
                                chat?.members?.[otherChatter]?.name?.ar?.slice(0, 30)
                                : localStorage.getItem("i18nextLng") === "ar" && !chat?.members?.[otherChatter]?.name?.ar && chat?.members?.[otherChatter]?.name?.en ?
                                  chat?.members?.[otherChatter]?.name?.en?.slice(0, 30)
                                  : localStorage.getItem("i18nextLng") === "en" && !chat?.members?.[otherChatter]?.name?.en && !chat?.members?.[otherChatter]?.name?.ar ?
                                    "No name"
                                    : localStorage.getItem("i18nextLng") === "ar" && !chat?.members?.[otherChatter]?.name?.ar && !chat?.members?.[otherChatter]?.name?.en ?
                                      "لا يوجد أسم" : ""
                          }
                        </p>
                        <div className="last-message">
                          <p><i className="fa-solid fa-paper-plane"></i> {chat?.lastMessage?.text?.slice(0, 20)}</p>
                        </div>
                        <div className="last-updated">
                          <p><i className="fa-solid fa-clock"></i> {new Date(chat?.lastUpdated)?.toLocaleTimeString()}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}

          </div>
          <div className="chat">
            <Chat ChatId={isClicked} chatInfo={chatInfo} />
          </div>

        </div>
      </div>
    ) : (
      ""
    )}

    {vendors ? (
      <div className="branch-cards-container">
        <VendorChat />
      </div>
    ) : (
      ""
    )}

    {branches ? (
      <div className="branch-cards-container">
        <BranchChat />
      </div>
    ) : (
      ""
    )}

    {clients ? (
      <div className="branch-cards-container">
        <ClientChat />
      </div>
    ) : (
      ""
    )}

    {agents ? (
      <div className="branch-cards-container">
        <AgentChat />
      </div>
    ) : (
      ""
    )}
  </>
}
