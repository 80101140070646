import React, { useState } from "react";
import GrandPermissionsTableHead from "./GrandPermissionsTableHead/GrandPermissionsTableHead";
import GrandPermissionsTableRow from "./GrandPermissionsTableRow/GrandPermissionsTableRow";
import { getMappeedPermissions } from "../../helpers/permissions";

export default function GrandPermissionsTable({
  permissionsObject = {},
  setPermissionsObject = () => { },
  viewOnly = false,
}) {
  const tableRows = permissionsObject ? permissionsObject : [];

  getMappeedPermissions(permissionsObject);

  return (
    <div className="mainTable">
      <table cellSpacing="0">
        <GrandPermissionsTableHead
          permissionsObject={permissionsObject}
          setPermissionsObject={setPermissionsObject}
          viewOnly={viewOnly}
        />
        <tbody>
          {tableRows &&
            Object.keys(tableRows).map((tableRowKey, i) => {
              return (
                <GrandPermissionsTableRow
                  viewOnly={viewOnly}
                  key={i}
                  tableRowKey={tableRowKey}
                  permissionsObject={permissionsObject}
                  setPermissionsObject={setPermissionsObject}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
