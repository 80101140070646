import Axios from "./Axios";

let ServiceServices = {
  getService: async (id) => {
    const response = await Axios.get(`/admin/service/get?_id=${id}`);
    return response;
  },

  deleteService: async (id) => {
    const response = await Axios.delete(`/admin/service/remove?_id=${id}`);
    return response;
  },

  listAllServices: async (lang = "", search = "", categoryId = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/service/list?${search.length > 0 ? `&serviceName.${lang}=${search}` : ""}
      ${categoryId ? `&category._id=${categoryId}` : ""}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },
};

export default ServiceServices;
