import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InfoInput from "../../../Components/InfoInput/InfoInput";
import MainTable from "../../../Components/MainTable/MainTable";
import VoucherServices from "../../../services/VoucherServices";
import "./VoucherDetails.scss";

export default function VoucherDetails() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { coupon: couponPermissions } = frontPermissions;
  let { client: clientPermissions } = frontPermissions;

  let params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [voucher, setVoucher] = useState({});
  const [clients, setClients] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getVoucher() {
    setLoading(true);
    try {
      const { data } = await VoucherServices.getVoucher(params.id);
      setLoading(false);
      setVoucher(data.record);
      setActive(true);
      let tableData = data?.record?.clients?.map((record, index) => {
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?._id?._id,
          "English Name": record?._id?.name?.en,
          "Arabic Name": record?._id?.name?.ar,
        };
      });
      setClients(tableData);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleClientRowClick(id) {
    navigate(`/clients/${id}`);
  }

  let clientName = ""
  if (localStorage.getItem("i18nextLng") === "en" && voucher?.name?.en) {
    clientName = voucher?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !voucher?.name?.en && voucher?.name?.ar) {
    clientName = voucher?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && voucher?.name?.ar) {
    clientName = voucher?.name?.ar
  } else if (localStorage.getItem("i18nextLng") === "ar" && !voucher?.name?.ar && voucher?.name?.en) {
    clientName = voucher?.name?.en
  } else if (localStorage.getItem("i18nextLng") === "en" && !voucher?.name?.en && !voucher?.name?.ar) {
    clientName = "No name"
  } else if (localStorage.getItem("i18nextLng") === "ar" && !voucher?.name?.ar && !voucher?.name?.en) {
    clientName = "لا يوجد أسم"
  }

  let voucherDetails = [
    {
      id: "name",
      title: "Name",
      type: "text",
      value: clientName,
    },
    {
      id: "english_description",
      title: "English Description",
      type: "text",
      value: voucher?.description?.en,
    },
    {
      id: "arabic_description",
      title: "Arabic Description",
      type: "text",
      value: voucher?.description?.ar,
    },
    { id: "code", title: "Code", type: "text", value: voucher?.code },
    { id: "value", title: "Value", type: "text", value: voucher?.value },
    {
      id: "quantity",
      title: "Quantity",
      type: "text",
      value: voucher?.quantity,
    },
    {
      id: "used",
      title: "Used",
      type: "text",
      value: voucher?.clients?.length,
    },
  ];

  useEffect(() => {
    getVoucher();
  }, []);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        ""
      )}
      {active && (
        <div className="main-details">
          <div className="back">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                navigate("/vouchers");
              }}
            ></i>
          </div>
          <div className="client-image vendor-image-details">
            <img
              src={
                "https://previews.123rf.com/images/dzm1try/dzm1try2011/dzm1try201100272/159903723-cash-voucher-outline-icon-clipart-image-isolated-on-white-background.jpg"
              }
              alt="Voucher Image"
            />
          </div>
          <div className="client-datails">
            {couponPermissions.canEdit && (
              <div className="edit">
                <button
                  onClick={() => navigate(`/vouchers/${voucher._id}/edit`)}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </div>
            )}

            <div className="data">
              {voucherDetails.map((detail, index) => {
                return (
                  <InfoInput
                    key={index}
                    id={detail.id}
                    title={detail.title}
                    type={detail.type}
                    value={detail.value}
                  />
                );
              })}

              <div className="toggler">
                <label htmlFor="productImage" className="word">
                  {t("Active")}
                </label>
                <label className="switch form-group">
                  <input checked={voucher?.isActive} type="checkbox" readOnly />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          {voucher?.clients?.length && clientPermissions.canView ? (
            <div className="branch-cards-container">
              <MainTable
                onRowClick={handleClientRowClick}
                tableRows={clients}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
