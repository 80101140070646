import Axios from "./Axios";

let ClientServices = {
  addClient: async (obj) => {
    const response = await Axios.post(`/admin/client/create`, obj);
    return response;
  },

  getClient: async (id) => {
    const response = await Axios.get(`/admin/client/get?_id=${id}`);
    return response;
  },

  uploadClientImage: async (id, obj) => {
    const response = await Axios.post(`/admin/client/image?_id=${id}`, obj);
    return response;
  },

  deleteClientImage: async (id) => {
    const response = await Axios.delete(`/admin/client/image?_id=${id}`);
    return response;
  },

  editClient: async (id, obj) => {
    const response = await Axios.put(`/admin/client/update?_id=${id}`, obj);
    return response;
  },

  changePassword: async (id, obj) => {
    const response = await Axios.put(`/admin/client/resetPassword?_id=${id}`, obj);
    return response;
  },

  deleteClient: async (id) => {
    const response = await Axios.delete(`/admin/client/remove?_id=${id}`);
    return response;
  },

  listAllActiveClients: async (
    lang = "",
    search = "",
    searchBy = "name",
    page,
    limit = 25,
    isSorted,
    sortLang
  ) => {
    "name";
    "email";
    "barcode";
    const response = await Axios.get(
      `/admin/client/list?${search.length > 0
        ? searchBy === "name"
          ? `&name.${lang}=${search}`
          : searchBy === "email"
            ? `&email=${search}`
            : searchBy === "barcode"
              ? `&barcode=${search}`
              : searchBy === "phone"
                ? `&phone=${search}`
                : ""
        : ""
      }&isActive=true${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listAllInactiveClients: async (
    lang = "",
    search = "",
    searchBy = "name",
    page,
    limit = 25,
    isSorted,
    sortLang
  ) => {
    "name";
    "email";
    "barcode";
    const response = await Axios.get(
      `/admin/client/list?${search.length > 0
        ? searchBy === "name"
          ? `&name.${lang}=${search}`
          : searchBy === "email"
            ? `&email=${search}`
            : searchBy === "barcode"
              ? `&barcode=${search}`
              : searchBy === "phone"
                ? `&phone=${search}`
                : ""
        : ""
      }&isActive=false${page ? `&page=${page}` : ""}&limit=${limit}${isSorted.length > 0 ? `&alpha=${sortLang}` : ""}`
    );
    return response;
  },

  listAllAgentClients: async (agentID, lang, search = "", page, limit = 25) => {
    const response = await Axios.get(
      `/admin/client/list?name.${lang}=${search}&agent=${agentID}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  listAllOrganizationClients: async (
    organizationID,
    page,
    limit = 25,
    lang,
    search = "",
  ) => {
    const response = await Axios.get(
      `/admin/client/list?name.${lang}=${search}&org=${organizationID}${page ? `&page=${page}` : ""}&limit=${limit}`
    );
    return response;
  },

  listAllOrganizationClientsWithoutPagination: async (organizationID,) => {
    const response = await Axios.get(
      `/admin/client/list?org=${organizationID}`
    );
    return response;
  },

  searchClientByEmail: async (search) => {
    const response = await Axios.get(`/admin/client/list?email=${search}`);
    return response;
  },

  searchClientByBarcode: async (search) => {
    const response = await Axios.get(`/admin/client/list?barcode=${search}`);
    return response;
  },

  getClientPoints: async (id) => {
    const response = await Axios.get(`/admin/points/list?client=${id}`);
    return response;
  },

  addClientPoints: async (id, obj) => {
    const response = await Axios.put(
      `/admin/points/updateSystemPoints?client=${id}&isAdd=true`,
      obj
    );
    return response;
  },

  deleteClientPoints: async (id, obj) => {
    const response = await Axios.put(
      `/admin/points/updateSystemPoints?client=${id}&isAdd=false`,
      obj
    );
    return response;
  },

  getClientWishlist: async (id) => {
    const response = await Axios.get(`/admin/wishlist/get?client=${id}`);
    return response;
  },

  getClientCart: async (id) => {
    const response = await Axios.get(`/admin/cart/get?client=${id}`);
    return response;
  },
  getClientReviews: async (id) => {
    const response = await Axios.get(`/admin/review/list?client=${id}`);
    return response;
  },

  deleteClientReview: async (id) => {
    const response = await Axios.delete(`/admin/review/remove?_id=${id}`);
    return response;
  },

  getClientOrders: async (id) => {
    const response = await Axios.get(`/admin/order/list?client=${id}`);
    return response;
  },

  getClientOrderRequests: async (id) => {
    const response = await Axios.get(`/admin/orderRequest/list?client=${id}`);
    return response;
  },

  getOrderDetails: async (id) => {
    const response = await Axios.get(`/admin/order/get?_id=${id}`);
    return response;
  },

  listAllProfessions: async () => {
    const response = await Axios.get(`/admin/profession/list`);
    return response;
  },

  listAllInterests: async () => {
    const response = await Axios.get(`/admin/interest/list`);
    return response;
  },

  countClients: async (city, gender, ageFrom, ageTo) => {
    const response = await Axios.get(`/admin/client/count?${city ? `&city=${city}` : ""
      }${gender ? `&gender=${gender}` : ""}${ageFrom ? `&ageFrom=${ageFrom}` : ""
      }${ageTo ? `&ageTo=${ageTo}` : ""}`);
    return response;
  },
};

export default ClientServices;
