import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PointServices from '../../services/PointServices';
import MainCard from '../../Components/MainCard/MainCard';
import MainTable from '../../Components/MainTable/MainTable';
import './Point.scss'

export default function Point() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPoints, setTotalPoints] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  async function getAllPoints() {
    try {
      const { data } = await PointServices.listAllPoints(currentPage);
      setLoading(false);
      let tableData = data.records.map((record, index) => {
        let clientName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.client?.name?.ar) {
          clientName = record?.client?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && record?.client?.name?.en) {
          clientName = record?.client?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.client?.name?.en && !record?.client?.name?.ar) {
          clientName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.client?.name?.ar && !record?.client?.name?.en) {
          clientName = "لا يوجد أسم"
        }
        return {
          Num: <div className="index">{index + 1}</div>,
          id: record?.client?._id,
          "Client name": clientName,
          "VIP points": record?.vipPoints,
          "Total points": record?.totalPoints,
        }
      });

      setPoints(point => {
        return [...point, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalPoints(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handlePointRowClick(id) {
    navigate(`/points/${id}`);
  }

  useEffect(() => {
    getAllPoints();
  }, [currentPage]);

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}
      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <MainCard
            text1={"Total Points"}
            text2={"Points"}
            total={totalPoints}
          />

          {totalPoints === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable onRowClick={handlePointRowClick} tableRows={points} />
          )}
          {
            points.length !== totalPoints &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
