import { createSlice } from "@reduxjs/toolkit";
let initialFrontPermissions = {
  admin: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
    canChangePassword: true,
  },

  vendor: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
    canSort: true,
    canChangePassword: true,
  },

  branch: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
    canChangePassword: true,
  },

  cashier: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
    canChangePassword: true,
  },

  product: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canSort: true,
    canDelete: true,
  },

  client: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
    canChangePassword: true,
  },

  order: {
    // canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  orderRequest: { canView: true },

  wishlist: {
    // canCreate: true,
    canView: true,
    // canEdit: true,
    // canDelete: true,
  },

  cart: {
    // canCreate: true,
    canView: true,
    // canEdit: true,
    // canDelete: true,
  },

  points: {
    // canCreate: true,
    canView: true,
    canEdit: true,
    // canDelete: true,
  },

  review: {
    // canCreate: true,
    canView: true,
    // canEdit: true,
    canDelete: true,
  },

  category: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canSort: true,
    canDelete: true,
  },

  agent: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
    canChangePassword: true,
  },

  org: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
    canChangePassword: true,
  },

  coupon: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  join: {
    // canCreate: true,
    canView: true,
    // canEdit: true,
    canDelete: true,
  },

  job: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  post: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  service: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  ad: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  banner: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  notification: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  page: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  interest: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  profession: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  barcode: {
    canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  subscription: {
    // canCreate: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },

  chat: {
    canView: true,
  },

  setting: { canView: true, canEdit: true },
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    AdminToken: localStorage.getItem("AdminToken"),
    frontPermissions: JSON.parse(localStorage.getItem("frontPermissions")),
    role: localStorage.getItem("role"),
  },
  reducers: {
    login(state, action) {
      localStorage.setItem("AdminToken", action.payload.AdminToken);
      localStorage.setItem("role", action.payload.role);

      action.payload.role === "superAdmin"
        ? localStorage.setItem(
            "frontPermissions",
            JSON.stringify(initialFrontPermissions)
          )
        : localStorage.setItem(
            "frontPermissions",
            JSON.stringify(action.payload.frontPermissions)
          );

      state.AdminToken = localStorage.getItem("AdminToken");
      state.role = localStorage.getItem("role");
      state.frontPermissions = JSON.parse(
        localStorage.getItem("frontPermissions")
      );
    },

    logout(state) {
      localStorage.removeItem("AdminToken");
      localStorage.removeItem("frontPermissions");
      localStorage.removeItem("role");
      state.AdminToken = localStorage.getItem("AdminToken");
      state.frontPermissions = localStorage.getItem("frontPermissions");
      state.role = localStorage.getItem("role");
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
