import React from 'react'
import TableHead from "./TableHead/TableHead";
import TableRow from "./TableRow/TableRow";
import './MainTable.scss'

export default function MainTable({
  tableRows = [],
  onRowClick = () => { }
}) {

  return <>
    <div className="mainTable">
      <table cellSpacing="0">
        <TableHead tableRows={tableRows} />
        <tbody>
          {tableRows &&
            tableRows.map((tableRow, i) => {
              return (
                <TableRow key={i} onRowClick={onRowClick} tableRow={tableRow} className="tableLine" />
              );
            })}
        </tbody>
      </table>
    </div>
  </>

}
