import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CreditRequestServices from '../../../../services/CreditRequestServices';
import MainTable from '../../../../Components/MainTable/MainTable';
import AgentServices from '../../../../services/AgentServices';
import './AcceptedCreditRequests.scss'

export default function AcceptedCreditRequests() {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [creditRequests, setCreditRequests] = useState([]);
  const [totalCreditRequests, setTotalCreditRequests] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState("");
  const [agents, setAgets] = useState([]);
  const [agent, setAget] = useState("");

  async function getAllAcceptedCreditRequests() {
    try {
      const { data } = await CreditRequestServices.listAllCreditRequestsWithAdmin(
        "accepted",
        type,
        agent,
        currentPage
      );

      setLoading(false);
      let tableData = data?.records?.map((record, index) => {

        let agentName = ""
        if (localStorage.getItem("i18nextLng") === "en" && record?.from?.name?.en) {
          agentName = record?.from?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.from?.name?.en && record?.from?.name?.ar) {
          agentName = record?.from?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && record?.from?.name?.ar) {
          agentName = record?.from?.name?.ar
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.from?.name?.ar && record?.from?.name?.en) {
          agentName = record?.from?.name?.en
        } else if (localStorage.getItem("i18nextLng") === "en" && !record?.from?.name?.en && !record?.from?.name?.ar) {
          agentName = "No name"
        } else if (localStorage.getItem("i18nextLng") === "ar" && !record?.from?.name?.ar && !record?.from?.name?.en) {
          agentName = "لا يوجد أسم"
        }

        return {
          Num: <div className="index" key={index}>{index + 1}</div >,
          id: record?._id,
          Name: agentName?.slice(0, 50),
          Credit: record?.credit,
          Type: record?.type,
          Timestamp: formatDate(new Date(record?.timestamp)),
        }
      });

      setCreditRequests(creditRequest => {
        if ((type && currentPage === 1) || (agent && currentPage === 1)) {
          return [...tableData].map((item, index) => {
            return {
              ...item, Num: index + 1
            }
          })
        }
        return [...creditRequest, ...tableData].map((item, index) => {
          return {
            ...item, Num: index + 1
          }
        })
      })
      setTotalCreditRequests(data.counts);
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  async function getAllAgents() {
    try {
      const { data } = await AgentServices.listAllAgents(localStorage.getItem("i18nextLng"));
      setAgets(data?.records)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  function handleCreditRequestRowClick(id) {
    navigate(`/agents/credit-requests/accepted/${id}`);
  }

  function formatDate(date) {
    const day = String(date?.getDate())?.padStart(2, '0');
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    getAllAcceptedCreditRequests()
  }, [type, agent, currentPage])

  useEffect(() => {
    getAllAgents()
  }, [])

  return (
    <>
      {errorMessage ? <div className="err">{errorMessage}</div> : ""}

      {loading ? (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      ) : (
        <div>
          <div className="form-search">
            <div className="filter-by-container">
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setType(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="">
                    {t("Type")}
                  </option>
                  <option value={"request"}>{t("Request")}</option>
                  <option value={"return"}>{t("Return")}</option>
                </select>
              </div>
              <div className="filter-by">
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setAget(e.target.value);
                  }}
                  className="search-by-input"
                  id="searchBy"
                  name="searchBy"
                  title="searchBy"
                >
                  <option value="">
                    {t("Agent")}
                  </option>
                  {agents?.map((singleAgent) => {

                    let agentName = ""
                    if (localStorage.getItem("i18nextLng") === "en" && singleAgent.name?.en) {
                      agentName = singleAgent.name?.en
                    } else if (localStorage.getItem("i18nextLng") === "en" && !singleAgent.name?.en && singleAgent.name?.ar) {
                      agentName = singleAgent.name?.ar
                    } else if (localStorage.getItem("i18nextLng") === "ar" && singleAgent.name?.ar) {
                      agentName = singleAgent.name?.ar
                    } else if (localStorage.getItem("i18nextLng") === "ar" && !singleAgent.name?.ar && singleAgent.name?.en) {
                      agentName = singleAgent.name?.en
                    } else if (localStorage.getItem("i18nextLng") === "en" && !singleAgent.name?.en && !singleAgent.name?.ar) {
                      agentName = "No name"
                    } else if (localStorage.getItem("i18nextLng") === "ar" && !singleAgent.name?.ar && !singleAgent.name?.en) {
                      agentName = "لا يوجد أسم"
                    }

                    return <option key={singleAgent?._id} value={singleAgent?._id}>{agentName}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          {creditRequests.length === 0 ? (
            <p className="no-results">{t("No results found!")}</p>
          ) : (
            <MainTable
              onRowClick={handleCreditRequestRowClick}
              tableRows={creditRequests} />
          )}
          {
            creditRequests.length !== totalCreditRequests &&
            <div className="load">
              <button onClick={() => { setCurrentPage((prev) => prev + 1) }}>
                {t("Load more...")}
              </button>
            </div>
          }
        </div>
      )}
    </>
  );
}
