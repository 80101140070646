import './App.css';
import Login from './pages/Login/Login';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { checkFixLang } from './helpers/lang';
import i18n from './locales/i18n';
import { useSelector } from 'react-redux';
import ProtectedRoute from './routes/ProtectedRoute/ProtectedRoute';
function App() {

  const lang = i18n.language
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    checkFixLang(lang)
  }, [lang])

  return <>

    <Routes>
      <Route path="/*" element={<ProtectedRoute />} />

      <Route
        path="/login"
        element={auth.AdminToken ? <Navigate to="/" /> : <Login />}
      />
    </Routes>
  </>
}

export default App;
