import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AdminServices from "../../../../services/AdminServices";
import toastPopup from "../../../../helpers/toastPopup";
import MainInput from "../../../../Components/MainInput";
import { useSelector } from "react-redux";
import GrandPermissionsTable from "../../../../Components/GrandPermissionsTable/GrandPermissionsTable";
import { getMappeedPermissions } from "../../../../helpers/permissions";
import { socket } from "../../../../services/socket";
import "./AdminEdit.scss";

export default function AdminEdit() {
  let auth = useSelector((state) => state.auth);

  let frontPermissions = auth.frontPermissions;

  let { admin: adminPermissions } = frontPermissions;

  let params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [adminRole, setAdminRole] = useState('')

  const [admin, setAdmin] = useState({
    name_en: "",
    name_ar: "",
    email: ""
  });
  const [newAdmin, setNewAdmin] = useState({
    name_en: "",
    name_ar: "",
    email: ""
  });

  const [newPassword, setNewPassword] = useState({
    email: "",
    new_password: "",
  });

  const [permissionsObject, setPermissionsObject] = useState({
    admin: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    vendor: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canSort: false,
      canChangePassword: false,
    },

    branch: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    cashier: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    product: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canSort: false,
      canDelete: false,
    },

    client: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    order: {
      // canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    orderRequest: { canView: false },

    wishlist: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      // canDelete: false,
    },

    cart: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      // canDelete: false,
    },

    points: {
      // canCreate: false,
      canView: false,
      canEdit: false,
      // canDelete: false,
    },

    review: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      canDelete: false,
    },

    category: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canSort: false,
      canDelete: false,
    },

    agent: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    org: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
      canChangePassword: false,
    },

    coupon: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    join: {
      // canCreate: false,
      canView: false,
      // canEdit: false,
      canDelete: false,
    },

    job: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    post: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    service: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    ad: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    banner: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    notification: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    page: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    interest: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    profession: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    barcode: {
      canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    subscription: {
      // canCreate: false,
      canView: false,
      canEdit: false,
      canDelete: false,
    },

    chat: {
      canView: false,
    },

    setting: { canView: false, canEdit: false },
  });

  let formData = [
    { name: "name_en", type: "text", required: false },
    { name: "name_ar", type: "text", required: false },
    { name: "email", type: "email", required: false },
  ];

  let newPasswordFormData = [
    { name: "email", type: "email", required: false, disabled: true },
    { name: "new_password", type: "password", required: false },
  ];

  function checkUpdatedFields(newData, oldData) {
    let finalEditiedData = {};

    Object.keys(oldData).forEach((oldDataKey) => {
      if (oldData[oldDataKey] !== newData[oldDataKey]) {
        finalEditiedData = {
          ...finalEditiedData,
          [oldDataKey]: newData[oldDataKey],
        };
      }
    });
    return finalEditiedData;
  }

  async function getAdmin() {
    setLoading(true);
    try {
      const { data } = await AdminServices.getAdmin(params.id);
      setLoading(false);

      setAdmin({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        email: data?.record[0]?.email
      });

      setNewAdmin({
        name_en: data?.record[0]?.name?.en,
        name_ar: data?.record[0]?.name?.ar,
        email: data?.record[0]?.email
      });

      setNewPassword({
        email: data?.record[0]?.email,
        new_password: "",
      });

      setUploadImage(data?.record[0]?.image?.Location);
      setPermissionsObject(data?.record[0]?.frontPermissions)
      setAdminRole(data?.record[0]?.role)
    } catch (e) {
      setLoading(false);
      setErrorMessage("Unexpected Error happened. Please try again later");
    }
  }

  /**
   * e.target.files[0]
   * 1. uploaded image => <img src={uploadedImage}   typeof === "string"
   * 2. not uploadedImage => <img src={placeholder}
   * 3. uploadingNewImage = > < img src={uploadedNewimg} typeof === "object"
   *
   *  HTML====>>>>>>   typeof adminImage === "object" ? URL.createObjectURL(uploadedNewImg) : adminImage ? `adminImage` : placeholder
   *
   *
   *  typeof adminImage === "object" && await updateimg
   *
   * uploadedNewImg ? uploadedNewImg : oldImage ? oldImage : placeholder
   *
   *
   *
   */

  async function editAdmin(e) {
    e.preventDefault();
    setLoading(true);
    let editedData = {};

    function isEmpty(value) {
      return (
        typeof value === 'string' && value.trim() === '' ||
        value === undefined ||
        value === null
      );
    }

    Object.keys(checkUpdatedFields(newAdmin, admin)).forEach((key) => {
      if (key === "name_en" || key === "name_ar") {
        editedData = {
          ...editedData,
          name: { en: newAdmin["name_en"], ar: newAdmin["name_ar"] },
        };
        if (isEmpty(editedData.name.en)) {
          delete editedData.name.en;
        }

        if (isEmpty(editedData.name.ar)) {
          delete editedData.name.ar;
        }
      } else {
        editedData = {
          ...editedData,
          [key]: newAdmin[key],
        };
      }
    });

    try {
      const { data } = await AdminServices.editAdmin(params.id, editedData);
      socket.emit('permissions', {
        admin: params?.id,
        permissions: getMappeedPermissions(permissionsObject),
        frontPermissions: permissionsObject
      }, (res) => {
        console.log('res', res);
      });
      setLoading(false);
      toastPopup.success(t("Updated successfully"));
      var formData = new FormData();
      formData.append("image", uploadImage);
      setLoading(true);
      try {
        // typeof adminImage ==="object" && await AdminServices.uploadAdminImage(adminImage)
        const { data } =
          typeof uploadImage === "object" &&
          (await AdminServices.uploadAdminImage(params.id, formData));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
      navigate(`/admins/${params.id}`);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response);
    }
  }

  const ref = useRef();
  const imageUploader = (e) => {
    ref.current.click();
  };

  async function changePasswordHandler(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const obj = {
        email: newPassword.email,
        newPassword: newPassword.new_password,
      };
      const { data } = await AdminServices.changePassword(obj);
      setLoading(false);
      toastPopup.success(t("Password changed successfully"));
      navigate(`/admins/${params.id}`);
    } catch (e) {
      setLoading(false);
      setPasswordErrorMessage(e.response.data.error);
    }
  }

  useEffect(() => {
    getAdmin();

    socket.on('connection', () => {
      console.log('Connected to server');
    });

    socket.connect();
  }, []);

  return (
    <>
      <div className="add-admin">
        <div className="back">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => {
              navigate(`/admins/${params.id}`);
            }}
          ></i>
        </div>
        <div className="header">
          <h3>{t("Edit Admin Info")}</h3>
          {errorMessage ? <div className="err">{t(errorMessage)}</div> : ""}
        </div>
        <form className="admin-form" onSubmit={editAdmin}>
          <div className="main-image-label admin-image">
            {uploadImage && (
              <img
                src={
                  typeof uploadImage === "object"
                    ? URL.createObjectURL(uploadImage)
                    : uploadImage
                      ? uploadImage
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgeRojQCaSZCVc3qj9TW_-2URYUpSq1AjwFw&usqp=CAU"
                }
                alt="imag-viewer"
                className="uploaded-img"
                onClick={() => {
                  window.open(
                    uploadImage ? URL.createObjectURL(uploadImage) : null
                  );
                }}
              />
            )}
            <input
              className="main-input-image"
              type="file"
              name="upload-img"
              ref={ref}
              onChange={(e) => {
                setUploadImage(e.target.files[0]);
              }}
            />
            <label
              className="main-label-image"
              onClick={imageUploader}
              htmlFor="upload-img"
            >
              {t("Add Image")}
            </label>
          </div>
          <div className="input-grid">
            {formData.map((formInput, index) => {
              return (
                <MainInput
                  className="input-edit-admin"
                  key={index}
                  name={formInput.name}
                  type={formInput.type}
                  required={formInput.required}
                  state={newAdmin}
                  setState={setNewAdmin}
                />
              );
            })}

            {adminRole === "admin" &&
              <GrandPermissionsTable
                permissionsObject={permissionsObject}
                setPermissionsObject={setPermissionsObject}
              />
            }

          </div>
          <div className="button-grid">
            <button className="main-button-admin" disabled={loading}>
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                t("Submit")
              )}
            </button>
          </div>
        </form>
      </div>

      {adminPermissions.canChangePassword && (
        <div className="change-password">
          <div className="header">
            <h3>{t("Change Password")}</h3>
            {passwordErrorMessage ? (
              <div className="err">{t(passwordErrorMessage)}</div>
            ) : (
              ""
            )}
          </div>

          <form className="form-grid" onSubmit={changePasswordHandler}>
            <div className="input-grid">
              {newPasswordFormData.map((formInput, index) => {
                return (
                  <MainInput
                    className="input-edit-admin"
                    key={index}
                    name={formInput.name}
                    type={formInput.type}
                    required={formInput.required}
                    disabled={formInput.disabled}
                    state={newPassword}
                    setState={setNewPassword}
                  />
                );
              })}
            </div>
            <div className="button-grid">
              <button className="main-button-admin" disabled={loading}>
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  t("Submit")
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
